import {
  createStore
} from 'vuex'


import api from '../api/apis'
export default createStore({
  state: {
    userInfo: null,
    activePage: '', // 登入信息
    userInfoDetail: {}, //登入信息详情
    message: null, //站内信通知
    cartCount: 0, //购物车数量
    searchInfo: '', //搜索关键词
    searchImg: '', //搜索关键图
    menushowLeft:false,// 菜单栏显示
    exchangeRate: 0, //汇率
    addressInfo: {},//地址详情
    languagetype:  localStorage.getItem('language') || 'English' ,
    goodslist: [],// 提出订单的商品列表
    showLoginDia: false, // 是否显示登录弹窗
  },
  getters: {
    token: state => state.token
  },
  mutations: {
 
    //更新地址详情数据
    setAddressInfo(state, data) {
      state.addressInfo = data
    },
    /* 退出登录 */
    outUser(state) {
      state.userInfo = null
      localStorage.removeItem("token");
      state.message = null
      window.location.reload()
    },        // 中英切换
    Togglelanguagetype(state, data){
   state.languagetype = data
      localStorage.setItem('language', state.languagetype);
  },
    // 显示登录弹窗
    changeshowLoginDia(state, data) {
      state.showLoginDia = data
    },
    getactivePage(state, data) {
      state.activePage = data
    },
     /* 获取登录信息 */
     userData(state, data) {
      //  console.log(data)
        state.userInfo = data

      },
      /* 获取登录信息 */
      userInfo(state, data) {

        state.userInfo = data

      },
      // 登入信息详情
      getuserInfoDetail(state, data) {
        state.userInfoDetail = data
      },
    // 搜索关键图
    getsearchImg(state, data) {
      state.searchImg = data
    },
    // 搜索关键词
    getsearchInfo(state, data) {
      state.searchInfo = data
    },
    // 获取汇率
    getexchangeRate(state, data) {
      state.exchangeRate = data
    },
    // 汇率
    rateData(state, data) {
      state.rate = data
    },
    // 提出订单的商品列表
    savegoodslist(state, data) {
      state.goodslist = data
    }

  },
  actions: {

    // 获取用户资料
    EuropegetUserInfo({
      commit
    }) {
      api.EuropegetUserInfo().then(res => {
        //(res);
        if (res.code == 0) {
          commit('userData', res.data)
        } else {
          commit('userData', null)
        }
      })

    },
 
  },
  modules: {}
})
