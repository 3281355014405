<template>
  <!-- 进度条组件 -->
  <div class="progress">
    <div class="pagebox">
      <!-- 1 -->
      <div class="step" :class="{ activestep: props.stepArr[0] }"> <span></span></div> <van-progress color='#FF730B'
        track-color="#E2E2E2" :show-pivot="false" :percentage="Progressarr[0]" />
      <!-- 2 -->
      <div class="step" :class="{ activestep: props.stepArr[1] }"> <span></span></div><van-progress color='#FF730B'
        track-color="#E2E2E2" :show-pivot="false" :percentage="Progressarr[1]" />
      <!-- 3 -->
      <div class="step" :class="{ activestep: props.stepArr[2] }"> <span></span></div><van-progress color='#FF730B'
        track-color="#E2E2E2" :show-pivot="false" :percentage="Progressarr[2]" />
      <!-- 4 -->
      <div class="step" :class="{ activestep: props.stepArr[3] }"> <span></span></div>
    </div>
    <div class="text">
      <!-- -->
      <span :class="{ active: props.steptext[0] }">{{ $fanyi('产品付款') }} </span>
      <span :class="{ active: props.steptext[1] }">{{ $fanyi('装运到仓库') }}</span>
      <span :class="{ active: props.steptext[2] }">{{ $fanyi('配送单付款') }}</span>
      <span :class="{ active: props.steptext[3] }">{{ $fanyi('完成') }}</span>
    </div>

  </div>
</template>
<script setup>
import { getCurrentInstance, defineProps } from 'vue'
const { proxy } = getCurrentInstance()

const props = defineProps({
  // 样式高亮
  stepArr: {
    type: Array,
    required: true
  },
  // 进度条展示
  Progressarr: {
    type: Array,
    required: true
  },
  // 文字显示
  steptext: {
    type: Array,
    required: true
  },

})
console.log(proxy);

</script>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.progress {
  width: 100%;
  overflow-x: scroll;
  padding-top: 15px;
  padding-bottom: 30px;



  background: #F6F6F6;

  .pagebox {
    width: 450px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    margin-bottom: 5px;

    .step {
      width: 20px;

      height: 20px;
      background: #F6F6F6;
      border: 0.5px solid #E2E2E2;
      border-radius: 50%;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      position: relative;


      span {
        width: 10px;
        height: 10px;
        background: #E2E2E0;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

      }

      :deep().van-progress {
        width: 100px;
        height: 2px;
      }


    }
  }



  .text {
    width: 450px;
    display: flex;
    padding-left: 6px;

    span {

      text-align: center;
      font-size: 10px;
      font-family: ArialMT;
      zoom: 0.85;
      color: #222222;
      line-height: 14px;

      &:nth-child(1) {
        flex: 0 0 60px;
        margin-right: 80px;
        padding-left: 10px;
      }

      &:nth-child(2) {
        flex: 0 0 80px;
        margin-right: 50px;
      }

      &:nth-child(3) {
        flex: 0 0 100px;
        margin-right: 40px;
      }

      &:nth-child(4) {
        flex: 0 0 100px;

      }


    }


  }

  .activestep {
    width: 20px;
    height: 20px;
    background: #F6F6F6;
    border: 0.5px solid #E2E2E2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FF730B !important;

    span {
      background: #FF730B !important;
    }


  }

  .active {
    font-size: 10px;
    font-family: ArialMT;
    color: #FF730B !important;
    line-height: 14px;
  }
}
</style>