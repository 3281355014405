// 美国各个州数据

import fanyi from './language'
export default {
    provincesList:[
        fanyi('亚利桑那州'),
        fanyi('阿肯色州'),
        fanyi('加利福尼亚州'),
        fanyi('科罗拉多州'),
        fanyi('阿拉巴马州'),
        fanyi('阿拉斯加州'),
        fanyi('德拉华州'),
        fanyi('康涅狄格州'),
        fanyi('特拉华州'),
        fanyi('佛罗里达州'),
        fanyi('佐治亚州'),
        fanyi('夏威夷州'),
        fanyi('(华盛顿)哥伦比亚特区'),
        fanyi('乔治亚州'),
        fanyi('爱达荷州'),
        fanyi('伊利诺州'),
        fanyi('印地安那州'),
        fanyi('衣阿华州'),
        fanyi('堪萨斯州'),
        fanyi('肯塔基州'),
        fanyi('路易斯安那州'),
        fanyi('缅因州'),
        fanyi('马里兰州'),
        fanyi('麻萨诸塞州'),
        fanyi('密执安州'),
        fanyi('明尼苏达州'),
        fanyi('密西西比州'),
        fanyi('密苏里州'),
        fanyi('蒙大拿州'),
        fanyi('内布拉斯加州'),
        fanyi('内华达州'),
        fanyi('新罕布什尔州'),
        fanyi('新泽西州'),
        fanyi('新墨西哥州'),
        fanyi('纽约州'),
        fanyi('北卡罗来纳州'),
        fanyi('北达科他州'),
        fanyi('俄亥俄州'),
        fanyi('俄克拉荷马州'),
        fanyi('俄勒冈州'),
        fanyi('宾夕法尼亚州'),
        fanyi('罗得岛州'),
        fanyi('南卡罗来纳州'),
        fanyi('南达科他州'),
        fanyi('田纳西州'),
        fanyi('德克萨斯州'),
        fanyi('犹他州'),
        fanyi('佛蒙特州'),
        fanyi('弗吉尼亚州'),
        fanyi('华盛顿州'),
        fanyi('西弗吉尼亚州'),
        fanyi('威斯康星州'),
        fanyi('怀俄明州')
  ],
 
}

