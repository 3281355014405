<template>
  <!-- 订单摘要 -->
  <div class="ordersummary" :class="{ maxheight: putdown }">
    <div class="top-title">

      <!-- 标题 -->
      <slot name="title">

      </slot>

      <span class="order_sn">{{ $route.query.order_sn ? $route.query.order_sn : $route.query.porder_sn }}</span>


    </div>
    <!-- 商品 -->
    <div class="list">
      <div class="item" v-for=" goodsitem in goodslist" :key="goodsitem">
        <div class="Content">

          <!-- 图片 -->
          <div class="img-box" @click="$fun.toCommodityDetails({
            goods_id: goodsitem.goods_id,
            shop_type: goodsitem.shop_type,
          }
          );">
            <van-image :src="goodsitem.pic">
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </div>
          <!--右侧商品信息  -->
          <div class="rightgoodsinfo">
            <!-- 商品描述和删除 -->
            <div class="Description">
              <p class="text">{{ goodsitem.goods_title }}</p>

            </div>
            <!-- 商品规格 订单提出 -->
            <div class="Commodityspecification" v-if="showdescribe == true && porder == false">
              <p>{{ goodsitem.text }}</p>


            </div>
            <!-- 提出 价格  订单提出-->
            <div class="price" v-if="showdescribe == true && porder == false">

              <span class="dollar">
                ${{
                  $fun.EURNumSegmentation(
                    $fun.ceil(goodsitem.price * $store.state.exchangeRate)
                  )
                }}
              </span>
              <span class="RMB">
                (￥{{ $fun.RMBNumSegmentation(goodsitem.price) }})
              </span>
            </div>
            <!-- 购买数量 订单支付 -->
            <div class="num" v-if="showdescribe == false && porder == false">

              <p><span>{{ $fanyi('购买数量') }}:</span> {{ goodsitem.confirm_num }}</p>


            </div>

            <!-- 购买价格 订单支付 -->
            <div class="gomaiprice" v-if="showdescribe == false && porder == false">
              <span class="title">{{ $fanyi('价格') }}</span>
              <span class="dollar">
                ${{
                  $fun.EURNumSegmentation(
                    $fun.ceil(goodsitem.confirm_price * $store.state.exchangeRate)
                  )
                }}
              </span>
              <span class="RMB">
                (￥{{ $fun.RMBNumSegmentation(goodsitem.confirm_price) }})
              </span>
            </div>
            <!-- 配送单支付页面 提货数 -->
            <div class="num" v-if="porder == true">
              <p><span>{{ $fanyi('提货数量') }}:</span> {{ goodsitem.submitNum }}</p>


            </div>
            <!-- 总支出 -->
            <div class="gomaiprice" v-if="porder == true">
              <span class="title">{{ $fanyi('价格') }}</span>
              <span class="dollar">
                ${{
                  $fun.RMBNumSegmentation(
                    $fun.ceil(
                      goodsitem.confirm_num *
                      goodsitem.confirm_price *
                      $store.state.exchangeRate
                    )
                  )
                }}
              </span>
              <span class="RMB">
                (￥{{
                  $fun.RMBNumSegmentation(
                    $fun.ceil(goodsitem.num * goodsitem.confirm_price)
                  )
                }})
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-box">
      <div v-if="putdown == false" @click="putdown = !putdown">
        <span>Open</span>
        <img src="../../../assets/tichuorder/open.svg" alt="">
      </div>
      <div v-else @click="putdown = !putdown">
        <span>Put away</span>
        <img src="../../../assets/tichuorder/putdown.svg" alt="">
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, defineProps } from 'vue'
const { proxy } = getCurrentInstance()
defineProps({
  goodslist: {
    type: Array,
    default: () => []
  },
  // 商品描述
  showdescribe: {
    type: Boolean,
    default: true
  },
  // 配送单是否支付页面
  porder: {
    type: Boolean,
    default: false
  }

})
console.log(proxy);

const putdown = ref(false)

</script>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.ordersummary {
  position: relative;
  width: 100%;
  max-height: 187px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 0.5px solid #E2E2E2;

  transition: 0.5s;
  margin-bottom: 10px;
  overflow: hidden;
  padding-bottom: 40px;

  .top-title {
    width: 100%;
    padding: 0 20px;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #222222;
    line-height: 18px;



    .order_sn {
      font-size: 12px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
    }

    border-bottom: 0.5px solid #E2E2E2;
  }



  .item {
    width: 100%;
    padding: 0 15px;
    margin: 15px 0;
  }

  .Content {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;

    margin-bottom: 5px;

    .img-box {

      width: 80px;
      height: 80px;
      margin-right: 10px;
      border-radius: 4px;

      :deep().van-image {
        width: 80px;
        height: 80px;
        border-radius: 4px;
      }

      :deep().van-image__img {
        border-radius: 4px;
      }
    }

    .rightgoodsinfo {
      flex: 1;
      height: 100%;

      // 描述
      .Description {
        height: 32px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        align-items: center;

        .text {
          height: 32px;
          font-size: 12px;
          font-family: ArialMT;
          color: #222222;
          line-height: 16px;

          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }


      }

      .Commodityspecification {
        padding: 0 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 179px;
        height: 20px;
        background: #f6f6f6;
        border-radius: 4px;
        margin-bottom: 5px;

        p {
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          // zoom: 0.9;
          font-size: 10px;
          font-family: ArialMT;
          color: #666666;
          line-height: 22px;
          zoom: 0.9;
        }

        img {
          margin-left: 10px;
          width: 8px;
          height: 4px;
        }
      }

      .price {
        height: 18px;
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;

        .dollar,
        .RMB {
          font-size: 12px;
          font-family: ArialMT;
          color: #222222;
          line-height: 18px;
        }
      }

      // 购买数量
      .num {
        margin-bottom: 5px;

        p {
          span {
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 14px;
            margin-right: 5px;

          }

          font-size: 10px;
          font-family: Arial-BoldMT,
          Arial;
          font-weight: 600;
          color: #222222;
          line-height: 14px;
          zoom: 0.9;
        }
      }

      // 购买价格
      .gomaiprice {
        height: 18px;
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;

        .title {
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          line-height: 14px;
          zoom: 0.9;
        }

        .dollar,
        .RMB {
          font-size: 10px;
          font-family: Arial-BoldMT, Arial;
          font-weight: 600;
          color: #222222;
          zoom: 0.9;
          line-height: 14px;
        }
      }
    }
  }


  .footer-box {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 34px;
    background: #FFFFFF;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 6px 6px;
    border-top: 0.5px solid #E2E2E2;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    div {
      display: flex;
      align-items: center;
    }

    span {
      font-size: 10px;
      font-family: ArialMT;
      color: #222222;
      line-height: 14px;
    }

    img {
      margin-left: 5px;
      width: 12px;
      height: 7px;
    }

  }
}

.maxheight {
  max-height: 1000000px;

  transition: 0.5s;
}
</style>