<template>
  <!-- 配送订单信息 -->

  <div class="shippingorderInformation" :class="{ maxheight: show }">
    <div class="top-title">
      <span>{{ $fanyi('配送订单') }}</span>
      <van-icon v-if="!show" name="arrow-down" @click="show = !show" />
      <van-icon v-else name="arrow-up" @click="show = !show" />


    </div>
    <!-- 内容区域 -->
    <div class="item" v-for=" item in  props.list" :key="item.id">
      <div class="ordernum">
        {{ $fanyi('配送单号') }}: <span>{{ item.porder_sn }}</span>
      </div>
      <p class="proposed"> <span>{{ $fanyi('提出时间') }}:</span> <span>{{ item.created_at ? item.created_at : '-----'
      }}</span>
      </p>
      <p class="delivery"> <span>{{ $fanyi('发货时间') }}:</span><span>{{ item.datetime_delivery ? item.datetime_delivery
        : '-------' }}</span></p>
      <p class="tracking"><span>{{ $fanyi('物流单号') }}:</span><span>{{ item.express_no ? item.express_no : '------'
      }}</span>
      </p>
      <span class="status">{{ $fanyi(item.status_name) }}</span>
    </div>

  </div>
</template>
<script setup>
import { getCurrentInstance, ref, defineProps } from 'vue'
const { proxy } = getCurrentInstance()
const props = defineProps({
  list: {
    type: Array,
    default: () => []
  }
})
const show = ref(false)
console.log(proxy)
</script>
<style lang="scss" scoped="scoped">
@import '@/style/scss/mixin.scss';

.shippingorderInformation {
  width: 100%;
  max-height: 48px;
  overflow: hidden;
  transition: max-height 0.5s linear;
  padding: 15px;
  padding-top: 0px;

  background: #FFFFFF;
  border-radius: 6px;
  border: 0.5px solid #E2E2E2;

  .top-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    border-bottom: 1px dashed #DBDBDB;

    span {
      font-size: 12px;
      font-family: PingFang-SC-Semibold, PingFang-SC;
      font-weight: 600;
      color: #222222;
      line-height: 18px;
    }

    :deep().van-icon {
      font-size: 14px;

    }
  }

  .item {
    position: relative;
    width: 100%;
    min-height: 120px;
    border-bottom: 1px dashed #DBDBDB;
    padding: 15px 0;

    .ordernum {

      color: #222222;
      font-size: 12px;
      font-family: ArialMT;
      color: #222222;
      line-height: 18px;
      margin-bottom: 10px;

      span {
        font-size: 12px;
        font-family: ArialMT;
        color: #FF730B;
        line-height: 18px;
        text-decoration-line: underline;
      }
    }

    p {
      font-size: 10px;
      font-family: ArialMT;
      color: #666666;
      line-height: 14px;
      margin-bottom: 10px;
      zoom: 0.95;

      span {
        &:first-child {
          margin-right: 3px;
        }
      }
    }

    .tracking {
      margin-bottom: 0px;
    }

    .status {
      position: absolute;
      right: 0px;
      bottom: 15px;
      max-width: 70px;
      min-height: 34px;
      background: #E8EEFF;
      align-items: center;
      display: flex;
      border-radius: 3px;
      font-size: 10px;
      font-family: ArialMT;
      color: #608BFF;
      line-height: 14px;
      text-align: center;
      padding: 0 5px;
      zoom: 0.9;

    }
  }
}

.maxheight {
  max-height: 10000000px;

  transition: max-height 0.5s linear;
}
</style>

