// 引入封装好的Api模块接口，统一导出
import index from './apis/index'

import user from './apis/user.js'
import product from './apis/product.js'
import opublic from './apis/public'
 const api= {
    ...index,
  
    ...user,
    ...product,
    ...opublic
}

export default api 
