<template>
  <!--联系我们  -->
  <div class="contactus">
    <div class="top-title">
      <h2>{{ $fanyi('你有什么问题吗?') }}</h2>
      <!-- <div class="why"><img src="../../../assets/tichuorder/why.svg" alt="">
        <p>{{ $fanyi('为什么要预估装运？') }}</p>
      </div> -->
    </div>

    <!-- 联系方式 -->
    <a href="mailto:service@rakumart.us">
      <div class="item">

        <div class="left">

          <img src="../../../assets/tichuorder/write.svg" alt="">

          <p>{{ $fanyi('写信给我们') }}</p>
        </div>
        <div class="right"> <img src="../../../assets/tichuorder/more.svg" alt=""></div>

      </div>
    </a>
    <div class="item">

      <div class="left">
        <img src="../../../assets/tichuorder/chat.svg" alt="">

        <p>{{ $fanyi('使用聊天与我们沟通。') }}</p>
      </div>
      <div class="right"> <img src="../../../assets/tichuorder/more.svg" alt=""></div>
    </div>
    <div class="item">
      <div class="left">

        <img src="../../../assets/tichuorder/phone.svg" alt="">
        <p>{{ $fanyi('通过Whastapp给我们写信。') }}</p>
      </div>
      <div class="right"> <img src="../../../assets/tichuorder/more.svg" alt=""></div>
    </div>



  </div>
</template>
<script setup>
import { getCurrentInstance } from 'vue'
const { proxy } = getCurrentInstance()
console.log(proxy);

</script>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.contactus {
  width: 100%;
  height: 243px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 0.5px solid #E2E2E2;
  padding: 15px;
  margin-bottom: 30px;

  .top-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    h2 {
      font-size: 12px;
      font-family: PingFang-SC-Semibold, PingFang-SC;
      font-weight: 600;
      color: #222222;
      line-height: 18px;
    }

    .why {
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
        width: 10px;
        height: 10px;

      }

      font-size: 10px;
      font-family: PingFang-SC-Regular,
      PingFang-SC;
      font-weight: 400;
      color: #FF730B;
      line-height: 14px;
    }

  }

  .item {

    width: 325px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 15px;

    .left {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;

      }

      p {
        font-size: 12px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #222222;
        line-height: 14px;
      }

    }

    .right {
      display: flex;
      align-items: center;
      width: 4px;
      height: 8px;
    }


  }
}
</style>