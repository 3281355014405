<template>
  <!-- 预估产品进口明细 -->

  <van-popup z-index="1000" v-model:show="showBottom" id="importdetails" @close="close" position="bottom">
    <div class="main">
      <!-- 标题 -->
      <div class="top-box">
        <h2>{{ $fanyi('预估产品进口明细') }}</h2>
        <van-icon @click="showBottom = false" name="cross" />
      </div>

      <div class="content">


        <!-- 内容区域 -->
        <div class="details">
          <!-- 商品详情 -->

          <div class="goodsDetails">
            <div class="img-box">
              <van-image :src="goods.pic">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>

            </div>
            <div class="right-box">
              <div class="Description">
                {{ goods.goods_title }}
              </div>

              <!-- 规格 -->
              <div class="text">
                {{ goods.text }}
              </div>
              <div class="price">
                {{ $fanyi('单价') }}:
                ${{
                  $fun.EURNumSegmentation(
                    $fun.ceil(
                      goods.price * $store.state.exchangeRate
                    )
                  )
                }}


                (￥{{ $fun.RMBNumSegmentation(goods.price) }})

              </div>

              <div class="num">
                {{ $fanyi('数量') }}:
                <span>{{ goods.num }}</span>
              </div>

            </div>
          </div>
          <div class="one">
            <span>{{ $fanyi('容积约为') }}</span>
            <span>{{ datas.volume }}m³</span>
          </div>
          <p>
            <span>{{ $fanyi('产品价值') }}</span>
            <span class="right"><span>${{ datas.goods_price_us }}</span> <span>({{ datas.goods_price }}￥)</span></span>
          </p>
          <p>
            <span>{{ $fanyi('佣金') }}</span>
            <span class="right"><span>${{ datas.service_rate_price_us }} </span> <span>({{ datas.service_rate_price
            }}￥)</span></span>
          </p>
          <p>
            <span>{{ $fanyi('附加服务') }}</span>
            <span class="right"><span>${{ datas.option_price_us }} </span> <span>({{ datas.option_price }}￥)</span></span>
          </p>
          <p>
            <span>{{ $fanyi('中国物流') }}</span>
            <span class="right"><span>${{ datas.freight_us }} </span> <span>({{ datas.freight }}￥)</span></span>
          </p>
          <div class="two">
            <span>{{ $fanyi('不含运费的产品总成本') }}</span>
            <span><span style="font-size: 10px;
                                                                                            font-family: Roboto-Black, Roboto;
                                                                                            font-weight: 600;
                                                                                            color: #222222;">${{
                                                                                              datas.sum_price_us
                                                                                            }}</span>
              <span>
                ({{
                  datas.sum_price
                }}￥)</span></span>
          </div>
          <p>
            <span>{{ $fanyi('产品成本') }}</span>
            <span class="right"><span>${{ datas.cost_price_us }} </span> <span>({{ datas.cost_price }}￥)</span></span>
          </p>
          <p>
            <span>{{ $fanyi('预估国际运费') }}</span>
            <span class="right"><span>${{ datas.logistics_price_us }} </span> <span>({{ datas.logistics_price
            }}￥)</span></span>
          </p>
          <p>
            <span>{{ $fanyi('预估美国国内运费') }}</span>
            <span class="right"><span>${{ datas.foreign_express_price_us }} </span> <span>({{ datas.foreign_express_price
            }}￥)</span></span>
          </p>
          <div class="there">
            <span>{{ $fanyi('包含运费产品总成本') }}</span> <span> <span style="font-size: 10px;
                                                                                            font-family: Roboto-Black, Roboto;
                                                                                            font-weight: 600;
                                                                                            color: #222222;">${{
                                                                                              datas.total_product_us
                                                                                            }}
              </span><span>({{
                datas.total_product
              }}￥)</span></span>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose } from 'vue'
const { proxy } = getCurrentInstance()
const showBottom = ref(false)
const datas = ref({})// 详情数据
const goods = ref('')//商品数据



console.log(proxy);
const open = async (item, id, wuliuid) => {
  goods.value = item


  showBottom.value = true
  const res = await proxy.$api.orderOneGoodsFee({
    detail_id: id,
    offer_id: wuliuid
  })
  if (res.code != 0) {
    return
  }
  datas.value = res.data
}

// 弹窗关闭
const close = () => {

}
defineExpose({
  open
})
</script>
<style lang="scss">
#importdetails {
  min-height: 70%;

  max-height: 88%;

  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;



  .top-box {
    position: sticky;
    top: 0px;
    z-index: 2000;
    width: 100%;
    height: 44px;
    background: #F6F6F6;
    border-radius: 8px 8px 0px 0px;

    display: flex;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    align-items: center;

    .van-icon-cross {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      color: #222222;
      font-weight: 600;
      font-size: 16px;

      // top: 14px;

    }
  }

}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";


.main {
  width: 100%;
  height: 100%;

  .content {
    padding: 20px 10px;

  }

  .details {
    padding: 20px 15px;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;


    .goodsDetails {
      display: flex;
      padding-bottom: 20px;

      .img-box {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        margin-right: 10px;

        :deep().van-image {
          width: 80px;
          height: 80px;
          border-radius: 4px;
        }

        :deep().van-image__img {
          width: 80px;
          height: 80px;
          border-radius: 4px;
        }
      }

      .right-box {
        .Description {
          width: 200px;
          height: 32px;
          font-size: 12px;
          font-family: ArialMT;
          color: #222222;
          line-height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 5px;
        }

        .text {
          width: 200px;
          height: 20px;
          background: #F6F6F6;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 10px;
          font-family: ArialMT;
          color: #666666;
          line-height: 20px;
          align-items: center;

          border-radius: 4px;
          padding: 0 5px;
          margin-bottom: 5px;
        }

        .price {
          font-size: 12px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #222222;
          line-height: 18px;
          margin-bottom: 5px;
        }

        .num {
          font-size: 12px;
          font-family: ArialMT;
          color: #222222;
          line-height: 18px;
        }


      }

    }

    .one {
      width: 100%;
      height: 40px;
      background: #F2F2F2;
      border-radius: 4px;
      border: 0.5px solid #E2E2E2;
      border: 0.5px solid #e2e2e2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-family: ArialMT;
      color: #222222;
      line-height: 20px;
      padding: 0 10px;

    }

    .two {
      width: 100%;


      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      background: #E9F8FB;
      border-radius: 4px;
      border: 1px solid #49C9E0;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      padding: 0 10px;
      color: #222222;
      font-size: 12px;
      font-family: ArialMT;
      color: #222222;
      line-height: 20px;

      // span {
      //   text-align: right;
      // }
      span {

        // &:first-child {}

        &:last-child {
          text-align: right;
        }

      }
    }

    .there {
      width: 100%;


      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      background: #E4FBF4;
      border-radius: 4px;
      border: 1px solid #1BA37B;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      padding: 0 10px;
      color: #222222;
      font-size: 12px;
      font-family: ArialMT;
      color: #222222;
      line-height: 20px;

      span {

        // &:first-child {}

        &:last-child {
          text-align: right;
          margin-left: 10px;
        }

      }
    }

    p {
      width: 100%;
      padding: 0 10px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        max-width: 230px;

        &:first-child {
          font-size: 10px;
          font-family: ArialMT;
          color: #666666;
          line-height: 14px;
        }
      }

      .right {
        display: flex;
        align-items: center;

        span {
          &:first-child {
            font-size: 10px;
            font-family: Arial-BoldMT, Arial;
            font-weight: 600;
            color: #222222;
            line-height: 14px;

          }

          &:last-child {
            font-size: 10px;
            font-family: ArialMT;
            color: #222222;
            line-height: 14px;

          }
        }
      }
    }

  }




}
</style>