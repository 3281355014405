<template>
  <!-- 菜单弹出层 -->

  <div class="menu">
    <div class="userinfo">
      <div class="left">
        <div class="img-box">

          <van-image v-if="$store.state.userInfo?.head_img" :src="$store.state.userInfo?.head_img">
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
          <img v-else src="../../assets/tabber/3.svg" alt="">
        </div>
        <div class="text">
          <p v-if="$store.state.userInfo">{{ $fanyi('个人中心') }}</p>
          <div v-else><span @click="$fun.toPage('/login')">{{ $fanyi('登录') }}</span><i>/</i> <span
              @click="$fun.toPage('/register')">{{ $fanyi('注册')
              }}</span>
          </div>


        </div>


      </div>
      <div v-if="$store.state.userInfo != null" class="logout" @click="Logout">{{ $fanyi('退出登录') }}</div>

    </div>

    <!-- 分类 -->
    <div class="classification">
      <div class="item" @click="$fun.toPage('/')">{{ $fanyi('首页') }}</div>
      <div class="item" @click="$fun.toPage('/about-us')">{{ $fanyi('我们是谁') }}</div>
      <div class="item" @click="$fun.toPage('/user-guide')"> {{ $fanyi(' 用户指南') }}</div>
      <div class="item" @click="$fun.toPage('/frequently-asked-questions')">{{ $fanyi('常见问题') }}</div>
      <div class="item" @click="$fun.toPage('/commission')">{{ $fanyi('手续费') }}</div>
      <div class="item" @click="$fun.toPage('/international-freight')">{{ $fanyi('国际物流') }}</div>
      <div class="item" @click="$fun.toPage('/video-maintenance')">{{ $fanyi('视频维护') }}</div>
      <!-- 中英切换 -->
      <div class="item" @click="showTop = true">{{ $fanyi('中英切换') }}</div>

    </div>
    <div class="bottomboder"></div>
    <div class="footerclassification">
      <div class="item" @click="$fun.toPage('/terms-of-service')">{{ $fanyi('服务条款') }}</div>
      <div class="item" @click="$fun.toPage('/privacypolicy')">{{ $fanyi('隐私政策') }}</div>
    </div>
  </div>
  <!-- 中英 -->
  <!-- 顶部弹出 -->
  <van-popup v-model:show="showTop" position="top" :style="{ height: '15%' }">
    <div class="Chinese" @click="Togglelanguagetype('Chinese')">Chinese</div>
    <div class="English" @click="Togglelanguagetype('English')">English</div>
  </van-popup>
</template>
<script setup>

import { getCurrentInstance, ref } from 'vue'
import { useStore } from 'vuex';
const { proxy } = getCurrentInstance()
const showTop = ref(false)
const store = useStore() // 全局使用
// 菜单弹出层



// 退出登录
const Logout = () => {
  localStorage.removeItem("user_token");
  proxy.$store.commit("userData", null);

  proxy.$fun.toPage("/");
  // console.log(proxy.$route.matched[0].meta.requireAuth);
  // let requireAuth = proxy.$route.matched[0].meta.requireAuth;
  // if (requireAuth) {

  // }
}



const Togglelanguagetype = (value) => {
  store.commit('Togglelanguagetype', value)
  showTop.value = false
}
</script>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

::v-deep.van-cell {
  border-bottom: none;
  padding-left: 20px;
}

.English,
.Chinese {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    background: #f5f7fa;
    border-radius: 6px;
    color: #ff730b;
  }
}

// 菜单
.menu {
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
  font-size: 16px;

  padding-top: 44px;
  padding-bottom: 60px;

  .userinfo {
    width: 100%;
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      height: 100%;
      display: flex;
      align-items: center;

      .img-box {
        margin-right: 10px;

        img {
          width: 32px;
          height: 32px;
        }

        :deep().van-image {
          width: 32px;
          height: 32px;
          border-radius: 50px;
        }

        :deep().van-image__img {
          width: 32px;
          height: 32px;
          border-radius: 50px;
        }
      }

      .text {


        div {
          font-size: 16px;
          font-family: ArialMT;
          color: #222222;

          line-height: 24px;

          span {
            font-family: ArialMT;
            color: #222222;

            line-height: 24px;

            &:first-child {
              margin-right: 3px;
            }

            // &:last-child {
            //   margin-left: 2px;
            // }
          }
        }

        p {
          font-size: 16px;
          font-family: ArialMT;
          color: #222222;
          line-height: 24px
        }
      }
    }

    .logout {
      min-width: 64px;
      height: 26px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 0.5px solid #DEDEDE;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .classification {

    width: 100%;
    padding-top: 30px;
    padding-bottom: 50px;


    .item {
      padding: 0 20px;
      width: 100%;
      height: 40px;
      display: flex;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
      align-items: center;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0px;
      }

      &:active {
        background: #f5f7fa;
        border-radius: 6px;
        color: #ff730b;
      }
    }



  }

  .bottomboder {

    width: 335px;
    height: 1px;
    background: #DBDBDB;
    padding: 0 20px;
    height: 1px;
    margin: auto;
    background: #DBDBDB;
  }

  .footerclassification {

    width: 100%;

    .item {
      padding: 0 20px;
      width: 100%;
      height: 40px;
      display: flex;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
      align-items: center;
      margin: 10px 0;

      &:active {
        border-radius: 6px;
        background: #f5f7fa;
        color: #ff730b;
      }
    }
  }

}
</style>