<template>
  <div id="app">
    <!-- vue3.0配置 -->
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" />
    </router-view>
    <loginpopup />

  </div>
</template>

<script setup>
import { getCurrentInstance, onMounted } from 'vue'
import loginpopup from './components/loginpopup/index.vue'
const { proxy } = getCurrentInstance();

// 首先进行判断有没有用户token
if (localStorage.getItem("user_token")) {
  proxy.$api.EuropegetUserInfo().then((res) => {
    // 登录失效 如果有获取用户信息如果过期了清除token
    if (res.code != 0) {
      proxy.$store.commit("userData", null);
      localStorage.removeItem("user_token");
      // 有需要验证的用户中心
      proxy.$fun.routerToPage("/");
      let requireAuth = this.$route.matched[0].meta.requireAuth;
      if (requireAuth) {
        proxy.$fun.routerToPage("/");
      }
      return;
    } else {
      return proxy.$store.commit("userData", res.data); // 用户信息存储
    }
  });
} else {
  proxy.$store.commit("userData", null);
  localStorage.removeItem("user_token");
}

onMounted(() => {
  // 在DOM挂载完成后执行相应的操作
  // 例如，调用API获取数据、初始化第三方库等等
  var timertwo = setInterval(() => {
    let element = document.getElementById("zsiq_float");
    if (element != null) {
      element.style.cssText = 'display: none'
      clearInterval(timertwo)
    }
  }, 100)
});
// 获取汇率
const commonExchangeRate = () => {
  proxy.$api.commonExchangeRate().then((res) => {
    proxy.$store.commit("getexchangeRate", res.data);
  });
}

commonExchangeRate()
const saveState = () => {
  sessionStorage.setItem("state", JSON.stringify(proxy.$store.state));
}
window.addEventListener("unload", saveState()); //监听页面刷新存数据


</script>

<style lang="scss">
body,
#app {
  height: 100% !important;
}

* {
  box-sizing: border-box;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
}
</style>
