<template>
  <!-- 忘记密码页面 -->
  <headBackBar :iCustomizdde="true" :bgcolor="bgcolor" :icon="true">
    <template v-slot:left-icon>
      <van-icon name="arrow-left" @click="step == 1 ? $router.go(-1) : (step = step - 1)" />
    </template>

    <template #default>
      <span>
        {{ $fanyi('忘记密码') }}
      </span>
    </template>
  </headBackBar>
  <div class="Forgetyourpassword">
    <!-- 选择验证方式 -->
    <div class="Selectverificationmode" v-if="step == 1">
      <h3>{{ $fanyi('请选择认证方式') }}</h3>
      <p>
        {{
          $fanyi('为了保护您的账户,Rakumart希望验证您是试图恢复或更改密码的人')
        }}
      </p>
      <div class="mobile" @click="; (step = 2), (verificationmode = 'mobile'), (bgcolor = '#fff')">
        <div class="left">
          <img src="../../assets/mobile.svg" alt="" />
          <span>{{ $fanyi('手机号') }}</span>
        </div>
        <div class="right"><van-icon name="arrow" /></div>
      </div>
      <div class="emali" @click="; (step = 2), (verificationmode = 'email'), (bgcolor = '#fff')">
        <div class="left">
          <img src="../../assets/mailbox.svg" alt="" />
          <span>{{ $fanyi('电子邮件') }}</span>
        </div>
        <div class="right">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
    <!-- 第二步，邮箱或者 手机号 -->
    <div class="verification" v-if="step == 2">
      <!-- 手机  -->
      <h2 v-if="verificationmode == 'mobile'">{{ $fanyi('手机号认证') }}</h2>

      <!-- 邮箱 -->
      <h2 v-if="verificationmode == 'email'">{{ $fanyi('电子邮箱认证') }}</h2>
      <!-- 表单区域 -->

      <van-form ref="form">
        <van-cell-group inset>
          <!-- 手机 -->
          <van-field v-if="verificationmode == 'mobile'" @blur="validatefile('mobile')" autocomplete="off"
            class="mobile" v-model="formdata.mobile" name="mobile" :label="$fanyi('请输入您的手机号') + ':'"
            :placeholder="$fanyi('请输入完整手机号')">
            <!-- <template #left-icon>
              <div class="title">+001</div>
            </template> -->
          </van-field>
          <p class="tishi" v-if="verificationmode == 'mobile'">
            {{ $fanyi('*手机号比对失败情况下，无法发送验证码') }}
          </p>
          <!-- 邮箱 -->
          <van-field v-if="verificationmode == 'email'" @blur="validatefile('email')" autocomplete="off" class="email"
            v-model="formdata.email" type="text" name="email" :label="$fanyi('请输入邮箱') + ':'"
            :placeholder="$fanyi('请输入完整电子邮箱')" />
          <p class="tishi" v-if="verificationmode == 'email'">
            {{ $fanyi('*电子邮箱比对失败情况下，无法发送验证码') }}
          </p>
          <!-- 验证码 手机 -->
          <van-field v-if="verificationmode == 'mobile'" autocomplete="off" @blur="validatefile('code')"
            v-model="formdata.mobilecode" type="text" class="code" name="code" label="密码"
            :placeholder="$fanyi('请输入验证码')">
            <!-- 自定义左侧icon -->
            <template #label>
              <div class="codelabel">
                <div class="left">{{ $fanyi('验证码') + ':' }}</div>
                <div class="right">
                  <span v-if="show" @click.stop="sendSMS">
                    <!-- 发送验证码 -->{{ $fanyi('发送验证码') }}</span>

                  <span v-else style="color: #999999"> {{ Countdown }} S</span>
                </div>
              </div>
            </template></van-field>
          <!-- 邮箱验证码 -->
          <van-field autocomplete="off" v-if="verificationmode == 'email'" @blur="validatefile('code')"
            v-model="formdata.emailcode" type="text" class="code" name="code" label="密码"
            :placeholder="$fanyi('请输入验证码')">
            <!-- 自定义左侧icon -->
            <template #label>
              <div class="codelabel">
                <div class="left">{{ $fanyi('验证码') + ':' }}</div>
                <div class="right">
                  <span v-if="show" @click.stop="sendSMS">
                    <!-- 发送验证码 -->{{ $fanyi('发送验证码') }}</span>

                  <span v-else style="color: #999999"> {{ Countdown }} S</span>
                </div>
              </div>
            </template></van-field>
        </van-cell-group>
      </van-form>
      <!-- 下一步 -->
      <div class="nextstep" @click.stop="nextstep">{{ $fanyi('下一步') }}</div>
    </div>
    <!-- 第三步设置密码 -->
    <div class="Setapassword" v-if="step == 3">
      <h3>{{ $fanyi('设置密码') }}</h3>
      <van-form ref="passwordform">
        <van-cell-group inset>
          <!-- 密码 -->

          <van-field class="password" @blur="passwordvalidateField('password')" autocomplete="off" :type="passwordtype"
            v-model.trim="passworddata.password" :class="{
              errinput: passwordRule.password == 0,
            }" name="password" label-align="top" :label="$fanyi('新密码')" :placeholder="$fanyi('请输入密码')" :rules="[
              {
                required: true,
                message: $fanyi('请输入密码'),
                trigger: 'onBlur',
              },
              {
                validator: validatorpassword,
                trigger: 'onBlur',
              },
            ]">
            <template #right-icon>
              <img src="../../assets/login/hide.svg" alt="" v-if="passwordtype == 'password'"
                @click="passwordtype = 'text'" />
              <img src="../../assets/login/display.svg" alt="" v-if="passwordtype == 'text'"
                @click="passwordtype = 'password'" />
            </template>
          </van-field>
          <!-- 确认密码 -->
          <van-field class="confirmpassword" @blur="passwordvalidateField('confirmpassword')" autocomplete="off"
            :type="confirmpasswordtype" v-model.trim="passworddata.confirmpassword" :class="{
              errinput: passwordRule.confirmpassword == 0,
            }" name="confirmpassword" label-align="top" :label="$fanyi('新密码重新确认')" :placeholder="$fanyi('请再次输入密码')"
            :rules="[
              {
                required: true,
                message: $fanyi('请再次输入密码'),
                trigger: 'onBlur',
              },
              {
                validator: validatorconfirmpassword,
                trigger: 'onBlur',
              },
            ]">
            <template #right-icon>
              <img src="../../assets/login/hide.svg" alt="" v-if="confirmpasswordtype == 'password'"
                @click="confirmpasswordtype = 'text'" />
              <img src="../../assets/login/display.svg" alt="" v-if="confirmpasswordtype == 'text'"
                @click="confirmpasswordtype = 'password'" />
            </template>
          </van-field>
        </van-cell-group>
      </van-form>
      <!-- 确认 -->
      <div class="confirmmodification" @click="confirmmodification">
        {{ $fanyi('确认修改') }}
      </div>
    </div>
    <!-- 第四部修改成功 -->
    <div class="Modifiedsuccessfully" v-if="step == 4">
      <div class="img-box">
        <img src="../../assets/login/success.svg" alt="">
      </div>

      <h3>{{ $fanyi('登录密码更改成功') }}</h3>
      <p>{{ $fanyi('您已经有了新密码，请重新登陆！') }}</p>

      <div class="gologin" @click="$fun.toPage('/login')">
        {{ $fanyi('去登录') }}
      </div>


    </div>
  </div>
</template>
<script setup>
import headBackBar from '../../components/headGoBack/index.vue'
import { getCurrentInstance, ref, watch } from 'vue'
import { showToast, showFailToast, showLoadingToast, closeToast } from 'vant'
// , closeToast, showFailToast, showLoadingToast
const { proxy } = getCurrentInstance()
const step = ref(1)
const verificationmode = ref('') //验证方式 mobile email
const bgcolor = ref('') // 顶部table颜色
const form = ref('')
const show = ref(true)
const Countdown = ref(60)
console.log(proxy)
const formdata = ref({
  mobile: '',
  email: '',
  mobilecode: '',
  emailcode: '',
})
// showLoadingToast({
//   duration: 0,
//   message: proxy.$fanyi("加载中..."),
//   forbidClick: true,
// });
watch(step, (newvalue) => {
  console.log(newvalue);
  if (newvalue == 1) {
    bgcolor.value = ''
  } else {
    bgcolor.value = '#ffffff'
  }
})
// 发送验证码
const sendSMS = async () => {
  // 判断手机，邮箱必填
  if (verificationmode.value == 'mobile') {
    if (formdata.value.mobile == '') {
      showToast(proxy.$fanyi('请输入您的手机号'))
      return
    }
  } else {
    if (formdata.value.email == '') {
      showToast(proxy.$fanyi('请输入正确的邮箱地址'))
      return
    }
  }

  show.value = false
  // 60秒倒计时

  var timer = setInterval(() => {
    Countdown.value--
    if (Countdown.value <= 0) {
      show.value = true
      Countdown.value = 60
      clearInterval(timer)
    }
  }, 1000)

  // 发送验证码
  showLoadingToast({
    duration: 0,
    message: proxy.$fanyi('加载中...'),
    forbidClick: true,
  })
  let number =
    verificationmode.value == 'mobile'
      ? formdata.value.mobile
      : formdata.value.email
  const res = await proxy.$api.sendVerificationCode({
    occasion: 'forget',
    tool: verificationmode.value == 'mobile' ? 'mobile' : 'email',
    number: number,
    area_code: '001',
  })
  closeToast()
  if (res.code !== 0) {
    show.value = true
    Countdown.value = 60
    clearInterval(timer)
    return showFailToast(proxy.$fanyi(res.msg))
  }
  showToast(proxy.$fanyi('验证码发送成功'))
}
// 输入框失焦进项校验手机号，邮箱，验证嘛
const validatefile = async (type) => {
  proxy.$api.cipherValidationField({
    tool: 'mobile',
    number: formdata.value.mobile
  })
  if (type == 'mobile') {
    // 首先判断格式
    if (formdata.value.mobile == '') {
      showToast(proxy.$fanyi('请输入您的手机号'))
      return
    }
    // if (/^(\w){10}$/.test(formdata.value.mobile) === false) {
    //   showFailToast(proxy.$fanyi('请输入正确的手机号'))
    //   return
    // }
    //校验是否注册过
    const res = await proxy.$api.cipherValidationField({
      tool: 'mobile',
      number: formdata.value.mobile,
    })

    if (res.code != 0) {
      showFailToast(proxy.$fanyi('请输入注册的手机号'))
    }

    // cipherValidationField
  }
  if (type == 'email') {
    if (formdata.value.email == '') {
      showToast(proxy.$fanyi('请输入正确的邮箱地址'))
      return
    }
    // if (
    //   /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/.test(
    //     formdata.value.email
    //   ) === false
    // ) {
    //   return showFailToast(proxy.$fanyi('请输入正确的邮箱地址'))
    // }

    // 校验是否注册过
    const res = await proxy.$api.cipherValidationField({
      tool: 'email',
      number: formdata.value.email,
    })

    if (res.code != 0) {
      showFailToast(proxy.$fanyi('请输入正确的邮箱地址'))
    }
  }
  if (type == 'code') {
    if (formdata.value.emailcode == '' && verificationmode.value == 'email') {
      showToast(proxy.$fanyi('请输入验证码'))
      return
    }
    if (formdata.value.mobilecode == '' && verificationmode.value == 'mobile') {
      showToast(proxy.$fanyi('请输入验证码'))
      return
    }
  }
}
// 数据填写好，进项下一步
const nextstep = async () => {
  //进行判断
  if (verificationmode.value == 'mobile') {
    if (formdata.value.mobile == '') {
      showToast(proxy.$fanyi('请输入您的手机号'))
      return
    }
  } else {
    if (formdata.value.email == '') {
      showToast(proxy.$fanyi('请输入正确的邮箱地址'))
      return
    }
  }
  showLoadingToast({
    duration: 0,
    message: proxy.$fanyi('加载中...'),
    forbidClick: true,
  })
  let number =
    verificationmode.value == 'mobile'
      ? formdata.value.mobile
      : formdata.value.email
  let code =
    verificationmode.value == 'mobile'
      ? formdata.value.mobilecode
      : formdata.value.emailcode
  const res = await proxy.$api.checkVerificationCode({
    occasion: 'forget',
    number: number,
    code: code,
  })
  closeToast()
  if (res.code !== 0) {
    showFailToast(proxy.$fanyi('验证码错误'))
  } else {
    // 关闭倒计时
    show.value = true
    Countdown.value = 0
    step.value = 3
  }
}
// 第三部分数据
// 密码框类型
const passwordtype = ref('password')
const passwordform = ref('')
const confirmpasswordtype = ref('password')
const passworddata = ref({
  password: '',
  confirmpassword: '',
})
const passwordRule = ref({
  password: 1,
  confirmpassword: 1,
})

const passwordvalidateField = (type) => {
  passwordform.value
    .validate(type)
    .then(() => {
      passwordRule.value[type] = 1;
    })
    .catch(() => {
      passwordRule.value[type] = 0
    })
}

/* eslint-disable*/
// 密码校验
const validatorpassword = (val) => {
  if (
    /^.{6,16}$/.test(
      val
    ) === false
  ) {
    return proxy.$fanyi('请输入6-16位包含大小写字母和数字的字符串')
  }
}
// 密码确认
const validatorconfirmpassword = (val) => {
  if (!val) {
    return proxy.$fanyi('请再次输入密码')
  }
  if (val !== passworddata.value.password) {
    return proxy.$fanyi('密码输入不一致')
  }
}
// 确认修改
const confirmmodification = async () => {
  try {
    await passwordform.value.validate()
    showLoadingToast({
      duration: 0,
      message: proxy.$fanyi('加载中...'),
      forbidClick: true,
    })
    const res = await proxy.$api.userPasswordSave({
      password: passworddata.value.confirmpassword,
    })
    closeToast()
    if (res.code != 0) {
      showFailToast(proxy.$fanyi(res.msg))
      return
    }
    step.value = 4
  } catch { }
}
</script>
<style>
.van-toast--fail {
  min-width: 150px;
}
</style>
<style lang="scss" scoped="scoped">
@import '@/style/scss/mixin.scss';

.headBox {
  span {
    font-size: 16px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #030303;
  }
}

:deep().van-toast {
  max-width: 100% !important;
}

:deep().van-toast__text {
  max-width: 80vw;
  /* 设置最大宽度为屏幕宽度的80% */
}

.Forgetyourpassword {
  width: 100%;
  height: calc(100vh - 44px);

  background: #f6f6f6;

  // 选额验证方式
  .Selectverificationmode {
    padding: 20px;

    width: 100%;
    background: #f6f6f6;

    h3 {
      font-size: 14px;
      font-family: ArialMT;
      color: #222222;
      line-height: 20px;
      margin-bottom: 10px;
    }

    P {
      font-size: 12px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      margin-bottom: 20px;
    }

    .mobile,
    .emali {
      width: 100%;
      height: 60px;
      background: #ffffff;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 0.5px solid #e2e2e2;
      padding-left: 20px;
      padding-right: 26px;

      .left {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
        }

        span {
          font-size: 14px;
          font-family: ArialMT;
          color: #222222;
          line-height: 20px;
        }
      }

      .right {
        :deep().van-icon-arrow {
          font-size: 16px;
          font-weight: 500;
          color: #c0c4cc;
        }
      }
    }

    .mobile {
      margin-bottom: 10px;

      img {
        width: 16px;
        height: 20px;
      }
    }

    .emali {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  // 手机，验证码
  .verification {
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 20px;

    h2 {
      font-size: 14px;
      font-family: ArialMT;
      color: #222222;
      line-height: 20px;
      margin-bottom: 20px;
    }

    :deep() .van-cell-group {
      margin: 0;
      padding: 0;
    }

    :deep() .van-cell:after {
      border-bottom: none !important;
    }

    :deep().van-field__error-message {
      padding-top: 5px;
      -webkit-text-size-adjust: none;
      font-size: 10px !important;
      line-height: 15px;
      // height: 45px;
      zoom: 0.9;
    }

    :deep() .van-field__body {
      position: relative;

      input {
        width: 335px;
        height: 40px;
        background: #ffffff;
        border-radius: 6px;
        border: 0.5px solid #dedede;
        padding-left: 10px;
      }

      input::placeholder {
        font-size: 12px;
        font-family: ArialMT;
        color: #c0c4cc;
      }
    }

    :deep() .van-field__label {
      width: 100%;
      font-size: 12px;
      font-family: ArialMT;
      color: #222222;
      line-height: 18px;
      margin-bottom: 10px;
    }

    :deep().van-cell {
      flex-direction: column;

      margin: 0px;
      padding: 0px !important;
      width: 335px;
    }

    .tishi {
      margin-top: 4px;
      width: 100%;
      height: 32px;
      font-size: 10px;
      font-family: ArialMT;
      color: #e74c3c;
      line-height: 16px;
      margin-bottom: 10px;
    }

    .mobile {
      :deep().van-cell {
        flex-direction: column;

        margin: 0px;
        padding: 0px !important;
        width: 335px;
      }

      :deep() .van-field__body {
        display: flex;
        justify-content: flex-end;
        position: relative;
        padding-right: 12px;

        input {
          width: 100%;
        }

        input::placeholder {
          font-size: 12px;
          font-family: ArialMT;
          color: #c0c4cc;
          letter-spacing: -0.5px;
        }
      }

      :deep() .van-field__left-icon {
        position: absolute;
        top: 28px;

        .title {
          width: 50px;
          height: 40px;
          background: #ffffff;
          border-radius: 6px;
          border: 0.5px solid #dedede;
          font-size: 12px;
          font-family: ArialMT;
          color: #222222;
          line-height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .code {
      min-height: 70px !important;

      padding-right: 1px;

      .codelabel {
        padding-right: 5px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .right {
          font-size: 12px;
          font-family: ArialMT;
          color: #ff730b;
          line-height: 18px;
        }
      }
    }

    .nextstep {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 335px;
      // margin-top: 20px;
      height: 40px;
      background: #ff730b;
      border-radius: 6px;
      font-size: 14px;
      font-family: ArialMT;
      color: #ffffff;
      line-height: 20px;
    }
  }

  .Setapassword {
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 20px;

    h3 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
      margin-bottom: 20px;
    }

    :deep().van-cell {
      flex-direction: column;
      min-height: 95px;
      padding: 0px;
      width: 335px;
    }

    :deep().van-field__label {
      width: 100%;
      font-size: 12px;
      font-family: ArialMT;
      color: #222222;
      line-height: 18px;
      margin-bottom: 8px !important;
    }

    :deep() .van-field__body {
      width: 335px;
      box-sizing: border-box;
      position: relative;

      input {
        width: 335px;
        height: 40px;
        background: #ffffff;
        border-radius: 6px;
        border: 0.5px solid #dedede;
        padding-left: 10px;
        font-size: 12px;
        font-family: ArialMT;

        color: #222222;
        line-height: 18px;
      }

      input::placeholder {
        font-size: 12px;
        font-family: ArialMT;
        color: #c0c4cc;
        line-height: 18px;
      }
    }

    :deep() .van-field__right-icon {
      display: flex;
      position: absolute;
      right: 15px;
      justify-content: center;
      align-items: center;

      img {
        width: 18px;
        height: 18px;
      }
    }

    :deep() .van-field__right-icon {
      display: flex;
      position: absolute;
      right: 15px;
    }

    :deep().van-field__error-message {
      padding-top: 2px;
      -webkit-text-size-adjust: none;
      font-size: 10px !important;
      line-height: 15px;
      // height: 45px;
      zoom: 0.9;
    }

    :deep().van-cell-group {
      margin: 0px;
    }

    // 错误
    .errinput {
      :deep() input {
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #e74c3c;
      }
    }

    .confirmpassword {
      min-height: 92px !important;
    }

    // 确认
    .confirmmodification {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      height: 40px;
      background: #ff730b;
      border-radius: 6px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
    }
  }

  .Modifiedsuccessfully {
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 20px;
    padding-top: 40px;

    .img-box {
      margin: auto;
      width: 48px;
      height: 48px;
      margin-bottom: 20px;

      img {
        width: 48px;
        height: 48px;
      }


    }

    h3 {
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
      margin-bottom: 5px;
    }

    p {
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-bottom: 40px;
    }

    .gologin {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;

      background: #FF730B;
      border-radius: 6px;
    }
  }
}
</style>
