<template>
  <div>
    <header>
      <van-icon name="arrow-left" @click="$router.replace('/user/deliverySite')" />
      <div>{{ $route.query.id != undefined ? $fanyi('编辑地址') : $fanyi('新增地址') }}</div>
    </header>
    <section>
      <van-form>
        <van-cell-group inset>
          <div class="fromLabel">{{ $fanyi('收件人') }}</div>
          <div class="defaultVanCell">
            <van-field v-model.trim="addressInfo.contacts" :placeholder="$fanyi('请填写收件人')" @blur="contactsBlur" />
            <div class="errorText">{{ errorTextInfo.contactsErrorText }}</div>
          </div>
          <div class="fromLabel">{{ $fanyi('邮编') }}</div>
          <div class="defaultVanCell">
            <van-field v-model.trim="addressInfo.zip_code" @blur="zip_codeBlur" :placeholder="$fanyi('请输入邮编')" />
            <div class="errorText">{{ errorTextInfo.zipCodeErrorText }}</div>
          </div>
          <div class="fromLabel">{{ $fanyi('州') }}</div>
          <div class="defaultVanCell">
            <van-field :class="{ inputwebkit: addressInfo.state !== '' }" v-model.trim="addressInfo.state"
              :placeholder="$fanyi('请选择地区')" @click="show = !show" disabled />
            <div class="errorText">{{ errorTextInfo.stateErrorText }}</div>
          </div>
          <div class="fromLabel">{{ $fanyi('城市') }}</div>
          <div class="defaultVanCell">
            <van-field v-model.trim="addressInfo.city" @blur="cityBlur" :placeholder="$fanyi('请输入城市')" />
            <div class="errorText">{{ errorTextInfo.cityErrorText }}</div>
          </div>
          <div class="fromLabel">{{ $fanyi('地址') }}</div>
          <div class="defaultVanCell">
            <van-field v-model.trim="addressInfo.address" :placeholder="$fanyi('请输入收货地址')" @blur="addressBlur" />
            <div class="errorText">{{ errorTextInfo.addressErrorText }}</div>
          </div>
          <div class="fromLabel">{{ $fanyi('手机') }}</div>
          <div class="mobileVanCell">

            <van-field v-model.trim="addressInfo.mobile" @blur="mobileBlur" :placeholder="$fanyi('请输入电话')" />
          </div>
          <div class="errorText">{{ errorTextInfo.mobileErrorText }}</div>
          <div class="fromLabel">{{ $fanyi('邮件箱') }}</div>
          <div class="defaultVanCell">
            <van-field v-model.trim="addressInfo.email" :placeholder="$fanyi('请输入邮箱')" @blur="emailBlur" />
            <div class="errorText">{{ errorTextInfo.emailErrorText }}</div>
          </div>
          <div class="setDefaultAddressContainer">
            <div>{{ $fanyi('设置默认地址') }}</div>
            <van-switch active-color="#FF730B" v-model="addressInfo.isAcquiescent" />
          </div>
        </van-cell-group>

      </van-form>
      <!-- 州选择 -->
      <van-action-sheet v-model:show="show">
        <van-picker :columns="columns" :title="$fanyi('州')" :cancel-button-text="$fanyi('取消')"
          :confirm-button-text="$fanyi('确认')" @cancel="show = false" @confirm="confirmUpdateState" />
      </van-action-sheet>
    </section>
    <footer>
      <div class="addAddressBtnContainer">
        <div v-if="$route.query.id != undefined"
          :style="$route.query.id != undefined ? 'width: 180px;margin-right: 10px;background:#608BFF' : ''"
          @click="deleteHintShow = !deleteHintShow">{{ $fanyi('删除') }}</div>
        <div @click="updateAddress" :style="$route.query.id != undefined ? 'width: 180px;' : 'width:100%'">{{
          $route.query.id != undefined ? $fanyi('确认修改') : $fanyi('确认添加') }}</div>
      </div>
      <!-- 结束时间选择弹出层 -->
      <van-popup z-index="1000" v-model:show="deleteHintShow">
        <div class="deleteAddressHintContainer">
          <div class="header">
            <div>{{ $fanyi('提示信息') }}</div>
            <van-icon name="cross" @click="deleteHintShow = !deleteHintShow" />
          </div>
          <div class="footer">
            <div class="footerTitle">{{ $fanyi('是否删除当前地址') }}？</div>
            <div class="btnList">
              <div @click="confirmDeleteAddress">{{ $fanyi('确认') }}</div>
              <div @click="deleteHintShow = !deleteHintShow">{{ $fanyi('取消') }}</div>
            </div>
          </div>
        </div>
      </van-popup>
    </footer>
  </div>
</template>

<script setup>
import { reactive, getCurrentInstance, ref } from "vue";
import stateList from "@/utlis/allImportData";
import { showToast } from "vant";
const { proxy } = getCurrentInstance()
let addressInfo = reactive({
  type: 1,
  company: '',
  zip_code: '',
  address: '',
  contacts: '',
  city: '',
  state: '',
  mobile: '',
  tel: '',
  email: '',
  cnpj: '',
  mark: '',
  acquiescent: 0,
  isAcquiescent: false,
});
// 州弹出层数据
const columns = ref([]);
const show = ref(false);
const deleteHintShow = ref(false);
stateList.provincesList.forEach((item) => {
  columns.value.push({
    text: item,
    value: item,
  });
});
let errorTextInfo = reactive({
  contactsErrorText: '',
  contactsStatus: false,
  zipCodeErrorText: '',
  zipCodeStatus: false,
  stateErrorText: '',
  stateStatus: false,
  cityErrorText: '',
  cityStatus: false,
  addressErrorText: '',
  addressStatus: false,
  mobileErrorText: '',
  mobileStatus: false,
  emailErrorText: '',
  emailStatus: false,
})

if (proxy.$route.query.id != undefined) {
  proxy.$api.addressDetail({ address_id: proxy.$route.query.id }).then((res) => {
    addressInfo.acquiescent = res.data.acquiescent;
    addressInfo.isAcquiescent = res.data.acquiescent == 0 ? false : true;
    addressInfo.address = res.data.address;
    addressInfo.city = res.data.city;
    addressInfo.cnpj = res.data.cnpj;
    addressInfo.company = res.data.company;
    addressInfo.contacts = res.data.contacts;
    addressInfo.country = res.data.country;
    addressInfo.created_at = res.data.created_at;
    addressInfo.email = res.data.email;
    addressInfo.id = res.data.id;
    addressInfo.mark = res.data.mark;
    addressInfo.mobile = res.data.mobile;
    addressInfo.state = res.data.state;
    addressInfo.tel = res.data.tel;
    addressInfo.type = res.data.type;
    addressInfo.updated_at = res.data.updated_at;
    addressInfo.user_id = res.data.user_id;
    addressInfo.zip_code = res.data.zip_code;
  })
}
//收件人信息验证
const contactsBlur = () => {
  if (addressInfo.contacts === '') {
    errorTextInfo.contactsErrorText = proxy.$fanyi('不能为空');
    return
  }
  errorTextInfo.contactsStatus = true;
  errorTextInfo.contactsErrorText = '';
}
//邮编信息验证
const zip_codeBlur = () => {
  if (addressInfo.zip_code === '') {
    errorTextInfo.zipCodeErrorText = proxy.$fanyi('不能为空');
    return
  }
  let str = /^(\w){5}$/;
  if (!str.test(addressInfo.zip_code)) {
    errorTextInfo.zipCodeErrorText = proxy.$fanyi('邮编输入错误');
    return
  }
  errorTextInfo.zipCodeStatus = true;
  errorTextInfo.zipCodeErrorText = '';
}
//城市信息验证
const cityBlur = () => {
  if (addressInfo.city === '') {
    errorTextInfo.cityErrorText = proxy.$fanyi('不能为空');
    return
  }
  errorTextInfo.cityStatus = true;
  errorTextInfo.cityErrorText = '';
}
//确认删除
const confirmDeleteAddress = () => {
  proxy.$api.useraddressDelete({ id: proxy.$route.query.id }).then((res) => {
    if (res.code != 0) return showToast(proxy.$fanyi(res.msg))
    showToast(proxy.$fanyi(res.msg))
    deleteHintShow.value = !deleteHintShow.value
    setTimeout(() => {
      proxy.$router.replace('/user/deliverySite')
    }, 1000)
  })
}
//地址信息验证
const addressBlur = () => {
  if (addressInfo.address === '') {
    errorTextInfo.addressErrorText = proxy.$fanyi('不能为空');
    return
  }
  errorTextInfo.addressStatus = true;
  errorTextInfo.addressErrorText = '';
}
//手机信息验证
const mobileBlur = () => {
  if (addressInfo.mobile === '') {
    errorTextInfo.mobileErrorText = proxy.$fanyi('不能为空');
    return
  }
  // let str = /^(\w){10}$/;
  // if (!str.test(addressInfo.mobile)) {
  //   errorTextInfo.mobileErrorText = proxy.$fanyi('手机号输入错误');
  //   return
  // }
  errorTextInfo.mobileStatus = true;
  errorTextInfo.mobileErrorText = '';
}
//邮箱信息验证
const emailBlur = () => {
  if (addressInfo.email === '') {
    errorTextInfo.emailErrorText = proxy.$fanyi('不能为空');
    return
  }
  // let str = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
  // if (!str.test(addressInfo.email)) {
  //   errorTextInfo.emailErrorText = proxy.$fanyi('手机号输入错误');
  //   return
  // }
  // let emailPostfixList = [
  //   'qq.com',
  //   '163.com',
  //   'fastmail.com',
  //   'me.com',
  //   'email.com',
  //   'gmail.com',
  //   '126.com',
  //   'yahoo.com',
  //   'sina.com',
  //   'games.com',
  //   'rocketmail.com',
  //   'wow.com',
  //   'googlemail.com',
  //   'comcast.net',
  //   'warwick.net',
  //   'comcast.net',
  //   'twcny.rr.com',
  //   'hotmail.com',
  //   'foxmail.com',
  //   'rakumartwork.com',
  //   'outlook.com',
  //   'facebook.com',
  //   'mail.com',
  //   'mac.com',
  //   'icloud.com',
  //   'zoho.com',
  //   'ymail.com',
  //   'ygm.com',
  //   'love.com',
  //   'aol.com',
  //   'verizon.net',
  //   'cs.com',
  //   'netzero.net',
  //   'rakumart.eu',
  // ]
  // let email = proxy.$fun.deepClone(addressInfo.email);
  // let index = email.indexOf('@')
  // let newStr = email.substr(index + 1, email.length)
  // if (emailPostfixList.indexOf(newStr) === -1) {
  //   errorTextInfo.emailErrorText = proxy.$fanyi('请输入正确的邮箱地址');
  //   return;
  // }
  errorTextInfo.emailStatus = true
  errorTextInfo.emailErrorText = '';
}
//更新地区
const confirmUpdateState = (val) => {
  show.value = false;
  addressInfo.state = val.selectedValues[0];
  errorTextInfo.stateStatus = true;
  errorTextInfo.stateErrorText = '';
}
//修改和新增地址
const updateAddress = () => {
  console.log('1111', addressInfo.state);
  if (addressInfo.state === '') {
    errorTextInfo.stateErrorText = proxy.$fanyi('不能为空');
    return
  }
  // 首先进行表单校验
  errorTextInfo.stateStatus = true
  contactsBlur();
  zip_codeBlur();
  cityBlur();
  mobileBlur();
  addressBlur();
  emailBlur();
  // console.log(errorTextInfo.stateStatus, errorTextInfo.addressStatus, errorTextInfo.contactsStatus, errorTextInfo.zipCodeStatus, errorTextInfo.emailStatus, errorTextInfo.cityStatus);
  if (!errorTextInfo.stateStatus || !errorTextInfo.addressStatus || !errorTextInfo.contactsStatus || !errorTextInfo.zipCodeStatus || !errorTextInfo.emailStatus || !errorTextInfo.cityStatus) {
    return;
  }

  if (proxy.$route.query.id == undefined) {
    proxy.$api.useraddressAdd(addressInfo).then((res) => {
      if (res.code != 0) return showToast(proxy.$fanyi(res.msg))
      showToast(proxy.$fanyi(res.msg))
      setTimeout(() => {
        proxy.$router.replace('/user/deliverySite')
      }, 100)
    })
  } else {
    // 如果为true 选定为默认地址
    if (addressInfo.isAcquiescent == true) {
      addressInfo.acquiescent = 1
    }
    proxy.$api.useraddressUpdate(addressInfo).then((res) => {
      if (res.code != 0) return showToast(proxy.$fanyi(res.msg))
      showToast(proxy.$fanyi(res.msg))
      setTimeout(() => {
        proxy.$router.replace('/user/deliverySite')
      }, 100)
    })
  }
}
</script>

<style lang="scss" scoped>
header {
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background: #fff;
  position: sticky;
  top: 0;
  left: 0;

  div {
    font-size: 16px;
    font-weight: bold;
    color: #030303;
    margin-left: 10px;
  }

  :deep().van-icon {
    font-size: 22px;
  }
}

.inputwebkit {
  :deep().van-field__control:disabled {
    -webkit-text-fill-color: #222222 !important;
  }
}



section {
  padding: 10px 10px 80px;

  .van-cell-group--inset {
    margin: 0;
    border: 0.5px solid #E2E2E2;
    border-radius: 6px;
    padding: 20px 15px;

    .fromLabel {
      color: #222222;
      font-size: 12px;
      margin-bottom: 5px;
    }

    .defaultVanCell {
      .van-cell {
        border-radius: 6px;
        border: 0.5px solid #DEDEDE;
        height: 40px;
        padding: 8px 0 0 10px;
      }
    }

    .errorText {
      height: 10px;
      font-size: 10px;
      color: #E74C3C;
      margin: 2px 0 3px 0;
    }

    .mobileVanCell {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .areaCode {
        width: 50px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 6px;
        border: 0.5px solid #DEDEDE;
        line-height: 40px;
        text-align: center;
        margin-right: 10px;
        color: #222222;
        font-size: 12px;
      }

      .van-cell {
        border-radius: 6px;
        border: 0.5px solid #DEDEDE;
        height: 40px;
        padding: 8px 0 0 10px;
      }
    }
  }

  .setDefaultAddressContainer {
    display: flex;
    align-items: center;

    div:first-child {
      color: #222222;
      font-size: 12px;
      margin-right: 20px;
    }

    .van-switch {
      width: 40px;
      height: 22px;
      padding-right: 2.5px;
    }

    :deep().van-switch__node {
      width: 18px;
      height: 18px;
    }
  }
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 68px;
  background: #FFFFFF;
  padding: 10px 20px;

  .addAddressBtnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      border-radius: 6px;
      background: #FF730B;
      height: 48px;
      text-align: center;
      line-height: 48px;
      color: #FFFFFF;
      font-size: 14px;
    }
  }

  .deleteAddressHintContainer {
    background: #fff;
    width: 295px;

    .header {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      border: 1px solid #DBDBDB;

      div {
        color: #222222;
        font-size: 14px;
      }

      .van-icon {
        font-size: 20px;
      }
    }

    .footer {
      padding: 20px 20px 30px;

      .footerTitle {
        color: #000000;
        font-size: 12px;
        margin-bottom: 20px;
      }

      .btnList {
        display: flex;
        align-items: center;

        div {
          width: 120px;
          height: 40px;
          background: #FF730B;
          border-radius: 6px;
          line-height: 40px;
          text-align: center;
          color: #FFFFFF;
          font-size: 14px;
        }

        div:first-child {
          margin-right: 15px;
        }

        div:last-child {
          background: #F2F2F2;
          color: #666666;
        }
      }
    }
  }

  :deep().van-popup {
    border-radius: 8px;
  }
}
</style>
