<template>
  <!-- 成功 -->
  <div class="successbox">
    <div class="img-box">
      <img src="../../../assets/tichuorder/success.svg" alt="">
    </div>
    <h2>{{ $fanyi('正在进行的报价') }}</h2>
    <p class="title">{{ $fanyi('谢谢你。您的订单已成功提出。') }}</p>
    <p>{{ $fanyi('在不到24小时内，您将收到一封电子邮件，详细说明可能的运输方式的估计运输费用。') }}</p>

    <div @click="$fun.toPage('/user/index')" class="index">{{ $fanyi('个人中心') }}</div>

  </div>
</template>
<script setup>
import { getCurrentInstance } from 'vue'
const { proxy } = getCurrentInstance()
console.log(proxy);

</script>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.successbox {
  width: 100%;
  min-height: 288px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 0.5px solid #E2E2E2;
  margin-bottom: 10px;
  padding-bottom: 30px;

  .img-box {
    margin: auto;
    margin-top: 40px;
    width: 48px;
    height: 48px;

    margin-bottom: 20px;
  }

  h2 {
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #222222;
    line-height: 20px;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
  }

  .title {
    text-align: center;
    width: 100%;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 20px;

  }

  p {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    padding: 0 30px;
    line-height: 18px;
    text-align: center;
  }

  .index {
    width: 295px;
    margin: auto;
    height: 40px;
    margin-top: 20px;
    background: #FF730B;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: ArialMT;
    color: #FFFFFF;
    line-height: 20px;

  }

}
</style>