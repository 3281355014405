<template>
  <!-- 登录弹出层 -->
  <van-popup class="loginpop" lock-scroll :overlay-style="{

  }" :style="{}" v-model:show="$store.state.showLoginDia">

    <div class="main">

      <img src="../../assets/defaulticon/wdl.svg" alt="">
      <h3>{{ $fanyi('您当前处于未登录状态') }}</h3>
      <p>{{ $fanyi('是否去登录') }}?</p>
      <div class="login"><span @click="$fun.toPage('/login')">{{ $fanyi('登录账号') }}</span></div>


    </div>
  </van-popup>
</template>
<script setup>




</script>
<style lang="scss" >
.loginpop {
  overflow-y: visible !important;
}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.loginpop {

  border-radius: 8px;

  // 遮罩层
  .main {
    width: 295px;
    height: 241px;
    // background: linear-gradient(180deg, #FFD8CB 0%, #FFFAF5 39%, #FFFFFF 100%) #FFFFFF;
    border-radius: 8px;
    padding-top: 90px;
    position: relative;

    img {
      position: absolute;
      width: 120px;
      height: 120px;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);

    }

    h3 {

      width: 100%;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
      text-align: center;
      margin-bottom: 5px;

    }

    p {
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      margin-bottom: 30px;
    }

    .login {

      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        min-width: 160px;
        height: 48px;
        background: #FF730B;
        border-radius: 6px;
        font-size: 14px;
        font-family: ArialMT;
        color: #FFFFFF;
        line-height: 20px;
        display: flex;
        justify-content: center;
        padding: 0 20px;
        align-items: center;
      }
    }
  }
}
</style>