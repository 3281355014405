<template>
  <!--产品属性组件和店铺 和店铺名称 -->
  <div class="Productattribute" @click="showBottom = true">
    <div class="lift-title">
      {{ $fanyi('商品属性') }}

    </div>
    <div class="text"></div>
    <img src="../../../assets/productDetails/right.svg" alt="">
  </div>
  <div class="Shopname"
    @click="$fun.toPage('/shopdetails?shopName=' + props.details.shopName + '&shoptype=' + props.details.shop_type)">
    <div class="lift-title">
      {{ $fanyi('店铺名称') }}

    </div>
    <div class="text">{{ $fanyi(props.details.shopName) }}</div>
    <img src="../../../assets/productDetails/right.svg" alt="">
  </div>

  <!-- 底部弹出产品属性 -->
  <van-popup z-index="50" v-model:show="showBottom" id="Productattribute" position="bottom">
    <div class="main">
      <div class="top-title">
        <span> {{ $fanyi('商品属性') }}</span>

        <van-icon @click="showBottom = false" name="cross" />
      </div>
      <p v-for=" (key, value) in props.details.goodsInfo.detail" :key="value"> <span>{{ key }} :</span> <span>{{ value
      }}</span> </p>
    </div>
  </van-popup>
</template>
<script setup>
import { defineProps, ref } from 'vue'
// const { proxy } = getCurrentInstance()
// console.log(proxy);
const showBottom = ref(false)
const props = defineProps({
  details: {
    type: Object,
    default: () => { }

  }
})

</script>

<style lang="scss">
#Productattribute {
  width: 375px;
  max-height: 85%;
  overflow-y: scroll;


  border-radius: 8px 8px 0px 0px;

  .main {
    position: relative;

    padding-bottom: 88px;

  }

  .top-title {
    position: sticky;
    top: 0;
    width: 375px;
    height: 44px;
    background: #F6F6F6;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: center;

    line-height: 24px;
    align-items: center;
    font-size: 16px;

    span {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }

  .van-icon-cross {
    color: #333;
    font-weight: 500;
    position: absolute;
    right: 20px;
  }

  p {
    width: 100%;
    padding: 0 20px;
    margin: 12px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {

      &:first-child {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-right: 5px;


      }

      &:last-child {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;
      }
    }
  }
}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.Productattribute,
.Shopname {
  position: relative;
  width: 355px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 0.5px solid #E2E2E2;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  align-items: center;
  margin-bottom: 10px;

  .lift-title {
    font-size: 12px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #222222;
    line-height: 18px;
  }

  img {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;

  }

  .text {
    padding-right: 15px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }

}

// .Shopname {
//   margin-bottom: 0px;
// }
</style>