<template>
  <div style="background-color: #f6f6f6;padding-bottom: 60px;">
    <!-- 顶部搜索 -->
    <headbar :lefticon="false" />
    <!-- 轮播图组件 -->
    <carouselMap />
    <!-- 导航栏 -->
    <description />
    <!-- 推荐商品 -->
    <recommendedgoods />
    <!-- 商品分类 -->
    <fenLeiShangPinVue />
    <!-- RAKU介绍 -->
    <rakuintroduce />
    <!-- 底部 -->
    <foot />
    <!-- 底部导航 -->
    <tabbar />
    <van-back-top>
      <template #default>
        <div class="custom">
          <van-icon name="arrow-up" />
        </div>
      </template>
    </van-back-top>
  </div>
  <!-- 通知弹层-->
  <van-dialog id="Noticedialong" v-model:show="Noticedialog" title="标题" show-cancel-button>
    <!-- default	自定义内容 title	自定义标题footer -->
    <template #title>
      <span>{{ title }}</span>

      <img @click="Noticedialog = false" src="../../assets/cart/close.svg" alt="" />
    </template>
    <template #default>
      <!-- 单个 -->
      <div class="content" v-html="Noticedata.content">

      </div>
    </template>

    <template #footer>
      <div class="deletefooter">
        <div class="queren" @click="dismissPrompt">
          {{ $fanyi("确认") }}
        </div>

      </div>
    </template>
  </van-dialog>
</template>
<script setup>
import { getCurrentInstance, } from 'vue'
import headbar from '../../components/headbar/index.vue'
import carouselMap from "./components/carouselMap.vue";
import description from './components/description.vue'
import tabbar from './components/tabbar.vue'
import recommendedgoods from './components/recommendedgoods'
import rakuintroduce from './components/rakuintroduce.vue';
import fenLeiShangPinVue from "./components/fenLeiShangPin.vue";
import foot from "../../components/footer/index.vue"; import { ref } from 'vue'
const { proxy } = getCurrentInstance()

  ;
const Noticedata = ref({
  content: '',
  title: '',
})

const Noticedialog = ref(false)
const title = ref('标题')
const getcustomerNotice = () => {
  proxy.$api
    .getcustomerNotice({
      platform: 2,
    })
    .then((res) => {
      showDailyPrompt(res.data);
      console.log(res);


    })
    .catch((err) => {
      console.log(err);
    });
}
getcustomerNotice()

const showDailyPrompt = (data) => {
  // 获取当前日期，只包含年月日部分  
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // 设置为当天开始的时间  
  const currentDateString = currentDate.toISOString().split('T')[0]; // 转换为ISO 8601格式的日期字符串  

  // 从localStorage中获取用户最后看到提示的日期  
  const lastPromptDate = localStorage.getItem('Americah5lastPromptDate');
  // 从localStorage中获取用户当天是否已经点击了“知道了”  
  const dismissedToday = localStorage.getItem('Americah5dismissedToday');

  // 如果新的一天或者用户还没有点击“知道了”  
  if (!lastPromptDate || lastPromptDate !== currentDateString || dismissedToday !== 'true') {
    console.log(lastPromptDate, currentDateString);
    // 显示提示  
    console.log('显示提示！');

    if (data) {
      // 示例用法：每天调用函数来显示或更新提示
      // 假设这个函数在用户每次访问页面时都被调用  
      Noticedata.value = data;
      Noticedialog.value = true;
    }

    // 在这里你可以添加代码来显示提示，并绑定dismissPrompt函数到“知道了”按钮上  

    // 更新localStorage中的日期为当前日期  
    localStorage.setItem('Americah5lastPromptDate', currentDateString);

    // 如果今天是新的一天，并且用户之前点击过“知道了”，则重置dismissedToday  
    if (lastPromptDate !== currentDateString && dismissedToday === 'true') {
      localStorage.removeItem('Americah5dismissedToday'); // 重置“我知道了”的标志  
    }
  } else {
    // 如果是同一天且用户已经点击了“知道了”，则不显示提示  
    console.log('不显示提示。');
  }
}
// 在用户点击“知道了”的回调函数中，调用dismissPrompt函数  
// dismissPrompt(); // 这个调用应该在实际的用户交互事件中，比如按钮点击事件中
// 假设这里有一个用户点击“知道了”的回调函数  
// 假设这里有一个用户点击“知道了”的回调函数  
const dismissPrompt = () => {
  // 当用户点击“知道了”时，设置localStorage中的dismissedToday为true  
  localStorage.setItem('Americah5dismissedToday', 'true');
  Noticedialog.value = false;
}
</script>
<style lang='scss'>
#Noticedialong {
  width: 320px;

  top: 50%;

  .van-dialog__header {
    width: 100%;
    border: 1px solid #dbdbdb;
    height: 50px;

    display: flex;
    margin: 0;
    padding: 0px;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 20px;
    align-items: center;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  .van-dialog__content {
    width: 100%;
    min-height: 58px;
    padding: 20px 20px;



    border-radius: 8px;
    height: 420px;
    overflow-y: scroll;

    * {
      font-weight: 400;
      font-size: 14px;
      color: #222222;
      line-height: 25px;
    }

    img {

      width: 218px;
      height: 145px;
    }

    p {
      margin: 0px !important;
      padding: 0px !important;
    }
  }


  .deletefooter {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .queren {
      min-width: 120px;
      min-height: 40px;
      background: #ff730b;
      border-radius: 6px;
      padding: 0 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }


  }
}

.van-back-top {

  width: 40px;
  height: 40px;
  right: 10px;
  bottom: 90px;
  z-index: 50;
  background: #FFFFFF;
  border-radius: 50px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
}

.van-icon-arrow-up {
  font-size: 20px;
  color: black;
  font-weight: 600;
}

.custom {
  padding-top: 5px;
  display: flex;
  align-items: center;
}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.left-title {
  font-size: 16px;
  font-family: ArialMT;
  color: #FFFFFF;
  line-height: 24px;
}

.right-arrow {


  width: 16px;


  height: 14px;
  color: #FFFFFF;

}
</style>