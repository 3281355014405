<template>
  <!-- 资金变动明细 -->

  <van-popup z-index="1000" v-model:show="showBottom" id="detailsofchangesinfunds" @close="close" position="bottom">
    <div class="main">
      <!-- 标题 -->
      <div class="top-box">
        <h2>{{ $fanyi('资金变动明细') }}</h2>
        <van-icon @click="showBottom = false" name="cross" />
      </div>
      <!-- 内容区域 -->
      <div class="content">
        <div class="item" v-for=" (item, ind) in datas" :key="ind">
          <div class="top-title">
            <h2>{{ $fanyi("数据对比") }}</h2>
            <p>{{ item.data_time }}</p>
          </div>
          <!-- 表格 -->
          <div class="tabble">
            <div class="title"><span>{{ $fanyi('调整前数据') }}</span> <span>{{ $fanyi('调整后数据') }}</span></div>
            <div class="one"><span>{{ item.detail[0].val }}</span> <span>{{ $fanyi('数量') }}</span><span>{{
              item.detail_edit[0].val }}</span></div>
            <div class="two"><span>${{ item.detail[1].val }}</span> <span>{{ $fanyi('单价') }}</span><span>${{
              item.detail_edit[1].val }}</span></div>
            <div class="there"><span>${{ item.detail[2].val }}</span> <span>{{ $fanyi('运费') }}</span><span>${{
              item.detail_edit[2].val }}</span></div>
          </div>
          <!-- 修改明细 -->
          <div class="mingxi">
            <h2>
              {{ $fanyi('修改明细') }}
            </h2>

            <textarea :disabled="true" v-model="item.adjust_detail" id="" cols="30" rows="10"></textarea>
          </div>
          <!-- 涉及金额 -->
          <div class="footer">
            <span>{{ $fanyi('涉及金额') }}</span>
            <span>${{ item.amount }}</span>
          </div>

        </div>

      </div>
    </div>
  </van-popup>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose } from 'vue'
const { proxy } = getCurrentInstance()
const showBottom = ref(false)
const datas = ref({})// 详情数据




console.log(proxy);
const open = async (id) => {



  showBottom.value = true
  const res = await proxy.$api.orderChangeDetails({
    order_detail_id: id
  })
  if (res.code != 0) {
    return
  }
  datas.value = res.data
}

// 弹窗关闭
const close = () => {

}
defineExpose({
  open
})
</script>
<style lang="scss">
#detailsofchangesinfunds {
  min-height: 60%;

  max-height: 88%;

  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;



  .top-box {
    position: sticky;
    top: 0px;
    z-index: 2000;
    width: 100%;
    height: 44px;
    background: #F6F6F6;
    border-radius: 8px 8px 0px 0px;

    display: flex;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    align-items: center;

    .van-icon-cross {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      color: #222222;
      font-weight: 600;
      font-size: 16px;

      // top: 14px;

    }
  }

  .content {
    width: 100%;
    padding: 20px 10px;

    .item {
      width: 355px;
      min-height: 446px;
      background: #FFFFFF;
      border-radius: 6px;
      border: 0.5px solid #E2E2E2;
      padding: 20px 15px;

      .top-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        h2 {
          font-size: 12px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #222222;
          line-height: 20px;
        }

        p {
          font-size: 12px;
          font-family: ArialMT;
          color: #999999;
          line-height: 20px;
        }
      }

      .tabble {
        width: 325px;
        height: 174px;
        background: #FFFFFF;
        border-radius: 6px;
        border: 0.5px solid #E2E2E2;
        margin-bottom: 20px;

        .title {
          padding: 0 20px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 10px;
          font-family: ArialMT;
          color: #222222;
          line-height: 14px;
          height: 40px;
          background: #F2F2F2;
          border-radius: 4px 4px 0px 0px;
          align-items: center;
          padding: 0 20px;

          span {
            font-size: 10px;
            font-family: ArialMT;
            color: #222222;
            line-height: 14px;

          }
        }

        .one,
        .two,
        .there {
          width: 100%;
          display: flex;
          height: 44px;
          align-items: center;
          border-bottom: 1px dashed #DBDBDB;



          span {
            flex: 0 0 33.333%;
            text-align: center;

            &:nth-child(1) {
              font-size: 10px;
              font-family: ArialMT;
              color: #222222;
              line-height: 14px;
            }

            &:nth-child(2) {
              font-size: 10px;
              font-family: Arial-BoldMT, Arial;
              font-weight: normal;
              color: #222222;
              line-height: 14px;
              font-weight: 600;
            }

            &:nth-child(3) {
              font-size: 10px;
              font-family: ArialMT;
              color: #222222;
              line-height: 14px;
            }
          }
        }

        .there {
          border: none;
        }
      }

      .mingxi {
        width: 100%;
        margin-bottom: 20px;

        h2 {
          margin-bottom: 10px;
          font-size: 12px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #222222;
          line-height: 20px;
        }

        textarea {
          width: 325px;
          height: 92px;
          background: #FFFFFF;
          border-radius: 6px;
          border: 0.5px solid #E2E2E2;
          padding: 10px;
          font-size: 10px;
          font-family: ArialMT;
          color: #666666;
          line-height: 14px;

        }
      }

      .footer {
        width: 100%;
        height: 40px;
        background: #E4FBF4;
        border-radius: 4px;
        border: 1px solid #1BA37B;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;

        span {
          font-size: 12px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #222222;
          line-height: 20px;
        }

      }

    }




  }

}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";
</style>