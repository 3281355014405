import {
	apiAxios
} from '../axios'

import Europess from '../wangZhi'
let Europe = Europess + '/client/'

export default {
	// 获取分类栏目 
	getShopGroup: data => apiAxios('post', Europe + "getShopGroup", data),
	// 网站Banner列表 
	getShopBanner: data => apiAxios('post', Europe + "getShopBanner", data),
	// 个性化商品推荐(注释参照商品搜索) 
	getPersonalizedGoods: data => apiAxios('post', Europe + "getPersonalizedGoods", data),
	// 普通商品推荐(注释参照分类栏目和商品搜索) 
  getRecommendedGoods: data => apiAxios('post', Europe + "getRecommendedGoods", data),
  // 获取banner 图
  getH5ShopBanner: data => apiAxios('post', Europe + "getH5ShopBanner", data),
  // 多语言搜索导航
  keywordSNQuerysearch: data => apiAxios('post', Europe + "keywordSNQuery", data),
    // 获取客户通知
    getcustomerNotice: data => apiAxios('post', Europe + "customerNotice", data),
}