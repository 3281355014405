<template>
  <!-- 商品搜索页面 -->
  <headbar></headbar>

  <div class="CommoditySearch">
    <!-- 顶部商品搜索信息  v-if="isImgSearch"-->
    <div class="imgserch" v-if="isImgSearch">
      <div class=" img-box">
        <van-image :src="imgUrl">
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
      </div>
      <!-- 右侧商品数量 -->

      <div class="commodityProducts">
        {{ $fanyi('共') }} <span>{{ total }}</span> {{ $fanyi('产品') }}
      </div>
    </div>
    <div class="keyword" v-else>
      <div class="keywordtext">
        {{ route.query.keyword }}
      </div>
      <div class="commodityProducts">

        {{ $fanyi('共') }} <span>{{ total }}</span> {{ $fanyi('产品') }}
      </div>
    </div>
    <!-- 下拉组件 -->
    <van-pull-refresh v-if="!nodata" v-model="refreshing" head-height="100" :loosing-text="$fanyi('释放即可刷新...')"
      :pulling-text="$fanyi('下拉刷新')" :loading-text="$fanyi('加载中')" :success-text="$fanyi('加载成功')" @refresh="onRefresh">
      <!--  list组件上划加载-->
      <van-list v-model:loading="loading" :loading-text="$fanyi('加载中')" :finished="finished" finished-text="没有更多了"
        @load="onLoad">

        <!-- 自定义加载完成样式finished -->

        <template #finished>
          <div class="finised">
            <p>
              {{ $fanyi('已无更多产品,请换个关键词试试') }}
            </p>


            <div class="img-box">
              <img src="../../assets/index/logo-rakumart.svg" alt="">
            </div>
          </div>


        </template>
        <!-- 竖版内容 -->
        <div class="Verticalplate" v-if="Verticalplate">
          <div class="shopitem" @click="$fun.toCommodityDetails({
            goods_id: goodsItem.goods_id,
            shop_type: goodsItem.shop_type,
          }

          );" v-for="goodsItem in list" :key="goodsItem.goods_id">
            <div class="img-box">
              <van-image :src="goodsItem.imgUrl" lazy-load>

                <template v-slot:loading>
                  <van-loading type="spinner" size="30" />
                </template>
              </van-image>
            </div>
            <!-- 商品描述 -->
            <div class="text">{{ goodsItem.title }}</div>
            <!-- 价格 -->
            <div class="price">
              <span class="dollar">
                ${{
                  $fun.EURNumSegmentation(
                    $fun.ceil(
                      goodsItem.goodsPrice * $store.state.exchangeRate
                    )
                  )
                }}

              </span>
              <span class="RMB">
                ￥{{ $fun.RMBNumSegmentation(goodsItem.goodsPrice) }}
              </span>
            </div>
          </div>

        </div>
        <!-- 横版内容 -->
        <div class="Horizontalplate" v-else>
          <div class="shopitem" @click="$fun.toCommodityDetails({
            goods_id: goodsItem.goods_id,
            shop_type: goodsItem.shop_type,
          }
          );" v-for="goodsItem in list" :key="goodsItem.goods_id">
            <div class=" img-box">
              <van-image :src="goodsItem.imgUrl" lazy-load>

                <template v-slot:loading>
                  <van-loading type="spinner" size="30" />
                </template>
              </van-image>

            </div>
            <!-- 右边商品价格，描述 -->
            <div class="right">
              <div class="text">
                {{ goodsItem.title }}
              </div>
              <!-- 价格 -->
              <div class="price">
                <span class="dollar">
                  ${{
                    $fun.EURNumSegmentation(
                      $fun.ceil(
                        goodsItem.goodsPrice * $store.state.exchangeRate
                      )
                    )
                  }}

                </span>
                <span class="RMB">

                  ￥{{ $fun.RMBNumSegmentation(goodsItem.goodsPrice) }}
                </span>
              </div>
              <!-- 销量 -->
              <div class="xiaoliag">{{ $fanyi('销量') }}：{{ goodsItem.sales }} {{ $fanyi('件') }}</div>

            </div>

          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <!-- 如果没有商品展示 -->
    <div class="nodata" :class="{ imgnodatasearchtop: isImgSearch }" v-else>
      <img src="../../assets/defaulticon/sssb.svg" alt="">
      <p v-if="!isImgSearch">{{ $fanyi('没有找到商品，请换一个关键词试试') }}</p>
      <p v-else>{{ $fanyi('没有找到商品，请换一张图片试试') }}</p>

    </div>
    <van-back-top>

      <template #default>
        <div class="custom">
          <van-icon name="arrow-up" />
        </div>
      </template>
    </van-back-top>
    <!-- 切换横版竖版 -->
    <div class="switchVerticalplate">

      <img src="../../assets/index/array1.svg" @click="Verticalplate = false" alt="" v-if="Verticalplate">
      <img src="../../assets/index/array2.svg" @click="Verticalplate = true" alt="" v-else>
    </div>
  </div>
</template>

<script setup>
import headbar from '../../components/headbar/index.vue'
import { getCurrentInstance, ref, watch } from 'vue'
import { showToast, showFailToast, showLoadingToast, closeToast } from 'vant'
import { useRoute } from 'vue-router'
const { proxy } = getCurrentInstance()
console.log(proxy);
const isImgSearch = ref(false);
const route = useRoute();
const imgUrl = ref(null);
const keywords = ref("");
const total = ref(0)//商品数量 
const Verticalplate = ref(true)//默认战术展示竖版
const shoptype = ref('1688')// 默认搜索1688 商品
const list = ref([])
const loading = ref(false); // 加载状态
const finished = ref(false);// 加载完成
const page = ref(0)// 页码
const pageSize = ref(20)//默认加载20页一条
const refreshing = ref(false);// 下拉刷新
const nodata = ref(false);// 是否显示没有数据
const imageId = ref('')// 图片id


//判断是不是照片搜索
if (route.query.type == "imgSearch") {
  isImgSearch.value = true;
  imgUrl.value = route.query.imgUrl;
  imageId.value = route.query.imageId;
}
// 关键词搜索
if (route.query.keyword) {

  proxy.$store.commit("getsearchInfo", route.query.keyword);
  // 进行判断如果路由中有CN 就说明是从分类页面来的需要解码成中文
  if (route.query.CN) {
    keywords.value = proxy.$fun.fromCode(route.query.CN);
  } else {
    keywords.value = route.query.keyword;
  }

  isImgSearch.value = false;
}
// 监听路由变化看是图片搜索还是关键词
watch(
  () => route,
  (newValue) => {
    console.log(newValue.name);
    if (newValue.name == "commoditySearch") {
      if (newValue.query.type == "imgSearch") {
        isImgSearch.value = true;
        imgUrl.value = newValue.query.imgUrl;
        imageId.value = route.query.imageId;
      } else if (newValue.query.keyword) {
        // 进行判断如果路由中有CN 就说明是从分类页面来的需要解码成中文
        isImgSearch.value = false;
        if (newValue.query.CN) {
          keywords.value = proxy.$fun.fromCode(route.query.CN);
        } else {
          keywords.value = newValue.query.keyword;
        }
      }
    }
  },
  { immediate: true }
);
showLoadingToast({
  duration: 0,
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
  loadingType: "spinner",
});
// 触底刷新
const onLoad = async () => {

  // 首先判断是图片还是关键词搜索
  if (route.query.keyword) {
    page.value += 1;

    const res = await proxy.$api.EuropeKeywordSearchProduct({
      keyWords: keywords.value,
      shop_type: shoptype.value,
      page: page.value,
      pageSize: pageSize.value,
    });
    if (res.code !== 0) {

      showFailToast(proxy.$fanyi(res.msg))
      closeToast();
      if (list.value.length < 0) {
        finished.value = true
      }
      return
    }
    closeToast();
    // 如果是商品链接搜索直接去商品详情
    console.log(res);
    if (res.data.data_type == "goods_detail") {
      proxy.$fun.toCommodityDetails(
        {
          goods_id: res.data.result.goods_id,
          shop_type: res.data.result.from_platform,
        }

      );
      return;
    }
    // 判断是上拉还是下拉
    if (refreshing.value == true) {
      list.value = [...res.data.result, ...list.value]
    }
    else {
      list.value = [...list.value, ...res.data.result,]
    }
    loading.value = false;
    refreshing.value = false;
    console.log(res.data.total != null);
    if (res.data.total != null && res.data.total) {
      total.value = res.data.total
    }

    if (list.value.length == 0 && res.data.result.length == 0) {
      nodata.value = true
    }
    if (
      res.data.result?.result?.length < pageSize.value ||
      list.value.length == res.data.total || res.data.result.length == 0
    ) {
      finished.value = true;
    }
  } else if (route.query.type == "imgSearch" && route.query.imgUrl) {
    // 图片搜索
    page.value += 1;

    const res = await proxy.$api.imageSearchProduct({
      picUrl: imgUrl.value,
      shop_type: shoptype.value,
      page: page.value,
      pageSize: pageSize.value,
      imageId: imageId.value
    });
    if (res.code !== 0) {

      showFailToast(proxy.$fanyi(res.msg))
      closeToast();
      if (list.value.length < 0) {
        finished.value = true
      }
      return
    }
    imageId.value = res.data.imageId
    closeToast();
    // 判断是上拉还是下拉
    if (refreshing.value == true) {
      list.value = [...res.data.data, ...list.value]
    }
    else {
      list.value = [...list.value, ...res.data.data,]
    }
    if (res.data.total != null && res.data.total) {
      total.value = res.data.total
    }
    loading.value = false;
    refreshing.value = false;
    if (list.value.length == 0) {
      nodata.value = true
    }
    if (
      res.data.data.length < pageSize.value ||
      list.value.length == res.data.total || res.data.length == 0
    ) {
      finished.value = true;
    }
  }


};
// 下拉刷新
const onRefresh = () => {
  if (finished.value == true) {
    showToast(proxy.$fanyi("没有更多了"));
    refreshing.value = false;
    return;
  }
  onLoad();
};

</script>

<style>
.van-back-top {

  width: 40px;
  height: 40px;
  right: 10px;
  bottom: 35px;
  z-index: 50;
  background: #FFFFFF;
  border-radius: 50px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
}


.van-icon-arrow-up {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
</style>

<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.custom {
  padding-top: 5px;
  display: flex;
  align-items: center;
}

.CommoditySearch {
  width: 100%;
  padding: 0 10px;
  min-height: 100vh;
  background: #F6F6F6;
  padding-bottom: 20px;

  .switchVerticalplate {
    position: fixed;
    width: 40px;
    height: 40px;
    right: 10px;
    bottom: 84px;
    z-index: 50;
    background: #FFFFFF;
    border-radius: 50px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .imgserch {
    width: 100%;
    background: #F6F6F6;
    height: 78px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .img-box {
      overflow: hidden;

      :deep().van-image {
        width: 48px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #FF730B;
        overflow: hidden;
      }

      :deep().van-image__error {
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        border-radius: 4px;
      }

      :deep().van-image__loading {
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        // border-radius: 4px;

      }

      :deep().van-image__img {
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        border-radius: 3px;
      }
    }

  }

  .finised {
    width: 100%;


    min-height: 64px;

    p {
      width: 100%;
      height: 64px;
      display: flex;
      padding: 0 20px;
      justify-content: center;
      line-height: 20px;
      align-items: center;
    }

    .img-box {
      width: 100%;

      display: flex;
      align-items: center;
      height: 50px;
      background: #FFFFFF;
      justify-content: center;

      img {
        width: 120px;
        height: 30px;
      }
    }

  }

  .nodata {
    margin-top: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 120px;
      height: 120px;

      margin-bottom: 20px;

    }

    p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      text-align: center;
      color: #666666;
    }
  }

  .imgnodatasearchtop {
    margin-top: 150px;
  }

  .keyword {
    width: 100%;

    display: flex;
    background: #F6F6F6;
    height: 48px;
    align-items: center;
    justify-content: space-between;

    .keywordtext {
      width: 165px;
      height: 18px;
      font-size: 12px;
      font-family: ArialMT;
      color: #666666;
      line-height: 18px;
      line-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .commodityProducts {

    font-size: 12px;
    font-family: ArialMT;
    color: #666666;
    line-height: 18px;

    span {
      font-size: 12px;
      font-family: ArialMT;
      color: #FF730B;
      line-height: 18px;
    }

  }

  // 竖版
  .Verticalplate {
    width: 100%;
    display: flex;
    // flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .shopitem {

      flex: 0 0 170px;

      height: 240px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 0.5px solid #E2E2E2;

      padding: 10px;
      margin-bottom: 10px;


      .img-box {
        width: 150px;
        height: 150px;
        background: #FFFFFF;
        margin-bottom: 10px;

        :deep().van-image {
          width: 150px;
          height: 150px;
        }
      }

      .text {
        height: 36px;
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        margin-bottom: 5px;
      }

      .price {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 20px;
        display: flex;
        align-items: center;

        .dollar {
          font-size: 14px;
          font-family: Arial-BoldMT, Arial;
          font-weight: 600;
          color: #FF730B;
          line-height: 20px;

        }

        .RMB {

          margin-left: 5px;
          font-size: 12px;
          font-family: PingFang-SC-Semibold, PingFang-SC;
          font-weight: 600;
          color: #FF730B;
          line-height: 18px;
        }
      }

    }
  }

  // 横版
  .Horizontalplate {

    .shopitem {
      width: 100%;
      margin-bottom: 10px;

      height: 100px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 0.5px solid #E2E2E2;
      display: flex;
      padding: 10px;
      justify-content: space-between;


      .img-box {
        width: 80px;
        height: 80px;
        margin-right: 10px;

        :deep().van-image {
          width: 80px;
          height: 80px;
        }
      }

      .right {
        flex: 1;
        height: 100%;

        .text {
          height: 36px;
          font-size: 12px;
          font-family: ArialMT;
          color: #222222;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 5px;
        }

        .price {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 20px;
          line-height: 20px;
          display: flex;
          align-items: center;
          margin-bottom: 5px;

          .dollar {
            font-size: 14px;
            font-family: Arial-BoldMT, Arial;
            font-weight: 600;
            color: #FF730B;
            // height: 20px;
            line-height: 20px;

          }

          .RMB {

            margin-left: 5px;
            font-size: 12px;
            font-family: PingFang-SC-Semibold, PingFang-SC;
            font-weight: 600;
            color: #FF730B;
            // height: 18px;
            line-height: 18px;
          }
        }

        .xiaoliag {
          height: 14px;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
        }


      }

    }
  }


}
</style>