<template>
  <!-- 附加服务弹出层 -->

  <van-popup z-index="1000" v-model:show="showBottom" id="additionalservices" @close="close" position="bottom">
    <div class="main">
      <!-- 标题 -->
      <div class="top-box">
        <h2>{{ $fanyi('附加服务') }}</h2>
        <van-icon @click="showBottom = false" name="cross" />
      </div>

      <div class="optionlist">
        <div class="item" v-show="item.checked == true" v-for="item in optionlist" :key="item.id">

          <div class="content">
            <div class="title">

              <span>{{ item.name_translate }}</span>
            </div>
            <div class="unitprice">
              <p class="text">{{ $fanyi('数量') }}</p>
              <p class="price">x{{ item.num }}
              </p>
            </div>
            <div class="subtotal">
              <p class="text">{{ $fanyi('小计') }}</p>
              <p class="price">${{
                $fun.EURNumSegmentation(
                  $fun.ceil((item.price * item.num) * $store.state.exchangeRate)
                )
              }} (￥{{ $fun.RMBNumSegmentation(item.price * item.num) }})
              </p>
            </div>
          </div>

        </div>
      </div>

    </div>
  </van-popup>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose } from 'vue'
const { proxy } = getCurrentInstance()
const showBottom = ref(false)
const optionlist = ref([])// option 列表


console.log(proxy);
const open = (item) => {
  showBottom.value = true
  optionlist.value = item

}

// 弹窗关闭
const close = () => {

}
defineExpose({
  open
})
</script>
<style lang="scss">
#additionalservices {
  min-height: 50%;

  max-height: 88%;

  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;



  .top-box {
    position: sticky;
    top: 0px;
    z-index: 2000;
    width: 100%;
    height: 44px;
    background: #F6F6F6;
    border-radius: 8px 8px 0px 0px;

    display: flex;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    align-items: center;

    .van-icon-cross {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      color: #222222;
      font-weight: 600;
      font-size: 16px;

      // top: 14px;

    }
  }

}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";


.main {
  width: 100%;
  height: 100%;




  .optionlist {
    padding: 10px;

    .item {
      width: 100%;

      min-height: 60px;
      background: #FFFFFF;
      border-radius: 6px;
      border: 0.5px solid #E2E2E2;
      padding: 0px 15px;
      align-items: center;
      display: flex;

      margin-bottom: 10px;

      .title {
        width: 72px;
        flex: 0px 0px 72px;
        display: flex;
        align-items: center;
        height: 18px;
        font-size: 12px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #222222;
        line-height: 18px;

        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          line-height: 18px;
        }
      }

      .content {

        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;



        .unitprice {

          min-height: 30px;

          .text {
            height: 14px;
            font-size: 10px;
            font-family: ArialMT;
            color: #999999;
            line-height: 12px;
          }

          .price {
            font-size: 12px;
            font-family: ArialMT;
            color: #222222;
            line-height: 16px;
          }
        }

        .subtotal {
          width: 90px;
          min-height: 30px;

          .text {
            height: 14px;
            font-size: 10px;
            font-family: ArialMT;
            color: #999999;
            line-height: 12px;
          }

          .price {
            font-size: 12px;
            font-family: ArialMT;
            color: #222222;
            line-height: 16px;
          }
        }

      }
    }

  }

}
</style>