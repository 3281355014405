import { createApp } from 'vue'
import App from './App.vue'
import { Locale } from 'vant';
import 'default-passive-events';
// 切换vantui语言
import enUS from 'vant/es/locale/lang/en-US';
import vantUiImport from './utlis/vantUiImport'
import api from './api/apis'
import fun from './utlis/CommonlyUsedFunctions'
import fanyi from './utlis/language'
import './style/theme.css'
import store from './store/index';
import router from './router/index';
import 'vant/lib/index.css';
import './style/scss/index.scss'
import './style/scss/reset.scss'
import 'amfe-flexible';
const app = createApp(App);


//将一些api挂到原型下
app.config.globalProperties.$fun = fun
app.config.globalProperties.$api = api
app.config.globalProperties.$fanyi = fanyi
app.config.globalProperties.$store = store
 
Locale.use('en-US', enUS);
vantUiImport(app)
app.use(store)
app.use(router)
app.mount('#app');
