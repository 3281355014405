<template>
  <!-- 顶部hearbar -->
  <div class="hearbar" v-if="scrollTop < 375">

    <div class="left">
      <!-- 返回 -->
      <van-icon name="arrow-left" @click="$router.back()" />

    </div>
    <div class="right">
      <!-- 收藏 -->
      <div class="shoucang">

        <img v-if="shopFavoriteStatus == true" @click="favoriteGoodsDelete"
          src="../../../assets/productDetails/collection2.svg" alt="">
        <img v-else @click="favoriteGoodsAdd" src="../../../assets/productDetails/collection1.svg" alt="">
      </div>
      <!-- 更多 -->
      <div class="img-box" @click="showTop = true">
        <img src="../../../assets/productDetails/more.svg" alt="">
      </div>
    </div>
  </div>
  <div class="hearbarwhite" v-else>


    <div class="left">
      <van-icon name="arrow-left" @click="$router.back()" />
      <span>{{ $fanyi('商品详情') }}</span>

    </div>
    <div class="right">

      <img v-if="shopFavoriteStatus == true" @click="favoriteGoodsDelete"
        src="../../../assets/productDetails/collection2.svg" alt="">
      <img v-else @click="favoriteGoodsAdd" src="../../../assets/productDetails/collection1.svg" alt="">
      <img class="more" @click="showTop = true" src="../../../assets/productDetails/more.svg" alt="">
    </div>

  </div>


  <!-- 顶部弹出 -->
  <van-popup class="morepop" z-index="100" :overlay="false" :closeable="true" v-model:show="showTop" position="top">

    <div class="top-more">
      <div class="toptitle">{{ $fanyi('快捷入口') }}</div>

      <div class="toplist">
        <div class="item" @click="clickjump(1)">
          <!-- <img src="" alt=""> -->
          <!-- 菜单 -->

          <div class="img-box"> <img src="../../../assets/productDetails/1.svg" alt=""></div>
          <div class="title">{{ $fanyi('菜单') }}</div>
        </div>
        <div class="item" @click="clickjump(2)">
          <!-- <img src="" alt=""> -->
          <!-- 分类 -->
          <div class="img-box"> <img src="../../../assets/productDetails/2.svg" alt=""></div>
          <div class="title">{{ $fanyi('分类') }}</div>
        </div>
        <div class="item" @click="clickjump(3)">
          <!-- <img src="" alt=""> -->
          <!-- 我的 -->
          <div class="img-box"> <img src="../../../assets/productDetails/3.svg" alt=""></div>
          <div class="title">{{ $fanyi('我的') }}</div>
        </div>
        <div class="item" @click="clickjump(4)">
          <!-- <img src="" alt=""> -->
          <!-- 购物车 -->
          <div class="img-box"> <img src="../../../assets/productDetails/4.svg" alt="">
            <span v-if="cartnum > 0">{{ cartnum }}</span>
          </div>
          <div class="title">{{ $fanyi('购物车') }}</div>
        </div>
        <div class="item" @click="clickjump(5)">
          <!-- <img src="" alt=""> -->
          <!-- 聊天 -->
          <div class="img-box"> <img src="../../../assets/productDetails/5.svg" alt=""></div>
          <div class="title">{{ $fanyi('聊天') }}</div>
        </div>
      </div>

    </div>
  </van-popup>
  <!-- 菜单弹出层 -->
  <van-popup z-index="110" closeable v-model:show="menushowLeft" position="left"
    :style="{ width: '100%', height: '100%' }">
    <menupopup />
  </van-popup>

  <!-- 商品分类弹出层 -->
  <van-popup z-index="110" closeable v-model:show="showLeft" position="left" :style="{ width: '100%', height: '100%' }">
    <classifypopup />
  </van-popup>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose } from 'vue'

import { useRoute } from 'vue-router';
import { showSuccessToast } from 'vant'
import classifypopup from '../../../components/classifypopup/index.vue'
import menupopup from '../../../components/menupopup/index.vue'
import { useStore } from 'vuex';
const { proxy } = getCurrentInstance()
const route = useRoute()
const logindom = ref('')
const showTop = ref(false)
const shopFavoriteStatus = ref(false)// 商品是否收藏
const scrollTop = ref(0)
const store = useStore()

const cartnum = ref(0)// 购物车数量
// 菜单弹出层
const menushowLeft = ref(false)

// 分类弹出层
const showLeft = ref(false)
window.addEventListener('scroll', function () {
  scrollTop.value = window.pageYOffset || document.documentElement.scrollTop;
  // console.log(scrollTop.value);
});
// 查看商品是否已收藏
const favoriteGoodsCheckIn = () => {

  proxy.$api
    .favoriteGoodsCheckIn({
      goods_id: JSON.stringify([route.query.goods_id]),
    })
    .then((res) => {

      if (res.code != 0) return false;

      shopFavoriteStatus.value = res.data[0].inFavorite;
    });
}
favoriteGoodsCheckIn()
const goodsToCartNum = async () => {
  const res = await proxy.$api.goodsToCartNum()
  if (res.code != 0) return
  cartnum.value = res.data
}
goodsToCartNum()
// 商品添加收藏 
const favoriteGoodsAdd = async () => {

  // 首先判断是否登录没有登录显示弹窗
  if (!proxy.$store.state.userInfo) {
    proxy.$store.commit('changeshowLoginDia', true) //打开登录弹窗
    return
  }

  let datas = {
    goods_id: proxy.$parent.details.goods_id,
    title: proxy.$parent.details.title,
    image_url: proxy.$parent.details.images[0],
    price: proxy.$parent.price,
    shop_type: proxy.$parent.details.from_platform
  }
  const res = await proxy.$api.favoriteGoodsAdd(datas)
  if (res.code != 0) return
  shopFavoriteStatus.value = true
  showSuccessToast(proxy.$fanyi('添加收藏成功'))
}
// 商品收藏移除
const favoriteGoodsDelete = async () => {

  // 首先判断是否登录没有登录显示弹窗
  if (!proxy.$store.state.userInfo) {
    logindom.value.open()
    return
  }
  const res = await proxy.$api.favoriteGoodsDelete({
    goods_id: proxy.$parent.details.goods_id,
  })
  if (res.code != 0) return
  shopFavoriteStatus.value = false
  showSuccessToast(proxy.$fanyi('取消收藏成功'))
}
// 点击tabar
const clickjump = (index) => {



  switch (index) {

    case 1:
      menushowLeft.value = !menushowLeft.value
      showLeft.value = false
      showTop.value = !showTop.value
      break;
    case 2:
      // 当 expression 等于 value2 时执行的代码
      showLeft.value = !showLeft.value
      menushowLeft.value = false
      showTop.value = !showTop.value
      break;
    case 3:
      // 首先判断是否登录没有登录显示弹窗
      if (!proxy.$store.state.userInfo) {
        showTop.value = false
        store.commit('changeshowLoginDia', true) //打开登录弹窗
        return
      }
      proxy.$fun.toPage('/user/index')
      // 当 expression 等于 value2 时执行的代码
      break;
    case 4:

      if (!proxy.$store.state.userInfo) {
        showTop.value = false
        store.commit('changeshowLoginDia', true) //打开登录弹窗
        return
      }

      proxy.$fun.toPage('/cart')
      // 当 expression 等于 value2 时执行的代码
      break;
    case 5:

      var link = document.createElement('a');

      // 设置 href 和 target 属性
      link.href = 'https://wa.me/+8615957125759';
      link.target = '_blank';

      // 模拟点击事件以打开链接
      link.click();

      // 清理 DOM
      document.body.appendChild(link); // 将 a 标签添加到 DOM 中
      link.remove(); //
      // var element = document.getElementById("zsiq_float");
      // // 创建事件

      // var event = document.createEvent("MouseEvents");
      // // 定义事件 参数： type, bubbles, cancelable
      // event.initEvent("click", true, true);


      // // 触发对象可以是任何元素或其他事件目标
      // element.dispatchEvent(event);
      // if (!proxy.$store.state.userInfo) {
      //   showTop.value = false
      //   store.commit('changeshowLoginDia', true) //打开登录弹窗
      //   return
      // }
      // 当 expression 等于 value2 时执行的代码
      break;
    default:
      // 当 expression 不等于任何 case 时执行的代码
      break;
  }


}
defineExpose({
  goodsToCartNum
})
</script>
<style lang="scss">
.loginpop {

  border-radius: 8px;

}

.van-popup {
  overflow-y: visible;
}

.morepop {
  width: 100%;
  padding: 0 20px;
  height: 147px;
  background: #F6F6F6;
  border-radius: 0px 0px 8px 8px;

  .top-more {
    width: 100%;
    min-height: 147px;

    .toptitle {
      width: 100%;
      height: 100%;
      height: 44px;
      line-height: 44px;
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #030303;
      margin-bottom: 10px;

    }

    .toplist {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .item {
        width: 50px;
        min-height: 70px;

        padding: 0px !important;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        .img-box {
          position: relative;
          width: 50px;
          height: 50px;
          background: #FFFFFF;
          border-radius: 50%;

          display: flex;
          align-items: center;
          margin-bottom: 8px;
          justify-content: center;

          span {
            right: 2px;
            top: 5px;
            position: absolute;
            min-width: 18px;
            height: 18px;
            background: #FF730B;
            border-radius: 50%;
            font-size: 12px;
            font-family: ArialMT;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 12px;

          }
        }

        img {
          width: 30px;
          height: 30px;
        }

        .title {
          font-size: 10px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #222222;
          line-height: 10px;
          height: 20px;
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
          letter-spacing: -0.5px;
        }




      }


    }
  }

}

.van-popup__close-icon {
  font-size: 16px;
  color: #222222;
}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.hearbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  height: 44px;
  z-index: 99;

  .left {
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    opacity: 0.4;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    :deep().van-icon-arrow-left {
      font-size: 16px;
      color: #333333;
    }
  }



  .right {
    display: flex;


    .img-box {
      width: 30px;
      height: 30px;
      background: #FFFFFF;
      opacity: 0.4;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 16px;
      height: 16px;


    }

    .shoucang {
      width: 30px;
      height: 30px;
      background: #FFFFFF;
      opacity: 0.4;
      margin-right: 10px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

}

.hearbarwhite {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  background-color: white;
  height: 44px;

  :deep().van-icon-arrow-left {
    font-size: 16px;
    color: #333333;
    margin-right: 10px;
    font-weight: 600;
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 16px;
      font-family: PingFang-SC-Semibold, PingFang-SC;
      font-weight: 600;
      color: #030303;
      line-height: 24px;
    }
  }

  .right {
    display: flex;
    align-items: center;

    .more {
      margin-left: 24px;
      margin-right: 7px;
    }
  }
}
</style>