<template>
  <!-- 物流方式 -->
  <div class="wuliu">
    <h2>{{ $fanyi('选择运输类型以获得最终的运输报价') }}</h2>
    <div @click="show = true" class="why"><img src="../../../assets/tichuorder/why.svg" alt="">
      <p>{{ $fanyi('为什么现在估计装运?') }}</p>
    </div>

    <!-- 物理列表 -->
    <div class="wuliulist">
      <div class="wuliulitem" v-for="(item, index) in  props.wuliulist" :key="item.id"
        :class="{ maxheight: item.showwuliu }">
        <div class="top-title">
          <div class="left" @click="Selectlogistics(item, index)">
            <div class="checkbox" :class="{ Selectcheckbox: item.check == true }">
              <span></span>
            </div>
            <div class="name">{{ $fanyi(item.logistics.name) }}</div>
          </div>
          <div class="right">
            <van-icon @click="item.showwuliu = !item.showwuliu" v-if="!item.showwuliu" name="arrow-down" />
            <van-icon @click="item.showwuliu = !item.showwuliu" v-else name="arrow-up" />
          </div>
        </div>
        <!-- 内容区域 -->
        <div class="content">
          <!-- 预计国际运费 -->
          <div class="yunfei">
            <div class="left">{{ $fanyi('预估国际运费') }}:</div>
            <div class="right">${{ $fun.EURNumSegmentation(item.international_freight) }}</div>
          </div>
          <!-- 预计交付时间 -->
          <div class="time">
            <div class="left">{{ $fanyi('预计交货时间') }}:</div>
            <div class="right">{{ item.logistics.useful_rule?.predict_duration }}</div>
          </div>
          <!-- 物流说明 -->
          <div class="shuming">
            <h5>{{ $fanyi('物流说明') }}</h5>
            <p>{{ item.logistics.useful_rule?.description }}</p>
          </div>
        </div>
        <span v-if="item.is_recommend == 1" class="tuijian">{{ $fanyi('推荐方式') }}</span>
      </div>

    </div>
  </div>
  <van-dialog id="dialong" v-model:show="show" show-cancel-button>
    <!-- default	自定义内容 title	自定义标题footer -->
    <template #title>
      <span>{{
        $fanyi("为什么现在估计装运?") }}</span>


      <img @click="show = false" src="../../../assets/cart/close.svg" alt="" />
    </template>
    <template #default>
      <p>{{ $fanyi('为了提供最好的进口报价，一旦产品到达我们的仓库，就会计算出准确的运费和其他费用报价，所以现在你只支付产品。') }}</p>
      <p>{{ $fanyi('由于货物的分组和其他数据，我们可以提供一个更好的最终报价的运输。') }}</p>
      <p>{{ $fanyi('这一细分包括产品的质量控制、合同规定的额外服务，以及在适当情况下合同规定的其他费用。') }}</p>
    </template>

    <template #footer>
      <div class="cofim" @click="show = false">{{ $fanyi('确认') }}</div>
    </template>
  </van-dialog>
</template>
<script setup>
import { getCurrentInstance, ref, defineProps, defineEmits } from 'vue'
const { proxy } = getCurrentInstance()
console.log(proxy);
const props = defineProps({
  wuliulist: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['updatelogistics'])
const Selectlogistics = (item, index) => {
  console.log(props.wuliulist);
  props.wuliulist.forEach((i) => {
    if (item.id == i.id) {
      i.check = true
    } else {
      i.check = false
    }
  })

  emit('updatelogistics', item.logistics_id, index)
}

const show = ref(false)

</script>
<style lang="scss">
#dialong {
  width: 320px;
  min-height: 210px;


  border-radius: 8px;

  .van-dialog__header {

    display: flex;
    align-items: center;
    padding: 10px 20px;
    justify-content: space-between;
    min-height: 50px;
    border-bottom: 0.5px solid #DBDBDB;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      text-align: left;
      line-height: 20px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  .van-dialog__content {
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    // zoom: 0.9;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 18px;
    padding-bottom: 20px;
  }

  .cofim {
    width: 120px;
    height: 40px;
    margin: auto;
    background: #FF730B;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
    margin-bottom: 30px;
  }
}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.wuliu {
  margin-top: 30px;
  width: 100%;

  min-height: 60px;
  background: #F6F6F6;

  h2 {
    font-size: 14px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #222222;
    line-height: 20px;
    margin-bottom: 10px;

  }

  .why {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    img {
      margin-right: 3px;
      width: 12px;
      height: 12px;
      line-height: 14px;

    }

    p {
      font-size: 10px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #FF730B;
      line-height: 14px;

    }
  }

  .wuliulist {
    width: 100%;

    :deep().van-icon {
      font-size: 14px;
    }

    .wuliulitem {
      position: relative;
      max-height: 68px;
      background: #FFFFFF;
      border-radius: 6px;
      padding: 0 15px;
      border: 0.5px solid #E2E2E2;
      margin-bottom: 10px;

      transition: 0.3s;
      overflow: hidden;

      .top-title {
        width: 100%;
        height: 68px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          display: flex;
          align-items: center;

          .checkbox {
            width: 16px;
            height: 16px;
            border-radius: 50px;
            border: 1px solid #CDCDCD;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              display: none;

            }
          }

          .name {
            font-size: 12px;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            color: #222222;
            line-height: 18px;
            margin-left: 10px;
          }

        }

        .right {
          display: flex;
          align-items: center;
        }

      }

      .content {
        width: 100%;
        border-top: 1px dashed #DBDBDB;
        padding-top: 15px;

        .yunfei,
        .time {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .left {
            font-size: 12px;
            font-family: ArialMT;
            color: #222222;
            line-height: 18px;
          }

          .right {
            font-size: 12px;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            color: #222222;
            font-weight: 600;
            line-height: 18px;
          }
        }

        .time {
          margin-bottom: 14px;
        }

        .shuming {
          margin-bottom: 15px;

          h5 {
            font-size: 10px;
            font-family: ArialMT;
            color: #999999;
            line-height: 14px;
            margin-bottom: 5px;
          }

          p {
            font-size: 10px;
            font-family: ArialMT;
            color: #999999;
            line-height: 14px;
          }

        }

      }


      .tuijian {
        position: absolute;
        left: 0;
        top: 0;
        padding: 0 5px;
        min-width: 68px;
        height: 20px;
        background: #608BFF;
        border-radius: 6px 0px 6px 0px;

        font-size: 10px;
        font-family: ArialMT;
        color: #FFFFFF;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .Selectcheckbox {
    width: 16px;
    height: 16px;
    border-radius: 50px;
    border: 1px solid #608BFF !important;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      width: 8px;
      height: 8px;
      background: #608BFF !important;
      display: block !important;
      border-radius: 50px;
    }
  }

  .maxheight {
    max-height: 1000px !important;

  }


}
</style>