<template>
  <!--  地址表单-->
  <div class="dizhiform">
    <!-- 顶部选择地址 -->
    <div class="top-box">
      <h2>{{ $fanyi('收货地址') }}</h2>
      <van-field class="selectdizhi" v-model="useraddress" is-link readonly :placeholder="$fanyi('选择')"
        @click="showPicker = true" />
      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker :columns="Addressarr" @cancel="showPicker = false" @confirm="AdderssonConfirm" />
      </van-popup>
    </div>
    <!-- 表单区域 -->
    <div class="registerform">
      <van-form ref="ruleform">
        <van-cell-group inset>
          <!-- 收件人 -->
          <van-field @blur="validateField('contacts')" autocomplete="off" v-model.trim="registerform.contacts" :class="{
            errinput: registerRule.contacts == 0,
            correctinput: registerRule.contacts == 2,
          }" name="contacts" label-align="top" :label="$fanyi('收件人')" :placeholder="$fanyi('请输入收件人')" :rules="[
  {
    required: true,
    message: $fanyi('请输入收件人'),
    trigger: 'onBlur',
  },
  {
    validator: validatorcontacts,
    trigger: 'onBlur',
  },
]">
            <template #right-icon>
              <van-icon name="success" v-if="registerRule.contacts == 2" />
            </template>
          </van-field>

          <!-- 州 -->
          <van-field class="state" style="color:#333" :disabled="true" @click="showstate = true" autocomplete="off"
            v-model.trim="registerform.state" :class="{
              errinput: registerRule.state == 0,
              correctinput: registerRule.state == 2,
            }" name="state" label-align="top" :label="$fanyi('州')" :placeholder="$fanyi('请选择州')" :rules="[
  {
    required: true,
    message: $fanyi('请选择州'),
    trigger: 'onBlur',
  },
]">
            <template #right-icon>
              <van-icon name="success" v-if="registerRule.state == 2" />
              <van-icon name="arrow-down" />
            </template>
          </van-field>
          <!-- 州弹出层 -->
          <van-popup v-model:show="showstate" round position="bottom" @close="validateField('state')">
            <van-picker :columns="columns" :title="$fanyi('州')" :cancel-button-text="$fanyi('取消')"
              :confirm-button-text="$fanyi('确认')" @cancel="showstate = false" @confirm="onConfirm" />
          </van-popup>

          <!-- 邮编 -->
          <van-field class="zip_code" @blur="validateField('zip_code')" autocomplete="off"
            v-model.trim="registerform.zip_code" :class="{
              errinput: registerRule.zip_code == 0,
              correctinput: registerRule.zip_code == 2,
            }" name="zip_code" label-align="top" :label="$fanyi('邮编')" :placeholder="$fanyi('请输入邮编')" :rules="[
  {
    required: true,
    message: $fanyi('请输入正确的邮编'),
    trigger: 'onBlur',
  },
  {
    validator: validatorzip_code,
    trigger: 'onBlur',
  },
]">
            <template #right-icon>
              <van-icon name="success" v-if="registerRule.zip_code == 2" />
            </template>
          </van-field>
          <!-- 城市 -->
          <van-field class="city" @blur="validateField('city')" autocomplete="off" v-model.trim="registerform.city"
            :class="{
              errinput: registerRule.city == 0,
              correctinput: registerRule.city == 2,
            }" name="city" label-align="top" :label="$fanyi('城市')" :placeholder="$fanyi('请输入城市')" :rules="[
  {
    required: true,
    message: $fanyi('不能为空'),
    trigger: 'onBlur',
  },

]">
            <template #right-icon>
              <van-icon name="success" v-if="registerRule.city == 2" />
            </template>
          </van-field>
          <!-- 地址 -->
          <van-field class="address" @blur="validateField('address')" autocomplete="off"
            v-model.trim="registerform.address" :class="{
              errinput: registerRule.address == 0,
              correctinput: registerRule.address == 2,
            }" name="address" label-align="top" :label="$fanyi('地址')" :placeholder="$fanyi('请输入地址')" :rules="[
  {
    required: true,
    message: $fanyi('不能为空'),
    trigger: 'onBlur',
  },

]">
            <template #right-icon>
              <van-icon name="success" v-if="registerRule.address == 2" />
            </template>
          </van-field>
          <!-- 电话 -->
          <van-field class="mobile" @blur="validateField('mobile')" autocomplete="off" v-model.trim="registerform.mobile"
            :class="{
              errinput: registerRule.mobile == 0,
              correctinput: registerRule.mobile == 2,
            }" name="mobile" label-align="top" :label="$fanyi('手机号')" :placeholder="$fanyi('请输入您的手机号')" :rules="[
  {
    required: true,
    message: $fanyi('手机号不能为空'),
    trigger: 'onBlur',
  },
  {
    validator: validatormobile,
    trigger: 'onBlur',
  },
]">
            <!-- 自定义左侧icon -->
            <template #left-icon> +001 </template>
            <template #right-icon>
              <van-icon name="success" v-if="registerRule.mobile == 2" />
            </template>
          </van-field>


          <!-- 邮箱 -->
          <van-field class="email" @blur="validateField('email')" autocomplete="off" v-model.trim="registerform.email"
            :class="{
              errinput: registerRule.email == 0,
              correctinput: registerRule.email == 2,
            }" name="email" label-align="top" :label="$fanyi('邮箱')" :placeholder="$fanyi('请输入邮箱')" :rules="[
  {
    required: true,
    message: $fanyi('请输入正确的邮箱地址'),
    trigger: 'onBlur',
  },
  {
    validator: validatoremail,
    trigger: 'onBlur',
  },
]">
            <template #right-icon>
              <van-icon name="success" v-if="registerRule.email == 2" />
            </template>
          </van-field>



        </van-cell-group>
      </van-form>




    </div>

  </div>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose } from 'vue'
import stateList from '../../../utlis/allImportData'
import { showFailToast, } from 'vant'
// closeToast,showLoadingToast showToast
const { proxy } = getCurrentInstance()
console.log(proxy);
// 收获地址
const Addressarr = ref([])// 收货地址列表

const ruleArr = ['contacts', 'zip_code', 'state', 'email', 'mobile', 'city', 'address']// 校验数组

const showPicker = ref(false) // 弹出层地址
const showstate = ref(false) //州弹出曾
const useraddress = ref('')// 用户地址

const ruleform = ref('') //表单dom

// 密码框类型


// 收货地址表单数据
const registerform = ref({
  contacts: '', //收件人
  state: '', //州
  email: '', // 邮箱
  address: '',//地址
  mobile: '', //手机号，
  zip_code: '',// 邮编
  city: '', //城市
})
// 数据类型校验 0 错误， 1 正常 ，2 正确
const registerRule = ref({
  contacts: 1, //sh
  zip_code: 1,// 邮编
  state: 1, //州
  email: 1, // 邮箱
  mobile: 1, //手机号，
  city: 1, //城市
  address: 1,//地址

})
// 州弹出层数据
const columns = ref([])
//获取用户地址列表
const getUserAddress = () => {
  proxy.$api.useraddressList().then((res) => {
    if (res.code != 0) {
      showFailToast(res.msg)
      return
    }
    console.log(res);

    if (res.data.consignee.length > 0) {
      res.data.consignee.forEach((item, index) => {
        Addressarr.value.push({
          text: proxy.$fanyi("地址") + (index + 1),
          value: JSON.stringify(item),
        })
      })
    }
    // Addressarr.value = res.data.consignee;


    Addressarr.value.forEach((item, index) => {
      if (JSON.parse(item.value).acquiescent == true) {
        useraddress.value = proxy.$fanyi("地址") + (index + 1);

        registerform.value = JSON.parse(item.value)
        ruleArr.forEach((i) => {
          validateField(i)
        })
      }
    });

  });
}
getUserAddress()
stateList.provincesList.forEach((item) => {
  columns.value.push({
    text: item,
    value: item,
  })
})
// 州
const onConfirm = ({ selectedOptions }) => {
  showstate.value = false
  registerform.value.state = selectedOptions[0].text

}
// 地址
const AdderssonConfirm = ({ selectedOptions }) => {

  showPicker.value = false
  registerform.value = JSON.parse(selectedOptions[0].value)
  useraddress.value = selectedOptions[0].text
  ruleArr.forEach((i) => {
    validateField(i)
  })
}
// 失去焦点校验表单 然后改变状态
const validateField = (type) => {
  ruleform.value
    .validate(type)
    .then(() => {
      registerRule.value[type] = 2
    })
    .catch(() => {
      console.log('11')
      registerRule.value[type] = 0
    })
}
// 收件人
const validatorcontacts = (val) => {
  console.log(val)
  if (val.length > 24 || val.length < 1) {
    return proxy.$fanyi('姓氏长度不能大于24位')
  }
}

// 邮编校验
const validatorzip_code = (val) => {
  if (
    /^(\w){5}$/.test(
      val
    ) === false
  ) {
    return proxy.$fanyi('邮编输入错误')
  }
}
// 邮箱校验
const validatoremail = (val) => {
  if (
    /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/.test(
      val
    ) === false
  ) {
    return proxy.$fanyi('请输入正确的邮箱地址')
  }
  // proxy.$api
  //   .checkRegisterField({
  //     field: 'email',
  //     value: val,
  //   })
  //   .then((res) => {
  //     if (res.success == false) {
  //       registerform.value.email = ''
  //       registerRule.value.email = 1
  //       return showFailToast(res.msg)
  //     }
  //   })
}
// 电话校验
const validatormobile = (val) => {
  if (/^(\w){10}$/.test(val) === false) {
    return proxy.$fanyi('请输入正确的手机号')
  }
  // proxy.$api
  //   .checkRegisterField({
  //     field: 'mobile',
  //     value: val,
  //   })
  //   .then((res) => {
  //     if (res.success == false) {
  //       registerform.value.mobile = ''
  //       registerRule.value.mobile = 1
  //       return showFailToast(res.msg)
  //     }
  //   })
}

/* eslint-disable*/

defineExpose({
  ruleform,
  registerform
})
</script>
<style >
.van-field__control:disabled {
  -webkit-text-fill-color: #333 !important;
}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

:deep().van-field {
  margin: 0px !important;
  padding: 0px !important;
  // overflow: unset;

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    background-color: transparent !important;
    color: #333;
  }
}


.dizhiform {
  width: 100%;
  min-height: 630px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 0.5px solid #E2E2E2;
  padding: 15px;
  margin-bottom: 10px;

  .top-box {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 12px;
      font-family: Arial-BoldMT, Arial;
      font-weight: 600;
      color: #222222;
      line-height: 18px;
    }

    .selectdizhi {
      width: 160px;
      height: 34px;
      background: #FFFFFF;
      border-radius: 6px;
      border: 0.5px solid #E2E2E2;
      display: flex;
      font-size: 10px;
      padding-right: 10px !important;
      align-items: center;
      padding-left: 10px !important;

      :deep().van-icon-arrow {
        font-size: 18px;
        transform: rotate(90deg);
      }
    }

  }

  .registerform {
    :deep().van-cell {
      flex-direction: column;
      min-height: 92px;
      padding: 0px;
      width: 325px;
    }

    :deep().van-cell__value {
      width: 325px;
    }

    :deep() .van-field__body {
      width: 325px;
      box-sizing: border-box;
      position: relative;
      margin: 0px !important;


      input {
        width: 99.2%;
        height: 40px;

        border-radius: 6px;
        border: 0.5px solid #dedede;
        padding-left: 10px;
        font-size: 12px;
        font-family: ArialMT;

        color: #222222;
        line-height: 18px;
      }

      input::placeholder {
        font-size: 12px;
        font-family: ArialMT;
        color: #c0c4cc;
        line-height: 18px;
      }
    }

    // 手机号码
    .mobile {
      :deep().van-field__body {
        padding-right: 3px;
        display: flex;
        justify-content: flex-end;

        input {
          width: 265px;
        }
      }

      :deep() .van-field__left-icon {
        position: absolute;
        top: 26px;
        width: 50px;
        height: 40px;
        background: #ffffff;
        border-radius: 6px;
        border: 0.5px solid #dedede;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }



    :deep() .van-field__right-icon {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;

      right: 15px;

      img {
        width: 18px;
        height: 18px;
      }
    }

    :deep() .van-icon-arrow-down {
      margin-left: 10px;
    }

    // 错误
    .errinput {
      :deep() input {
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #e74c3c;
      }
    }

    //  正确
    .correctinput {
      :deep() input {
        background: #fff8f3;
        border-radius: 6px;
        border: 1px solid #ff730b;
      }
    }

    // 下一步
    .Consentagreement {
      width: 290px;
      height: 40px;
      font-size: 10px;
      font-family: ArialMT;
      color: #ffffff;
      line-height: 20px;
      background: #608bff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      // zoom: 0.8;
      letter-spacing: -0.5px;
    }

    .Privacy {
      margin-top: 20px;
      display: flex;
      align-items: center;

      .Privacy-left {
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;
        display: flex;
        align-items: center;

        :deep().van-checkbox {
          display: flex;
          align-items: center;
          vertical-align: middle;
        }

        .text {
          // margin-left: 5px;
          display: flex;
          align-items: center;
        }

        :deep().van-icon-success {
          display: flex;
          color: #fff;
          // margin-top: -0.5px;
        }

        :deep().van-badge__wrapper {
          display: flex;
          width: 12px;
          height: 12px;
        }

        :deep().van-badge__wrapper::after {
          display: flex;
          width: 12px;
          height: 12px;
        }

        :deep().van-checkbox__icon {
          display: flex;
          width: 12px;
          height: 12px;
        }

        :deep().van-icon-success:before {
          width: 12px;
          height: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
        }

        :deep().van-checkbox__label {
          display: flex;
          font-size: 12px;
          font-family: ArialMT;
          color: #222222;
          // padding-top: 1px;
          vertical-align: middle;
          margin-left: 5px !important;
          // letter-spacing: -0.5px;
        }

        .agreement {
          font-size: 12px;
          font-family: ArialMT;
          color: #ff730b;
          line-height: 18px;
          letter-spacing: -0.5px;
        }
      }
    }

    :deep().van-icon-success {
      color: #ff730b;
    }

    :deep().van-field__label {
      width: 100%;
      font-size: 12px;
      font-family: ArialMT;
      color: #222222;
      line-height: 18px;
      margin-bottom: 8px !important;
    }

    :deep() .van-cell:after {
      border-bottom: none !important;
    }

    :deep().van-field__error-message {
      padding-top: 2px;
      -webkit-text-size-adjust: none;
      font-size: 10px !important;
      line-height: 15px;
      // height: 45px;
      zoom: 0.9;
    }

    :deep().van-cell-group {
      margin: 0px;
    }
  }
}
</style>