<template>
  <!-- RAKUintroduce -->
  <div class="rakuintroduce">
    <!-- 顶部标题 -->
    <h2>{{ $fanyi('Rakumart，我们帮助您从中国进口') }}</h2>
    <p>
      {{ $fanyi('Rakumart让您轻松获得从中国进口的“一站式”服务，包括工厂采购、质量检验、附加服务和国际航运。') }}
    </p>
    <!-- 列表区域 -->
    <div class="goodlist">
      <!-- 用户指南 -->


      <div class="item">

        <div class="img-box">
          <img src="../../../assets/index/picture1.png" alt="">
          <h3>{{ $fanyi('全面、便捷的中国进口管理。') }}</h3>
        </div>
        <!-- 底部 -->
        <div class="footer-text">

          <p>{{ $fanyi('专注于你的事业') }}</p>
          <h3>{{ $fanyi("用户指南") }} ></h3>
        </div>
      </div>


      <!-- 国际物流 -->

      <div class="item">
        <div class="img-box">
          <img src="../../../assets/index/picture2.png" alt="">
          <h3>{{ $fanyi('我们为您进口并优化所有物流成本') }}</h3>
        </div>

        <!-- 底部 -->
        <div class="footer-text">

          <p>{{ $fanyi('完整的工厂物流') }}</p>
          <h3>{{ $fanyi("后勤服务") }} ></h3>
        </div>
      </div>
      <!-- 手续费 -->

      <div class="item">
        <div class="img-box">
          <img src="../../../assets/index/picture3.png" alt="">
          <h3>{{ $fanyi('公平和有价值的佣金为您的业务') }}</h3>
        </div>
        <!-- 底部 -->
        <div class="footer-text">

          <p>{{ $fanyi('多消费，少佣金') }}</p>
          <h3>{{ $fanyi("佣金") }} ></h3>
        </div>
      </div>
      <!-- 附加服务 -->

      <div class="item">
        <div class="img-box">
          <img src="../../../assets/index/picture4.png" alt="">
          <h3>{{ $fanyi('为所有产品需求提供额外服务') }}</h3>
        </div>
        <!-- 底部 -->
        <div class="footer-text">

          <p>{{ $fanyi('服务和整体解决方案') }}</p>
          <h3>{{ $fanyi("额外服务") }} ></h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance } from 'vue'
const { proxy } = getCurrentInstance()
console.log(proxy)
</script>
<style lang="scss" scoped="scoped">
@import '@/style/scss/mixin.scss';

.rakuintroduce {
  width: 100%;
  height: 318px;
  margin-bottom: 26px;
  padding: 0 10px;
  padding-right: 0px;

  h2 {
    font-size: 14px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #222222;
    line-height: 20px;
    margin-bottom: 10px;
  }

  P {
    font-size: 12px;
    font-family: ArialMT;
    color: #222222;
    line-height: 18px;
    margin-bottom: 20px;
  }

  .goodlist {
    width: 100%;
    height: 214px;
    top: 64px;

    display: flex;
    overflow-x: scroll;
    border-radius: 6px 6px 0 0;

    .item {

      flex: 0 0 290px;

      height: 214px;
      margin-right: 10px;
      background: #FFFFFF;
      border-radius: 6px;
      border: 0.5px solid #E2E2E2;

      .img-box {
        width: 290px;
        height: 140px;
        position: relative;
        border-radius: 6px 6px 0 0;

        img {
          width: 100%;
          height: 100%;
          border-radius: 6px 6px 0 0;
        }

        h3 {
          position: absolute;
          width: 250px;
          left: 20px;
          font-size: 14px;
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #FFFFFF;
          line-height: 20px;
          top: 50px;
        }
      }

      .footer-text {
        padding-left: 20px;
        padding-top: 15px;

        p {
          font-size: 14px;
          font-family: ArialMT;
          color: #222222;
          margin-bottom: 4px;
          line-height: 20px;
        }

        h3 {
          font-size: 14px;
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #222222;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
