// 配置按需引入vant组件
import {
  Button,
  Icon,
  Image as VanImage,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  PullRefresh,
  Collapse,
  CollapseItem,
  Stepper,
  DropdownMenu,
  DropdownItem,
  Loading,
  Skeleton,
  Progress,
  Switch,
  Tab,
  BackTop,
  List,
  Sticky,
  Calendar,
  ActionSheet,
  Lazyload,
  Dialog,
  Form,
  Field,
  
  Search,
  ImagePreview ,
  Popup,
  Picker,
  NavBar,
  Empty,
  CellGroup,
  Toast,
  Swipe,
  Tabs,
  SwipeItem,
  Popover,

  Uploader,
  Cell,
} from "vant"

const  components=[ Button,
  Icon,
 VanImage,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  PullRefresh,
  Collapse,
  CollapseItem,
  BackTop,
  Stepper,
  DropdownMenu,
  DropdownItem,
  Loading,
  ImagePreview ,
  Skeleton,
  Switch,
  Tab,
  List,
  Progress,
  Sticky,
  Calendar,
  ActionSheet,
  Lazyload,
  Dialog,
  Form,
  Field,
  Search,
  Popup,
  Picker,
  NavBar,
  Empty,
  CellGroup,
  Toast,
  Swipe,
  Tabs,
  SwipeItem,
  Popover,
  Uploader,
  Cell,]
export default (app) => {
  components.forEach((item) => {
    app.use(item)
  })
}
