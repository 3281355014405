<template>
  <!-- 顶部搜索 -->
  <headbar :lefticon="false" />
  <!-- 视频维护1 分类 -->
  <div class="video-maintenance">

    <div class="content">
      <div class="top-vedio" @click="$fun.toPage('video-maintenance-type?type=all')">
        <img src="../../assets/staticpage/video-maintenance/video.svg" alt="">
        <span>Video Maintenance</span>

      </div>
      <div class="sort">
        <div class="item" @click="$fun.toPage('video-maintenance-type?type=' + item.english_name + '&id=' + item.id)"
          v-for="item in list " :key="item.id">
          {{ item.english_name }}</div>
      </div>
    </div>


    <!-- 底部 -->
    <foot />
  </div>
  <!-- 底部导航 -->
  <tabbar />
</template>

<script setup>
import headbar from '../../components/headbar/index.vue'
import tabbar from '../homepageIndex/components/tabbar.vue'
import { getCurrentInstance, ref } from 'vue'
const { proxy } = getCurrentInstance()
import foot from "../../components/footer/index.vue";
const list = ref([])// 视频分组列表
const getVideoSummary = async () => {
  const res = await proxy.$api.getVideoSummary()

  if (res.code !== 0) {
    // this.$message.error(res.msg);
    return
  }
  // console.log(res);
  await res.data.forEach((i) => {

    list.value.push(i)
    // if (i.video_list.length > 0) {
    //   i.video_list.forEach((ind) => {
    //     let str = ind.video_link.replace(/youtu.be/g, "www.youtube.com/embed");
    //     ind.url = str
    //     ind.load = true
    //   })
    // } else {
    //   i.class_mark.forEach((ite) => {
    //     ite.video_list.forEach((item) => {
    //       let str = item.video_link.replace(/youtu.be/g, "www.youtube.com/embed");

    //       item.url = str
    //       item.load = true

    //     })
    //   })
    // }

  })
}
getVideoSummary()
</script>

<style lang="scss" scoped>
.video-maintenance {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 60px;

  .content {
    border-top: 0.5px solid #E2E2E2;
    border-bottom: 0.5px solid #E2E2E2;

    min-height: 230px;


    .top-vedio {
      width: 100%;
      width: 375px;
      height: 50px;
      background: #F6F6F6;
      padding: 0 25px;
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      span {
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;
      }

      &:active {
        color: #FF730B;
      }
    }

    .sort {
      width: 100%;
      // padding-bottom: 20px;
      padding: 0 25px;
      padding-top: 30px;

      padding-bottom: 20px;

      .item {
        margin-bottom: 20px;
        font-size: 14px;
        font-family: ArialMT;
        color: #222222;
        line-height: 20px;

        &:active {
          color: #FF730B;
        }
      }
    }
  }

}
</style> 