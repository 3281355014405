<template>
  <!-- 支付失败页面 -->
  <div class="paypalPaymentError">
    <headbar :lefticon="false" />
    <div class="content">
      <div class="header">

        {{ $fanyi('支付失败') }}
      </div>

      <div class="main">
        <div class="icon"><van-icon name="cross" /> </div>


        <h2>{{ $fanyi('对于给您带来的不便，我们深表歉意，但请重新操作。') }}</h2>
        <div class="item" @click="$router.push('/')">{{ $fanyi('返回首页') }}</div>
        <div class="item" @click="$router.push('/user/index')">{{ $fanyi('个人中心') }}</div>
        <div class="item" @click="$router.push('/user/churujinList')">{{ $fanyi('出入金记录') }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import headbar from '../../components/headbar/index.vue'
const router = useRouter();
console.log(router);
</script>

<style lang="scss" scoped>
.paypalPaymentError {
  width: 100%;
  height: 100vh;
  background-color: #f6f6f6;

  .content {

    width: 92%;
    height: 520px;
    background-color: #fff;
    margin: auto;
    margin-top: 10px;

    border-radius: 6px;

    .header {
      height: 48px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      font-size: 18px;
      color: black;
      font-weight: 600;

      border-bottom: 1px solid #f6f6f6;

    }

    .main {
      width: 100%;
      height: 100%;
      padding-top: 40px;

      .icon {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ff730b;
        border-radius: 50%;
        margin: auto;
        margin-bottom: 30px;

        :deep().van-icon-cross {
          font-size: 38px;
          color: #fff;
        }
      }

      h2 {
        width: 100%;
        text-align: center;
        font-size: 18px;

        line-height: 28px;
        width: 280px;
        margin: auto;
        text-align: center;
        margin-bottom: 38px;
      }

      .item {
        width: 280px;
        height: 48px;
        background: #fff;
        border: 1px solid #ff730b;
        border-radius: 3px;

        border-radius: 6px;


        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        color: #ff730b;
        font-size: 14px;
        padding: 0 15px;
        margin: auto;
        margin-bottom: 15px;


        &:last-child {
          background: #ff730b;
          color: #fff;
        }
      }

    }

  }
}
</style>