<template>
  <div class="slideImgs">
    <van-swipe class="my-swipe" indicator-color="white" :loop="false">
      <van-swipe-item v-if="details.video">
        <video class="bigVideo" ref="videoBox" muted autoplay controls>
          <source :src="details.video" type="video/mp4" />
          <source :src="details.video" type="video/ogg" />
          您的浏览器不支持 video 标签。
        </video>
      </van-swipe-item>
      <van-swipe-item v-for="(item, index) in details.images" :key="index">
        <van-image class="roundCastPicture" :src="item" @click="getBigImg(details.images, index)">
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, computed } from "vue";
import { showImagePreview } from "vant";
const { proxy } = getCurrentInstance();
const videoBox = ref('')
const details = computed(() => {
  return proxy.$parent.details;
});

const getBigImg = (image, index) => {
  showImagePreview({
    images: image,
    showIndex: true,
    loop: false,
    startPosition: index,
  });
};
</script>
<style lang="scss" scoped="scoped">
@import "@/style/scss/mixin.scss";

.slideImgs {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  height: 375px;

  .bigVideo {
    width: 100%;
    height: 375px;
  }

  .my-swipe .van-swipe-item {
    color: #dcdfe6;
    font-size: 12px;
    line-height: 12px;
    text-align: center;

    .roundCastPicture {
      width: 100%;
      height: 375px;
    }

    img {
      width: 100%;
      height: 375px;
    }
  }

  :deep().van-swipe__indicator--active {
    //
    background-color: #b4272b !important;
  }
}
</style>
