<template>
  <div class="Commodityclassification">
    <!-- 每一级 -->
    <div class="title-item" v-for="item in ShopGrouplist" :key="item.id">
      <!-- 一级分类 -->
      <div class="oneclassification" :class="{ openbgc: item.rotate }">
        <div class="left">
          <img v-if="item.rotate == false" :src="item.default_icon" alt="">
          <img v-else :src="item.trigger_icon" alt="">
          <span @click="
            $fun.toPage('/commoditySearch?keyword=' + item.translation_name + '&CN=' + $fun.toCode(item.chinese_name)
            )" :class="{ opentext: item.rotate }">{{ item.translation_name }}</span>
        </div>
        <div class="right">
          <!-- 默认 -->
          <van-icon name="arrow-down" :class="{ rotate: item.rotate }" @click="rotate(item)" />

        </div>
      </div>
      <!-- 二级分类 -->
      <div class="Secondaryclassification" :class="{ Secondaryclassificationhight: item.rotate }">
        <div class="item" v-for="gooditem in item.childs" :key="gooditem.id" @click="
          $fun.toPage('/commoditySearch?keyword=' + gooditem.translation_name + '&CN=' + $fun.toCode(gooditem.chinese_name)
          )">{{ gooditem.translation_name }}</div>
      </div>



    </div>



  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from 'vue'
import { showFailToast } from 'vant'
const { proxy } = getCurrentInstance()
// 获取商品分类数据
const ShopGrouplist = ref([])//商品分类数组
const getShopGroup = async () => {
  const res = await proxy.$api.getShopGroup()
  if (res.code !== 0) {
    showFailToast(proxy.$fanyi(res.msg))
    return
  }
  res.data.forEach((item) => {

    item.rotate = false
  })
  ShopGrouplist.value = res.data

}
getShopGroup()
// 点击展开图标
const rotate = (item) => {

  ShopGrouplist.value.forEach((i) => {
    if (i.id == item.id) {
      i.rotate = !i.rotate
    } else {
      i.rotate = false
    }
  })
}
</script>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.Commodityclassification {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 52px;
  overflow-y: scroll;

  .title-item {
    margin-bottom: 10px;

    .oneclassification {
      width: 375px;
      height: 40px;
      padding: 0 20px;

      // background-color: pink;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        span {
          font-size: 14px;
          font-family: ArialMT;
          color: #222222;

          &:active {
            color: #FF730B;
          }
        }


      }

      .right {

        :deep().van-icon-arrow-down {
          font-size: 13.3px;
          font-weight: 500px;
          transform: rotate(0deg);
          transition: 0.3s;
        }






        .rotate {
          transform: rotate(180deg);
          color: #ff730b;


        }
      }
    }



  }







  .Secondaryclassification {
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s;

    .item {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      margin: 10px 0;
      padding-left: 50px;
      font-size: 14px;

      &:active {
        color: #FF730B;
      }
    }

  }

  .opentext {
    font-size: 14px;
    font-family: ArialMT;
    color: #FF730B !important;
  }

  .openbgc {
    background: #FFF8F3;
  }

  .Secondaryclassificationhight {

    max-height: 10000px;


  }
}
</style>