<template>
  <!-- 底部导航 -->
  <div class="tabbar">

    <div class="item" @click="clickjump(0)">
      <!-- <img src="" alt=""> -->
      <!-- 菜单 -->
      <img src="../../../assets/tabber/1-0.svg" v-if="list[0].checked" alt="">
      <img src="../../../assets/tabber/1-1.svg" v-else alt="">
      <div class="title" :class="{ titlecolor: !list[0].checked }">{{ $fanyi('菜单') }}</div>
    </div>
    <div class="item" @click="clickjump(1)">
      <!-- <img src="" alt=""> -->
      <img src="../../../assets/tabber/2-0.svg" v-if="list[1].checked" alt="">
      <img src="../../../assets/tabber/2-1.svg" v-else alt="">
      <div class="title" :class="{ titlecolor: !list[1].checked }">{{ $fanyi('分类') }}</div>
    </div>
    <div class="item" @click="clickjump(2)">
      <!-- <img src="" alt=""> -->
      <img src="../../../assets/tabber/3.svg" alt="">

      <div class="title" :class="{ titlecolor: !list[2].checked }">{{ $fanyi('我的') }}</div>
    </div>
    <div class="item" @click="clickjump(3)">
      <!-- <img src="" alt=""cartnum> -->
      <div class="img-box"> <img src="../../../assets/tabber/4-0.svg" v-if="list[3].checked" alt="">
        <img src="../../../assets/tabber/4-1.svg" v-else alt="">
        <span v-if="cartnum > 0">{{ cartnum }}</span>
      </div>

      <div class="title" :class="{ titlecolor: !list[3].checked }">{{ $fanyi('购物车') }}</div>

    </div>
    <div class="item" :class="{ titlecolor: !list[3].checked }" @click="clickjump(4)">
      <!-- <img src="" alt=""> -->
      <img src="../../../assets/tabber/5-0.svg" v-if="list[4].checked" alt="">
      <img src="../../../assets/tabber/5-1.svg" v-else alt="">
      <div class="title" :class="{ titlecolor: !list[4].checked }">{{ $fanyi('聊天') }}</div>
    </div>
  </div>
  <!-- 菜单弹出层 -->
  <van-popup z-index="99" @click-close-icon="menuclose" closeable v-model:show="menushowLeft" position="left"
    :style="{ width: '100%', height: '100%' }">
    <menupopup />
  </van-popup>

  <!-- 商品分类弹出层 -->
  <van-popup z-index="99" @click-close-icon="close" closeable v-model:show="showLeft" position="left"
    :style="{ width: '100%', height: '100%' }">
    <classifypopup />
  </van-popup>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose } from 'vue'
import { useRoute } from 'vue-router'


import classifypopup from '../../../components/classifypopup/index'
import menupopup from '../../../components/menupopup/index.vue'
import { useStore } from 'vuex';
const { proxy } = getCurrentInstance();

console.log(proxy);
const route = useRoute()

const store = useStore()
const cartnum = ref(0)// 购物车条数
// 菜单弹出层
const menushowLeft = ref(false)
// 分类弹出层
const showLeft = ref(false)

const list = ref([
  {
    index: 0,
    checked: true,
  },
  {
    index: 1,
    checked: true,
  },
  {
    index: 2,
    checked: true,
  },
  {
    index: 3,
    checked: true,
  },
  {
    index: 4,
    checked: true,
  },


])
if (route.name == 'cart') {
  // 说明在购物车图标高亮
  list.value[3].checked = false
}
// 获取购物车数量
const goodsToCartNum = async () => {
  const res = await proxy.$api.goodsToCartNum()
  if (res.code != 0) return
  cartnum.value = res.data
}
goodsToCartNum()
// 点击tabar
const clickjump = (index) => {

  list.value.forEach((item) => {
    if (item.index == index) {
      item.checked = !item.checked
    } else {
      item.checked = true
    }
  })

  switch (index) {
    case 0:
      menushowLeft.value = !menushowLeft.value
      showLeft.value = false
      break;
    case 1:
      // 当 expression 等于 value2 时执行的代码
      showLeft.value = !showLeft.value
      menushowLeft.value = false
      break;
    case 2:
      // $fun.routerToPage(
      //     '/CommoditySearch?keyword=' +
      //     bigItem.translate_name +
      //     '&CN=' +
      //     $fun.toCode(bigItem.chinese_name)
      // )

      if (!proxy.$store.state.userInfo) {
        store.commit('changeshowLoginDia', true) //打开登录弹窗
        return
      }
      menushowLeft.value = false
      showLeft.value = false
      proxy.$fun.routerToPage('/user/index')
      // showMine.value = !showMine.value
      // 当 expression 等于 value2 时执行的代码
      break;
    case 3:

      proxy.$fun.toPage('/cart')
      // 当 expression 等于 value2 时执行的代码
      break;
    case 4:
      //var element = document.getElementById("zsiq_float");
      // 创建事件

      //  var event = document.createEvent("MouseEvents");

      //  console.log(element);
      // 定义事件 参数： type, bubbles, cancelable
      //  event.initEvent("click", true, true);


      // 触发对象可以是任何元素或其他事件目标
      //    element.dispatchEvent(event);
      // if (!proxy.$store.state.userInfo) {

      //   store.commit('changeshowLoginDia', true) //打开登录弹窗
      //   return
      // }
      // 当 expression 等于 value2 时执行的代码
      // 创建一个 a 标签
      var link = document.createElement('a');

      // 设置 href 和 target 属性
      link.href = 'https://wa.me/+8615957125759';
      link.target = '_blank';

      // 模拟点击事件以打开链接
      link.click();

      // 清理 DOM
      document.body.appendChild(link); // 将 a 标签添加到 DOM 中
      link.remove(); //
      break;
    default:

      // 当 expression 不等于任何 case 时执行的代码
      break;
  }


}
// 菜单关闭
const menuclose = () => {
  list.value[0].checked = true;
}
// 分类关闭
const close = () => {
  list.value[1].checked = true;
}

defineExpose({
  goodsToCartNum,
  cartnum,
})

</script>
<style>
.van-popup__close-icon {
  font-size: 18px;
  color: #222222 !important;
  font-weight: 500;
  right: 20px;
}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";



.tabbar {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: inset 0px 0.5px 0px 0px #E2E2E2;
  padding: 0 14px;

  align-items: center;

  .item {
    width: 48px;
    min-height: 48px;
    padding: 0px !important;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img {
      width: 30px;
      height: 30px;
      margin-bottom: 5px;
    }

    .img-box {
      width: 30px;
      height: 30px;
      position: relative;

      img {
        width: 100%;
        height: 100% // margin-bottom: 3px;
      }

      span {
        position: absolute;
        height: 12px;
        font-size: 12px;
        font-family: ArialMT;
        top: -2px;
        right: 2px;
        color: #FFFFFF;
        transform: translateX(50%);
        line-height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 18px;
        border-radius: 50%;
        height: 18px;
        background: #FF730B;

      }
    }


    .title {
      font-size: 10px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #222222;
      line-height: 10px;
      display: flex;
      text-align: center;
      justify-content: center;
      letter-spacing: -0.5px;
    }

    .titlecolor {
      color: #ff730b;
    }

    // &:nth-child(1) {
    //   img {}
    // }

    // &:nth-child(2) {
    //   img {}
    // }

    // &:nth-child(3) {
    //   img {}
    // }

    // &:nth-child(4) {
    //   img {}
    // }

    // &:nth-child(5) {
    //   img {}
    // }

  }

}

// 菜单
.menu {
  width: 100%;
  height: 100%;

  padding-top: 44px;

  .userinfo {
    width: 100%;
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      height: 100%;
      display: flex;
      align-items: center;

      .img-box {
        margin-right: 10px;

        img {
          width: 32px;
          height: 32px;
        }

        :deep().van-image {
          width: 32px;
          height: 32px;
          border-radius: 50px;
        }

        :deep().van-image__img {
          width: 32px;
          height: 32px;
          border-radius: 50px;
        }
      }

      .text {

        div {
          font-size: 16px;
          font-family: ArialMT;
          color: #222222;

          line-height: 24px;

          span {
            font-family: ArialMT;
            color: #222222;

            line-height: 24px;

            &:first-child {
              margin-right: 3px;
            }

            // &:last-child {
            //   margin-left: 2px;
            // }
          }
        }

        p {
          font-size: 16px;
          font-family: ArialMT;
          color: #222222;
          line-height: 24px
        }
      }
    }

    .logout {
      min-width: 64px;
      height: 26px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 0.5px solid #DEDEDE;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .classification {

    width: 100%;
    padding-top: 30px;
    padding-bottom: 50px;


    .item {
      padding: 0 20px;
      width: 100%;
      height: 40px;
      display: flex;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
      align-items: center;

      &:active {
        background: #f5f7fa;
        border-radius: 6px;
        color: #ff730b;
      }
    }

    border-bottom:1px solid #DBDBDB;
    ;
  }

  .footerclassification {

    width: 100%;

    .item {
      padding: 0 20px;
      width: 100%;
      height: 40px;
      display: flex;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
      align-items: center;
      margin: 10px 0;

      &:active {
        border-radius: 6px;
        background: #f5f7fa;
        color: #ff730b;
      }
    }
  }

}

.Commodityclassification {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 52px;

  .title-item {
    margin-bottom: 10px;

    .oneclassification {
      width: 375px;
      height: 40px;
      padding: 0 20px;

      // background-color: pink;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        span {
          font-size: 14px;
          font-family: ArialMT;
          color: #222222;

          &:active {
            color: #FF730B;
          }
        }


      }

      .right {

        :deep().van-icon-arrow-down {
          font-size: 18px;
          font-weight: 500px;
          transform: rotate(0deg);
          transition: 0.3s;
        }






        .rotate {
          transform: rotate(180deg);
          color: #ff730b;


        }
      }
    }



  }







  .Secondaryclassification {
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s;

    .item {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      margin: 10px 0;
      padding-left: 50px;
      font-size: 14px;

      &:active {
        color: #FF730B;
      }
    }

  }

  .opentext {
    font-size: 14px;
    font-family: ArialMT;
    color: #FF730B !important;
  }

  .openbgc {
    background: #FFF8F3;
  }

  .Secondaryclassificationhight {

    max-height: 10000px;


  }
}
</style>
