<template>
  <hearbar bgcolor="white">
    <template v-slot:left-icon>
      <van-icon name="arrow-left" />
    </template>

    <template #default>
      <div>
        <h3>{{ $fanyi(title) }}</h3>
        <p>
          {{ $fanyi("配送单号") }}: <span>{{ orderdata?.porder_sn }}</span>
        </p>
      </div>
    </template>

  </hearbar>
  <div class="deliveryorderdetails" :class="{ padingbottom: ['等待付款'].indexOf(status_name) != -1 }">
    <!--顶部标题 -->
    <div class="topbox">
      {{ $fanyi('If there is any modification to the delivery order information,please contact your responsible person')
      }}

    </div>
    <!-- 配送单 -->
    <div class="order-item" :class="{ maxheght: item.check }" v-for="item in orderdata.result?.porder_detail"
      :key="item.id">

      <div class="ordersnbox">
        <div class="ordersn">
          {{ $fanyi('订单号') }} :
          <span>{{ item.order_sn }}</span>
        </div>
        <van-icon v-if="!item.check" @click="item.check = !item.check" name="arrow-down" />
        <van-icon v-else @click="item.check = !item.check" name="arrow-up" />
      </div>
      <!-- 商品 -->
      <div class="goodsitem" v-for="goodsitem in item.order_detail" :key="goodsitem.id">
        <div class="Content">
          <!-- 图片 -->
          <div class="img-box" @click="
            $fun.toCommodityDetails({
              goods_id: goodsitem.goods_id,
              shop_type: goodsitem.shop_type,
            })
            ">
            <van-image :src="goodsitem.pic">
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </div>
          <!--右侧商品信息  -->
          <div class="rightgoodsinfo">
            <!-- 商品描述和删除 -->
            <div class="Description">
              <p class="text">{{ goodsitem.goods_title }}</p>
            </div>

            <div class="Commodityspecification">
              <p>{{ goodsitem.text }}</p>
            </div>

          </div>
        </div>

        <!-- 价格运费区域 -->
        <div class="totalprice"> <span>{{ $fanyi('总价') }}</span> <span>${{
          $fun.RMBNumSegmentation($fun.ceil(goodsitem.totalPrice * item.order.exchange_rate)) }}</span> </div>
        <div class="price"><span>{{ $fanyi('单价') }}</span> <span>${{
          $fun.RMBNumSegmentation($fun.ceil(goodsitem.confirm_price * item.order.exchange_rate)) }}</span> </div>
        <div class="num"> <span>{{ $fanyi('购买数量') }}</span> <span>{{ goodsitem.confirm_num }}</span></div>
        <div class="tichushu"><span> {{ $fanyi('本次提出数') }} </span> <span>{{ goodsitem.submitNum }}</span></div>
        <div class="freight"> <span>{{ $fanyi('国内运费') }}</span> <span> {{
          $fun.RMBNumSegmentation($fun.ceil(goodsitem.confirm_freight * item.order.exchange_rate)) }}</span> </div>
        <!-- 备注 -->
        <input type="text" class="remarks" :placeholder="$fanyi('无备注')" :disabled="true"
          v-model="goodsitem.client_remark">

      </div>


    </div>
    <!-- 收货地址 -->
    <div :class="{ maxaddress: addressall }" class="Receivingaddress">
      <div class="addresstopbox">
        <div class="left">{{ $fanyi('我的收货地址') }}</div>
        <div class="right"><van-icon @click="addressall = !addressall" v-if="!addressall" name="arrow-down" />
          <van-icon name="arrow-up" v-else @click="addressall = !addressall" />

        </div>
      </div>

      <div class="addresslist">
        <p><span>{{ $fanyi("收件人") }}:</span>
          <span>{{ orderaddress?.contacts ? orderaddress?.contacts : '-----' }}</span>
        </p>
        <p><span>{{ $fanyi("州") }}:</span> <span>{{ orderaddress?.state ? orderaddress?.state : '-----' }}</span> </p>
        <p><span>{{ $fanyi("邮编") }}:</span> <span> {{ orderaddress?.zip_code ? orderaddress?.zip_code : '-----' }}</span>
        </p>
        <p><span>{{ $fanyi("城市") }}:</span> <span> {{ orderaddress?.city ? orderaddress?.city : '-----' }}</span> </p>
        <p><span>{{ $fanyi("街道") }}:</span> <span> {{ orderaddress?.address ? orderaddress?.address : '-----' }}</span>
        </p>
        <p><span>{{ $fanyi("手机号") }}:</span> <span> {{ orderaddress?.mobile ? orderaddress?.mobile : '-----' }}</span>
        </p>
        <p><span> {{ $fanyi("邮箱") }}:</span> <span> {{ orderaddress?.email ? orderaddress?.email : '-----' }}</span>
        </p>


      </div>

    </div>
    <!-- 清关方式 -->
    <div class="Customsclearancemode">
      <h3>{{ $fanyi('清关方式') }}</h3>
      <input name="" id="" :disabled="true" v-model="personal_clearance" />
    </div>

    <!-- 选择国际运输公司 -->
    <div class="Internationaltransport">
      <h3>{{ $fanyi('选择国际运输公司') }}</h3>
      <input name="" id="" v-model="logistics" :disabled="true" />
    </div>

    <!-- 订单备注 -->
    <div class="Ordernote">
      <h3>{{ $fanyi('订单备注') }}</h3>
      <textarea name="" id="" v-model="client_remark" :disabled="true" />
    </div>
    <!-- 费用总额 -->
    <div class="Totalcost" v-if="['报价中', '临时保存'].indexOf(status_name) == -1">
      <div class="title">
        {{ $fanyi('费用总额') }}
      </div>

      <p> <span>{{ $fanyi('国际物流费用') }}</span> <span>$ {{ $fun.EURNumSegmentation(orderdata.result?.logistics_price)
      }}</span> </p>

      <p> <span>{{ $fanyi('美国物流成本') }}</span> <span>${{ $fun.EURNumSegmentation(orderdata.result?.foreign_express_price)
      }}</span>
      </p>

      <p> <span>{{ $fanyi('其他费用') }}</span> <span>${{ $fun.EURNumSegmentation(orderdata.result?.other_price) }}</span>
      </p>
      <div class="download" @click="getFapiao">{{ $fanyi('形式发票') }}</div>
    </div>

    <!-- 发货方式 -->
    <div class="Deliverymethod" :class="{ maxheght: showdelivery }" v-if="['报价中', '临时保存'].indexOf(status_name) == -1">
      <div class="title">
        <span> {{ $fanyi('发货方式') }}</span>
        <van-icon @click="showdelivery = !showdelivery" v-if="!showdelivery" name="arrow-down" />
        <van-icon name="arrow-up" v-else @click="showdelivery = !showdelivery" />
      </div>

      <div class="item" v-for="item in wuliu" :key="item.id" :class="{ bgc: item.type == 1 }">
        <p> <span>{{ $fanyi('国际物流') }}</span> <span>{{ item.foreign_express ? item.foreign_express : '-------'
        }}({{ item.type == 0 ? $fanyi('国际') : $fanyi('国内') }})</span> </p>

        <p> <span>{{ $fanyi('物流单号') }}</span> <span>{{ item.foreign_express_no ? item.foreign_express_no :
          '-------' }}</span>
        </p>

        <p> <span>{{ $fanyi('计费重量') }}(kg)</span> <span>{{ item.weight ? item.weight : '-------' }}
          </span>
        </p>
        <p> <span>{{ $fanyi('发货时间') }}</span> <span>{{ item.created_at ? item.created_at : '-------' }}
          </span>
        </p>
      </div>
    </div>
    <!-- 箱子信息 -->
    <div class="Boxinformation" v-if="['报价中', '临时保存'].indexOf(status_name) == -1">

      <div class="top-title">
        <span>{{ $fanyi('箱号') }}</span><span>{{ $fanyi('重量') }}(kg)</span> <span>{{ $fanyi('箱规') }}(cm)</span><span>{{
          $fanyi('体积') }}(m³)</span>

      </div>
      <div class="Caseitem" @click="Packinginformationdom.open(item)" v-for="(item, ind) in porder_freight"
        :key="item.id">

        <span> <span class="xiaoghao">{{ ind + 1 }}</span></span>
        <span>{{ item.weight }}</span>
        <span>{{ item.length + "*" + item.width + "*" + item.height }}</span>
        <span> {{ $fun.ceil(
          (item.length * item.width * item.height) /
          1000000,
          3
        )
        }}</span>
      </div>

    </div>


  </div>
  <!-- 底部展示栏 报价中-->
  <div class="footer-box" v-if="status_name == '报价中' || status_name == '临时保存'">

    <div class="total-box">
      <!-- 总种类 -->

      <div class="total">{{ $fanyi('商品种类') }} : <span>{{ ordertotal.shangPinZhongLei }}</span></div>
      <!-- 总数量 -->
      <div class="num"> {{ $fanyi('数量总计') }} :<span>{{ ordertotal.shuLiangZongJi }}</span> </div>
    </div>

  </div>
  <!--  其他状态底部操作栏 -->
  <div class="orthherfooterbox" v-if="status_name != '报价中' && status_name != '等待付款' && status_name != '临时保存'">
    <div class="total-box">
      <!-- 总种类 -->

      <div class="total">{{ $fanyi('商品种类') }} : <span>{{ ordertotal.shangPinZhongLei }}</span></div>
      <!-- 总数量 -->
      <div class="num"> {{ $fanyi('数量总计') }} :<span>{{ ordertotal.shuLiangZongJi }}</span> </div>
    </div>
    <div class="totalprice">

      <div class="price">{{ $fanyi('总价') }} :<span>$
          {{
            $fun.EURNumSegmentation(
              $fun.ceil(orderdata.result?.amount)
            )
          }}</span></div>
    </div>

  </div>
  <!-- 等待付款底部操作栏 -->
  <div class="paymentfoot-box" v-if="['等待付款'].indexOf(status_name) != -1">

    <div class="total-box">
      <!-- 总种类 -->

      <div class="quantity">
        <div class="total">{{ $fanyi('商品种类') }} : <span>{{ ordertotal.shangPinZhongLei }}</span></div>
        <!-- 总数量 -->
        <div class="num"> {{ $fanyi('数量总计') }} :<span>{{ ordertotal.shuLiangZongJi }}</span> </div>
      </div>
    </div>
    <!-- 优惠卷 -->
    <div class="couponsbox">
      <div class="left">
        <van-checkbox class="couponschexk" v-model="couponcheck" checked-color="#FF730B"
          @click="discountcoupondom.open('porder_sn')">{{ $fanyi("使用优惠券") }}</van-checkbox>
      </div>
      <div class="rightprice">
        {{ $fanyi("总价") }} :<span class="dollar">$
          {{
            $fun.EURNumSegmentation(
              $fun.ceil(orderdata.result?.amount)
            )
          }}</span>

      </div>
    </div>
    <!-- 支付 -->
    <div class="paymentbox">

      <div class="payment" @click="payment">
        {{ $fanyi('付款') }}
      </div>
    </div>

  </div>

  <!-- 弹层等待付款 -->
  <van-dialog id="porsersndialong" v-model:show="showdialog" title="标题" show-cancel-button>
    <!-- default	自定义内容 title	自定义标题footer -->
    <template #title>
      <span>{{ $fanyi("注意") }}</span>

      <img @click="showdialog = false" src="../../assets/cart/close.svg" alt="" />
    </template>
    <template #default>
      <!-- 单个 -->
      <div class="content">
        <p>
          Hello
          {{
            $store.state.userInfo?.user_name
          }}, you are currently waiting for the details page to pay for the delivery order.
        </p>
        <p>
          You can view the quotation information of specific international rates on the current page. After confirming
          that
          there is no problem, you can click the "To the payment" button to make payment. After confirming the receipt, we
          will arrange the logistics delivery. The logistics status will be sent to you by mail. If you need to adjust the
          products you send, please send the details to the seller. We will make a new quotation after adjusting according
          to
          your modified requirements.
        </p>
        <p>
          (Because directly adjusting the customer will cause the actual packaging data of the
          product to be abnormal, we have limited the function of directly adjusting the customer delivery order, please
          forgive us.)
        </p>
      </div>
    </template>

    <template #footer>
      <div class="deletefooter">
        <div class="queren" @click="showdialog = false">
          {{ $fanyi("已了解") }}
        </div>

      </div>
    </template>
  </van-dialog>
  <!-- 装箱信息弹层 -->
  <Packinginformation ref="Packinginformationdom" />
  <!-- 优惠卷弹层 -->
  <discountcoupon @updatacoupon="updatacoupon" ref="discountcoupondom" />
</template>
<script setup>

import { getCurrentInstance, ref } from 'vue'
import hearbar from "../../components/headGoBack/index.vue";
import { useRoute } from 'vue-router'
import { showLoadingToast, showFailToast, closeToast } from 'vant'
import discountcoupon from '../../view/orderdetails/components/discountcoupon.vue'
import Packinginformation from './components/Packinginformation.vue'
import axios from 'axios'
import Europess from '../../api/wangZhi'
let Europe = Europess + '/client/'
const { proxy } = getCurrentInstance()
const title = ref('配送单详情')
const route = useRoute()
const orderdata = ref('') // 订单数据
const addressall = ref(false)// 是否展示全部地址
const orderaddress = ref()// 订单收货地址
const personal_clearance = ref('')// 通关方式
const logistics = ref('')// 物流
const wuLiuOptions = ref([])// 物流列表
const client_remark = ref('')// 备注
const ordertotal = ref({
  shangPinZhongLei: 0, //商品种类
  shuLiangZongJi: 0, //数量总计
  zongJia: 0, //总价(人民币)
})
const status_name = ref('')// 订单状态名
const showdialog = ref(false)
const couponcheck = ref(false) // 是否使用优惠卷
const couponid = ref()// 优惠券id
const showdelivery = ref(false)// 是否展示发货方式
const wuliu = ref([])// 物流列表
const porder_freight = ref([])// 箱子列表
const Packinginformationdom = ref('')// 装修信息弹层dom
const discountcoupondom = ref('')// 优惠券弹层dom
showLoadingToast({
  duration: 0,
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
  loadingType: "spinner",
});
const getstorageDeliverDetail = async () => {
  const res = await proxy.$api.storageDeliverDetail({ porder_sn: route.query.order_sn })
  if (res.code != 0) {
    closeToast();
    console.log(res.data);
    if (res.code != 0) {
      showFailToast(res.msg);
      return;
    }
  }
  closeToast();
  res.data.result.porder_detail.forEach((item) => {
    item.order_detail.forEach((i) => {
      i.text = ''
      // 规格筛选拼接
      i.detail.forEach((detailitem) => {
        i.text += detailitem.key + ":" + " " + detailitem.value + " ";
      });
      i.totalPrice = proxy.$fun.ceil(
        i.confirm_num * i.confirm_price
      )
    })
  })
  orderaddress.value = res.data.result.receive
  orderdata.value = res.data;
  status_name.value = res.data.result.status_name
  if (status_name.value == '等待付款') {
    showdialog.value = true

  }
  // 物流
  wuliu.value = res.data.result.porder_foreign_express
  // 备注
  client_remark.value = res.data.result.client_remark
  // 箱子列表
  porder_freight.value = res.data.result.porder_freight
  // 通关方式
  if (res.data.result.personal_clearance_name == 'rakumart代通关') {
    personal_clearance.value = proxy.$fanyi('双清包税')
  } else if (res.data.result.personal_clearance_name == '个人通关') {
    personal_clearance.value = proxy.$fanyi('自行交税通关')
  }
  setOrderNum()
  // 物流
  proxy.$api.internationalLogisticsTransportation().then((res) => {
    console.log(res);
    if (res.code != 0) return
    if (res.data.length == 0) return false
    res.data.forEach((item) => {
      console.log(item);
      if (
        item.useful_rule.logistics_id == orderdata.value.result.logistics
      ) {
        logistics.value = item.name
      }
    })
    wuLiuOptions.value = res.data
  })

}
if (route.query.order_sn) {
  getstorageDeliverDetail()
}
// 计算商品种类和数量总计
const setOrderNum = () => {
  ordertotal.value.shangPinZhongLei = 0
  ordertotal.value.shuLiangZongJi = 0
  orderdata.value.result.porder_detail.forEach((orderItem) => {
    orderItem.order_detail.forEach((goodsItem) => {
      ordertotal.value.shangPinZhongLei++
      ordertotal.value.shuLiangZongJi += Number(goodsItem.submitNum)
    })
  })

}
// 点击支付
const payment = () => {
  proxy.$fun.toPage(
    '/payment?porder_sn=' +
    orderdata.value.porder_sn +
    '&peiSong=true' +
    '&coupon_ids=' +
    couponid.value
  )
}
// 发票下载
const getFapiao = () => {
  showLoadingToast({
    duration: 0,
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
    loadingType: "spinner",
  });

  let params = {
    porder_sn: orderdata.value.porder_sn,
  }

  axios
    .post(Europe + 'download.invoiceDownload', params, {
      responseType: 'blob', // 1.首先设置responseType对象格式为 blob:
    })
    .then((res) => {
      closeToast()
      console.log(res);

      if (res.size < 200) {
        return showFailToast(proxy.$fanyi('操作失败'))
      }


      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
      let url = window.URL.createObjectURL(blob) // 3.创建一个临时的url指向blob对象

      // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
      let a = document.createElement('a')
      a.href = url
      a.download = 'Proforma invoice' + orderdata.value.porder_sn + '.xls'
      a.click()
      // 5.释放这个临时的对象url
      window.URL.revokeObjectURL(url)

    })
    .catch((error) => {
      closeToast()
      showFailToast(error.message)

    })
}

// 选择优惠卷
const updatacoupon = async (id) => {
  couponid.value = id;
  const res = await proxy.$api.storagePOrderPayment({
    porder_sn: orderdata.value.porder_sn,
    coupon_ids: id,
  })
  if (res.code !== 0) {
    showFailToast(proxy.$fanyi(res.msg))
    return
  }
  orderdata.value.result.amount = res.data.porder_amount

  if (id != "") {
    couponcheck.value = true;
  } else {
    couponcheck.value = false;
  }
};

</script>
<style lang="scss">
#porsersndialong {
  width: 320px;
  min-height: 179px;

  border-radius: 8px;

  .van-dialog__header {
    width: 100%;
    border: 1px solid #dbdbdb;
    height: 50px;

    display: flex;
    margin: 0;
    padding: 0px;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 20px;
    align-items: center;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  .van-dialog__content {
    width: 100%;
    min-height: 58px;
    padding: 20px 20px;
    display: flex;
    align-items: center;

    p {
      display: flex;

      width: 100%;

      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
      margin-bottom: 10px;

    }
  }

  .deletefooter {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .queren {
      min-width: 120px;
      min-height: 40px;
      background: #ff730b;
      border-radius: 6px;
      padding: 0 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }


  }
}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.headBox {
  h3 {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #030303;
    line-height: 20px;
  }

  p {
    height: 14px;
    font-size: 10px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #030303;
    line-height: 14px;
    zoom: 0.9;
  }
}

// 底部报价中
.footer-box {
  width: 100%;
  height: 48px;
  background: #FFFFFF;
  box-shadow: inset 0px 0.5px 0px 0px #E2E2E2;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;

  .total-box {
    display: flex;
    align-items: center;

    .total {
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 16px;
      margin-right: 20px;

      span {
        color: #222222;
        font-weight: 600;

      }
    }

    .num {
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 16px;

      span {
        color: #222222;
        font-weight: 600;
      }
    }

  }



}

// 等待付款底部操作栏
.paymentfoot-box {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 148px;
  background: #fff;


  .total-box {
    display: flex;
    align-items: center;
    height: 40px;
    background: #fff;
    padding: 0 20px;
    justify-content: flex-end;

    .quantity {
      display: flex;

      .total {
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
        margin-right: 20px;

        span {
          color: #222222;
          font-weight: 600;

        }
      }

      .num {
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 16px;

        span {
          color: #222222;
          font-weight: 600;
        }
      }
    }



  }

  .couponsbox {
    width: 100%;
    height: 40px;
    background: #ffffff;
    box-shadow: inset 0px 0.5px 0px 0px #e2e2e2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    :deep().van-icon-success:before {
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      border-radius: 50%;
    }

    :deep().van-checkbox__icon {
      height: 12px;
    }

    :deep().van-checkbox__label {
      margin-left: 5px;
      font-size: 12px;
      font-family: ArialMT;
      color: #ff730b;
      line-height: 16px;
    }

    :deep().van-badge__wrapper {
      width: 12px;
      height: 12px;
      font-size: 12px;
    }
  }

  .rightprice {
    font-size: 12px;
    font-family: ArialMT;
    color: #222222;
    line-height: 20px;

    .dollar {
      margin-left: 5px;
      margin-right: 5px;
      font-size: 14px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #ff730b;
      line-height: 20px;
    }

    .RMB {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
    }
  }

  .paymentbox {
    height: 68px;
    background: #FFFFFF;
    box-shadow: inset 0px 0.5px 0px 0px #E2E2E2;
    display: flex;
    justify-content: center;
    align-items: center;

    .payment {
      width: 335px;
      height: 48px;
      background: #FF730B;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: ArialMT;
      color: #FFFFFF;
      line-height: 20px;
    }
  }

}

// 其他状态
.orthherfooterbox {
  width: 100%;
  height: 88px;
  position: fixed;
  bottom: 0;
  background: #ffff;

  .total-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    box-shadow: inset 0px 0.5px 0px 0px #E2E2E2;
    padding: 0 20px;

    .total {

      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 16px;
      margin-right: 20px;

      span {
        color: #222222;
        font-weight: 600;

      }
    }

    .num {
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 16px;

      span {
        color: #222222;
        font-weight: 600;
      }
    }

  }

  .totalprice {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    box-shadow: inset 0px 0.5px 0px 0px #E2E2E2;

    .price {
      font-size: 12px;
      font-family: ArialMT;
      color: #222222;
      line-height: 20px;

      span {
        margin-left: 3px;
        font-size: 14px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #FF730B;
        font-weight: 600;
        line-height: 20px;
      }
    }

  }
}

.deliveryorderdetails {
  width: 100%;
  min-height: calc(100vh - 88px);
  background: #F6F6F6;
  padding: 10px;
  padding-bottom: 100px;

  .topbox {
    width: 100%;
    font-size: 12px;
    font-family: ArialMT;
    color: #608BFF;
    line-height: 18px;
    min-height: 56px;
    background: #E8EEFF;
    border-radius: 6px;
    padding: 10px 15px;
    margin-bottom: 10px;
  }

  .order-item {
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border-radius: 6px;
    transition: all 0.5s;
    border: 0.5px solid #E2E2E2;
    margin-bottom: 10px;
    overflow: hidden;

    :deep().van-icon {
      font-size: 14px;
    }

    .ordersnbox {
      width: 100%;
      height: 48px;
      background: #FFFFFF;
      border-radius: 6px 6px 0px 0px;
      border-bottom: 0.5px solid #E2E2E2;
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ordersn {

        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 18px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 18px;

        span {
          margin-left: 3px;
          font-size: 12px;
        }

      }
    }

    .goodsitem {
      width: 100%;
      min-height: 345px;

      border-bottom: 0.5px solid #E2E2E2;
      padding: 15px;

      .Content {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;

        margin-bottom: 5px;

        .img-box {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          border-radius: 4px;

          :deep().van-image {
            width: 80px;
            height: 80px;
            border-radius: 4px;
          }

          :deep().van-image__img {
            border-radius: 4px;
          }
        }

        .rightgoodsinfo {
          flex: 1;
          height: 100%;

          // 描述
          .Description {
            height: 32px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;

            align-items: center;

            .text {
              height: 32px;
              font-size: 12px;
              font-family: ArialMT;
              color: #222222;
              line-height: 16px;
              padding-right: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }

            img {
              width: 24px;
              height: 24px;
              margin-left: 10px;
            }
          }

          .Commodityspecification {
            padding: 0 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 200px;
            height: 20px;
            background: #f6f6f6;
            border-radius: 4px;
            margin-bottom: 5px;

            p {
              align-items: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              // zoom: 0.9;
              font-size: 12px;
              font-family: ArialMT;
              color: #666666;
              line-height: 22px;
              zoom: 0.9;
            }

            img {
              margin-left: 10px;
              width: 8px;
              height: 4px;
            }
          }


        }
      }

      .totalprice,
      .price,
      .num,
      .tichushu,
      .freight {
        width: 100%;
        height: 34px;
        border-bottom: 1px dashed #DBDBDB;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          &:first-child {
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
          }

          &:last-child {
            font-size: 10px;
            font-family: ArialMT;
            color: #222222;
            line-height: 14px;
          }
        }
      }

      .remarks {
        width: 100%;
        padding: 0 10px;
        height: 40px;
        background: #FFFFFF;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 18px;
        border-radius: 6px;
        border: 0.5px solid #DEDEDE;
        margin-top: 15px;

        input:placeholder {

          font-family: ArialMT;
          color: #C0C4CC;
        }
      }
    }

  }

  // 收获地址
  .Receivingaddress {
    width: 100%;
    max-height: 48px;

    background: #FFFFFF;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;
    padding-bottom: 10px;
    overflow: hidden;
    transition: all 0.8s;
    margin-bottom: 10px;

    .addresstopbox {
      padding: 0 15px;
      height: 48px !important;
      width: 100%;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      transition: all 0.5s;
      color: #333;
      justify-content: space-between;

      .left {
        font-size: 12px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #222222;
        line-height: 18px;
      }

      .right {
        display: flex;
        align-items: center;

        :deep().van-icon {
          font-size: 16px;
        }
      }
    }

    .addresslist {
      padding: 0 15px;

      p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        span {

          &:first-child {
            flex: 0 0 130px;

            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 18px;
          }

          &:last-child {
            flex: 0 0 160px;

            font-size: 12px;
            font-family: ArialMT;
            color: #222222;
            line-height: 18px;
          }
        }

      }

    }
  }

  .maxaddress {
    max-height: 1000px !important;
    transition: all 0.8s;
  }

  // 通关方式   // 选择国际运输公司
  .Customsclearancemode,
  .Internationaltransport {
    width: 100%px;
    min-height: 106px;
    padding: 15px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;
    margin-bottom: 10px;

    h3 {
      font-size: 12px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #222222;
      line-height: 18px;
      margin-bottom: 10px;
    }

    input {
      width: 100%;
      height: 48px;
      background: #F6F6F6;
      font-size: 12px;
      font-family: ArialMT;
      color: #222222;
      line-height: 18px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      border: none;
      padding-left: 10px;
    }

  }




  // 订单备注
  .Ordernote {
    width: 355px;
    height: 150px;
    background: #FFFFFF;
    padding: 15px;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;
    margin-bottom: 10px;

    h3 {
      font-size: 12px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #222222;
      line-height: 18px;
      margin-bottom: 10px;
    }

    textarea {
      width: 100%;
      padding: 10px;
      height: 92px;
      background: #F6F6F6;
      font-size: 12px;
      font-family: ArialMT;
      color: #222222;

      line-height: 18px;
      border: none;
      border-radius: 6px;
      padding-left: 10px;
    }


  }

  // 费用总额
  .Totalcost {
    width: 355px;
    min-height: 210px;
    background: #FFFFFF;
    padding: 15px;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;
    margin-bottom: .125rem;

    .title {
      font-size: 12px;
      font-family: PingFang-SC-Semibold, PingFang-SC;
      font-weight: 600;
      color: #222222;
      line-height: 18px;
      margin-bottom: 10px;
    }

    p {
      width: 100%;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px dashed #DBDBDB;

      span {
        &:first-child {
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          zoom: 0.9;
        }

        &:last-child {
          font-size: 10px;
          font-family: ArialMT;
          color: #222222;
          line-height: 14px;
          zoom: 0.9;
        }
      }
    }

    .download {
      margin-top: 14px;
      width: 325px;
      height: 40px;
      background: #FF730B;
      border-radius: 4px;
      font-size: 12px;
      font-family: ArialMT;
      color: #FFFFFF;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  :deep().van-icon {
    font-size: 16px;
  }

  // 发货方式

  .Deliverymethod {
    width: 355px;
    height: 48px;
    padding-top: 15px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;
    overflow: hidden;
    padding-bottom: 5px;
    margin-bottom: 10px;

    .title {
      width: 100%;
      display: flex;
      padding: 0 15px;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 12px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #222222;
        line-height: 18px;

      }

      margin-bottom: 10px;
    }

    .item {
      width: 345px;
      height: 157px;

      border-radius: 6px;
      margin: auto;
      padding: 10px;
      padding-top: 6px;

    }

    p {
      width: 100%;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px dashed #DBDBDB;

      span {
        &:first-child {
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          zoom: 0.9;
        }

        &:last-child {
          font-size: 10px;
          font-family: ArialMT;
          color: #222222;
          line-height: 14px;
          zoom: 0.9;
        }
      }
    }

    .bgc {
      background: #F6F6F6 !important;
    }

  }

  // 箱子信息youkong
  .Boxinformation {
    width: 100%;
    border-radius: 4px 6px 0px 0px;
    min-height: 38px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;
    overflow: hidden;

    .top-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 38px;
      background: #9AE7D1;
      border-radius: 4px 6px 0px 0px;



      span {
        flex: 1;
        font-size: 10px;
        font-family: ArialMT;


        color: #222222;
        line-height: 14px;
        text-align: center;
        zoom: 0.9;
      }

    }

    .Caseitem {
      width: 100%;
      height: 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #DBDBDB;

      >span {
        flex: 1;
        font-size: 10px;
        font-family: ArialMT;
        color: #222222;
        line-height: 40px;
        height: 40px;



        text-align: center;
        zoom: 0.9;

        &:nth-child(1) {
          display: flex;
          border-right: 1px solid #DBDBDB;

          .xiaoghao {
            width: 40px;
            margin: auto;
            height: 20px;
            border-radius: 4px;
            border: 1px solid #00C18B;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #00C18B;
            line-height: 14px;


          }
        }

        &:nth-child(2) {
          border-right: 1px solid #DBDBDB;
        }

        &:nth-child(3) {
          border-right: 1px solid #DBDBDB;
        }

        // &:nth-child(4) {}
      }
    }
  }

  .maxheght {
    height: auto !important;
    transition: all 0.5s;
  }



}

.padingbottom {
  padding-bottom: 158px !important;
}
</style>