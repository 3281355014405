<!-- 首页滚动图组件 -->
<template>
  <van-swipe class="homeImgSw" indicator-color="000000" lazy-render :autoplay="3000" :loop="true">
    <van-swipe-item v-for="(item, index) in imageArr" :key="index">

      <van-image class="userRegister" :src="item.img_url" @click="$fun.routerToPage('/register')" usemap="#planetmap">
        <template v-slot:loading>
          <van-loading type="spinner" size="80" />
        </template>
      </van-image>
    </van-swipe-item>
    <!-- <van-swipe-item>

      <van-image class="userRegister" :src="require('../../../assets/index/banner-H5-2.jpg')"
        @click="$fun.routerToPage('/register')" usemap="#planetmap">
        <template v-slot:loading>
          <van-loading type="spinner" size="80" />
        </template>
      </van-image>
    </van-swipe-item>
    <van-swipe-item>

      <van-image class="userRegister" :src="require('../../../assets/index/banner-H5-3.jpg')"
        @click="$fun.routerToPage('/register')" usemap="#planetmap">
        <template v-slot:loading>
          <van-loading type="spinner" size="80" />
        </template>
      </van-image>
    </van-swipe-item>
    <van-swipe-item>

      <van-image class="userRegister" :src="require('../../../assets/index/banner-H5-4.png')"
        @click="$fun.routerToPage('/register')" usemap="#planetmap">
        <template v-slot:loading>
          <van-loading type="spinner" size="80" />
        </template>
      </van-image>
    </van-swipe-item> -->
    <!-- <van-swipe-item>

      <van-image class="userRegister" :src="require('../../../assets/index/qmH5.jpg')"
        @click="$fun.routerToPage('/register')" usemap="#planetmap">
        <template v-slot:loading>
          <van-loading type="spinner" size="80" />
        </template>
      </van-image>
    </van-swipe-item> -->
  </van-swipe>
</template>
<script setup>


import { getCurrentInstance, ref } from 'vue'
const { proxy } = getCurrentInstance()
console.log(proxy);
// 定义轮播图数组
const imageArr = ref([])
// 获取banner列表
console.log(imageArr)
const getH5ShopBanner = async () => {
  const res = await proxy.$api.getH5ShopBanner()
  imageArr.value = res.data.list

}
getH5ShopBanner()


// 写一个函数实现冒泡排序 


</script>
<style lang="scss" scoped="scoped">
@import "@/style/scss/mixin.scss";

.homeImgSw {
  height: 480px;
}

:deep().van-swipe-item {
  height: 480px;
  width: 100%;


}

:deep().van-image {
  height: 480px;
  width: 100%;
}


:deep().van-swipe__indicator {
  background: #000000;
  opacity: 0.2;
}

:deep().van-swipe__indicator--active {
  background-color: #FF730B;
  opacity: 1;
}
</style>
