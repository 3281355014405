<template>
  <!-- 顶部搜索 -->
  <headbar :lefticon="false" />
  <!-- 订单提出成功页面 -->
  <div class="successpage">



    <!-- 成功提示 -->
    <successbox />
    <!-- 订单·摘要-->
    <ordersummary :goodslist="$store.state.goodslist">
      <template #title>
        {{ $fanyi('订单汇总') }}
      </template>
    </ordersummary>

    <subsequentoperations />
    <!-- 联系我们 -->
    <contactus></contactus>
    <!-- 推荐商品页面 -->
    <Recommend :Recommendlist="Recommendlist" />
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from 'vue'
import headbar from '../../components/headbar/index.vue'
import contactus from './components/contactus'
import subsequentoperations from './components/subsequentoperations'
import successbox from './components/successbox.vue'
import ordersummary from './components/ordersummary.vue'
import Recommend from '../cart/components/Recommendlist.vue'
// import { useStore } from 'vuex'
const { proxy } = getCurrentInstance()
const Recommendlist = ref([]); // 推荐商品列表

console.log(proxy);
// 获取推荐商品
const getPersonalizedGoods = async () => {
  const res = await proxy.$api.getPersonalizedGoods();
  if (res.code != 0) return false;

  Recommendlist.value = res.data.result;
};
getPersonalizedGoods()

</script>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.successpage {
  padding: 10px;
  min-height: 100%;
  background: #F6F6F6;
}
</style>