<template>
  <!-- 顶部搜索 -->
  <headbar :lefticon="false" />
  <!-- 视频维护1 分类 -->
  <div class="video-maintenance">

    <div class="content">

      <div class="item" v-for="item in list" :key="item.id">
        <div class="top-title">{{ item.english_name }}</div>
        <div class="fengxian"></div>


        <!-- 有一级分类用一级 -->
        <div v-if="item.video_list.length == 0">

          <div class="listitem" v-for="ite in  item.video_list.length > 0 ? item.video_list : item.class_mark"
            :key="ite.id">
            <div class="listitem-title">1111</div>

            <div class="vediolist">
              <div class="vedioitem">

                <div class="vid-box"> <iframe ref="iframe" class="bigVideo" @load="onload($event, i)" :src="i.url"
                    frameborder="0" allowfullscreen></iframe>
                  <div class="loading" v-if="i.load">
                    <div class="loader"></div>
                  </div>

                </div>
                <!-- 标题 -->
                <div class="text">
                  {{ i.video_title }}
                </div>
                <div class="footer">
                  <div class="leftpic"> <img src="../../assets//staticpage//video-maintenance//play.svg" alt="">
                    <div><span></span> frequency</div>
                  </div>
                  <div class="right">
                    {{ i.created_at }}
                  </div>



                </div>

              </div>
            </div>

          </div>
        </div>
        <div v-else>
          <div class="vediolist">

            <div class="vedioitem" v-for="(i, index) in  item.video_list" :key="index">

              <div class="vid-box"> <iframe ref="iframe" class="bigVideo" @load="onload($event, i)" :src="i.url"
                  frameborder="0" allowfullscreen></iframe>
                <div class="loading" v-if="i.load">
                  <div class="loader"></div>
                </div>

              </div>
              <!-- 标题 -->
              <div class="text">
                {{ i.video_title }}
              </div>
              <div class="footer">
                <div class="leftpic"> <img src="../../assets/staticpage/video-maintenance/frequency.svg" alt="">
                  <div><span>{{ i.click_num }}</span> frequency</div>
                </div>
                <div class="right">
                  {{ i.created_at }}
                </div>



              </div>

            </div>
          </div>

        </div>




      </div>

    </div>


    <!-- 底部 -->
    <foot />
  </div>
  <!-- 底部导航 -->
  <tabbar />
</template>

<script setup>
import headbar from '../../components/headbar/index.vue'
import tabbar from '../homepageIndex/components/tabbar.vue'
import { getCurrentInstance, ref } from 'vue'
import { useRoute } from 'vue-router'

const { proxy } = getCurrentInstance()
import foot from "../../components/footer/index.vue";
const route = useRoute()
const list = ref([])// 视频分组列表
const getVideoSummary = async () => {
  const res = await proxy.$api.getVideoSummary()

  if (res.code !== 0) {
    // this.$message.error(res.msg);
    return
  }
  // console.log(res);


  await res.data.forEach((i) => {

    if (i.video_list.length > 0) {
      i.video_list.forEach((ind) => {
        let str = ind.video_link.replace(/youtu.be/g, "www.youtube.com/embed");
        ind.url = str
        ind.load = true
      })
    } else {
      i.class_mark.forEach((ite) => {
        ite.video_list.forEach((item) => {
          let str = item.video_link.replace(/youtu.be/g, "www.youtube.com/embed");

          item.url = str
          item.load = true

        })
      })
    }

  })
  // 进行判断
  if (route.query.type == 'all') {
    list.value = res.data
  }
  else if (route.query.id) {
    list.value.push(res.data.find(i => i.id == route.query.id))

  }
}

getVideoSummary()
const onload = async (e, i) => {

  i.load = false

}
</script>

<style lang="scss" scoped>
.loader {
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ff730b;
  border-top-color: transparent;
  animation: spin 0.8s ease-in-out infinite;

  transition: opacity 0.3s ease-in-out;
}



@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.video-maintenance {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 60px;

  .content {
    border-top: 0.5px solid #E2E2E2;
    border-bottom: 0.5px solid #E2E2E2;
    padding: 40px 25px;
    padding-right: 30px;
    padding-bottom: 0px;

    .item {
      width: 100%;
      margin-bottom: 60px;

      .top-title {
        margin-bottom: 10px;
        font-size: 14px;
        font-family: ArialMT;
        color: #222222;
        line-height: 20px;



      }

      .fengxian {
        width: 100%;
        height: 0.5px;
        background-color: #E2E2E2;
      }

      .listitem {
        .listitem-title {
          width: 100%;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          line-height: 18px;
          padding-bottom: 10px;
          border-bottom: 0.5px solid #E2E2E2;
        }

      }

      .vediolist {
        width: 100%;

        .vedioitem {
          width: 100%;
          margin-bottom: 30px;

          .vid-box {
            margin-top: 30px;
            position: relative;
            width: 100%;
            height: 180px;
            margin-bottom: 10px;
            border-radius: 6px;

            .bigVideo {
              width: 100%;
              height: 180px;
              border-radius: 6px;
            }

            .loading {
              width: 100%;
              height: 180px;
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              left: 0;
              top: 0;
              background-color: black !important;

            }


          }

          .text {
            width: 100%;
            font-size: 16px;
            font-family: ArialMT;
            color: #222222;
            line-height: 24px;
            margin-bottom: 10px;

          }

          .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .leftpic {
              display: flex;
              align-items: center;
              width: 16px;
              height: 16px;
              flex: 1;

              img {
                width: 16px;
                height: 16px;
              }

              div {
                margin-left: 5px;
                font-size: 12px;
                font-family: ArialMT;
                color: #999999;
                line-height: 16px;


                span {
                  margin-right: 5px;
                }
              }

            }

            .right {
              font-size: 12px;
              font-family: ArialMT;
              color: #999999;
              line-height: 16px;
            }
          }


        }
      }

    }
  }

}
</style> 