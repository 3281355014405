<template>
  <div style="position: relative">
    <hearbar bgcolor="white" :icon="true">
      <template v-slot:left-icon>
        <van-icon name="arrow-left" @click="goback" />
      </template>

      <template #default>
        <div>
          <h3>{{ $fanyi(title) }}</h3>
          <p>
            {{ $fanyi("订单号") }}: <span>{{ orderdata?.order_sn }}</span>
          </p>
        </div>
      </template>
      <!-- 修改订单展示 -->
      <template v-if="state == 'editing'" v-slot:right-icon>
        <div class="bianji" @click="edit">
          {{ showedit == false ? $fanyi("编辑") : $fanyi("完成") }}
        </div>
      </template>
    </hearbar>
    <!-- 顶部修订单待付款修改订单 modify  -->
    <div class="ordermodify-top" v-if="['obligation', 'Pending'].indexOf(state) != -1">
      <div class="time">
        <span>{{ $fanyi("时间") }}: </span>{{ orderdata.created_at }}
      </div>
      <div class="change" @click="
        (state = 'editing'), (title = '修改订单'), ModifyStatisticalquantity()
        ">
        {{ $fanyi("修改订单") }}
      </div>
    </div>
    <!-- 其他状态 订单详情 -->
    <div v-if="state !== 'editing'" class="orderdetails" :class="{ obligation: state == 'obligation' }">
      <!-- 商品列表 offer  -->
      <div class="shoplist" v-if="['offer', 'cancel', 'delete'].indexOf(state) != -1" :class="{ maxheight: putdown }">
        <!-- 店铺 -->
        <div class="shopitem" v-for="item in orderdata.detail" :key="item.shop_id">
          <h3>
            <input class="checkbox-box" @change="shopcheckboxchange(item)" type="checkbox" v-model="item.shopchecked" />
            <span>{{ $fanyi("店铺") }} :</span> {{ item.shop_name }}
          </h3>

          <!-- 商品 -->
          <div class="gooditem" :class="{ borderbottom: index != 0 }" v-for="(goodsitem, index) in item.list"
            :key="goodsitem.goods_id">
            <div class="Content">
              <input class="checkbox-box" @change="goodscheckboxchange(item)" type="checkbox"
                v-model="goodsitem.goodschecked" />
              <!-- 图片 -->
              <div class="img-box" @click="
                $fun.toCommodityDetails({
                  goods_id: goodsitem.goods_id,
                  shop_type: goodsitem.shop_type,
                })
                ">
                <van-image :src="goodsitem.pic">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </div>
              <!--右侧商品信息  -->
              <div class="rightgoodsinfo">
                <!-- 商品描述和删除 -->
                <div class="Description">
                  <p class="text">{{ goodsitem.goods_title }}</p>
                </div>

                <div class="Commodityspecification">
                  <p>{{ goodsitem.text }}</p>
                </div>
                <!-- <div class="Commodityspecification" @click="specificationspopupdom.open(goodsitem, 'cart')">
                  <p>{{ goodsitem.text }}</p>
                </div> -->
                <!-- 价格 -->
                <div class="price">
                  <span class="dollar">
                    ${{
                      $fun.EURNumSegmentation(
                        $fun.ceil(goodsitem.price * $store.state.exchangeRate)
                      )
                    }}
                  </span>
                  <span class="RMB">
                    (￥{{ $fun.RMBNumSegmentation(goodsitem.price) }})
                  </span>
                </div>
              </div>
            </div>

            <!-- 总价 -->
            <div class="totalprice">
              <div class="left-price">
                <span class="title">{{ $fanyi("总价") }}:</span>
                <!-- <span class="dollar"> $3.03( 21.00¥)</span> -->
                <span class="dollar">
                  ${{
                    $fun.EURNumSegmentation(
                      $fun.ceil(
                        goodsitem.price *
                        goodsitem.num *
                        $store.state.exchangeRate
                      )
                    )
                  }}
                </span>

                <span class="RMB">
                  (￥{{
                    $fun
                      .RMBNumSegmentation(goodsitem.price * goodsitem.num)
                      .toFixed(2)
                  }})
                </span>
              </div>
              <!-- <div class="right-input">
              <van-stepper @change="changeinput(2, goodsitem)" v-model="goodsitem.num" :min="1" integer />
            </div> -->
              <div class="right-num"><span>X</span> {{ goodsitem.num }}</div>
            </div>
            <!-- 附加服务 -->
            <div v-if="goodsitem.option.length > 0" class="Additionalservices">
              <p class="optiontext">
                <span v-show="i.checked == true" v-for="(i) in goodsitem.option" :key="i.id">
                  {{
                    i.tag
                  }}
                </span>
              </p>
              <span class="right" @click="servicepopupdom.open(goodsitem.option)">
                {{ $fanyi("查看") }}
                <van-icon name="arrow" />
              </span>
            </div>
            <!-- 备注 -->
            <div v-if="goodsitem.client_remark" class="Remarks">
              <input type="text" :disabled="true" :placeholder="$fanyi('无备注')" v-model="goodsitem.client_remark" />
            </div>
          </div>
        </div>

        <div class="foot-box">
          <div v-if="putdown == false" @click="putdown = !putdown">
            <span>Open</span>
            <img src="../../assets/tichuorder/open.svg" alt="" />
          </div>
          <div v-else @click="putdown = !putdown">
            <span>Put away</span>
            <img src="../../assets/tichuorder/putdown.svg" alt="" />
          </div>
        </div>
      </div>
      <!-- 商品列表 obligation,Pending ,purchased,'purchase''shelves','warehouse','shipping'-->
      <div class="shoplist" v-if="[
        'obligation',
        'Pending',
        'purchase',
        'purchased',
        'shelves',
        'warehouse',
        'shipping',
      ].indexOf(state) != -1
        " :class="{ maxheight: putdown }">
        <div class="shopitem obligationshopitem" v-for="item in orderdata.detail" :key="item.shop_id">
          <h3>
            <input class="checkbox-box" @change="shopcheckboxchange(item)" type="checkbox" v-model="item.shopchecked" />
            <span>{{ $fanyi("店铺") }} :</span> {{ item.shop_name }}
          </h3>

          <!-- 商品 -->
          <div class="obligationgooditem" v-for="goodsitem in item.list" :key="goodsitem.goods_id">
            <div class="gooditem" :class="{
              blueborder:
                goodsitem.order_detail_replace.length > 0 &&
                ['obligation'].indexOf(state) != -1,
              redborder:
                goodsitem.order_detail_replace.length == 0 &&
                goodsitem.confirm_num == 0 &&
                ['obligation'].indexOf(state) != -1,
            }">
              <div class="Content">
                <input class="checkbox-box" @change="goodscheckboxchange(item)" type="checkbox"
                  v-model="goodsitem.goodschecked" />
                <!-- 图片 -->
                <div class="img-box" @click="
                  $fun.toCommodityDetails({
                    goods_id: goodsitem.goods_id,
                    shop_type: goodsitem.shop_type,
                  })
                  ">
                  <van-image :src="goodsitem.pic">
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                </div>
                <!--右侧商品信息  -->
                <div class="rightgoodsinfo">
                  <!-- 商品描述和删除 -->
                  <div class="Description">
                    <p class="text">{{ goodsitem.goods_title }}</p>
                  </div>

                  <div class="Commodityspecification">
                    <p>{{ goodsitem.text }}</p>
                  </div>
                  <!-- 价格 -->
                  <div class="price">
                    <span class="dollar">
                      ${{
                        $fun.EURNumSegmentation(
                          $fun.ceil(
                            goodsitem.confirm_price * $store.state.exchangeRate
                          )
                        )
                      }}
                    </span>
                    <span class="RMB">
                      (￥{{ $fun.RMBNumSegmentation(goodsitem.confirm_price) }})
                    </span>
                  </div>
                </div>
              </div>

              <!-- 下单购买数 在库数 -->
              <div class="Orderquantitybox">
                <!-- 购买数 -->
                <div class="Orderquantity" v-if="['obligation'].indexOf(state) != -1">
                  {{ $fanyi("购买数量") }}:
                  <span>{{ goodsitem.num }}</span>
                </div>
                <!-- 在库数  -->
                <div class="Numberofsurveys">
                  {{ $fanyi("调查在库数") }}:
                  <span>{{ goodsitem.confirm_num }}</span>
                </div>
                <!-- 采购数量 -->
                <div class="Numberofsurveys" v-if="[
                  'purchase',
                  'purchased',
                  'shelves',
                  'warehouse',
                  'shipping',
                ].indexOf(state) != -1
                  ">
                  {{ $fanyi("采购数量") }}:
                  <span>{{ goodsitem.purchase_num }}</span>
                </div>
              </div>
              <!-- 中国总成本 -->
              <poper :showarrow="false" v-if="[
                'obligation',
                'Pending',
                'purchase',
                'purchased',
                'shelves',
                'warehouse',
                'shipping',
              ].indexOf(state) != -1
                " @open="goodsitem.showpoper = true" @close="goodsitem.showpoper = false">
                <!-- 内容 -->
                <template #obody>
                  <div class="TotalcostinChinabody">
                    <!-- 产品价值 -->
                    <div class="Productvalue flex">
                      <span class="title">{{ $fanyi("产品价值") }}</span>
                      <span class="price">${{
                        $fun.EURNumSegmentation(
                          goodsitem.china_amount.list.goods_amount
                        )
                      }}</span>
                    </div>
                    <!-- 手续费 -->
                    <div class="ServiceCharge flex">
                      <span class="title">{{ $fanyi("手续费") }}</span>
                      <span class="price">
                        ${{
                          $fun.EURNumSegmentation(
                            goodsitem.china_amount.list.service_amount
                          )
                        }}</span>
                    </div>

                    <!-- 附加服务 -->
                    <div class="fujiafuwu flex">
                      <span class="title">{{ $fanyi("附加服务") }}</span>
                      <span class="price">${{
                        $fun.EURNumSegmentation(
                          goodsitem.china_amount.list.option_amount
                        )
                      }}</span>
                    </div>

                    <!-- 运费 -->
                    <div class="yunfei flex">
                      <span class="title">{{ $fanyi("中国国内运费") }}</span>
                      <span class="price">
                        ${{
                          $fun.EURNumSegmentation(
                            goodsitem.china_amount.list.china_freight_amount
                          )
                        }}</span>
                    </div>
                  </div>
                </template>
                <!-- 点击 -->
                <template #btn>
                  <div class="TotalcostinChina">
                    <div class="leftprice">
                      {{ $fanyi("中国总成本") }}:

                      <!-- 美元，人民币 -->
                      <span class="dollor">${{ goodsitem.china_amount.sum_amount }}</span>
                      <!-- <span class="RMB"> (￥{{ 1212 }}) </span> -->
                    </div>
                    <!--右侧查看 -->
                    <div class="rightbox">
                      <span> {{ $fanyi("查看") }}</span>
                      <van-icon v-if="!goodsitem.showpoper" name="arrow-down" />
                      <van-icon v-else name="arrow-up" />
                    </div>
                  </div>
                </template>
              </poper>

              <!-- 预估总费用 -->
              <poper v-if="goodsitem.order_detail_replace.length == 0 &&
                ['obligation'].indexOf(state) != -1
                " :showarrow="false" @open="goodsitem.checkpoper = true" @close="goodsitem.checkpoper = false">
                <!-- 内容 -->
                <template #obody>
                  <div class="Estimatedtotalcost">
                    <!-- 产品成本 -->
                    <div class="Productvalue flex">
                      <span class="title">{{ $fanyi("产品成本") }}</span>
                      <span class="price">${{
                        $fun.EURNumSegmentation(
                          goodsitem.client_buy_amount[ind].list.china_amount
                        )
                      }}</span>
                    </div>

                    <!-- 预估国际运费 -->
                    <div class="Internationalfreight flex">
                      <span class="title">{{ $fanyi("预估国际运费") }}</span>
                      <span class="price">
                        ${{
                          $fun.EURNumSegmentation(
                            goodsitem.client_buy_amount[ind].list
                              .international_freight_amount
                          )
                        }}</span>
                    </div>

                    <!-- 预估美国国内运费 -->
                    <div class="UnitedStatesfreight flex">
                      <span class="title">{{
                        $fanyi("预估美国国内运费")
                      }}</span>
                      <span class="price">${{
                        $fun.EURNumSegmentation(
                          goodsitem.client_buy_amount[ind].list
                            .client_internal_amount
                        )
                      }}</span>
                    </div>
                  </div>
                </template>
                <!-- 点击 -->
                <template #btn>
                  <div class="yuguTotalcost">
                    <div class="leftprice">
                      {{ $fanyi("预计总成本") }}:

                      <!-- 美元，人民币 -->
                      <span class="dollor">${{
                        $fun.EURNumSegmentation(
                          goodsitem.client_buy_amount[ind].sum_amount
                        )
                      }}
                      </span>
                      <!-- <span class="RMB"> (￥{{ 1212 }}) </span> -->
                    </div>
                    <!--右侧查看 -->
                    <div class="rightbox">
                      <span> {{ $fanyi("查看") }}</span>
                      <van-icon v-if="!goodsitem.checkpoper" name="arrow-down" />
                      <van-icon v-else name="arrow-up" />
                    </div>
                  </div>
                </template>
              </poper>

              <!-- 预计产品进口详情 -->
              <div v-if="goodsitem.order_detail_replace.length == 0 &&
                ['obligation'].indexOf(state) != -1
                " class="Importdetails" @click="importdetailsdom.open(goodsitem, goodsitem.id, wuliuid)">
                <div class="left">{{ $fanyi("预计产品进口详情") }}</div>
                <div class="rightbox">
                  <span>{{ $fanyi("产品细节") }}</span>
                  <van-icon name="arrow" />
                </div>
              </div>
              <!-- 入金确认中订单详情 资金变动明细  -->

              <div class="Depositconfirmationdetails" v-if="[
                'Pending',
                'purchase',
                'purchased',
                'shelves',
                'warehouse',
                'shipping',
              ].indexOf(state) != -1 && goodsitem.change_price != 0
                " @click="detailsofchangesinfundsdom.open(goodsitem.id)">
                <div class="leftprice">
                  {{ $fanyi("金额变动明细") }}:

                  <!-- 美元，人民币 -->
                  <span class="dollor">${{ $fun.EURNumSegmentation(goodsitem.change_price) }}
                  </span>
                </div>
                <!--右侧查看 -->
                <div class="rightbox">
                  <span> {{ $fanyi("查看") }}</span>
                  <van-icon name="arrow" />
                </div>
              </div>
              <!-- 产品详情 -->
              <div class="ViewProducts" v-if="goodsitem.order_detail_replace.length > 0 &&
                state == 'obligation'
                " @click="
    $fun.toCommodityDetails({
      goods_id: goodsitem.goods_id,
      shop_type: goodsitem.shop_type,
    })
    ">
                {{ $fanyi("产品详情") }}
              </div>
              <!--  更换产品 -->
              <div class="Replacementproduct" v-if="goodsitem.order_detail_replace.length > 0 &&
                state == 'obligation'
                " @click="replacementofgoodsdom.open(goodsitem)">
                {{ $fanyi("更换产品") }}
              </div>
              <!-- 附加服务 -->
              <div class="Additionalservices" v-if="goodsitem.option.length > 0">
                <p class="optiontext">
                  <span v-show="i.checked == true" v-for="(i) in goodsitem.option" :key="i.id">
                    {{
                      i.tag
                    }}
                  </span>
                </p>
                <span class="right" @click="servicepopupdom.open(goodsitem.option)">
                  {{ $fanyi("查看") }}
                  <van-icon name="arrow" />
                </span>
              </div>

              <!-- 备注 -->
              <div v-if="goodsitem.client_remark" class="Remarks">
                <input type="text" :disabled="true" :placeholder="$fanyi('无备注')" v-model="goodsitem.client_remark" />
              </div>

              <!-- 替换商品 -->
              <div class="bluebuzu" v-if="goodsitem.order_detail_replace.length > 0 &&
                ['obligation'].indexOf(state) != -1
                ">
                {{ $fanyi('推荐方式') }}
              </div>
              <!-- 库存不足还没有替换商品 -->
              <div class="redbuzu" v-if="false">Product unavailable</div>
            </div>
            <!-- 虚线 -->
            <div class="Dottedline" v-if="true"></div>
          </div>
        </div>

        <!-- 底部 -->
        <div class="foot-box">
          <div v-if="putdown == false" @click="putdown = !putdown">
            <span>Open</span>
            <img src="../../assets/tichuorder/open.svg" alt="" />
          </div>
          <div v-else @click="putdown = !putdown">
            <span>Put away</span>
            <img src="../../assets/tichuorder/putdown.svg" alt="" />
          </div>
        </div>
      </div>
      <!-- 订单备注offer editing -->
      <div class="orderRemarks" v-if="['editing', 'offer', 'cancel', 'delete'].indexOf(state) != -1">
        <h2>{{ $fanyi("订单备注") }}</h2>
        <p>
          {{
            $fanyi(
              "请写下您对产品的任何附加服务或特殊要求，例如定制包装或产品定制有经验的经纪人会与你联系，讨论细节。"
            )
          }}
        </p>

        <textarea name="" class="beizhu" :disabled="state != 'editing'" :placeholder="$fanyi('备注将同步展示给代理人员')"
          v-model="orderdata.client_remark"></textarea>
      </div>

      <!-- 待付款订单备注 obligation  Pending,purchase,purchased-->

      <div v-if="[
        'obligation',
        'Pending',
        'purchase',
        'purchased',
        'shelves',
        'warehouse',
        'shipping',
      ].indexOf(state) != -1
        " class="obligationRemarks" :class="{ remarks: obligationRemarkshow }">
        <div class="top-title">
          <span class="title">{{ $fanyi("备注") }}</span>
          <van-icon @click="obligationRemarkshow = !obligationRemarkshow" v-if="!obligationRemarkshow"
            name="arrow-down" />
          <van-icon @click="obligationRemarkshow = !obligationRemarkshow" v-else name="arrow-up" />
        </div>
        <!--备注 内容区域 -->
        <div class="remarkmain">
          <div class="Content">
            <div class="orderremark">
              <h3>{{ $fanyi("订单备注") }}</h3>
              <textarea name="" class="beizhu" :disabled="state != 'editing'" :placeholder="$fanyi('备注将同步展示给代理人员')"
                v-model="orderdata.client_remark"></textarea>
            </div>
            <div class="Agentreply">
              <h3>{{ $fanyi("业务给客户的回复") }}</h3>
              <textarea name="" class="beizhu" :disabled="state != 'editing'" :placeholder="$fanyi('备注将同步展示给代理人员')"
                v-model="orderdata.y_remark"></textarea>
            </div>
          </div>
        </div>
      </div>
      <!-- 物流方式  -->
      <logisticsmode v-if="[
        'obligation',
        'Pending',
        'purchase',
        'purchased',
        'shelves',
        'warehouse',
        'shipping',
      ].indexOf(state) != -1
        " @updatelogistics="updatelogistics" :wuliulist="orderdata.orderOfferLogistics" />

      <!-- 配送单列表 -->
      <ShippingOrderInformation v-if="ShippingOrder.length > 0" :list="ShippingOrder" />
      <!--产品总支出 -->
      <Producttotal :pricedata="pricedata" />
    </div>

    <!--! 修改订单逻辑代码 -->
    <div v-if="state == 'editing'" class="Modifytheorder">
      <!-- 商品列表 -->
      <div class="shoplist" :class="{ maxheight: putdown }">
        <!-- 店铺 -->
        <div class="shopitem" v-for="item in orderdata.detail" :key="item.shop_id">
          <h3>
            <span>{{ $fanyi("店铺") }} :</span> {{ item.shop_name }}
          </h3>

          <!-- 商品 -->
          <div class="gooditem" :class="{ borderbottom: index != 0 }" v-for="(goodsitem, index) in item.list"
            :key="goodsitem.goods_id">
            <div class="Content">
              <input v-if="showedit" class="checkbox-box" @change="Modifygoodscheckboxchange(item)" type="checkbox"
                v-model="goodsitem.Modifygoodschecked" />
              <!-- 图片 -->
              <div class="img-box" @click="
                $fun.toCommodityDetails({
                  goods_id: goodsitem.goods_id,
                  shop_type: goodsitem.shop_type,
                })
                ">
                <van-image :src="goodsitem.pic">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </div>
              <!--右侧商品信息  -->
              <div class="rightgoodsinfo">
                <!-- 商品描述和删除 -->
                <div class="Description">
                  <p class="text">{{ goodsitem.goods_title }}</p>
                  <img src="../../assets/cart/delete_0.svg" alt="" @click="deleteitem(goodsitem.id)" />
                </div>
                <!-- 商品规格 -->
                <div class="Commodityspecification" @click="specificationspopupdom.open(goodsitem, 'obligation')">
                  <p>{{ goodsitem.text }}</p>

                  <img src="../../assets/cart/expansion.svg" alt="" />
                </div>

                <!-- 价格 -->
                <div class="price">
                  <span class="dollar">
                    ${{
                      $fun.EURNumSegmentation(
                        $fun.ceil(goodsitem.price * $store.state.exchangeRate)
                      )
                    }}
                  </span>
                  <span class="RMB">
                    (￥{{ $fun.RMBNumSegmentation(goodsitem.price) }})
                  </span>
                </div>
              </div>
            </div>

            <!-- 总价 -->
            <div class="totalprice">
              <div class="left-price">
                <span class="title">{{ $fanyi("总价") }}:</span>
                <!-- <span class="dollar"> $3.03( 21.00¥)</span> -->
                <span class="dollar">
                  ${{
                    $fun.EURNumSegmentation(
                      $fun.ceil(
                        goodsitem.price *
                        goodsitem.num *
                        $store.state.exchangeRate
                      )
                    )
                  }}
                </span>

                <span class="RMB">
                  (￥{{
                    $fun
                      .RMBNumSegmentation(goodsitem.price * goodsitem.num)
                      .toFixed(2)
                  }})
                </span>
              </div>
              <div class="right-input">
                <van-stepper @change="changeinput(2, goodsitem)" v-model="goodsitem.num" :min="1" integer />
              </div>
            </div>
            <!-- 附加服务 -->
            <div class="Additionalservices" @click="
              additionalservicesdom.open(goodsitem.option_old, goodsitem)
              ">
              <p v-if="goodsitem.option.length == 0">
                {{ $fanyi("点击选择附加服务") }}
              </p>
              <p v-else class="optiontext">
                <span v-show="i.checked == true" v-for="(i) in goodsitem.option" :key="i.id">
                  {{
                    i.tag
                  }}
                </span>
              </p>
              <img src="../../assets/tichuorder/addservice.svg" alt="" />
            </div>
            <!-- 备注 -->
            <div class="Remarks">
              <input @blur="inputblur(goodsitem)" type="text" :placeholder="$fanyi('备注栏')"
                v-model="goodsitem.user_postscript" />
            </div>
          </div>
        </div>

        <div class="foot-box">
          <div v-if="putdown == false" @click="putdown = !putdown">
            <span>Open</span>
            <img src="../../assets/tichuorder/open.svg" alt="" />
          </div>
          <div v-else @click="putdown = !putdown">
            <span>Put away</span>
            <img src="../../assets/tichuorder/putdown.svg" alt="" />
          </div>
        </div>
      </div>
      <!-- 订单备注 -->
      <div class="orderRemarks">
        <h2>{{ $fanyi("订单备注") }}</h2>
        <p>
          {{
            $fanyi(
              "请写下您对产品的任何附加服务或特殊要求，例如定制包装或产品定制有经验的经纪人会与你联系，讨论细节。"
            )
          }}
        </p>

        <textarea name="" class="beizhu" :placeholder="$fanyi('备注将同步展示给代理人员')" v-model="client_remark"></textarea>
      </div>

      <!--产品总支出 -->
      <Producttotal :pricedata="pricedata" />
    </div>

    <!-- 代付款订单底部  obligation-->

    <div v-if="['obligation'].indexOf(state) != -1" class="obligationfooter">
      <!-- 顶部统计 -->
      <div class="top-box">
        <!-- 加入商品库 -->
        <div class="addlibrary" @click="Addproductlibrary">
          {{ $fanyi("加入商品库") }}
        </div>
        <!-- 总体积 -->
        <div class="quantity">
          {{ $fanyi("数量总计") }}: <span>{{ quantitynum }}</span>
        </div>
        <!-- 数量 -->
        <div class="total">
          {{ $fanyi("总体积") }} :<span>{{ $fun.ceil(orderdata.orderVolume_m3) }}m³</span>
        </div>
      </div>
      <!-- 使用优惠券 -->
      <div class="couponsbox">
        <div class="left">
          <van-checkbox class="couponschexk" v-model="couponcheck" checked-color="#FF730B"
            @click="discountcoupondom.open('order')">{{ $fanyi("使用优惠券") }}</van-checkbox>
        </div>
        <div class="rightprice">
          {{ $fanyi("总价") }} :<span class="dollar">$
            {{
              $fun.EURNumSegmentation(
                $fun.ceil(pricedata?.sum_discount_price_dollar)
              )
            }}</span>
          <span class="RMB">￥{{
            $fun.RMBNumSegmentation(pricedata?.sum_discount_price)
          }}</span>
        </div>
      </div>
      <div class="paymentbox">
        <div class="gopayment" @click="gopayment">{{ $fanyi("付款") }}</div>
      </div>
    </div>
    <!-- 底部操作栏 offer Pending，purchase,purchased -->
    <div class="Bottomoperationbar" v-if="[
      'offer',
      'Pending',
      'purchase',
      'purchased',
      'shelves',
      'warehouse',
      'shipping',
      'cancel',
      'delete',
    ].indexOf(state) != -1
      ">
      <div class="allchecked">
        <div class="all">
          <input class="checkbox-box" @change="allcheckedchange()" type="checkbox" v-model="allchecked" />
          <span>{{ $fanyi("全选/取消") }}</span>
        </div>
        <!-- 数量 -->
        <div class="shuliang">
          {{ $fanyi("数量总计") }}: <span>{{ quantitynum }}</span>
        </div>
        <!-- 体积 -->
        <div class="total" v-if="['Pending'].indexOf(state) != -1">
          {{ $fanyi("总体积") }} :<span>{{ $fun.ceil(orderdata.orderVolume_m3) }}m³</span>
        </div>
      </div>
      <div class="caoziolan">
        <!--  加入商品库 -->
        <div class="tichubox">
          <div class="left-prce">
            <span class="title">{{ $fanyi("总价") }}:</span>
            <div class="price">
              <p class="dollar">
                $
                {{
                  $fun.EURNumSegmentation(
                    $fun.ceil(pricedata?.sum_discount_price_dollar)
                  )
                }}
              </p>
              <p class="REM">
                ￥{{ $fun.RMBNumSegmentation(pricedata?.sum_discount_price) }}
              </p>
            </div>
          </div>
          <!-- 加入商品库 -->
          <div class="tichu" @click="Addproductlibrary">
            {{ $fanyi("加入商品库") }}
          </div>
        </div>
      </div>
    </div>
    <!-- !底部操作栏修改 -->
    <div class="footercaozuolan" v-if="showedit == false && ['editing'].indexOf(state) != -1">
      <!-- 附加产品 -->

      <div class="Additionalproductbox">
        <div class="leftproduct" @click="addproduct">
          <img src="../../assets/tichuorder/additionalproduct.svg" alt="" />
          <span>{{ $fanyi("追加商品") }}</span>
        </div>
        <div class="quantity">
          {{ $fanyi("数量总计") }} <span>{{ Totalquantity }}</span>
        </div>
      </div>
      <!-- 底部提出订单操作栏 -->
      <div class="tichu-box">
        <!-- 总价 -->
        <div class="totalprice">
          <div class="title">{{ $fanyi("总价") }}:</div>
          <div class="price">
            <p class="dollar">
              ${{
                $fun.EURNumSegmentation(
                  $fun.ceil(totalPrice * $store.state.exchangeRate)
                )
              }}
            </p>
            <p class="RMB">
              (￥{{ $fun.RMBNumSegmentation(totalPrice).toFixed(2) }})
            </p>
          </div>
        </div>

        <!-- 取消 -->
        <div class="Temporarypreservation" @click="goback">
          <img src="../../assets/orderdetails/cancel.svg" alt="" />
          <p>{{ $fanyi("取消") }}</p>
        </div>
        <!-- 提出 -->
        <div class="Placeanorder" @click="Placeanorder()">
          {{ $fanyi("重新提出") }}
        </div>
      </div>
    </div>
    <!-- !点击编辑底部操作栏 -->
    <div class="editfooterbox" v-if="showedit == true && ['editing'].indexOf(state) != -1">
      <!-- 全选 -->
      <div class="allcheckbox">
        <div class="left-check">
          <input class="checkbox-box" @change="allcheckchange()" type="checkbox" v-model="Modifyallchecked" />
          <span>{{ $fanyi("全选/取消") }}</span>
        </div>

        <div class="Totalquantity">
          {{ $fanyi("数量总计") }}:
          <span>{{ Totalquantity }}</span>
        </div>
      </div>
      <!-- 删除，加入商品库-->
      <div class="caozuobox">
        <div class="delete" @click="Operation('delete')">
          {{ $fanyi("删除") }}
        </div>
        <div class="addgoods" @click="Operation('add')">
          {{ $fanyi("加入商品库") }}
        </div>
      </div>
    </div>
    <!-- 展示预估产品进口明细弹层 -->
    <importdetails ref="importdetailsdom" />
    <!-- 替换商品弹层 -->
    <replacementofgoods @getdata="getorderDetail()" ref="replacementofgoodsdom" />
    <!-- 展示附加服务弹层 -->
    <servicepopup ref="servicepopupdom" />
    <!-- 修改附加服务组件 -->
    <additionalservices ref="additionalservicesdom" />
    <!-- 优惠卷弹层 -->
    <discountcoupon @updatacoupon="updatacoupon" ref="discountcoupondom" />
    <!-- 商品规格 -->
    <specificationspopup @renewdata="Updata" ref="specificationspopupdom" />
    <!-- 添加商品弹层组件 -->
    <additiveproduct @addData="addToDataProcessing" ref="additiveproductdom" />
    <!-- 资金变动明细 -->
    <detailsofchangesinfunds ref="detailsofchangesinfundsdom" />
    <!-- 删除弹窗 -->
    <van-dialog id="deletedialong" v-model:show="showdelete" title="标题" show-cancel-button>
      <!-- default	自定义内容 title	自定义标题footer -->
      <template #title>
        <span>{{ $fanyi("删除") }}</span>

        <img @click="showdelete = false" src="../../assets/cart/close.svg" alt="" />
      </template>
      <template #default>
        <!-- 单个 -->
        <p style="justify-content: flex-start">
          {{ $fanyi("是否删除所选商品?") }}
        </p>
      </template>

      <template #footer>
        <div class="deletefooter">
          <div class="delete" @click="confirmdelete">
            {{ $fanyi("删除") }}
          </div>
          <div class="cancel" @click="showdelete = false">
            {{ $fanyi("取消") }}
          </div>
        </div>
      </template>
    </van-dialog>
    <!-- 库存不足弹窗 -->
    <van-dialog id="Inventorydialong" v-model:show="showInventory" title="标题" show-cancel-button>
      <!-- default	自定义内容 title	自定义标题footer -->
      <template #title>
        <span>{{ $fanyi("提示信息") }}</span>

        <img @click="showInventory = false" src="../../assets/cart/close.svg" alt="" />
      </template>
      <template #default>
        <!-- 单个 -->
        <p>
          {{
            $fanyi(
              "当前订单商品暂无库存，无法完成购买。您可以调整订单重新提出 也可以直接删除该订单。"
            )
          }}
        </p>
      </template>

      <template #footer>
        <div class="deletefooter">
          <div class="delete" @click="
          (state = 'editing'),
            (title = '修改订单'),
            ModifyStatisticalquantity(),
            (showInventory = false)
            ">
            {{ $fanyi("修改订单") }}
          </div>
          <div class="cancel" @click="delObligationOrder">
            {{ $fanyi("删除订单") }}
          </div>
        </div>
      </template>
    </van-dialog>
  </div>
</template>
<script setup>
// 临时保存的：temporary，报价中：offer，等待付款：obligation，购买中：purchase，购买完成：purchased，删除：delete,修改中 : editing上架完了:warehouse )上架中:shelves出货完成:shipping已付款待财务确认：Pending(Pending financial confirmation)
import { getCurrentInstance, ref } from "vue";
import { useRoute } from "vue-router";
import Producttotal from "../placeanorder/components/Producttotal.vue";
import poper from "./components/poper.vue";
import importdetails from "./components/importdetails.vue";
import logisticsmode from "./components/logisticsmode";
import additionalservices from "../../view/placeanorder/components/additionalservices.vue";
import replacementofgoods from "./components/replacementofgoods.vue";
import specificationspopup from "../cart/components/specificationspopup.vue";
import additiveproduct from "../../view/placeanorder/components/additiveproduct.vue";
import discountcoupon from "./components/discountcoupon.vue";
import detailsofchangesinfunds from "./components/detailsofchangesinfunds";
import ShippingOrderInformation from "./components/shippingorderInformation.vue";
import { showToast, showFailToast, showLoadingToast, closeToast } from "vant";

// 附加服务弹层
import servicepopup from "./components/servicepopup.vue";
import hearbar from "../../components/headGoBack/index.vue";
const { proxy } = getCurrentInstance();

const route = useRoute();
const state = ref(""); // 订单状态
const title = ref(""); // 标题
const allchecked = ref(false); // 全选
const servicepopupdom = ref(""); // 弹层dom
const logistics = ref(""); // 修改订单物流方式
const orderdata = ref({}); // 数据
const pricedata = ref({}); //  产品总支出
const couponcheck = ref(false); // 是否选择优惠券
const Selectedarr = ref([]); // 选中的商品
const replacementofgoodsdom = ref(""); //替换商品弹层dom
const importdetailsdom = ref(""); // 预估产品进口明细dom
const discountcoupondom = ref(""); // 优惠卷dom
const detailsofchangesinfundsdom = ref(""); //  资金变动明细dom
const putdown = ref(false); // 是否全部展开
const quantitynum = ref(); // 番数数量
const obligationRemarkshow = ref(false); //待付款订单备注是否显示完整
const ind = ref(0); // 预估总费用索引
const wuliuid = ref(null); // 物流id默认第一个
const couponid = ref(); //选中的优惠卷id
const showInventory = ref(false); // 库存不足弹窗
const ShippingOrder = ref([]); // 仓库配送单列表
console.log(proxy);
if (route.query.type) {
  if (route.query.type == "Pending financial confirmation") {
    state.value = "Pending";
  } else {
    state.value = route.query.type;
  }
  console.log(route.query.type);

  if (route.query.type == "offer") {
    title.value = "报价中详细";
  } else if (route.query.type == "obligation") {
    title.value = "待付款详细";
  } else if (route.query.type == "Pending financial confirmation") {
    title.value = "入金确认中详细";
  } else if (route.query.type == "purchase") {
    title.value = "出货中详情";
  } else if (route.query.type == "purchased") {
    if (route.query.status) {
      title.value = "检品中详细";
    } else {
      title.value = "出货完成详情";
    }
  } else if (route.query.type == "shelves") {
    title.value = "上架中详情";
  } else if (route.query.type == "warehouse") {
    title.value = "仓库中详情";
  } else if (route.query.type == "shipping") {
    title.value = "出货完成详情";
  } else if (route.query.type == "cancel") {
    title.value = "已取消详细";
  } else if (route.query.type == "delete") {
    title.value = "已取消详细";
  }
}
showLoadingToast({
  duration: 0,
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
  loadingType: "spinner",
});
const getorderDetail = async () => {
  const res = await proxy.$api.orderDetail({
    order_sn: route.query.order_sn,
  });
  closeToast();
  console.log(res.data);
  if (res.code != 0) {
    showFailToast(res.msg);
    return;
  }
  if (res.data.orderOfferLogistics.length > 0) {
    wuliuid.value = res.data.orderOfferLogistics[0].id;
    res.data.orderOfferLogistics[0].check = true;
  }

  if (state.value == "obligation") {
    // 红色状态
    let redstate = [];
    // 蓝色状态
    let bluesate = [];
    // 正常状态
    let Normal = [];
    res.data.detail.forEach((item) => {
      item.list.forEach((i) => {
        if (i.confirm_num === 0 && i.order_detail_replace.length == 0) {
          redstate.push(i);
        } else if (i.order_detail_replace.length !== 0) {
          bluesate.push(i);
        } else if (i.order_detail_replace.length == 0 && i.confirm_num > 0) {
          Normal.push(i);
        }
      });

      item.list = [...redstate, ...bluesate, ...Normal];
      redstate = [];
      bluesate = [];
      Normal = [];
    });

    //替换链接数组
    let chainingarray = [];
    let newdetail = [];
    let arr = [];
    res.data.detail.forEach((item) => {
      for (let i = 0; i < item.list.length; i++) {
        if (
          item.list[i].confirm_num == 0 &&
          item.list[i].order_detail_replace.length == 0
        ) {
          // 库存不足
          newdetail.push(item);
          return;
        } else if (item.list[i].order_detail_replace.length > 0) {
          chainingarray.push(item);
          // 替换链接
          return;
        } else if (
          item.list[i].order_detail_replace.length == 0 &&
          item.list[i].confirm_num > 0
        ) {
          arr.push(item);

          return;
        }
      }
    });
    res.data.detail = [...newdetail, ...chainingarray, ...arr];
    newdetail = [];
    chainingarray = [];
    arr = [];
  }
  orderdata.value = res.data;
  pricedata.value = res.data.order_money_group;
  orderdata.value.exchange_rate = Number(orderdata.value.exchange_rate);
  ShippingOrder.value = res.data.porder_detail;
  // 如果订单状态为已付款待财务确认，就显示已付款页面
  // if (this.orderStatus == 'Pending financial confirmation') {
  //   this.orderStatus = 'purchased'
  // }
  console.log(res.data.orderOfferLogistics);
  if (res.data.status > 30) {
    let arr = [];
    res.data.orderOfferLogistics.forEach((logisticsIt) => {
      if (logisticsIt.logistics_id === res.data.logistics) {
        logisticsIt.check = true;
        arr.push(logisticsIt);
      }
    });
    res.data.orderOfferLogistics = arr;
  }
  Statisticalquantity();
};
getorderDetail();
// 获取物流方式
const internationalLogisticsTransportation = async () => {
  const data = await proxy.$api.internationalLogisticsTransportation();
  console.log(data.data[0].name);
  logistics.value = data.data[0].name;
};
internationalLogisticsTransportation();
// 全选
const allcheckedchange = () => {
  orderdata.value.detail.forEach((item) => {
    item.shopchecked = allchecked.value;
    item.list.forEach((i) => {
      i.goodschecked = allchecked.value;
    });
  });
  Statisticalquantity();
};
const Statisticalquantity = () => {
  let checkarr = [];
  quantitynum.value = 0;

  // 选中的商品
  Selectedarr.value = [];
  orderdata.value.detail.forEach((item) => {
    item.list.forEach((i) => {
      if (
        ["purchase", "purchased", "shelves", "warehouse", "shipping"].indexOf(
          state
        ) != -1
      ) {
        quantitynum.value += i.purchase_num;
      } else {
        quantitynum.value += i.confirm_num;
      }

      i.text = "";
      if (i.goodschecked == true) {
        // 把选中的goodid存起来
        Selectedarr.value.push(i.id);
      }
      // 规格筛选拼接
      i.detail.forEach((detailitem) => {
        i.text += detailitem.key + ":" + " " + detailitem.value + " ";
      });
    });
    checkarr.push(item.list.every((ind) => ind.goodschecked == true));
  });

  if (checkarr.every((i) => i == true)) {
    allchecked.value = true;
  } else {
    allchecked.value = false;
  }
};
// 点击加入商品库
const Addproductlibrary = async () => {
  if (Selectedarr.value.length == 0) {
    showToast(proxy.$fanyi("请选择至少一项产品"));
    return;
  }
  //  说明是添加到商品库 循环筛选选中的数据
  let data = []; // 数组
  Selectedarr.value.forEach((id) => {
    orderdata.value.detail.forEach((item) => {
      item.list.forEach((i) => {
        if (i.id == id) {
          console.log(item);
          console.log(i);
          let obj = {
            shop_type: i.shop_type,
            goods_id: i.goods_id,
            goods_title: i.goods_title,
            shop_id: item.shop_id,
            shop_name: item.shop_name,
            img_url: i.pic,
            price_range: i.price_range,
            detailChinese: i.detailChinese,
            specification_checked: i.detailChinese,
            option: i.option,
          };
          data.push(obj);
        }
      });
    });
  });
  const res = await proxy.$api.favoriteInventoryBatchSave({
    data: JSON.stringify(data),
  });
  console.log(res);
  if (res.code != 0) {
    showFailToast(proxy.$fanyi(res.msg));
    return;
  }
  showToast(proxy.$fanyi("加入商品库成功"));
};
// 店铺单选
const shopcheckboxchange = (item) => {
  item.list.forEach((i) => {
    i.goodschecked = item.shopchecked;
  });

  Statisticalquantity();
};
// 商品单选
const goodscheckboxchange = (item) => {
  item.shopchecked = item.list.every((i) => i.goodschecked == true);
  Statisticalquantity();
};
// 更新选中的物流id
const updatelogistics = (id, index) => {
  wuliuid.value = id;
  ind.value = index;
};
//删除订单
const delObligationOrder = () => {
  proxy.$api
    .orderDelete({
      order_sn: route.query.order_sn,
    })
    .then((res) => {
      if (res.code != 0) return;
      showInventory.value = false;
      proxy.$fun.toPage("/user/order?status=delete");
    });
};
// 数据待支付订单逻辑代码
// 顶部返回
const goback = () => {
  if (state.value == "obligation" && route.query.type == "obligation") {
    proxy.$router.go(-1);
  } else if (state.value == "editing" && route.query.type == "obligation") {
    (state.value = "obligation"), (title.value = "待付款详细");
    // 取消优惠卷信息，重新获取数据
    couponid.value = "";
    couponcheck.value = false;
    getorderDetail();
  } else {
    proxy.$router.go(-1);
  }
};
// 选择优惠卷
const updatacoupon = (id) => {

  if (id != "") {
    couponcheck.value = true;
    couponid.value = id;

  } else {

    couponid.value = ''
    couponcheck.value = false;
  }
  calculateOrder();
};
// 待支付点击去支付
const gopayment = async () => {
  // 首先确认选择物流id
  if (!wuliuid.value) {
    showToast(proxy.$fanyi("请至少选择一项物流方式"));
    return;
  }

  const res = await proxy.$api.updateOrderLogistics({
    order_sn: route.query.order_sn,
    logistics: wuliuid.value,
  });
  if (res.code !== 0) {
    return;
  }
  // 然后判断在库是不是为空
  proxy.$api.confirmLibrary({ order_sn: route.query.order_sn }).then((res) => {
    if (!res.success) {
      showInventory.value = true;
      return;
    }
    let urlStr = "?order_sn=" + route.query.order_sn;
    // 如果有优惠卷id
    if (couponid.value) {
      urlStr += "&coupon_ids=" + couponid.value;
    }
    proxy.$fun.toPage("/payment" + urlStr);
  });
};

// 修改订单逻辑代码
const showedit = ref(false); // 是否展示编辑
const Modifyallchecked = ref(false); // 修改全选
const Totalquantity = ref(0); // 数量总量
const ModifySelectedarr = ref([]); // 选中的修改 arr数组
const showdelete = ref(false); // 展示删除弹窗
const additiveproductdom = ref(""); // 组件 添加商品dom
const totalPrice = ref(0); // 商品总价

const client_remark = ref(""); // 修改订单的备注
const deletegoodsid = ref(""); // 删除商品的id
const specificationspopupdom = ref(""); // 修改订单商品规格dom
const additionalservicesdom = ref(""); // 修改附加服务组件

// 点击编辑按钮
const edit = () => {
  showedit.value = !showedit.value;
};
// 点击修改全选事件
const allcheckchange = () => {
  orderdata.value.detail.forEach((item) => {
    item.list.forEach((i) => {
      i.Modifygoodschecked = Modifyallchecked.value;
    });
  });
  ModifyStatisticalquantity()();
};
// 加入商品库弹窗
const addproduct = () => {
  let idarr = [];
  orderdata.value.detail.forEach((item) => {
    item.list.forEach((i) => {
      if (i.cart_id) {
        idarr.push(i.cart_id);
      }
    });
  });

  // 商品id
  console.log(idarr);

  let ids = idarr.join(",");

  additiveproductdom.value.open(ids);
};
// 追加商品数据处理
const addToDataProcessing = (addData) => {
  // 如果是待支付状态的订单，就把追加商品的价格转换成报价价格字段
  addData.newList.forEach((shopitem) => {
    shopitem.list.forEach((goodsitem) => {
      // 从商品库添加的商品如果数量为0就把他改为1
      if (goodsitem.num == 0) {
        goodsitem.num = 1;
      }
      goodsitem.confirm_price = goodsitem.price;
      goodsitem.confirm_num = goodsitem.num;
    });
  });

  // 将追加的商品数据放入当前列表
  addData.newList.forEach((item) => {
    // 判断是否是同一店铺，是的话将商品加入同一店铺下,因为不同种类id不同所以不用判断种类
    console.log(item);
    let isSame = false;
    orderdata.value.detail.forEach((jtem) => {
      if (item.shop_name == jtem.shop_name) {
        item.list.forEach((etem) => {
          jtem.list.push(etem);
          isSame = true;
        });
      }
    });
    if (isSame == false) {
      orderdata.value.detail.push(item);
    }
  });
  calculateOrder();
  ModifyStatisticalquantity();
};
// 点击删除和加入商品库触发
const Operation = async (type) => {
  // delete  删除 add 加入商品库
  if (ModifySelectedarr.value.length == 0) {
    showToast(proxy.$fanyi("请选择至少一项产品"));
    return;
  }
  if (type == "delete") {
    showdelete.value = true;
  } else {
    //  说明是添加到商品库 循环筛选选中的数据
    let data = []; // 数组
    ModifySelectedarr.value.forEach((id) => {
      orderdata.value.detail.forEach((item) => {
        item.list.forEach((i) => {
          if (i.id == id) {
            console.log(i);
            let obj = {
              shop_type: i.shop_type,
              goods_id: i.goods_id,
              goods_title: i.goods_title,
              shop_id: item.shop_id,
              shop_name: item.shop_name,
              img_url: i.pic,
              price_range: i.price_range,
              detailChinese: i.detailChinese,
              specification_checked: i.detailChinese,
              option: i.option,
            };
            data.push(obj);
          }
        });
      });
    });
    console.log(data);
    const res = await proxy.$api.favoriteInventoryBatchSave({
      data: JSON.stringify(data),
    });
    console.log(res);
    if (res.code != 0) {
      showFailToast(proxy.$fanyi(res.msg));
      return;
    }
    showToast(proxy.$fanyi("加入商品库成功"));
  }
};
// 修改订单处理数据
const ModifyStatisticalquantity = () => {
  let checkarr = [];
  // 商品总数
  Totalquantity.value = 0;
  // 总价
  totalPrice.value = 0;
  // 选中的商品
  ModifySelectedarr.value = [];
  orderdata.value.detail.forEach((item) => {
    item.list.forEach((i) => {
      Totalquantity.value += i.num;
      totalPrice.value += Number(i.price) * Number(i.num);
      if (i.Modifygoodschecked == true) {
        // 把选中的goodid存起来
        ModifySelectedarr.value.push(i.id);
      }
      // 规格筛选拼接
      i.detail.forEach((detailitem) => {
        i.text += detailitem.key + ":" + " " + detailitem.value + " ";
      });
    });
    checkarr.push(item.list.every((ind) => ind.Modifygoodschecked == true));
  });

  if (checkarr.every((i) => i == true)) {
    Modifyallchecked.value = true;
  } else {
    Modifyallchecked.value = false;
  }
};
// 计算价格
const calculateOrder = async () => {
  let data = [];

  orderdata.value.detail.forEach((item) => {
    item.list.forEach((i) => {
      data.push(i);
    });
  });
  let datas = {
    data: JSON.stringify(data),
  };
  // 如果有优惠卷id
  if (couponid.value !== "") {
    datas.coupon_ids = couponid.value;
  }
  const res = await proxy.$api.calculateOrder(datas);
  if (res.code != 0) {
    showFailToast(proxy.$fanyi(res.msg));
    return;
  }
  pricedata.value = res.data;
};
// 点击修改订单单选触发
const Modifygoodscheckboxchange = () => {
  ModifyStatisticalquantity();
};
// 点击删除图片删除
const deleteitem = (id) => {
  deletegoodsid.value = id;
  showdelete.value = true;
};

// 确认删除
// 确认 删除函数
const confirmdelete = () => {
  if (showedit.value == false || deletegoodsid.value != "") {
    // 说明是单个删除
    orderdata.value.detail.forEach((item, ind) => {
      console.log(item);
      item.list = item.list.filter((i) => i.id != deletegoodsid.value);
      if (item.list.length == 0) {
        orderdata.value.detail.splice(ind, 1);
      }
    });
    showdelete.value = false;
    deletegoodsid.value = "";
    calculateOrder();
    ModifyStatisticalquantity();
  } else {
    // 说明是编辑批量删除 如果
    ModifySelectedarr.value.forEach((id) => {
      orderdata.value.detail.forEach((item, ind) => {
        item.list = item.list.filter((i) => i.id != id);
        if (item.list.length == 0) {
          orderdata.value.detail.splice(ind, 1);
        }
      });
    });
    showdelete.value = false;
    calculateOrder();
    ModifyStatisticalquantity();
  }
};
// 修改完规格后数据更新
const Updata = (goods, obj, detailsImg) => {
  orderdata.value.detail.forEach((item) => {
    item.list.forEach((i) => {
      if (i.id == goods.id) {
        i.text = "";
        i.pic = detailsImg;
        // 重新添加delita
        i.detail.forEach((ite, index) => {
          i.detail[index].value = obj.value[index].value;
          i.detailChinese[index].value = obj.value[index].value;
        });
      }
    });
  });

  ModifyStatisticalquantity();
};
// 重新提出订单
const Placeanorder = async () => {
  console.log("111");

  // orderdata.value.detail.forEach((listItem) => {
  //   listItem.list.forEach((Item) => {
  //     // 将用户选中的规格匹配中文传回后台 pageSpecification pageSpecificationChinese
  //     Item.detailChinese = ((
  //       detailEnglish,
  //       pageSpecification,
  //       pageSpecificationChinese
  //     ) => {
  //       let detailChinese = []
  //       detailEnglish.forEach((item, index) => {
  //         detailChinese.push({
  //           key: pageSpecificationChinese[index].key,
  //           value:
  //             pageSpecificationChinese[index].value[
  //               pageSpecification[index].active
  //             ].name,
  //         })
  //       })
  //       return detailChinese
  //     })(Item.detail, Item.pageSpecification, Item.pageSpecificationChinese)
  //   })
  // })
  const res = await proxy.$api.orderEdit({
    data: JSON.stringify(orderdata.value),
  });
  if (res.code !== 0) {
    showFailToast(res.msg);
    return;
  }
  proxy.$fun.toPage("/user/order");
};
// 数量发生变化
const changeinput = async (type, goodsitem) => {
  //  首先确保是数组 然后 进行区间判断

  if (JSON.parse(goodsitem.price_range).constructor == Array) {
    JSON.parse(goodsitem.price_range).forEach((item, ind) => {
      if (
        goodsitem.num < JSON.parse(goodsitem.price_range)[ind + 1]
          ? JSON.parse(goodsitem.price_range)[ind + 1].startQuantity
          : true && goodsitem.num >= item.startQuantity
      ) {
        goodsitem.price = item.price;
      } else if (
        goodsitem.num < JSON.parse(goodsitem.price_range)[0].startQuantity
      ) {
        goodsitem.price = JSON.parse(goodsitem.price_range)[0].price;
      }
    });
  }
  // 如果传入的type 是2 说明是输入框触发，
  if (type == 2) {
    // 然后发送请求修改后台价格
    calculateOrder();
    ModifyStatisticalquantity();
  }
};
</script>

<style lang="scss">
.van-popover__content {
  width: 325px !important;
  padding: 0 20px;
  padding-top: 15px;

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .title {
      font-size: 12px !important;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      max-width: 180px;
      line-height: 15px;
    }

    .price {
      font-size: 12px !important;
      font-family: Arial-BoldMT, Arial;
      font-weight: 600;
      color: #222222;
      line-height: 18px;
    }
  }
}

#deletedialong,
#Inventorydialong {
  width: 295px;
  height: 179px;

  border-radius: 8px;

  .van-dialog__header {
    width: 100%;
    border: 1px solid #dbdbdb;
    height: 50px;

    display: flex;
    margin: 0;
    padding: 0px;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 20px;
    align-items: center;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  .van-dialog__content {
    width: 100%;
    min-height: 58px;
    display: flex;
    align-items: center;

    p {
      display: flex;

      width: 100%;
      padding: 0 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
    }
  }

  .deletefooter {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    .delete,
    .queren {
      min-width: 120px;
      min-height: 40px;
      background: #ff730b;
      border-radius: 6px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .cancel {
      width: 120px;
      height: 40px;
      background: #f2f2f2;
      border-radius: 6px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 20px;
    }
  }
}

#Inventorydialong {
  min-height: 230px;

  .van-dialog__content {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .deletefooter {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    margin-bottom: 30px;
  }
}
</style>
<style lang="scss" scoped="scoped">
@import "@/style/scss/mixin.scss";

:deep().van-icon-arrow-left {
  font-size: 16px;
}

// 选择框样式
.checkbox-box {
  width: 12px;
  height: 12px;
  padding: 0px;
  margin: 0px;
  border-radius: 2px;
  border: 1px solid #dedede;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;
}

input[type="checkbox"]:checked {
  background: #ff730b;
  border-color: #ff730b;
  border: 1px solid#ff730b;
}

input[type="checkbox"]::before {
  position: absolute;
  top: -2px;
  left: 0;
  content: "\2713";
  /* Unicode编码，表示对号 */
  display: inline-block;

  width: 12px;
  height: 12px;

  text-align: center;
  font-size: 14px;
  opacity: 0;
  color: #ffffff;
  /* 对号的颜色 */
  font-weight: bold;
  transform: scale(0.8, 0.5);
  /* 缩放 */
  transition: transform 0.2s ease-in-out;
  /* 缩放过渡效果 */
}

input[type="checkbox"]:checked::before {
  opacity: 1;
}

.headBox {
  h3 {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #030303;
    line-height: 20px;
  }

  p {
    height: 14px;
    font-size: 10px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #030303;
    line-height: 14px;
    zoom: 0.9;
  }
}

.bianji {
  font-size: 12px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #030303;
  line-height: 24px;
}

// 代付款订单顶部修改

.ordermodify-top {
  position: fixed;
  top: 44px;
  width: 100%;
  height: 48px;
  background: #ffffff;
  box-shadow: inset 0px 0px 0px 0px #e2e2e2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 4;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  box-shadow: inset 0px 0px 0px 0px #e2e2e2;

  .time {
    font-size: 10px;
    font-family: ArialMT;
    color: #666666;
    line-height: 14px;

    span {
      font-size: 10px;
      font-family: ArialMT;
      color: #666666;
      line-height: 14px;
      zoom: 0.9;
    }
  }

  .change {
    min-width: 84px;
    padding: 0 10px;
    height: 28px;
    background: #ff730b;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-family: ArialMT;
    color: #ffffff;
    line-height: 14px;
    zoom: 0.9;
  }
}

// 底部价格栏 offer 样式

.Bottomoperationbar {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: #ffffff;

  height: 108px;

  .allchecked {
    padding: 0 20px;
    height: 40px;
    display: flex;
    background: #ffffff;
    box-shadow: inset 0px 0.5px 0px 0px #e2e2e2;
    justify-content: space-between;

    .fanshu {
      display: flex;
      align-items: center;
      height: 100%;

      font-size: 10px;
      font-family: ArialMT;
      color: #666666;
      line-height: 16px;
      zoom: 0.9;

      span {
        display: flex;
        margin-left: 2px;
        color: #222222;
        font-weight: 600;
      }
    }

    .shuliang {
      display: flex;
      align-items: center;
      height: 16px;
      font-size: 10px;
      font-family: ArialMT;
      color: #666666;
      line-height: 16px;
      height: 100%;
      zoom: 0.9;

      span {
        display: flex;
        margin-left: 2px;
        color: #222222;
        font-weight: 600;
      }
    }

    .total {
      min-width: 94px;
      font-size: 12px;
      font-family: ArialMT;
      color: #666666;
      display: flex;
      align-items: center;
      zoom: 0.88;

      span {
        color: #222222;
        font-weight: 600;
        zoom: 0.88;
      }
    }

    .all {
      display: flex;
      align-items: center;
      height: 100%;
      flex: 0 0 130px;

      .checkbox-box {
        margin-right: 5px;
      }

      span {
        display: flex;

        font-size: 12px;
        font-family: ArialMT;
        zoom: 0.9;
        color: #666666;
        // line-height: 20px;
      }
    }
  }

  .caoziolan {
    padding: 0 20px;
    height: 68px;
    background: #ffffff;
    box-shadow: inset 0px 0.5px 0px 0px #e2e2e2;

    // 提出
    .tichubox {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tichu {
        width: 160px;
        height: 48px;
        background: #608bff;
        border-radius: 6px;

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: ArialMT;
        color: #ffffff;
        line-height: 20px;
        letter-spacing: -0.5px;
        border-radius: 6px;
      }

      .left-prce {
        height: 100%;
        display: flex;
        padding-top: 15px;

        .title {
          display: flex;
          min-width: 60px;
          height: 20px;
          font-size: 12px;
          font-family: ArialMT;
          color: #666666;
          margin-right: 5px;
        }

        .price {
          height: 100%;

          .dollar {
            height: 20px;
            font-size: 14px;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            color: #ff730b;
            line-height: 20px;
          }

          .REM {
            height: 16px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
          }
        }
      }
    }

    // 编辑
    .bianjibox {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .delete {
        width: 160px;
        height: 48px;
        background: #608bff;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        align-items: center;
      }

      .MoveintoCollection {
        width: 160px;
        height: 48px;
        background: #ff730b;
        border-radius: 6px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

//  订单详情
.orderdetails {
  width: 100%;
  background: #f6f6f6;
  padding: 10px;
  min-height: calc(100vh - 44px);
  padding-bottom: 120px;

  // offer 样式
  .shoplist {
    position: relative;

    background: #ffffff;
    border-radius: 6px;
    border: 0.5px solid #e2e2e2;
    max-height: 337px;
    overflow: hidden;
    transition: max-height 0.5s;
    padding-bottom: 30px;

    .shopitem {
      padding: 15px;
      padding-bottom: 0px;

      border-bottom: 1px solid #dbdbdb;

      h3 {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;
        margin-bottom: 15px;
      }

      .gooditem {
        width: 100%;
        min-height: 100px;

        width: 100%;

        .Content {
          width: 100%;
          height: 80px;
          display: flex;
          align-items: center;

          margin-bottom: 5px;

          .img-box {
            width: 80px;
            height: 80px;
            margin-right: 10px;
            border-radius: 4px;

            :deep().van-image {
              width: 80px;
              height: 80px;
              border-radius: 4px;
            }

            :deep().van-image__img {
              border-radius: 4px;
            }
          }

          .rightgoodsinfo {
            flex: 1;
            height: 100%;

            // 描述
            .Description {
              height: 32px;
              display: flex;
              justify-content: space-between;
              margin-bottom: 5px;

              align-items: center;

              .text {
                height: 32px;
                font-size: 12px;
                font-family: ArialMT;
                color: #222222;
                line-height: 16px;
                padding-right: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }

              img {
                width: 24px;
                height: 24px;
                margin-left: 10px;
              }
            }

            .Commodityspecification {
              padding: 0 5px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 200px;
              height: 20px;
              background: #f6f6f6;
              border-radius: 4px;
              margin-bottom: 5px;

              p {
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                // zoom: 0.9;
                font-size: 12px;
                font-family: ArialMT;
                color: #666666;
                line-height: 22px;
                zoom: 0.9;
              }

              img {
                margin-left: 10px;
                width: 8px;
                height: 4px;
              }
            }

            .price {
              height: 18px;
              font-size: 12px;
              font-family: ArialMT;
              color: #222222;
              line-height: 18px;

              .dollar,
              .RMB {
                font-size: 12px;
                font-family: ArialMT;
                color: #222222;
                line-height: 18px;
              }
            }
          }
        }

        // 总价
        .totalprice {
          width: 100%;
          height: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .left-price {
            display: flex;
            align-items: center;
            height: 30px;

            .title {
              height: 18px;
              font-size: 12px;
              font-family: ArialMT;
              color: #222222;
              line-height: 16px;
              display: flex;
              margin-right: 5px;
            }

            .dollar {
              margin-right: 2px;
            }

            .dollar,
            .RMB {
              height: 18px;
              font-size: 12px;
              font-family: Arial-BoldMT, Arial;
              font-weight: 600;
              color: #ff730b;
              line-height: 18px;
            }
          }

          .right-input {
            width: 100px;
            height: 30px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #222222;
            display: flex;
            align-items: center;
            justify-content: center;

            :deep().van-haptics-feedback {
              width: 22px;
              height: 22px;
              background: #f2f2f2;
              border-radius: 2px;
            }

            :deep().van-stepper__minus--disabled {
              width: 22px;
              height: 22px;
              background: #f2f2f2;
              border-radius: 2px;
            }

            :deep().van-stepper {
              height: 22px;
              display: flex;
              align-items: center;
            }

            :deep().van-stepper__input {
              width: 40px;
              height: 16px;
              font-size: 12px;

              font-family: ArialMT;
              background-color: #fff;
            }
          }

          .right-num {
            height: 18px;
            font-size: 12px;
            font-family: ArialMT;
            color: #999999;
            line-height: 18px;
          }
        }

        // 附加服务
        .Additionalservices {
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 40px;
          background: #ffffff;
          border-radius: 6px;
          border: 0.5px solid #dedede;
          margin-bottom: 10px;

          p {
            width: 275px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            font-family: ArialMT;
            color: #666666;
          }

          .optiontext {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 18px;
            align-items: center;
            max-width: 323px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;



            span {
              font-size: 14px;
              line-height: 20px;
              margin-right: 5px;
            }
          }

          .right {
            display: flex;

            min-width: 44px;
            margin-left: 10px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 18px;
            align-items: center;

            :deep().van-icon-arrow {
              margin-left: 3px;

              font-size: 14px;
              line-height: 14px;
              display: flex;
              align-items: center;
            }
          }
        }

        // 备注

        .Remarks {
          // width: 325px;
          // height: 40px;
          // background: #FFFFFF;
          // border-radius: 6px;
          // border: 1px solid #DEDEDE;

          input {
            width: 100%;
            height: 40px;
            background: #ffffff;
            border-radius: 6px;
            border: 0.5px solid #dedede;
            font-size: 12px;
            font-family: ArialMT;
            padding-left: 10px;
            line-height: 18px;
            margin-bottom: 15px;
          }

          input:placeholder {
            font-size: 12px;
            font-family: ArialMT;
            color: #C0C4CC;
          }
        }
      }
    }

    // obligation 样式
    .obligationshopitem {
      padding-left: 0px;
      padding-right: 0px;

      h3 {
        padding: 0 15px;
      }

      .obligationgooditem {
        padding-left: 5px;
        padding-right: 5px;

        .gooditem {
          padding: 0 10px;

          // border: 1px solid red;
          border-radius: 6px;
          min-height: 100px;

          // .Content {}
          // 下单数在库数
          .Orderquantitybox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 10px 0;

            .Orderquantity {
              font-size: 12px;
              font-family: ArialMT;
              color: #222222;
              line-height: 18px;

              span {
                margin-left: 5px;
                color: #222222;
                font-weight: 600;
              }
            }

            .Numberofsurveys {
              font-size: 12px;
              font-family: ArialMT;
              color: #222222;
              line-height: 18px;

              span {
                margin-left: 5px;
                color: #222222;
                font-weight: 600;
              }
            }
          }

          .Remarks {
            input {
              margin-bottom: 10px;

              input:placeholder {

                font-family: ArialMT;
                color: #C0C4CC;
              }
            }
          }

          :deep().van-popover__wrapper {
            width: 100%;
          }

          // 中国总成本呢

          .TotalcostinChina,
          .yuguTotalcost {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            background: #e9f8fb;
            border-radius: 6px;
            border: 1px solid #49c9e0;
            padding: 0 10px;
            margin-bottom: 10px;

            .leftprice {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #222222;
              line-height: 18px;

              .dollor {
                font-family: Arial-BoldMT, Arial;
                font-weight: 600;
                color: #222222;
                line-height: 18px;
              }

              .RMB {
                font-size: 12px;
                font-family: ArialMT;
                color: #222222;
                line-height: 18px;
              }
            }

            .rightbox {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #222222;
              line-height: 18px;

              span {
                margin-right: 2px;
              }

              :deep().van-icon {
                font-size: 14px;
                margin-right: 4px;
              }
            }
          }

          .yuguTotalcost {
            background: #e4fbf4;
            border-radius: 6px;
            border: 1px solid #1ba37b;
          }
        }

        // 预计产品进口详情
        .Importdetails {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 40px;
          background: #eff4ff;
          border-radius: 6px;
          border: 1px solid #608bff;
          padding: 0 10px;
          margin-bottom: 10px;

          .left {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 18px;
          }

          .rightbox {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 18px;

            span {
              margin-right: 2px;
            }

            :deep().van-icon {
              font-size: 14px;
              margin-right: 4px;
            }
          }
        }

        // 资金变动明细
        .Depositconfirmationdetails {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0 10px;

          height: 40px;
          margin-bottom: 10px;
          justify-content: space-between;
          background: #e4fbf4;
          border-radius: 6px;
          border: 1px solid #1ba37b;

          .leftprice {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 18px;

            .dollor {
              font-family: Arial-BoldMT, Arial;
              font-weight: 600;
              color: #222222;
              line-height: 18px;
            }

            .RMB {
              font-size: 12px;
              font-family: ArialMT;
              color: #222222;
              line-height: 18px;
            }
          }

          .rightbox {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 18px;

            span {
              margin-right: 2px;
            }

            :deep().van-icon {
              font-size: 14px;
              margin-right: 4px;
            }
          }
        }

        // 产品详情
        .ViewProducts {
          width: 100%px;
          height: 40px;
          background: #eff4ff;
          border-radius: 6px;
          border: 1px solid #608bff;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: ArialMT;
          color: #608bff;
          line-height: 18px;
        }

        // 更换产品
        .Replacementproduct {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: ArialMT;
          color: #ffffff;
          margin-bottom: 10px;
          line-height: 18px;
          height: 40px;
          background: #608bff;
          border-radius: 6px;
        }

        .redborder {
          position: relative;
          border: 1px solid red;
          border-radius: 6px;
          padding-top: 25px;
          background: #fdf3f2;

          .redbuzu {
            position: absolute;
            left: 0;
            top: 0;
            min-width: 98px;
            height: 20px;
            background: #e74c3c;
            border-radius: 5px 0px 6px 0px;

            display: flex;
            justify-content: center;
            font-size: 10px;
            display: flex;
            align-items: center;
            font-family: ArialMT;
            color: #ffffff;
            line-height: 14px;

            align-items: center;
            padding: 0 4px;
          }
        }

        .blueborder {
          position: relative;
          border-radius: 6px;
          border: 1px solid #608bff;
          padding-top: 25px;
          background: #eff4ff;

          .bluebuzu {
            min-width: 68px;
            height: 20px;
            background: #608bff;
            border-radius: 5px 0px 6px 0px;
            position: absolute;
            font-size: 10px;
            padding: 0 4px;
            display: flex;
            align-items: center;
            font-family: ArialMT;
            color: #ffffff;
            line-height: 14px;
            left: 0;
            top: 0;
          }
        }

        .Dottedline {
          width: 325px;
          margin: auto;
          margin-top: 15px;
          margin-bottom: 15px;
          border: 1px dashed #dbdbdb;
        }
      }
    }

    .foot-box {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 34px;
      background: #ffffff;
      box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.04);
      border-radius: 0px 0px 6px 6px;
      border-top: 1px solid #e2e2e2;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      div {
        display: flex;
        align-items: center;
      }

      span {
        font-size: 10px;
        font-family: ArialMT;
        color: #222222;
        line-height: 14px;
      }

      img {
        margin-left: 5px;
        width: 12px;
        height: 7px;
      }
    }
  }

  // 待付款订单备注 obligation
  .obligationRemarks {
    margin-top: 10px;
    width: 100%;
    height: 48px;
    background: #ffffff;
    border-radius: 6px;
    border: 0.5px solid #e2e2e2;
    overflow: hidden;

    transition: 0.5s;

    .top-title {
      width: 100%;
      height: 48px;
      background: #ffffff;
      display: flex;
      padding: 0 15px;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 12px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #222222;
        line-height: 18px;
      }

      :deep().van-icon {
        font-size: 14px;
        color: #222222;
      }
    }

    .remarkmain {
      height: 100%;
      width: 100%;
      padding: 15px;
      padding-top: 0px;
      border-radius: 6px;

      .Content {
        width: 100%;
        height: 100%;
        border-top: 1px dashed #e2e2e2;

        .orderremark,
        .Agentreply {
          width: 100%;
          margin-top: 18px;

          h3 {
            font-size: 12px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #222222;
            line-height: 18px;
            margin-bottom: 10px;
          }

          .beizhu {
            width: 100%;
            padding: 10px;
            font-size: 12px;
            font-family: ArialMT;
            color: #222222;
            line-height: 18px;
            height: 92px;
            background: #f9f9f9;
            border-radius: 6px;
            border: 0.5px solid #e2e2e2;
          }
        }
      }
    }
  }

  .remarks {
    height: 344px;

    transition: 0.5s;
  }

  .borderbottom {
    border-top: 1px dashed #dbdbdb;

    padding-top: 14px;
  }
}

//订单备注offer editing -
.orderRemarks {
  min-height: 193px;
  padding: 15px;
  margin-top: 10px;
  background: #ffffff;
  border-radius: 6px;
  border: 0.5px solid #e2e2e2;

  h2 {
    height: 18px;
    font-size: 12px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #222222;
    line-height: 18px;
    margin-bottom: 10px;
  }

  p {
    height: 28px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    zoom: 0.9;
    margin-bottom: 15px;
  }

  .beizhu {
    height: 92px;
    width: 100%;
    background: #ffffff;
    padding: 10px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #C0C4CC;
    line-height: 18px;
    background: #f9f9f9;
    border-radius: 6px;
    border: 0.5px solid #dedede;
  }
}

// 代付款订单底部
.obligationfooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  height: 148px;

  .top-box {
    width: 100%;
    height: 40px;
    background: #ffffff;
    box-shadow: inset 0px 0.5px 0px 0px #e2e2e2;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .addlibrary {
      min-width: 106px;
      height: 22px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #608bff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-family: ArialMT;
      color: #608bff;
      line-height: 16px;
      padding: 0 5px;

      zoom: 0.9;
      letter-spacing: -0.5px;
    }

    .quantity {
      min-width: 94px;
      font-size: 12px;
      font-family: ArialMT;
      color: #666666;
      display: flex;
      align-items: center;
      zoom: 0.88;

      span {
        margin-left: 2px;
        color: #222222;
        font-weight: 600;
        zoom: 0.88;
      }
    }

    .total {
      min-width: 94px;
      font-size: 12px;
      font-family: ArialMT;
      color: #666666;
      display: flex;
      align-items: center;
      zoom: 0.88;

      span {
        color: #222222;
        font-weight: 600;
        zoom: 0.88;
      }
    }
  }

  .couponsbox {
    width: 100%;
    height: 40px;
    background: #ffffff;
    box-shadow: inset 0px 0.5px 0px 0px #e2e2e2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    :deep().van-icon-success:before {
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      border-radius: 50%;
    }

    :deep().van-checkbox__icon {
      height: 12px;
    }

    :deep().van-checkbox__label {
      margin-left: 5px;
      font-size: 12px;
      font-family: ArialMT;
      color: #ff730b;
      line-height: 16px;
    }

    :deep().van-badge__wrapper {
      width: 12px;
      height: 12px;
      font-size: 12px;
    }
  }

  .rightprice {
    font-size: 12px;
    font-family: ArialMT;
    color: #222222;
    line-height: 20px;

    .dollar {
      margin-left: 5px;
      margin-right: 5px;
      font-size: 14px;
      font-family: Arial-BoldMT, Arial;
      font-weight: 600;
      color: #ff730b;
      line-height: 20px;
    }

    .RMB {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
    }
  }

  .paymentbox {
    width: 100%;
    height: 68px;
    background: #ffffff;
    box-shadow: inset 0px 0.5px 0px 0px #e2e2e2;
    box-shadow: inset 0px 0.5px 0px 0px #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;

    .gopayment {
      width: 335px;
      height: 48px;
      background: #ff730b;
      border-radius: 6px;
      display: flex;
      font-size: 14px;
      font-family: ArialMT;
      color: #ffffff;
      line-height: 20px;
      justify-content: center;
      align-items: center;
    }
  }
}

.obligation {
  padding-top: 60px;
  padding-bottom: 158px;
}

// 修改订单样式
.Modifytheorder {
  width: 100%;
  background: #f6f6f6;
  height: calc(100vh - 44px);
  padding: 10px;
  padding-bottom: 118px;
  overflow-y: scroll;

  .shoplist {
    position: relative;

    background: #ffffff;
    border-radius: 6px;
    border: 0.5px solid #e2e2e2;
    max-height: 337px;
    overflow: hidden;
    transition: max-height 0.5s;
    padding-bottom: 30px;

    .shopitem {
      padding: 15px;
      border-bottom: 1px solid #dbdbdb;

      h3 {
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;
        margin-bottom: 15px;
      }

      .gooditem {
        width: 100%;
        height: 230px;

        width: 100%;

        .Content {
          width: 100%;
          height: 80px;
          display: flex;
          align-items: center;

          margin-bottom: 5px;

          .img-box {
            width: 80px;
            height: 80px;
            margin-right: 10px;
            border-radius: 4px;

            :deep().van-image {
              width: 80px;
              height: 80px;
              border-radius: 4px;
            }

            :deep().van-image__img {
              border-radius: 4px;
            }
          }

          .rightgoodsinfo {
            flex: 1;
            height: 100%;

            // 描述
            .Description {
              height: 32px;
              display: flex;
              justify-content: space-between;
              margin-bottom: 5px;
              align-items: center;

              .text {
                height: 32px;
                font-size: 12px;
                font-family: ArialMT;
                color: #222222;
                line-height: 16px;

                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }

              img {
                width: 24px;
                height: 24px;
                margin-left: 10px;
              }
            }

            .Commodityspecification {
              padding: 0 5px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 179px;
              height: 20px;
              background: #f6f6f6;
              border-radius: 4px;
              margin-bottom: 5px;

              p {
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                // zoom: 0.9;
                font-size: 12px;
                font-family: ArialMT;
                color: #666666;
                line-height: 22px;
                zoom: 0.9;
              }

              img {
                margin-left: 10px;
                width: 8px;
                height: 4px;
              }
            }

            .price {
              height: 18px;
              font-size: 12px;
              font-family: ArialMT;
              color: #222222;
              line-height: 18px;

              .dollar,
              .RMB {
                font-size: 12px;
                font-family: ArialMT;
                color: #222222;
                line-height: 18px;
              }
            }
          }
        }

        // 总价
        .totalprice {
          width: 100%;
          height: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .left-price {
            display: flex;
            align-items: center;
            height: 30px;

            .title {
              height: 18px;
              font-size: 12px;
              font-family: ArialMT;
              color: #222222;
              line-height: 16px;
              display: flex;
              margin-right: 5px;
            }

            .dollar {
              margin-right: 2px;
            }

            .dollar,
            .RMB {
              height: 18px;
              font-size: 12px;
              font-family: Arial-BoldMT, Arial;
              font-weight: 600;
              color: #ff730b;
              line-height: 18px;
            }
          }

          .right-input {
            width: 100px;
            height: 30px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #222222;
            display: flex;
            align-items: center;
            justify-content: center;

            :deep().van-haptics-feedback {
              width: 22px;
              height: 22px;
              background: #f2f2f2;
              border-radius: 2px;
            }

            :deep().van-stepper__minus--disabled {
              width: 22px;
              height: 22px;
              background: #f2f2f2;
              border-radius: 2px;
            }

            :deep().van-stepper {
              height: 22px;
              display: flex;
              align-items: center;
            }

            :deep().van-stepper__input {
              width: 40px;
              height: 16px;
              font-size: 12px;

              font-family: ArialMT;
              background-color: #fff;
            }
          }
        }

        // 附加服务
        .Additionalservices {
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 40px;
          background: #ffffff;
          border-radius: 6px;
          border: 0.5px solid #dedede;
          margin-bottom: 10px;

          p {
            width: 275px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            font-family: ArialMT;
            color: #666666;
          }

          .optiontext {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 18px;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;



            span {
              font-size: 14px;
              line-height: 20px;
              margin-right: 5px;
            }
          }

          img {
            margin-left: 10px;
            width: 20px;
            height: 20px;
          }
        }

        // 备注

        .Remarks {
          // width: 325px;
          // height: 40px;
          // background: #FFFFFF;
          // border-radius: 6px;
          // border: 1px solid #DEDEDE;

          input {
            width: 100%;
            height: 40px;
            background: #ffffff;
            border-radius: 6px;
            border: 0.5px solid #dedede;
            font-size: 12px;
            font-family: ArialMT;
            padding-left: 10px;
            line-height: 18px;
          }

          input:placeholder {
            font-size: 12px;
            font-family: ArialMT;
            color: #C0C4CC;
          }
        }
      }

      .borderbottom {
        border-top: 1px dashed #dbdbdb;

        padding-top: 14px;
      }
    }

    .foot-box {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 34px;
      background: #ffffff;
      box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.04);
      border-radius: 0px 0px 6px 6px;
      border-top: 1px solid #e2e2e2;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      div {
        display: flex;
        align-items: center;
      }

      span {
        font-size: 10px;
        font-family: ArialMT;
        color: #222222;
        line-height: 14px;
      }

      img {
        margin-left: 5px;
        width: 12px;
        height: 7px;
      }
    }
  }

  .maxheight {
    max-height: 10000000px;
    transition: max-height 0.5s;
  }
}

// 修改订单底部操作栏
.footercaozuolan {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 108px;

  background: #ffffff;

  .Additionalproductbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    box-shadow: inset 0px 0.5px 0px 0px #e2e2e2;
    padding: 0 20px;

    .leftproduct {
      display: flex;

      img {
        width: 12px;
        height: 12px;
        margin-right: 3px;
      }

      span {
        font-size: 10px;
        font-family: ArialMT;
        color: #222222;
        line-height: 16px;
        zoom: 0.9;
      }
    }

    .quantity {
      font-size: 12px;
      font-family: ArialMT;
      color: #666666;
      line-height: 16px;
      zoom: 0.9;

      span {
        color: #222222;
        font-weight: 600;
      }
    }
  }

  .tichu-box {
    display: flex;
    height: 68px;
    justify-content: space-between;
    box-shadow: inset 0px 0.5px 0px 0px #e2e2e2;
    align-items: center;
    padding: 0 20px;

    .totalprice {
      display: flex;

      .title {
        height: 20px;
        font-size: 12px;
        font-family: ArialMT;
        color: #666666;
        line-height: 15px;
        margin-right: 5px;
      }

      .price {
        .dollar {
          margin-bottom: 2px;
          height: 20px;
          font-size: 14px;
          font-family: Arial-BoldMT, Arial;
          font-weight: 600;
          display: flex;
          align-items: center;
          color: #ff730b;
          line-height: 20px;
        }

        .RMB {
          height: 16px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 16px;
        }
      }
    }

    .Temporarypreservation {
      margin-left: 20px;
      width: 50px;
      display: flex;
      padding-top: 15px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;

      img {
        width: 15px;
        height: 15px;
        margin-bottom: 2px;
      }

      p {
        margin-top: 2px;
        height: 20px;
        font-size: 10px;
        font-family: ArialMT;
        color: #666666;
        line-height: 10px;
        zoom: 0.9;
      }
    }

    .Placeanorder {
      width: 120px;
      height: 48px;
      background: #ff730b;
      border-radius: 6px;
      font-size: 14px;
      font-family: ArialMT;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 20px;
    }
  }
}

// 修改 编辑底部操作栏
.editfooterbox {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 108px;

  background: #ffffff;

  .allcheckbox {
    height: 40px;
    background: #ffffff;
    box-shadow: inset 0px 0.5px 0px 0px #e2e2e2;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-check {
      font-size: 10px;
      font-family: ArialMT;
      color: #666666;

      display: flex;
      align-items: center;
    }

    .checkbox-box {
      margin-right: 5px;
    }

    .Totalquantity {
      font-size: 12px;
      font-family: ArialMT;
      color: #666666;
      line-height: 16px;

      span {
        color: #222222;
        font-weight: 600;
      }
    }
  }

  .caozuobox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    background: #ffffff;
    box-shadow: inset 0px 0.5px 0px 0px #e2e2e2;
    padding: 0 20px;

    .delete {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 48px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      background: #608bff;
      border-radius: 6px;
    }

    .addgoods {
      display: flex;

      justify-content: center;
      width: 160px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      height: 48px;
      background: #ff730b;
      border-radius: 6px;
      align-items: center;
    }
  }
}

// 最大高度
.maxheight {
  max-height: 100000000000px !important;
  transition: max-height 0.5s;
}
</style>
