<template>
  <div :style="step > 0 ? 'background:#fff;' : ''" class="updateInformationValidationPage">
    <header>
      <van-icon name="arrow-left" @click="goBack" />
      <div>{{ headerTitle }}</div>
    </header>
    <footer>
      <div v-if="step === 0">
        <div class="selectAttestationText">{{ $fanyi('请选择认证方式') }}</div>
        <div class="attestationFunctionContainer" style="margin-bottom:20px"
          @click="updateValidationFunction('password')">
          <img src="../../../../../assets/userIndex/userDetail/password.svg" alt="">
          <div class="attestationFunctionText">{{ $fanyi('密码验证') }}</div>
          <div class="selectText">{{ $fanyi("选择") }}</div>
        </div>
        <div class="attestationFunctionContainer" style="margin-bottom:20px"
          @click="updateValidationFunction('mobile')">
          <img src="../../../../../assets/userIndex/userDetail/mobile.svg" alt="">
          <div class="attestationFunctionText">{{ $fanyi('手机号码') }}</div>
          <div class="selectText">{{ $fanyi("选择") }}</div>
        </div>
        <div class="attestationFunctionContainer" @click="updateValidationFunction('email')">
          <img src="../../../../../assets/userIndex/userDetail/email.svg" alt="">
          <div class="attestationFunctionText">{{ $fanyi('电子邮箱') }}</div>
          <div class="selectText">{{ $fanyi("选择") }}</div>
        </div>
      </div>
      <div v-if="step === 1">
        <!--密码验证开始-->
        <div v-if="typeValue === 'password'" class="passwordValidationContainer">
          <div class="validationTitleText">{{ $fanyi('密码验证') }}</div>
          <div class="validationFunctionPlaceholderText">{{ $fanyi('请输入密码') }}：</div>
          <div class="rowFlexItemsCenter">
            <van-field v-model="passwordValidationValue" :placeholder="$fanyi('请输入新的密码')"
              :type="passwordValidationFieldType">
              <template #button>
                <img :src="passwordIcon" style="margin-right: 13px" @click="updatePasswordValidationFieldType">
              </template>
            </van-field>
          </div>
          <div class="btn" @click="validationPassword">{{ $fanyi('下一步') }}</div>
        </div>
        <!--密码验证结束-->
        <!--邮箱验证开始-->
        <div v-if="typeValue === 'email'" class="updateEmailAddressContainer">
          <div class="validationTitleText">{{ $fanyi('邮箱验证') }}</div>
          <div class="validationFunctionPlaceholderText">{{ $fanyi('RAKUMART电子邮箱账户') }}：</div>
          <div>
            <van-field v-model="Emil" :placeholder="$fanyi('请输入您在RAKUMART的电子邮箱')" @blur="emilBlur"></van-field>
            <div class="newEmilErrorText">{{ EmilErrorText }}</div>
          </div>
          <div class="validationCodeContainer">
            <div class="validationFunctionPlaceholderText" style="margin-bottom: 0">{{ $fanyi('验证码') }}：</div>
            <div v-if="EmilCodeSend === false" class="sendText" @click="EmilSendCode">{{ $fanyi('发送验证码') }}</div>
            <div v-else class="secondText">{{ Countdown }}S</div>
          </div>
          <div class="rowFlexItemsCenter">
            <van-field v-model="EmilCode" :placeholder="$fanyi('请输入验证码')"></van-field>
          </div>
          <div class="btn" @click="validationEmil" style="margin-top: 20px">{{ $fanyi('下一步') }}</div>
        </div>
        <!--邮箱验证结束-->
        <!--手机验证开始-->
        <div v-if="typeValue === 'mobile'" class="updateMobileAddressContainer">
          <div class="validationTitleText">{{ $fanyi('手机号验证') }}</div>
          <div class="validationFunctionPlaceholderText">{{ $fanyi('RAKUMART手机账户') }}：</div>
          <div>
            <div class="rowFlexItemsCenter" style="margin-bottom: 4px">
              <!-- <div class="areaCodeContainer">+001</div> -->
              <van-field v-model="mobile" :placeholder="$fanyi('请输入您的手机号')" @blur="mobileBlur"></van-field>
            </div>
            <div class="newEmilErrorText">{{ mobileErrorText }}</div>
          </div>
          <div class="validationCodeContainer">
            <div class="validationFunctionPlaceholderText" style="margin-bottom: 0">{{ $fanyi('验证码') }}：</div>
            <div v-if="mobileCodeSend === false" class="sendText" @click="mobileSendCode">{{ $fanyi('发送验证码') }}</div>
            <div v-else class="secondText">{{ Countdown }}S</div>
          </div>
          <div class="rowFlexItemsCenter">
            <van-field v-model="mobileCode" :placeholder="$fanyi('请输入验证码')"></van-field>
          </div>
          <div class="btn" @click="validationMobile" style="margin-top: 20px">{{ $fanyi('下一步') }}</div>
        </div>
        <!--手机验证结束-->
      </div>
      <div v-if="step === 2">
        <!--修改邮箱开始-->
        <div v-if="$route.query.type === 'email'" class="updateEmailAddressContainer">
          <div class="validationTitleText">{{ $fanyi('更新邮箱地址') }}</div>
          <div class="validationFunctionPlaceholderText">{{ $fanyi('新电子邮箱账户') }}：</div>
          <div>
            <van-field v-model="newEmil" :placeholder="$fanyi('请输入您要替换的电子邮箱地址')" @blur="newEmilBlur"></van-field>
            <div class="newEmilErrorText">{{ newEmilErrorText }}</div>
          </div>
          <div class="validationCodeContainer">
            <div class="validationFunctionPlaceholderText" style="margin-bottom: 0">{{ $fanyi('验证码') }}：</div>
            <div v-if="newEmilCodeSend === false" class="sendText" @click="newEmilSendCode">{{ $fanyi('发送验证码') }}</div>
            <div v-else class="secondText">{{ Countdown }}S</div>
          </div>
          <div class="rowFlexItemsCenter">
            <van-field v-model="newEmilCode" :placeholder="$fanyi('请输入验证码')"></van-field>
          </div>
          <div class="btn" @click="validationNewEmil" style="margin-top: 20px">{{ $fanyi('下一步') }}</div>
        </div>
        <!--修改邮箱结束-->
        <!--修改手机开始-->
        <div v-if="$route.query.type === 'mobile'" class="updateMobileAddressContainer">
          <div class="validationTitleText">{{ $fanyi('更新手机号') }}</div>
          <div class="validationFunctionPlaceholderText">{{ $fanyi('新手机') }}：</div>
          <div>
            <div class="rowFlexItemsCenter" style="margin-bottom: 4px">
              <div class="areaCodeContainer">
                <div class="quhao" @click.stop="showAreacode = true">
                  <span>{{ Mobilephoneareacode }} </span>
                  <span><van-icon name="arrow-down" /></span>
                </div>
              </div>
              <van-field v-model="newMobile" :placeholder="$fanyi('请输入新手机号')" @blur="newMobileBlur"></van-field>
            </div>
            <div class="newEmilErrorText">{{ newMobileErrorText }}</div>
          </div>
          <div class="validationCodeContainer">
            <div class="validationFunctionPlaceholderText" style="margin-bottom: 0">{{ $fanyi('验证码') }}：</div>
            <div v-if="newMobileCodeSend === false" class="sendText" @click="newMobileSendCode">{{ $fanyi('发送验证码') }}
            </div>
            <div v-else class="secondText">{{ Countdown }}S</div>
          </div>
          <div class="rowFlexItemsCenter">
            <van-field v-model="newMobileCode" :placeholder="$fanyi('请输入验证码')"></van-field>
          </div>
          <div class="btn" @click="validationNewMobile" style="margin-top: 20px">{{ $fanyi('下一步') }}</div>
        </div>
        <!--修改手机结束-->
        <!--修改密码开始-->
        <div v-if="$route.query.type === 'password'" class="updateEmailAddressContainer">
          <div class="validationTitleText">{{ $fanyi('设置密码') }}</div>
          <div class="validationFunctionPlaceholderText">{{ $fanyi('新密码') }}：</div>
          <div>
            <div class="rowFlexItemsCenter">
              <van-field v-model="password" :type="passwordFieldType" :placeholder="$fanyi('请输入新密码')"
                @blur="newPasswordBlur">
                <template #button>
                  <img :src="newPasswordIcon" style="margin-right:  13px" @click="updateNewPasswordFieldType">
                </template>
              </van-field>
            </div>
            <div class="newEmilErrorText">{{ newPasswordErrorText }}</div>
          </div>
          <div class="validationCodeContainer">
            <div class="validationFunctionPlaceholderText" style="margin-bottom: 0">{{ $fanyi('确认密码') }}：</div>
          </div>
          <div class="rowFlexItemsCenter">
            <van-field v-model="repassword" :placeholder="$fanyi('请再次输入密码')" :type="repasswordFieldType"
              @blur="newRePasswordBlur">
              <template #button>
                <img :src="repasswordIcon" style="margin-right: 13px" @click="updateRePasswordFieldType">
              </template>
            </van-field>
          </div>
          <div class="newEmilErrorText">{{ repasswordErrorText }}</div>
          <div class="btn" @click="confirmUpdatePassword" style="margin-top: 20px">{{ $fanyi('确认修改') }}</div>
        </div>
        <!--修改密码结束-->
      </div>
      <div v-if="step === 3" class="updateSuccessContainer">
        <img src="../../../../../assets/userIndex/userDetail/success.svg" alt="">
        <div class="updateSuccessHeaderText">
          {{ $route.query.type === 'email' ? $fanyi('邮箱修改成功') : $route.query.type === 'mobile' ? $fanyi('手机号更改成功') :
            $fanyi('密码修改成功') }}
        </div>
        <div class="f12 color999">
          {{ $route.query.type === 'email' ? $fanyi('您已经有了新邮箱，请重新登陆!') : $route.query.type === 'mobile' ?
            $fanyi('您已经有了新手机号，请重新登陆!') : $fanyi('您已经有了新密码，请重新登录！') }}
        </div>
        <div class="btn" @click="$router.replace('/login')" style="margin-top: 40px">{{ $fanyi('去登录') }}</div>
      </div>
    </footer>
  </div>
  <!-- 选择手机区号弹出层 -->
  <van-popup v-model:show="showAreacode" round position="bottom">
    <van-picker :columns="AreacodeArr" :cancel-button-text="$fanyi('取消')" :confirm-button-text="$fanyi('确认')"
      @cancel="showAreacode = false" @confirm="onConfirmAreacode" />
  </van-popup>
</template>

<script setup>
import { getCurrentInstance, ref } from 'vue';
import Areacode from '../../../../../utlis/mobilephoneareacode.js'
import { showToast } from "vant";
const { proxy } = getCurrentInstance()
const step = ref(0);
const typeValue = ref('');
const newEmil = ref('');
const newEmilErrorText = ref('');
const newEmilCode = ref('');
const newEmilCodeSend = ref(false);
const Emil = ref('');
const EmilErrorText = ref('');
const EmilCode = ref('');
const EmilCodeSend = ref(false);
const mobile = ref('');
const mobileErrorText = ref('');
const mobileCode = ref('');
const mobileCodeSend = ref(false);
const newMobile = ref('');
const showAreacode = ref(false)
const newMobileErrorText = ref('');
const newMobileCode = ref('');
const newMobileCodeSend = ref(false);
const headerTitle = ref(proxy.$fanyi(''));
const Countdown = ref(60);
const passwordValidationFieldType = ref('password');
const passwordFieldType = ref('password');
const repasswordFieldType = ref('password');
const repasswordErrorText = ref('');
const passwordValidationValue = ref('');
const newPasswordErrorText = ref('');
const password = ref('');
const Mobilephoneareacode = ref('+001')
const repassword = ref('');
const passwordIcon = ref(require('../../../../../assets/userIndex/userDetail/hide.svg'));
const repasswordIcon = ref(require('../../../../../assets/userIndex/userDetail/hide.svg'));
const newPasswordIcon = ref(require('../../../../../assets/userIndex/userDetail/hide.svg'));
const AreacodeArr = ref([])
//更新验证方式
const updateValidationFunction = (type) => {
  step.value++
  typeValue.value = type
}
if (proxy.$route.query.type == 'password') {
  headerTitle.value = proxy.$fanyi('登录密码修改');
}
if (proxy.$route.query.type == 'email') {
  headerTitle.value = proxy.$fanyi('电子邮箱修改');
}
if (proxy.$route.query.type == 'mobile') {
  headerTitle.value = proxy.$fanyi('手机号修改');
}
//验证密码是否正确
const validationPassword = () => {
  if (passwordValidationValue.value !== '') {
    proxy.$api.userCheckPassword({
      password: passwordValidationValue.value,
    })
      .then((res) => {
        if (res.data != true) {
          return showToast(proxy.$fanyi("密码错误"))
        }

        step.value++;
      });
  }
}
Areacode.forEach((item) => {
  if (proxy.$store.state.languagetype == "English") {
    AreacodeArr.value.push({
      text: item.sx + ' ' + item.value,
      value: item.value
    })
  } else if (proxy.$store.state.languagetype == 'Chinese') {
    AreacodeArr.value.push({
      text: item.key + ' ' + item.value,
      value: item.value
    })
  }

})
// 点击确定选择区号
const onConfirmAreacode = ({ selectedOptions }) => {

  Mobilephoneareacode.value = selectedOptions[0].value
  showAreacode.value = false
}
//更新验证密码输入框的type
const updatePasswordValidationFieldType = () => {
  if (passwordIcon.value === require('../../../../../assets/userIndex/userDetail/hide.svg')) {
    passwordIcon.value = require('../../../../../assets/userIndex/userDetail/display.svg');
    passwordValidationFieldType.value = 'text';
  } else {
    passwordIcon.value = require('../../../../../assets/userIndex/userDetail/hide.svg');
    passwordValidationFieldType.value = 'password'
  }
}

//更新修改密码时新密码输入框的type
const updateNewPasswordFieldType = () => {
  if (newPasswordIcon.value === require('../../../../../assets/userIndex/userDetail/hide.svg')) {
    newPasswordIcon.value = require('../../../../../assets/userIndex/userDetail/display.svg');
    passwordFieldType.value = 'text';
  } else {
    newPasswordIcon.value = require('../../../../../assets/userIndex/userDetail/hide.svg');
    passwordFieldType.value = 'password'
  }
}
//更新确认密码时新密码输入框的type
const updateRePasswordFieldType = () => {
  if (repasswordIcon.value === require('../../../../../assets/userIndex/userDetail/hide.svg')) {
    repasswordIcon.value = require('../../../../../assets/userIndex/userDetail/display.svg');
    repasswordFieldType.value = 'text';
  } else {
    repasswordIcon.value = require('../../../../../assets/userIndex/userDetail/hide.svg');
    repasswordFieldType.value = 'password'
  }
}
//新密码输入框失焦校验
const newPasswordBlur = () => {
  let passwordReg = /^.{6,16}$/;
  if (!passwordReg.test(password.value)) {
    newPasswordErrorText.value = proxy.$fanyi("请输入6-16位包含大小写字母和数字的字符串");
    return
  }
  newPasswordErrorText.value = ''
}
//确认新密码输入框失焦校验
const newRePasswordBlur = () => {
  if (repassword.value === '') {
    repasswordErrorText.value = proxy.$fanyi("请再次输入密码");
    return
  }
  if (repassword.value !== password.value) {
    repasswordErrorText.value = proxy.$fanyi("密码输入不一致");
    return
  }
  repasswordErrorText.value = '';
}
//确认修改密码
const confirmUpdatePassword = () => {
  if ((password.value !== '' && newPasswordErrorText.value === '') && (repassword.value !== '' && repasswordErrorText.value === '')) {
    let datas = {
      password: password.value,
      login_name: proxy.$store.state.userInfo.login_name,
      verification: {
        number: proxy.$store.state.userInfo.mobile,
      },
    };
    proxy.$api.userPasswordSave(datas).then((res) => {
      if (res.code != 0) return showToast(proxy.$fanyi(res.msg));
      localStorage.removeItem("user_token");
      proxy.$store.commit("userData", null);
      step.value++
    });
  }
}
//校验旧邮箱验证码
const validationEmil = () => {


  if (emilBlur() !== true) return
  if (Emil.value !== '' && EmilErrorText.value === '') {
    let data = {
      occasion: 'forget',
      number: Emil.value,
      code: EmilCode.value
    }
    proxy.$api.checkVerificationCode(data).then((res) => {
      if (res.code != 0) return showToast(proxy.$fanyi(res.msg));
      step.value++

    });
  }
}
//校验旧手机号验证码
const validationMobile = () => {

  if (mobileBlur() !== true) return
  if (mobile.value !== '' && mobileErrorText.value === '') {
    let data = {
      occasion: 'forget',
      number: mobile.value,
      code: mobileCode.value
    }
    proxy.$api.checkVerificationCode(data).then((res) => {
      if (res.code != 0) return showToast(proxy.$fanyi(res.msg));
      step.value++

    });
  }
}
//验证新邮箱验证码
const validationNewEmil = () => {
  if (newEmil.value !== '' && newEmilErrorText.value === '') {
    let data = {
      occasion: 'auto',
      number: newEmil.value,
      code: newEmilCode.value
    }
    proxy.$api.checkVerificationCode(data).then((res) => {
      if (res.code != 0) return showToast(proxy.$fanyi(res.msg));
      proxy.$api.userEmailSave({ email: newEmil.value }).then((res) => {
        if (res.code != 0) return showToast(proxy.$fanyi(res.msg));
        localStorage.removeItem("user_token");
        proxy.$store.commit("userData", null);
        step.value++
      });
    });
  }
}
//验证新手机号验证码
const validationNewMobile = () => {
  if (newMobileBlur !== true) return
  if (newMobile.value !== '' && newMobileErrorText.value === '') {
    let data = {
      occasion: 'auto',
      number: newMobile.value,
      code: newMobileCode.value
    }
    proxy.$api.checkVerificationCode(data).then((res) => {
      if (res.code != 0) return showToast(proxy.$fanyi(res.msg));
      proxy.$api.userMobileSave({ mobile: newMobile.value }).then((res) => {
        if (res.code != 0) return showToast(proxy.$fanyi(res.msg));
        localStorage.removeItem("user_token");
        proxy.$store.commit("userData", null);
        step.value++
      });
    });
  }
}
//旧邮箱发送验证码
const EmilSendCode = () => {

  if (Emil.value !== '' && EmilErrorText.value === '') {
    let datas = {
      occasion: "forget",
      tool: 'email',
      number: Emil.value,
      login_name: proxy.$store.state.userInfo.login_name,
    };
    Countdown.value = 60;
    var timer = setInterval(() => {
      Countdown.value--;
      if (Countdown.value <= 0) {
        EmilCodeSend.value = false;
        Countdown.value = 60;
        clearInterval(timer);
      }
    }, 1000);
    EmilCodeSend.value = true;
    proxy.$api.sendVerificationCode(datas).then((res) => {
      if (res.code != 0) {
        EmilCodeSend.value = false;
        return showToast(proxy.$fanyi(res.msg));
      }
      EmilCodeSend.value = true;
    });
  }
}
//旧手机号发送验证码
const mobileSendCode = () => {

  if (mobile.value !== '' && mobileErrorText.value === '') {
    let datas = {
      occasion: "forget",
      tool: 'mobile',
      number: mobile.value,
      login_name: proxy.$store.state.userInfo.login_name,

    };
    Countdown.value = 60;
    var timer = setInterval(() => {
      Countdown.value--;
      if (Countdown.value <= 0) {
        mobileCodeSend.value = false;
        Countdown.value = 60;
        clearInterval(timer);
      }
    }, 1000);
    mobileCodeSend.value = true;
    proxy.$api.sendVerificationCode(datas).then((res) => {
      if (res.code != 0) {
        mobileCodeSend.value = false;
        return showToast(proxy.$fanyi(res.msg));
      }
      mobileCodeSend.value = true;
    });
  }
}
//新邮箱发送验证码
const newEmilSendCode = () => {

  if (newEmilBlur() !== true) return
  if (newEmil.value !== '' && newEmilErrorText.value === '') {
    let datas = {
      occasion: "auto",
      tool: 'email',
      number: newEmil.value,
      login_name: proxy.$store.state.userInfo.login_name,
    };
    Countdown.value = 60;
    var timer = setInterval(() => {
      Countdown.value--;
      if (Countdown.value <= 0) {
        newEmilCodeSend.value = false;
        Countdown.value = 60;
        clearInterval(timer);
      }
    }, 1000);
    newEmilCodeSend.value = true;
    proxy.$api.sendVerificationCode(datas).then((res) => {
      if (res.code != 0) {
        newEmilCodeSend.value = false;
        return showToast(proxy.$fanyi("res.msg"));
      }
      newEmilCodeSend.value = true;
    });
  }
}
//新手机号发送验证码
const newMobileSendCode = () => {
  if (newMobile.value !== '' && newMobileErrorText.value === '') {
    let datas = {
      occasion: "auto",
      tool: 'mobile',
      number: newMobile.value,
      login_name: proxy.$store.state.userInfo.login_name,
      area_code: Mobilephoneareacode.value,
    };
    Countdown.value = 60;
    var timer = setInterval(() => {
      Countdown.value--;
      if (Countdown.value <= 0) {
        newMobileCodeSend.value = false;
        Countdown.value = 60;
        clearInterval(timer);
      }
    }, 1000);
    newMobileCodeSend.value = true;
    proxy.$api.sendVerificationCode(datas).then((res) => {
      if (res.code != 0) {
        newMobileCodeSend.value = false;
        return showToast(proxy.$fanyi(res.msg));
      }
      newMobileCodeSend.value = true;
    });
  }
}
//旧邮箱格式校验
const emilBlur = () => {
  if (Emil.value === '') {
    EmilErrorText.value = '*' + proxy.$fanyi("请输入您的电子邮箱");
    return false
  }
  // var emailRegExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  // if (!emailRegExp.test(Emil.value)) {
  //   EmilErrorText.value = proxy.$fanyi("请输入正确的邮箱地址");
  //   return false
  // }
  EmilErrorText.value = ''
  return true
}
//旧手机号格式校验
const mobileBlur = () => {

  if (mobile.value === '') {
    mobileErrorText.value = '*' + proxy.$fanyi("请输入您的手机号");
    return false
  }
  // let mobileReg = /^(\w){10}$/;
  // if (!mobileReg.test(mobile.value)) {
  //   mobileErrorText.value = proxy.$fanyi("请输入正确的手机号");
  //   return false
  // }
  mobileErrorText.value = ''
  return true
}
//新邮箱格式校验
const newEmilBlur = () => {
  if (newEmil.value === '') {
    newEmilErrorText.value = '*' + proxy.$fanyi("请输入您的电子邮箱");
    return false
  }
  // var emailRegExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  // if (!emailRegExp.test(newEmil.value)) {
  //   newEmilErrorText.value = proxy.$fanyi("请输入正确的邮箱地址");
  //   return false
  // }
  newEmilErrorText.value = ''
  return true
}
//新手机格式校验
const newMobileBlur = () => {
  if (newMobile.value === '') {
    newMobileErrorText.value = '*' + proxy.$fanyi("请输入您的手机号");
    return false
  }
  // let mobileReg = /^(\w){10}$/;
  // if (!mobileReg.test(newMobile.value)) {
  //   newMobileErrorText.value = proxy.$fanyi("请输入正确的手机号");
  //   return false
  // }
  newMobileErrorText.value = ''
  return true
}
//页面返回
const goBack = () => {
  // if (step.value == 0 || step.value == 1 || step.value == 2) {
  //   proxy.$router.replace('/user/userDetail')
  // } else {
  //   proxy.$router.replace('/user/index')
  // }
  if (step.value == 0) {
    proxy.$router.back()
  } else {
    step.value = step.value - 1
  }
}
</script>

<style lang="scss" scoped>
.updateInformationValidationPage {
  height: 100vh;
  overflow: hidden;

}

header {
  height: 40px;
  display: flex;
  align-items: center;
  background: #fff;
  position: sticky;
  top: 0;

  :deep().van-icon {
    font-size: 22px;
  }

  div {
    font-size: 16px;
    font-weight: bold;
    color: #030303;
    margin-left: 10px;
  }
}

:deep().van-field__button {
  img {
    width: 20px;
    height: 20px;
  }
}

footer {
  padding: 20px 20px 40px;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 60px;



  .selectAttestationText {
    color: #222222;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .attestationFunctionContainer {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    border: 0.5px solid #E2E2E2;
    border-radius: 6px;

    .attestationFunctionText {
      margin: 10px 0 20px;
      font-size: 16px;
      font-weight: bold;
    }

    .selectText {
      width: 160px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #FF730B;
      line-height: 40px;
      text-align: center;
      color: #FF730B;
      font-size: 14px;
    }
  }

  .btn {
    width: 100%;
    height: 40px;
    background: #FF730B;
    border-radius: 6px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
    font-size: 14px;
  }

  .validationTitleText {
    color: #222222;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .validationFunctionPlaceholderText {
    color: #222222;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .passwordValidationContainer {
    .van-cell {
      border-radius: 6px;
      border: 0.5px solid #DEDEDE;
      height: 40px;
      padding: 8px 0 0 10px;
      margin-bottom: 20px;
    }
  }

  .f12 {
    font-size: 12px;
  }

  .color999 {
    color: #999;
    text-align: center;
  }

  .updateEmailAddressContainer,
  .updateMobileAddressContainer {
    .van-cell {
      border-radius: 6px;
      border: 0.5px solid #DEDEDE;
      height: 40px;
      padding: 8px 0 0 10px;
      margin-bottom: 4px;
    }

    .newEmilErrorText {
      color: #E74C3C;
      font-size: 10px;
      height: 16px;
      margin-bottom: 10px;
    }

    .validationCodeContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .secondText {
        color: #999999;
        font-size: 12px;
      }

      .sendText {
        color: #FF730B;
        font-size: 12px;
      }
    }
  }

  .updateMobileAddressContainer {
    .van-cell {
      margin-bottom: 0;
    }
  }

  .updateSuccessContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .updateSuccessHeaderText {
      font-size: 14px;
      color: #222222;
      margin: 20px 0 5px 0;
    }
  }

  .areaCodeContainer {
    width: 65px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 0.5px solid #DEDEDE;
    line-height: 40px;
    text-align: center;
    color: #222222;
    font-size: 12px;
    margin-right: 10px;

    .quhao {
      display: flex;
      align-items: center;
      width: 65px;
      height: 40px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 18px;
      border-radius: 4px;
      justify-content: center;


      .van-icon {
        font-size: 12px;
        width: 12px;
        height: 16px;
        margin-left: 5px;
      }
    }
  }
}
</style>
