<template>
  <!-- 顶部bar -->
  <hearbar ref="headercart" />
  <div class="Commoditydetails" v-if="!showdialong">
    <!-- 轮播图 -->
    <picturesOfProducts />

    <!-- 价格区间 -->
    <div class="Pricerange">
      <!-- 说明是数组 -->
      <div class="range" v-if="details.goodsInfo?.priceRanges && details.goodsInfo?.priceRanges.length > 0">
        <div class="priceitem" v-for="(item, ind) in details.goodsInfo.priceRanges" :key="ind">
          <div class="price">
            <span class="dollar">${{
              $fun.EURNumSegmentation(
                $fun.ceil(
                  item.price * $store.state.exchangeRate
                )
              )
            }}</span>
            <span class="RMB">(￥{{ $fun.RMBNumSegmentation(item.price) }})</span>
          </div>
          <p>Start with {{ item.startQuantity }} pieces </p>
        </div>
      </div>
      <!--说明是对象一个数据  -->
      <div class="range rangeobj" v-else>
        <div class="priceitem">
          <div class="price">
            <span class="dollar">${{
              $fun.EURNumSegmentation(
                $fun.ceil(
                  details.goodsInfo?.priceRanges.priceMin * $store.state.exchangeRate
                )
              )
            }}</span>
            <span class="RMB">(￥{{ $fun.RMBNumSegmentation(details.goodsInfo?.priceRanges.priceMin) }})</span>
          </div>
          <p>Start with {{ details.goodsInfo?.priceRangesstartQuantity }} pieces </p>
        </div>
        <div class="priceitem">
          <div class="price">
            <span class="dollar">${{
              $fun.EURNumSegmentation(
                $fun.ceil(
                  details.goodsInfo?.priceRanges.priceMax * $store.state.exchangeRate
                )
              )
            }}</span>
            <span class="RMB">(￥{{ $fun.RMBNumSegmentation(details.goodsInfo?.priceRanges.priceMax) }})</span>
          </div>
          <!-- <p>Start with {{ value }} pieces </p> -->
        </div>
      </div>

      <!-- 标题 -->
      <p class="text">
        {{ details.title }}
      </p>

    </div>

    <!--  商品规格-->
    <Commodityspecification ref="Commodityspecificationdom" @getcolor="getcolor" @getprice="getprice"
      :details="details" />
    <!-- 产品属性 店铺名称  一个组件-->
    <Productattribute :details="details" />



    <!-- 接口返回的商品介绍 -->
    <div class="introductionOfGoods">
      <div v-html="details.description"></div>
    </div>
    <!-- 底部 -->
    <div class="footerindex">

      <img @click="$fun.toPage('/')" src="../../assets/index/logo-rakumart.svg" alt="">
    </div>


  </div>
  <div class="footeraddShoppingCart">
    <!-- 左侧价格 -->
    <div class="left">
      <h2>{{ $fanyi('产品数量') }}: <span>{{ total }}</span></h2>
      <div class="price">
        <span class="left-title">{{ $fanyi('总计') }}:</span>
        <span class="dollar">${{
          $fun.EURNumSegmentation(
            $fun.ceil(
              (details.Unitprice * total) * $store.state.exchangeRate
            )
          )
        }}</span>
        <span class="RMB">(￥{{ $fun.RMBNumSegmentation($fun.ceil(
          (details.Unitprice * total)
        )) }})</span>

      </div>

    </div>

    <!-- 右侧加入购物车 -->

    <div class="addcart" @click="addcart">
      {{ $fanyi('加入购物车') }}
    </div>


  </div>
  <!-- 登录弹出层 -->
  <loginpopup ref="logindom" />
  <!-- 找不到商品弹出层 -->
  <van-dialog id="nodatadialong" v-model:show="showdialong">
    <!-- default	自定义内容 title	自定义标题footer -->

    <div class="img-box">
      <img src="../../assets/loadfail.svg" alt="">
    </div>
    <p>{{ $fanyi('加载错误，商品未找到') }}</p>



    <div class="othershop" @click="$router.back()">
      {{ $fanyi('查看其他商品') }}
    </div>

  </van-dialog>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose, nextTick } from 'vue'
import { useRoute } from 'vue-router';
import hearbar from './components/hearbar.vue';
import Commodityspecification from './components/Commodityspecification.vue'
import picturesOfProducts from './components/picturesOfProducts.vue';
import Productattribute from './components/Productattribute'
import loginpopup from '../../components/loginpopup/index.vue'
import { showImagePreview } from "vant";
import { showToast, showFailToast, showLoadingToast, closeToast, showSuccessToast } from 'vant'


const { proxy } = getCurrentInstance()
const route = useRoute()

const details = ref({})// 商品详情
const showType = ref('img')// 控制 第一张展示图片还是视频
const price = ref(0)// 收藏时要传入的价格
const str = ref('') // 根据选中的规格拼接成的字符
const text = ref('') //处理好要展示的规格
const total = ref(0)// 寻选中的商品数量
const logindom = ref('')
const skuId = ref('')// 选中的skuid
const specId = ref('')// 选中的speId
const Commodityspecificationdom = ref('')
const headercart = ref()
const showdialong = ref(false)// 搜索不到商品详情弹窗
const detailsImg = ref('')// 默认图片
// 首先获取单号和id,商品信息
const datas = ref({
  goods_id: route.query.goods_id,
  shop_type: route.query.shop_type,
})
showLoadingToast({
  duration: 0,
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
  loadingType: "spinner",
});
// 获取商品详情函数
const getproductDetails = async () => {
  const res = await proxy.$api.goodsParticularsV2(datas.value)
  closeToast()

  if (res.code != 0 || !res.data.goodsInfo) {
    showFailToast(proxy.$fanyi(res.msg))
    showdialong.value = true
    return
  }
  // 然后根据第一个筛选单价
  //  商品详情信息存储信息
  details.value = res.data;
  detailsImg.value = details.value.images[0]
  // 默认设置选中第一个数据
  for (let key in details.value?.goodsInfo?.specification) {
    // console.log( details.value ?.goodsInfo?.specification[key]);
    details.value.goodsInfo?.specification[key].forEach((item, ind) => {
      if (ind == 0) {
        item.check = true
        if (item.picUrl) {
          detailsImg.value = item.picUrl
        }

      }
      else {
        item.check = false
      }
    })
  }
  Processingdata()

  if (res.data.video !== "" && res.data.video !== null) {
    // 是否展示视频
    showType.value = "video";
  }
  // 选择收藏的时候的价格
  const priceRanges = res.data.goodsInfo.priceRanges;
  if (Array.isArray(priceRanges)) {
    price.value = res.data.goodsInfo.priceRanges[
      res.data.goodsInfo.priceRanges.length - 1
    ].price;
  } else {
    price.value = res.data.goodsInfo.priceRanges.priceMin;
  }
  nextTick(() => {
    // 有的span 文字燕娥白色改成黑色
    let div = document.querySelector(".introductionOfGoods");

    let spanElements = div?.getElementsByTagName("span");
    for (var i = 0; i < spanElements?.length; i++) {
      if (spanElements[i].style.color == "rgb(255, 255, 255)") {
        spanElements[i].style.color = "#222222";
      }
    }
    let images = document
      .querySelector(".introductionOfGoods")
      .querySelectorAll("img");

    let imgsrc = [];
    images.forEach((item, index) => {
      imgsrc.push(item.src);
      item.addEventListener("click", () => {
        getBigImg(imgsrc, index);
      });
    });
  });
}

getproductDetails()
// 选择规格筛选数据
const getcolor = (key, index, item) => {
  if (item.picUrl) {
    detailsImg.value = item.picUrl
  }
  // 循环对象每一项对比，如果一致修改checed
  for (let itemkey in details.value.goodsInfo?.specification) {

    if (key == itemkey) {

      details.value.goodsInfo?.specification[itemkey].forEach((item, ind) => {
        if (ind == index) {
          item.check = true



        }
        else {
          item.check = false
        }
      })
    }


  }
  Processingdata()
}
// 拿到输入框数量进项筛选价格区间

const getBigImg = (image, index) => {
  showImagePreview({
    images: image,
    showIndex: true,
    loop: false,
    startPosition: index,
  });
};
const getprice = (num) => {

  total.value = num
  let throttling = true
  details.value.goodsInfo.goodsInventory.forEach((item) => {


    if (str.value == item.keyC) {

      item.valueC.forEach((ind) => {

        if (
          num >= ind.startQuantity && ind.startQuantity_last !== null
            ? num < ind.startQuantity_last
            : true
        ) {
          if (throttling) {
            // console.log(throttling);

            ind.check = true
            // ind.num = i.num
            throttling = false
            Processingdata()
          } else {
            ind.check = false
          }
        } else {
          ind.check = false
        }
      })


    }
  })

}
// 处理数据
const Processingdata = () => {
  // 清空数据
  str.value = ''
  text.value = ''
  for (let key in details.value?.goodsInfo?.specification) {
    details.value.goodsInfo?.specification[key].forEach((item,) => {
      if (item.check == true) {
        str.value += item.name !== '' ? item.name + '㊖㊎' : ''
        text.value += RemoveDoublequotationmarks(key) + ':' + ' ' + item.name + ' '
      }
    })
  }
  // 去掉最后两个彩金
  str.value = str.value.substr(0, str.value.length - 2)
  details.value.goodsInfo.goodsInventory.forEach((item) => {

    if (str.value == item.keyC) {
      item.valueC.forEach((i) => {
        if (i.check == true)
          //  选中的单价
          details.value.Unitprice = i.price
        // 库存
        details.value.amountOnSale = i.amountOnSale
        //skuid
        skuId.value = i.skuId
        // specid
        specId.value = i.specId



      })
    } else {
      // // 匹配不到库存为零
      // details.value.amountOnSale = 0

    }
  })
  let index = details.value.goodsInfo.goodsInventory.findIndex((item) => str.value == item.keyC)
  if (index == -1) {
    details.value.amountOnSale = 0
  }
}

// 加入购物车
const addcart = async () => {
  // 首先判断是否登录没有登录显示弹窗
  if (!proxy.$store.state.userInfo) {
    proxy.$store.commit('changeshowLoginDia', true) //打开登录弹窗
    return
  }
  if (total.value <= 0) {
    showToast(proxy.$fanyi('请选择商品'));
    return
  }
  // 进行去中文查找规格 找到符合规格的sku 对应的数组索引
  let index = details.value.goodsInfo.goodsInventory.findIndex((item) => str.value == item.keyC)
  if (index == -1) {
    showToast(proxy.$fanyi('商品暂无库存'))
    return
  }
  let detailChinese = []
  let chineseArr = []
  for (let key in JSON.parse(JSON.stringify(details.value.goodsInfo.specificationChinese))) {

    chineseArr.push(JSON.parse(key))
  }
  console.log(chineseArr, details.value.goodsInfo.goodsInventoryChinese[index]);
  chineseArr.forEach((i, ind) => {
    detailChinese.push({
      key: i,
      value: details.value.goodsInfo.goodsInventoryChinese[index].keyC.split('㊖㊎')[ind],
    })
  })


  let goodsList = [
    {
      sku_id: skuId.value,
      spec_id: specId.value,
      price: details.value.Unitprice,
      num: total.value,
      pic: detailsImg.value,
      detailChinese: detailChinese,

    },
  ]
  let data = {
    shop_id: details.value.shopId,
    shop_name: details.value.shopName,
    goods_id: details.value.goods_id,
    goods_title: details.value.title,
    goods_specification: JSON.stringify(goodsList),
    from_platform: details.value.from_platform,
    priceRanges: JSON.stringify(details.value.goodsInfo.priceRanges)
  }
  const res = await proxy.$api.goodsToCart(data)
  if (res.code != 0) {
    showFailToast(proxy.$fanyi(res.msg))
    return
  }
  showSuccessToast(proxy.$fanyi('购物车添加成功'))
  Commodityspecificationdom.value.inputvalue = 0
  headercart.value.goodsToCartNum()
  getprice(0)
  // location.reload()
}
// 去除上双引号
const RemoveDoublequotationmarks = (value) => {
  value = value.replace(/^"|"$/g, '')
  return value
}

// 暴露数据
defineExpose({
  details,
  price,
  text
})
</script>
<style lang="scss">
#nodatadialong {
  width: 295px;
  height: 268px;
  background: linear-gradient(180deg, #FEC1A0 0%, #FFFFFF 100%);
  border-radius: 8px;



  .img-box {
    width: 108px;
    height: 103px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 30px;

    img {
      width: 108px;
      height: 103px;
    }
  }

  p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;

  }


  .van-dialog__footer {
    display: none !important;
  }


  .othershop {
    margin: auto;

    height: 48px;
    background: #FF730B;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    padding: 0 30px;
    line-height: 20px;
    width: 210px;
    justify-content: center;

  }


}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";


.Commoditydetails {
  width: 100%;
  background: #F6F6F6;

  padding-bottom: 102px;



  .introductionOfGoods {
    width: 100%;
    background: #F6F6F6;
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 0 10px;
  }

  :deep().introductionOfGoods {

    font-size: unset;

    span {
      font-size: unset;
    }



    p {
      width: 100% !important;
    }

    img {
      max-width: 350px;
      object-fit: contain;
    }

    img {
      width: 100%;
      height: 100%;
    }

    table {
      width: 100% !important;
      max-width: 100vw !important;
      height: 100% !important;
      table-layout: fixed !important;
      word-break: break-all !important;
    }

    div>table {
      width: 100% !important;
      max-width: 100vw !important;
      height: 100% !important;
      overflow: hidden !important;
    }

    div {
      width: 100% !important;
      max-width: 100vw !important;
      height: 100% !important;
      overflow: hidden !important;
    }
  }

  .Pricerange {
    padding: 15px;


    margin: auto;
    margin-bottom: 10px;
    width: 355px;
    min-height: 40px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;

    .range {
      width: 100%;
      margin-bottom: 10px;
      min-height: 40px;
      flex-wrap: wrap;
      display: flex;

      // justify-content: space-between;

      .priceitem {
        width: 107px;





        .price {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 5px;

          .dollar {
            font-size: 14px;
            font-family: Arial-BoldMT,
              Arial;
            font-weight: 600;
            color: #FF730B;
            line-height: 20px;
          }


          .RMB {
            font-size: 12px;
            font-family: ArialMT;
            color: #FF730B;
            line-height: 18px;
          }
        }

        p {

          font-size: 10px;
          font-family: ArialMT;
          color: #999999;
          line-height: 14px;

          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          zoom: 0.9;
          text-overflow: ellipsis;
        }
      }
    }





    >p {

      height: 40px;
      font-size: 14px;
      font-family: ArialMT;
      color: #222222;
      line-height: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }


}

.footeraddShoppingCart {
  position: fixed;
  z-index: 100;
  bottom: 0;
  width: 100%;
  height: 88px !important;
  background: #FFFFFF;
  padding: 0 20px;
  box-shadow: inset 0px 0.5px 0px 0px #E2E2E2;
  padding-top: 13px;
  display: flex;
  justify-content: space-between;

  .left {
    flex: 1;

    h2 {
      height: 18px;
      font-size: 12px;
      font-family: ArialMT;
      color: #666666;
      line-height: 18px;
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      span {
        margin-left: 3px;
        height: 18px;
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;
      }
    }

    .price {
      height: 20px;

      .left-title {
        height: 20px;
        font-size: 12px;
        font-family: ArialMT;
        color: #666666;
        line-height: 20px;
        margin-right: 5px;
      }

      .dollar {
        height: 20px;
        font-size: 14px;
        font-family: Arial-BoldMT, Arial;
        font-weight: 600;
        color: #FF730B;
        line-height: 20px;
      }

      .RMB {
        height: 20px;
        font-size: 12px;
        font-family: ArialMT;
        color: #FF730B;
        line-height: 20px;
      }
    }
  }

  .addcart {
    width: 120px;
    height: 48px;
    background: #FF730B;
    border-radius: 6px;
    display: flex;
    font-size: 14px;
    font-family: ArialMT;
    padding: 0 10px;
    color: #FFFFFF;
    line-height: 16px;


    text-align: center;
    align-items: center;
  }

}

.footerindex {
  width: 355px;
  height: 50px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;


  img {
    width: 120px;
    height: 30px;
  }
}
</style>