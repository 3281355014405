<template>
  <!-- 选择优惠卷弹出层 -->
  <van-popup z-index="1000" v-model:show="showBottom" id="discountcoupon" @close="close" position="bottom">
    <div class="main">
      <!-- 标题 -->
      <div class="coupoptop-box">
        <h2>{{ status == 10 ? $fanyi('选择优惠券') : $fanyi('选择代金券') }}</h2>
        <van-icon @click="showBottom = false" name="cross" />
      </div>
      <!-- 优惠券 -->
      <div class="Content" v-if="status == 10 && couponslst.length > 0">

        <div class="couponitem" :class="{ Seleccoupon: item.checked }" @click="Seleccoupon(item)"
          v-for="item in couponslst" :key="item.number">
          <div class="left"> <span>{{ $fanyi('优惠券') }}</span></div>
          <!-- 优惠卷内容 -->
          <div class="right-box">

            <p class="no"><span>No:</span>{{ item.number }}</p>

            <div class="text">{{ item.title }}</div>



            <div class="time">
              <p class="title">{{ $fanyi('生效时间段') }}:</p>
              <p>
                {{ item.free_datetime_start }}--{{ item.free_datetime_end }} </p>
            </div>

          </div>

        </div>
      </div>

      <!-- 代金券 -->
      <div class="daijinContent" v-if="status == 30 && couponslst.length > 0">

        <div class="couponitem" :class="{ Seleccoupon: item.checked }" @click="Seleccoupon(item)"
          v-for="item in couponslst" :key="item.number">
          <div class="left"> <span>{{ $fanyi('代金券') }}</span></div>
          <!-- 优惠卷内容 -->
          <div class="right-box">

            <p class="no"><span>No:</span>{{ item.number }}</p>

            <div class="text">{{ item.title }}</div>
            <div class="dingdanjine">
              {{ $fanyi("订单金额") }} >$ {{ item.restrictions }}
            </div>
            <div class="time">
              <p class="title">{{ $fanyi('生效时间段') }}:</p>
              <p>
                {{ item.free_datetime_start }}--{{ item.free_datetime_end }} </p>
            </div>

          </div>

        </div>
      </div>

      <!-- 没有优惠卷 -->
      <div v-if="couponslst.length == 0 && status == 10" class="nodata">
        <div class="img-box">
          <img src="../../../assets/coupon/coupon_0.svg" alt="">
        </div>
        <p class="text">{{ $fanyi('暂时没有可使用的优惠券') }}</p>
      </div>
      <!-- 没有代金卷 -->
      <div v-if="couponslst.length == 0 && status == 30" class="nodata">
        <div class="img-box">
          <img src="../../../assets/coupon/voucher_0.svg" alt="">
        </div>
        <p class="text">{{ $fanyi('暂时没有可使用的代金券') }}</p>

      </div>
    </div>
    <div class="footer" v-if="couponslst.length !== 0">
      <h2>{{ $fanyi('Tips: One order can only use one coupon, free coupon for order, voucher for delivery order ') }}</h2>
      <div @click="confirm">{{ $fanyi('选择该优惠券') }}</div>

    </div>
  </van-popup>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose, defineEmits } from 'vue'
import { showToast } from 'vant'
const { proxy } = getCurrentInstance()
const showBottom = ref(false)
const couponslst = ref([])// 详情数据
const couponid = ref('')

const status = ref(0)// 优惠卷类型



console.log(proxy);
const open = (type) => {
  // oeder 订单
  showBottom.value = true

  if (type == 'order') {
    if (couponslst.value == 0) {
      status.value = 10
      couponGet()
    }

  } else {
    if (couponslst.value == 0) {
      status.value = 30
      couponGet()
    }

  }



}
const emit = defineEmits(['updatacoupon'])
const couponGet = async () => {
  const res = await proxy.$api.couponGet({
    page: 1,
    pageSize: 999,
    type: status.value,
  })
  if (res.code != 0) {

    showToast(res.msg)
    return
  }
  console.log(res.data);
  couponslst.value = res.data.data
  couponslst.value.forEach((i) => {

    i.checked = false
  })

}
const Seleccoupon = (item) => {
  couponslst.value.forEach((i) => {
    if (item.id == i.id) {
      i.checked = !i.checked

    }
    else {
      i.checked = false
    }
  })
}

// 弹窗关闭
const close = () => {

  emit('updatacoupon', couponid.value,)
  if (!couponslst.value.some((i) => i.checked == true)) {
    couponid.value = ''
  }

}
const confirm = async () => {

  couponslst.value.forEach((i) => {
    if (i.checked == true) {
      couponid.value = i.id
    }

  })
  if (!couponslst.value.some((i) => i.checked == true)) {
    couponid.value = ''
  }
  // if (couponid.value == '') {
  //   showToast(proxy.$fanyi('请选择优惠卷'))
  //   return
  // }
  showBottom.value = false
  emit('updatacoupon', couponid.value)

}
defineExpose({
  open
})
</script>
<style lang="scss">
#discountcoupon {
  min-height: 50%;

  max-height: 88%;

  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;



  .coupoptop-box {
    position: sticky;
    top: 0px;
    z-index: 2000;
    width: 100%;
    height: 44px;
    background: #F6F6F6;
    border-radius: 8px 8px 0px 0px;

    display: flex;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    align-items: center;

    .van-icon-cross {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      color: #222222;
      font-weight: 600;
      font-size: 16px;

      // top: 14px;

    }
  }

}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";



.nodata {
  width: 100%;
  height: 100%;

  margin-top: 50px;

  .img-box {
    margin: auto;
    width: 128px;
    height: 128px;
    margin-bottom: 10px;

    img {
      width: 128px;
      height: 128px;
      margin: auto;
    }
  }


  .text {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #666666;
  }

}

// 优惠
.Content {
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-bottom: 120px;

  .couponitem {
    width: 355px;
    min-height: 154px;
    background: url('../../../assets/coupon/coupon_1.svg');
    padding: 10px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;

    .left {
      width: 48px;
      height: 110px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #608BFF;
      display: flex;
      margin-left: 12px;
      align-items: center;

      justify-content: center;

      span {
        transform: rotate(270deg);
        font-size: 18px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #608BFF;
        white-space: nowrap;
        display: inline-block;




      }



    }

    .right-box {
      margin-left: 12px;
      padding-right: 10px;

      .no {
        width: 100%;
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;
        margin-bottom: 12px;
      }

      .text {
        font-size: 20px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #608BFF;
        line-height: 20px;
        height: 52px;
      }

      .time {
        font-size: 10px;
        font-family: ArialMT;
        color: #222222;
        line-height: 14px;
        zoom: 0.9;

        .title {
          font-size: 10px;
          font-family: ArialMT;
          color: #222222;
          line-height: 14px;
          zoom: 0.9;
          margin-bottom: 2px;

        }

        p{
          font-size: 10px;
          font-family: ArialMT;
          color: #222222;
          line-height: 14px;
          zoom: 0.9;
        }
      }


    }


  }

  .Seleccoupon {
    background: url('../../../assets/coupon/coupon_2.svg');
    background-repeat: no-repeat;
    background-size: cover;

    .no {
      color: #fff !important;

    }

    .text {
      color: #fff !important;
    }

    .time {
      color: #fff !important;

      .title {
        color: #fff !important;
      }

      p {
        color: #fff !important;
      }
    }
  }
}

// 代金
.daijinContent {
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-bottom: 120px;

  .couponitem {
    width: 355px;
    min-height: 154px;
    background: url('../../../assets/coupon/coupon_1.svg');
    padding: 10px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;

    .left {
      width: 48px;
      height: 110px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #00C18B;
      display: flex;
      margin-left: 12px;
      align-items: center;

      justify-content: center;

      span {
        transform: rotate(270deg);
        font-size: 18px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #00C18B;
        line-height: 22px;
        white-space: nowrap;
      }



    }

    .right-box {
      margin-left: 12px;
      padding-right: 10px;

      .no {
        width: 100%;
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;
        margin-bottom: 10px;
      }

      .dingdanjine {
        font-size: 10px;
        font-family: ArialMT;
        color: #222222;
        line-height: 14px;
        margin-bottom: 4px;
      }

      .text {
        font-size: 20px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #00C18B;
        line-height: 20px;
        height: 45px;
        display: flex;
        align-items: center;
      }

      .time {
        font-size: 10px;
        font-family: ArialMT;
        color: #222222;
        line-height: 14px;
        zoom: 0.9;

        .title {
          font-size: 10px;
          font-family: ArialMT;
          color: #222222;
          line-height: 14px;
          zoom: 0.9;
          margin-bottom: 2px;

        }

        p{
          font-size: 10px;
          font-family: ArialMT;
          color: #222222;
          line-height: 14px;
          zoom: 0.9;
        }
      }


    }


  }

  .Seleccoupon {
    background: url('../../../assets/coupon/voucher_2.svg');
    background-repeat: no-repeat;
    background-size: cover;

    .no {
      color: #fff !important;

    }

    .text {
      color: #fff !important;
    }

    .dingdanjine {
      color: #fff !important;
    }

    .time {
      color: #fff !important;

      .title {
        color: #fff !important;
      }

      p {
        color: #fff !important;
      }
    }
  }
}

.footer {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  position: fixed;
  bottom: 0;
  width: 375px;
  height: 120px;
  background: #FFFFFF;
  box-shadow: inset 0px 0.5px 0px 0px #E2E2E2;
  background: #FFFFFF;
  box-shadow: inset 0px 0.5px 0px 0px #E2E2E2;

  h2 {
    height: 28px;
    font-size: 10px;
    font-family: ArialMT;
    color: #666666;
    line-height: 14px;
    margin-bottom: 10px;

  }

  div {
    width: 335px;
    height: 48px;
    background: #FF730B;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 16px;
  }
}
</style>