<template>
  <!-- 推荐商品 -->
  <div class="Pickoftheweek">
    <h2>{{ $fanyi("推荐商品") }}</h2>

    <div class="shoplist">
      <div class="shopitem" @click="
        $fun.toCommodityDetails({
          goods_id: goodsItem.goods_id,
          shop_type: goodsItem.shop_type,
        })
        " v-for="goodsItem in props.Recommendlist" :key="goodsItem.goods_id">
        <div class="img-box">
          <van-image :src="goodsItem.imgUrl" lazy-load>
            <template v-slot:loading>
              <van-loading type="spinner" size="30" />
            </template>
          </van-image>
        </div>
        <!-- 商品描述 -->
        <div class="text">{{ goodsItem.title }}</div>
        <!-- 价格 -->
        <div class="price">
          <span class="dollar">
            ${{
              $fun.EURNumSegmentation(
                $fun.ceil(goodsItem.goodsPrice * $store.state.exchangeRate)
              )
            }}
          </span>
          <span class="RMB">
            ￥{{ $fun.RMBNumSegmentation(goodsItem.goodsPrice) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, defineProps } from 'vue'
const { proxy } = getCurrentInstance()
console.log(proxy);
const props = defineProps({
  Recommendlist: {
    type: Array,
    default: () => []
  }
})
</script>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.Pickoftheweek {
  h2 {
    margin-bottom: 20px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #222222;
    line-height: 20px;
    text-align: center;
  }

  .shoplist {
    width: 100%;
    display: flex;

    padding: 0 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 60px;

    .shopitem {
      flex: 0 0 160px;

      height: 230px;
      background: #ffffff;
      border-radius: 4px;
      border: 0.5px solid #e2e2e2;

      padding: 10px;
      margin-bottom: 10px;

      .img-box {
        width: 140px;
        height: 140px;
        background: #ffffff;
        margin-bottom: 10px;

        :deep().van-image {
          width: 140px;
          height: 140px;
        }
      }

      .text {
        height: 36px;
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        margin-bottom: 5px;
      }

      .price {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 20px;
        display: flex;
        align-items: center;

        .dollar {
          font-size: 14px;
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #ff730b;
          line-height: 20px;
        }

        .RMB {
          margin-left: 5px;
          font-size: 12px;
          font-family: PingFang-SC-Semibold, PingFang-SC;
          font-weight: 600;
          color: #ff730b;
          line-height: 18px;
        }
      }
    }
  }
}
</style>