<template>
  <hearbar bgcolor="white">
    <template v-slot:left-icon>
      <van-icon name="arrow-left" @click="step == 1 ? $router.go(-1) : (step = 1)" />
    </template>

    <template #default>
      <div>
        <h3> {{ $route.query.order_sn ? $fanyi('订单支付') : $fanyi('配送单支付') }}</h3>
        <p>{{ $route.query.order_sn ? $fanyi('订单号') : $fanyi('配送单号') }}: <span>{{ $route.query.order_sn ?
          goodsdatas?.order_sn : goodsdatas?.porder_sn }}</span></p>
      </div>
    </template>

  </hearbar>

  <div>

    <!-- 支付页面 -->
    <div class="payment" v-if="step == 1">
      <!-- 顶部支付进度条 -->
      <progressstep :stepArr="stepArr" :steptext="steptext" :Progressarr="Progressarr" />

      <!-- 地址表单组件 订单展示 -->
      <dizhifrom v-if="route.query.order_sn" ref="dizhiformdom" />

      <!-- 配送单费用 -->
      <div class="Internationalquotation" v-if="$route.query.porder_sn">
        <h2>{{ $fanyi("国际航运的最终报价") }}</h2>
        <p>{{ $fanyi("进口总成本细目(包括物流和税收)") }}</p>

        <!-- 国际物流费用 -->
        <div class="guojiwuliu">

          <span>{{ $fanyi("国际物流费用") }}</span> <span>
            ${{
              $fun.EURNumSegmentation(goodsdatas.logistics_price)
            }}
          </span>
        </div>

        <!-- 美国物流成本 -->
        <div class="meiguowuliu">
          <span>{{ $fanyi("美国物流成本") }}</span> <span>${{
            $fun.EURNumSegmentation(goodsdatas.foreign_express_price)
          }}</span>
        </div>
        <!-- 其他费用 -->
        <div class="ortherprice"> <span>{{ $fanyi("其他费用") }}</span> <span>

            ${{ $fun.EURNumSegmentation(goodsdatas.other_price) }}
          </span> </div>

        <!-- 费用总额 -->
        <div class="feiyongzonge"><span>{{ $fanyi('应付总额') }}</span> <span>${{ $fun.ceil(payAllCNPrice) }}</span></div>
      </div>
      <!-- 收货地址  -->
      <div v-if="$route.query.porder_sn" :class="{ maxaddress: addressall }" class="Receivingaddress">
        <div class="top-box">
          <div class="left">{{ $fanyi('我的收货地址') }}</div>
          <div class="right"><van-icon @click="addressall = !addressall" v-if="!addressall" name="arrow-down" />
            <van-icon name="arrow-up" v-else @click="addressall = !addressall" />

          </div>
        </div>

        <div class="addresslist">
          <p><span>{{ $fanyi("收件人") }}:</span>
            <span>{{ orderaddress?.contacts ? orderaddress?.contacts : '-----' }}</span>
          </p>
          <p><span>{{ $fanyi("州") }}:</span> <span>{{ orderaddress?.state ? orderaddress?.state : '-----' }}</span> </p>
          <p><span>{{ $fanyi("邮编") }}:</span> <span> {{ orderaddress?.zip_code ? orderaddress?.zip_code : '-----'
              }}</span>
          </p>
          <p><span>{{ $fanyi("城市") }}:</span> <span> {{ orderaddress?.city ? orderaddress?.city : '-----' }}</span> </p>
          <p><span>{{ $fanyi("街道") }}:</span> <span> {{ orderaddress?.address ? orderaddress?.address : '-----'
              }}</span>
          </p>
          <p><span>{{ $fanyi("手机号") }}:</span> <span> {{ orderaddress?.mobile ? orderaddress?.mobile : '-----' }}</span>
          </p>
          <p><span> {{ $fanyi("邮箱") }}:</span> <span> {{ orderaddress?.email ? orderaddress?.email : '-----' }}</span>
          </p>


        </div>

      </div>
      <!-- 订单汇总 -->
      <ordersummary :goodslist="buyGoodsArr" :showdescribe="false" :porder="route.query.porder_sn ? true : false">
        <template #title>
          {{ $fanyi('订单汇总') }}
        </template>
      </ordersummary>


      <!-- 支付 -->
      <!--! 余额支付 余额大于0 并且余额减去订单金额大于等于零显示余额支付 -->
      <!-- "balance > 0 && balance - payAllCNPrice >= 0" -->
      <div class="blane" v-if="balance > 0 && balance - payAllCNPrice >= 0">
        <!-- 顶部标题 -->
        <div class="top-title">
          <div class="left">{{ $fanyi('余额支付') }}</div>
          <div class="right"> <span></span> <span></span></div>
        </div>
        <div class="content">
          <!-- 订单金额 -->
          <div class="oeder-box" style="border-bottom: 1px dashed #e2e2e2">
            <div class="order">
              <span>{{ $fanyi("订单金额") }}</span>
              <span>${{ $fun.EURNumSegmentation(Totalamount) }}</span>
            </div>
            <div class="jine">
              <span>{{ $fanyi("应付款金额") }}</span>
              <span> $ {{ $fun.EURNumSegmentation(payAllCNPrice) }}</span>
            </div>
          </div>
          <div class="oeder-box">
            <div class="useblane">
              <span>{{ $fanyi("账户余额") }}</span>
              <span>$ {{ $fun.EURNumSegmentation(balance) }}</span>
            </div>
            <div class="paymentblane">
              <span>{{ $fanyi("付款后余额") }}</span>
              <span>
                $
                {{
                  $fun.EURNumSegmentation((balance - payAllCNPrice).toFixed(2))
                }}</span>
            </div>
          </div>

          <!-- 余额支付 -->
          <div class="payment-box" @click="blanepayment"> {{ $fanyi('付款') }}</div>

          <!-- 隐私协议 -->
          <div class="Agreementcheck">
            <van-checkbox v-model="Agreementcheck" checked-color="#FF730B"></van-checkbox>
            <p>
              {{ $fanyi("我知道并同意本网站的") }}
              <span @click="$fun.toPage('/privacypolicy')">{{ $fanyi("隐私政策条款和条件") }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- ? 在线支付 balance < 0 || balance - payAllCNPrice < 0-->
      <div class="Onlinepayment" v-if="balance < 0 || balance - payAllCNPrice < 0">
        <div class="top-title">
          <div class="right" @click="payType = 2" :class="{ active: payType == 2 }">
            <img src="../../assets/payment/paypal.svg" alt="">
          </div>

          <div class="left" :class="{ active: payType == 0 }" @click="showBankingmode = true"> <img
              src="../../assets/payment/xt.svg" style="margin-right: 10px;" alt="" />
            {{ $fanyi('银行支付') }}
            <span style="margin-left: 2px;
                    font-size: 12px;font-weight: 600 " v-if="Bankingmode">{{ '(' + Bankingmode + ')' }}</span>
          </div>
          <!-- <div class="left" @click="payType = 1" :class="{ active: payType == 1 }">{{ $fanyi('PingPong') }}</div> -->

          <!--       <div class="left">{{ $fanyi('在线支付') }}</div> -->
        </div>
        <!-- 在线支付 -->
        <div class="content" v-if="payType == 1">

          <!-- 订单金额，应付金额 -->
          <div class="orderprice">
            <p> <span>{{ $fanyi('订单金额') }}</span> <span> ${{ $fun.EURNumSegmentation(Totalamount) }}</span> </p>
            <p> <span>{{ $fanyi('订单应付款金额') }}</span> <span> ${{ $fun.EURNumSegmentation(payAllCNPrice) }}</span> </p>
          </div>
          <!-- 最低付款 -->
          <div class="Minimumpayment">
            <span>{{ $fanyi('最低付款金额') }}</span>
            <span> ${{
              balance > 0 && balance - payAllCNPrice > 0
                ? $fun.EURNumSegmentation(payAllCNPrice)
                : paymentdata.min_bank_pay_amount
            }}</span>
          </div>
          <!-- pingpong -->
          <div class="pingpong">
            <img src="../../assets/PingPong_logo.png" alt="">
          </div>
          <!-- 支付金额 -->
          <div class="zhifuprice">
            <p><span>* </span>{{ $fanyi('支付金额') }}:</p>
            <input type="text" :disabled="true" v-model="orderpayInput">

          </div>
          <!-- 在线支付 -->
          <div class="Online" @click="Online">{{ $fanyi('付款') }}</div>
          <!-- 隐私协议 -->
          <div class="Agreementcheck">
            <van-checkbox v-model="Agreementcheck" checked-color="#FF730B"></van-checkbox>
            <p>
              {{ $fanyi("我知道并同意本网站的") }}
              <span @click="$fun.toPage('/privacypolicy')">{{ $fanyi("隐私政策条款和条件") }}</span>
            </p>
          </div>
        </div>

        <!-- PayPal -->
        <div class="content" v-if="payType == 2">

          <!-- 订单金额，应付金额 -->
          <div class="orderprice">
            <p> <span>{{ $fanyi('可用余额') }}</span> <span> ${{ $fun.EURNumSegmentation(balance) }}</span> </p>
            <p> <span>{{ $fanyi('订单金额') }}</span> <span> ${{ $fun.EURNumSegmentation(Totalamount) }}</span> </p>
            <p> <span>{{ $fanyi('订单应付款金额') }}</span> <span> ${{ $fun.EURNumSegmentation(payAllCNPrice) }}</span> </p>
          </div>
          <!-- 最低付款 -->
          <div class="Minimumpayment">
            <span>{{ $fanyi('最低付款金额') }}</span>
            <span> ${{
              balance > 0 && balance - payAllCNPrice > 0
                ? $fun.EURNumSegmentation(payAllCNPrice)
                : paymentdata.min_bank_pay_amount
            }}</span>
          </div>


          <!-- pingpong -->
          <div class="pingpong">
            <img src="../../assets/pp_fc_hl.svg" alt="">
          </div>
          <div class="Paymentprompt">
            <p>1.{{ $fanyi('请输入充值金额，使其超过估价总额') }}</p>
            <p>2.{{ $fanyi('paypal付款的情况下，还需要4%的手续费，请事先谅解') }}</p>
          </div>
          <!-- 支付金额 -->
          <div class="zhifuprice">
            <p><span>* </span>{{ $fanyi('支付金额') }}:</p>
            <input type="text" :disabled="true" v-model="orderpayInput">

          </div>
          <!-- <div v-if="orderpayInput < 1" class="Total">({{ $fanyi('总计') }}:&nbsp;{{ ((1 * 0.045) +
            orderpayInput).toFixed(2) }}
            &nbsp;&nbsp;
            {{ $fanyi('手续费') + ' 4.5%' }} )</div>
          <div v-else class="Total">({{ $fanyi('总计') }}:&nbsp;{{ ((orderpayInput * 0.045) + orderpayInput).toFixed(2) }}
            &nbsp;&nbsp;
            {{ $fanyi('手续费') + ' 4.5%' }} )</div> -->
          <!-- 在线支付 -->
          <div class="Online" @click="Online">{{ $fanyi('付款') }}</div>
          <!-- 隐私协议 -->
          <div class="Agreementcheck">
            <van-checkbox v-model="Agreementcheck" checked-color="#FF730B"></van-checkbox>
            <p>
              {{ $fanyi("我知道并同意本网站的") }}
              <span @click="$fun.toPage('/privacypolicy')">{{ $fanyi("隐私政策条款和条件") }}</span>
            </p>
          </div>
        </div>

        <!-- 银行卡支付 -->
        <div v-if="payType == 0" class="Rabobank">
          <div class="top-box">
            <div class="useblane">
              <span>{{ $fanyi('账户余额') }}</span>
              <span>$ {{ $fun.EURNumSegmentation(balance) }}</span>
            </div>
            <div class="Lowest">
              <span>{{ $fanyi('最低入金金额') }}</span><span>
                ${{
                  balance > 0 && balance - payAllCNPrice > 0
                    ? $fun.EURNumSegmentation(payAllCNPrice)
                    : paymentdata.min_bank_pay_amount
                }}</span>
            </div>
          </div>
          <!-- 银行卡信息区域 -->
          <div class="content">
            <div class="pic-box">
              <img v-if="Bankingmode == 'Fedwire'" src="../../assets/payment/JPMorgan-logo.png" alt="" />
              <img v-if="Bankingmode == 'ACH'" src="../../assets/payment/cfsb.png" alt="" />
            </div>
            <!-- 收件人银行信息 -->
            <!-- 弹窗如何运作的 -->
            <van-dialog v-model:show="showOperate" show-cancel-button>
              <template #title>
                <span>{{
                  $fanyi("它是如何运作的?")
                }}</span>


                <img @click="showOperate = false" src="../../assets/cart/close.svg" alt="" />
              </template>
              <div class="operationshow">
                <div class="left">
                  <div class="one">1</div>
                  <span></span>
                  <div class="two">2</div>
                </div>
                <div class="right">
                  <div class="box">
                    <h3>{{ $fanyi('银行转账') }}</h3>
                    <p>{{ $fanyi('将您订单中需花费的钱转账到我们账户') }}</p>
                  </div>
                  <div class="box" style="margin: 0px">
                    <h3>{{ $fanyi('等待处理') }}</h3>
                    <p>{{ $fanyi('等待1至2个工作日处理订单.') }}</p>
                  </div>
                </div>
              </div>
              <template #footer>
                <span class="showOperate-footer">
                  <van-button class="queren" @click="showOperate = false">{{ $fanyi('确认') }}</van-button>
                </span>
              </template>

            </van-dialog>
            <div class="Attninfo">
              <h2>{{ $fanyi('收件人银行信息') }}</h2>
              <p>
                {{
                  $fanyi(
                    '使用以下银行数据通过您的在线银行或移动应用程序进行转账，订单处理后，将进行购买。'
                  )
                }}
              </p>
              <div class="operation" @click="showOperate = true">
                <img src="../../assets/why.svg" alt="" />
                <span>{{ $fanyi('它是如何运作的?') }}</span>
              </div>
              <h2 style="margin-bottom: 20px">
                {{ $fanyi('使用订单号作为付款概念/参考') }}
              </h2>
              <div class="ordersn">
                <img src="../../assets/order.svg" alt="" />
                <span>
                  {{
                    $route.query.order_sn
                      ? $route.query.order_sn
                      : $route.query.porder_sn
                  }}</span>
              </div>
              <template v-if="Bankingmode == 'Fedwire'">
                <p class="title">{{ $fanyi('账号名称') }}</p>
                <p class="text">
                  Sameway Global Trade Investment Limited
                  <img @click="Copytheaccountname" src="../../assets/payment/copy.svg" alt="">
                </p>

                <p class="title">{{ $fanyi('银行账户') }}</p>
                <p class="text">
                  20000011884118
                  <img @click="copyTextToClipboard" src="../../assets/payment/copy.svg" alt="" />
                </p>

                <p class="title">{{ $fanyi('银行名称') }}</p>
                <p class="text">
                  JPMORGAN CHASE BANK, N.A
                  <img @click="copyTextToClipboard" src="../../assets/payment/copy.svg" alt="" />
                </p>

                <p class="title">ABA/ Routing Number：</p>
                <p class="text">
                  021000021
                  <img @click="copyTextToClipboard" src="../../assets/payment/copy.svg" alt="" />
                </p>

                <p class="title">{{ $fanyi('银行地址') }}</p>
                <p class="text">
                  JPMORGAN CHASE BANK, N.A. COLUMBUS,OH 43240 COLUMBUS,OH US
                  UNITED STATES OF AMERICA
                  <img @click="copyTextToClipboard" src="../../assets/payment/copy.svg" alt="" />
                </p>
                <p style="
                  font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 12px;
color: #999999;
line-height: 22px;">
                  Remark：<br />When you make an online bank Transfer, please include our full account name in the
                  remarks: Sameway Global Trade Investment Limited.
                </p>
              </template>
              <template v-if="Bankingmode == 'ACH'">
                <p class="title">{{ $fanyi('账号名称') }}</p>
                <p class="text">
                  Sameway Global Trade Investment Limited
                  <img @click="Copytheaccountname" src="../../assets/payment/copy.svg" alt="" />
                </p>

                <p class="title">{{ $fanyi('银行账户') }}</p>
                <p class="text">
                  8338401008
                  <img @click="copyTextToClipboard" src="../../assets/payment/copy.svg" alt="" />
                </p>

                <p class="title">{{ $fanyi('银行名称') }}</p>
                <p class="text">
                  Community Federal Savings Bank
                  <img @click="copyTextToClipboard" src="../../assets/payment/copy.svg" alt="" />
                </p>

                <p class="title">ABA/ Routing Number：</p>
                <p class="text">
                  026073150
                  <img @click="copyTextToClipboard" src="../../assets/payment/copy.svg" alt="" />
                </p>

                <p class="title">{{ $fanyi('银行地址') }}</p>
                <p class="text">
                  Seventh Avenue, New York, NY 10019, US
                  <img @click="copyTextToClipboard" src="../../assets/payment/copy.svg" alt="" />
                </p>

                <p style="
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 12px;
color: #999999;
line-height: 22px;">
                  Remark：<br />When you make an online bank Transfer, please include our full account name in the
                  remarks: Sameway Global Trade Investment Limited.
                </p>
              </template>
            </div>
          </div>


          <div class="ruleformbox">
            <van-form ref="ruleform">
              <van-cell-group inset>
                <!-- 付款日期 -->
                <van-field @blur="validateField('pay_date')" class="disabled-style" required
                  @click.prevent="startTimeShow = true" :class="{
                    errinput: registerRule.pay_date == 0,
                    correctinput: registerRule.pay_date == 2,
                  }" autocomplete="off" readonly="true" v-model.trim="ruleForm.pay_date" name="pay_date"
                  label-align="top" :label="$fanyi('付款日期')" :placeholder="$fanyi('请选择日期')" :rules="[
                    {
                      required: true,
                      message: $fanyi('请选择日期'),
                      trigger: 'onBlur',
                    },
                  ]">
                  <template #right-icon>
                    <van-icon name="success" v-if="registerRule.pay_date == 2" />
                    <van-icon style="margin-left: 10px;" @click="startTimeShow = true" name="underway-o" />
                  </template>

                </van-field>

                <!-- 提交人 -->
                <van-field @blur="validateField('pay_realname')" required :class="{
                  errinput: registerRule.pay_realname == 0,
                  correctinput: registerRule.pay_realname == 2,
                }" autocomplete="off" v-model.trim="ruleForm.pay_realname" name="pay_realname" label-align="top"
                  :label="$fanyi('提交人')" :placeholder="$fanyi('请输入提交人')" :rules="[
                    {
                      required: true,
                      message: $fanyi('请填写付款人姓名'),
                      trigger: 'onBlur',
                    },

                  ]">
                  <template #right-icon>
                    <van-icon name="success" v-if="registerRule.pay_realname == 2" />
                  </template>
                </van-field>
                <!-- 付款金额 -->
                <van-field @blur="validateField('pay_amount')" required :class="{
                  errinput: registerRule.pay_amount == 0,
                  correctinput: registerRule.pay_amount == 2,
                }" autocomplete="off" v-model.trim="ruleForm.pay_amount" name="pay_amount" label-align="top"
                  :label="$fanyi('付款金额')" :placeholder="$fanyi('请输入付款金额')" :rules="[
                    {
                      required: true,
                      message: $fanyi('请填写转账金额'),
                      trigger: 'onBlur',
                    },
                    { validator: checkamount, trigger: 'onBlur' },
                  ]">
                  <template #right-icon>
                    <van-icon name="success" v-if="registerRule.pay_amount == 2" />
                  </template>
                </van-field>
                <!-- 备注 -->
                <van-field @blur="validateField('pay_remark')" required :class="{
                  errinput: registerRule.pay_remark == 0,
                  correctinput: registerRule.pay_remark == 2,
                }" autocomplete="off" v-model.trim="ruleForm.pay_remark" name="pay_remark" label-align="top"
                  :label="$fanyi('备注')" :placeholder="$fanyi('请输入备注信息')" :rules="[
                    {
                      required: true,
                      message: $fanyi('请填写备注信息'),
                      trigger: 'onBlur',
                    },
                    // {
                    //   validator: validatorlastname,
                    //   trigger: 'onBlur',
                    // },
                  ]"><template #right-icon>
                    <van-icon name="success" v-if="registerRule.pay_remark == 2" />
                  </template>

                </van-field>

                <!-- 付款截图 -->
                <van-field style="height: 20px;" class="uploadbox" @blur="validateField('pay_image_url')" required
                  autocomplete="off" v-model.trim="ruleForm.pay_image_url" name="pay_image_url" label-align="top"
                  :label="$fanyi('付款截图')" :placeholder="$fanyi('请上传付款证明')" :rules="[
                    {
                      required: true,
                      message: $fanyi('请上传付款证明'),
                      trigger: 'onBlur',
                    },

                  ]">
                  <template #right-icon>
                    <van-icon name="success" v-if="registerRule.pay_image_url == 2" />
                  </template>
                </van-field>
                <!-- 上传 -->
                <van-uploader @delete="deleteImage" accept="image/*" :after-read="afterRead" v-model="fileList" multiple
                  :max-count="1" />
                <div class="ererorimage" v-if="registerRule.pay_image_url == 0">{{ $fanyi('请上传付款证明') }}</div>


              </van-cell-group>
            </van-form>
          </div>
          <!-- 确认转账 -->
          <div class="Confirmthetransfer" @click="Confirmthetransfer">
            {{ $fanyi('确认转账') }}
          </div>
          <!-- 隐私协议 -->
          <div class="Agreementcheck">
            <van-checkbox v-model="Agreementcheck" checked-color="#FF730B"></van-checkbox>
            <p>
              {{ $fanyi("我知道并同意本网站的") }}
              <span @click="$fun.toPage('/privacypolicy')">{{ $fanyi("隐私政策条款和条件") }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- 支付成功页面 -->
    <div class="paymentsucces" v-if="step == 2">
      <!-- 顶部支付进度条 -->
      <progressstep :stepArr="stepArr" :steptext="steptext" :Progressarr="Progressarr" />
      <!-- 订单支付完成 -->
      <div class="top-Complete" v-if="$route.query.order_sn">
        <div class="img-box">
          <img src="../../assets/tichuorder/success.svg" alt="">
        </div>
        <h2>{{ $fanyi('订单成功完成') }}</h2>
        <p class="ordertext"> {{ $fanyi("谢谢，你的订单号") }}{{ $route.query.order_sn }}
          {{
            $fanyi(
              "已生成正确，并且已经在发送到我们仓库的过程中。 我们已经向您发送了一封包含详细信息的电子邮件。请按照个人中心中的状态进行操作。")
          }}</p>
        <div @click="$fun.toPage('/user/index')" class="Personalcenter">{{ $fanyi('个人中心') }}</div>
      </div>

      <!-- 配送单付款完成 -->
      <div class="DeliveryorderComplete" v-else>
        <div class="img-box">
          <img src="../../assets/tichuorder/success.svg" alt="">
        </div>
        <h2>{{ $fanyi('订单成功完成') }}</h2>
        <p class="ordertext"> {{ $fanyi("谢谢，关于您的订单号") }} {{ $route.query.porder_sn }}:
          {{
            $fanyi(
              "的发送：您已正确付款并且您的货物已在发送至我们的仓库。 商品到货时将提供国家快递（GLS）的追踪号码。您可以在个人中心中查看状态。"
            )
          }}</p>

        <div @click="$fun.toPage('/user/index')" class="Personalcenter">{{ $fanyi('个人中心') }}</div>
      </div>
      <!-- 收货地址 -->

      <div :class="{ maxaddress: addressall }" class="Receivingaddress">
        <div class="top-box">
          <div class="left">{{ $fanyi('我的收货地址') }}</div>
          <div class="right"><van-icon @click="addressall = !addressall" v-if="!addressall" name="arrow-down" />
            <van-icon name="arrow-up" v-else @click="addressall = !addressall" />

          </div>
        </div>

        <div class="addresslist">
          <p><span>{{ $fanyi("收件人") }}:</span>
            <span>{{ orderaddress?.contacts ? orderaddress?.contacts : '-----' }}</span>
          </p>
          <p><span>{{ $fanyi("州") }}:</span> <span>{{ orderaddress?.state ? orderaddress?.state : '-----' }}</span> </p>
          <p><span>{{ $fanyi("邮编") }}:</span> <span> {{ orderaddress?.zip_code ? orderaddress?.zip_code : '-----'
              }}</span>
          </p>
          <p><span>{{ $fanyi("城市") }}:</span> <span> {{ orderaddress?.city ? orderaddress?.city : '-----' }}</span> </p>
          <p><span>{{ $fanyi("街道") }}:</span> <span> {{ orderaddress?.address ? orderaddress?.address : '-----'
              }}</span>
          </p>
          <p><span>{{ $fanyi("手机号") }}:</span> <span> {{ orderaddress?.mobile ? orderaddress?.mobile : '-----' }}</span>
          </p>
          <p><span> {{ $fanyi("邮箱") }}:</span> <span> {{ orderaddress?.email ? orderaddress?.email : '-----' }}</span>
          </p>


        </div>

      </div>
      <!-- 订单汇总 -->
      <ordersummary :goodslist="buyGoodsArr" :showdescribe="false" :porder="route.query.porder_sn ? true : false">
        <template #title>
          {{ $fanyi('订单汇总') }}
        </template>
      </ordersummary>


      <!-- 后续操作 -->
      <subsequentoperations v-if="route.query.order_sn" />



    </div>

  </div>
  <van-popup z-index="1000" v-model:show="startTimeShow" position="bottom" :style="{ width: '100%', height: '50%' }">
    <van-date-picker v-model="startTime" :columns-type="['year', 'month', 'day']"
      @cancel="startTimeShow = !startTimeShow" @confirm="startTimeConfirm" :max-date="maxDate" />
  </van-popup>

  <!-- 确认支付弹窗 -->

  <van-dialog id="paymentdialong" v-model:show="showpayment" title="标题" show-cancel-button>
    <!-- default	自定义内容 title	自定义标题footer -->
    <template #title>
      <span>{{
        $fanyi("提示信息")
      }}</span>


      <img @click="showpayment = false" src="../../assets/cart/close.svg" alt="" />
    </template>
    <template #default>
      <!-- 单个 -->
      <p v-if="balance > 0 && balance - payAllCNPrice >= 0" style="justify-content: flex-start">
        {{ $fanyi("是否确认使用余额支付?") }}
      </p>
      <p v-if="payType == 1 && (balance < 0 || balance - payAllCNPrice < 0)" style=" justify-content: flex-start">
        {{ $fanyi("是否确认使用在线支付?") }}
      </p>
      <p v-if="payType == 2 && (balance < 0 || balance - payAllCNPrice < 0)" style=" justify-content: flex-start">
        {{ $fanyi("是否使用PayPal支付？") }}
      </p>
      <p v-if="payType == 0 && (balance < 0 || balance - payAllCNPrice < 0)" style=" justify-content: flex-start">
        {{ $fanyi("是否使用银行卡支付？") }}
      </p>
    </template>

    <!-- 订单支付， -->
    <template #footer v-if="route.query.order_sn">
      <div class="deletefooter">

        <div v-if="balance > 0 && balance - payAllCNPrice >= 0" @click="submitUserBalancePayOrder" class="cofim">
          {{ $fanyi("确认") }}
        </div>
        <div v-if="payType == 1 && (balance < 0 || balance - payAllCNPrice < 0)" @click="Onlinepayment" class="cofim">
          {{ $fanyi("确认") }}
        </div>
        <div v-if="payType == 2 && (balance < 0 || balance - payAllCNPrice < 0)" @click="Paypalpayment" class="cofim">
          {{ $fanyi("确认") }}
        </div>
        <div v-if="payType == 0 && (balance < 0 || balance - payAllCNPrice < 0)" @click="bankpayment" class="cofim">
          {{ $fanyi("确认") }}
        </div>


        <div class="cancel" @click="showpayment = false">
          {{ $fanyi("取消") }}
        </div>
      </div>
    </template>
    <!-- 配送单支付 -->
    <template #footer v-else>
      <div class="deletefooter">

        <div v-if="balance > 0 && balance - payAllCNPrice >= 0" @click="Deliveryorderpayment" class="cofim">
          {{ $fanyi("确认") }}
        </div>
        <div v-if="payType == 1 && (balance < 0 || balance - payAllCNPrice < 0)" @click="Deliveryorderonlinepayment"
          class="cofim">
          {{ $fanyi("确认") }}
        </div>
        <div v-if="payType == 2 && (balance < 0 || balance - payAllCNPrice < 0)" @click="Paypalpayment" class="cofim">
          {{ $fanyi("确认") }}
        </div>
        <div v-if="payType == 0 && (balance < 0 || balance - payAllCNPrice < 0)" @click="bankpayment" class="cofim">
          {{ $fanyi("确认") }}
        </div>
        <div class="cancel" @click="showpayment = false">
          {{ $fanyi("取消") }}
        </div>
      </div>
    </template>
  </van-dialog>
  <van-popup v-model:show="showBankingmode" round position="bottom">
    <van-picker :columns="BankingmodeArr" :cancel-button-text="$fanyi('取消')" :confirm-button-text="$fanyi('确认')"
      @cancel="showBankingmode = false" @confirm="onConfirmBankingmode" />
  </van-popup>
</template>
<script setup>
import { getCurrentInstance, ref, } from 'vue'
import hearbar from "../../components/headGoBack/index.vue";
import progressstep from './components/progress.vue'
import dizhifrom from './components/dizhiform.vue'
import ordersummary from '../successpage/components/ordersummary.vue'
import subsequentoperations from './components/subsequentoperations.vue'
import { useRoute, } from 'vue-router'
import { showLoadingToast, showFailToast, closeToast, showToast, showConfirmDialog } from 'vant'
console.log(showConfirmDialog);

// closeToast,showLoadingToast showToast
// 进度条参数
const Progressarr = ref([50, 0, 0])
const paymentdata = ref('')// 订单支付信息 
const route = useRoute()
const goodsdatas = ref('')// 商品信息
const buyGoodsArr = ref([])// 获取商品数组展示
const balance = ref(0)// 用户余额
const step = ref(1)
const payType = ref(2)// 选择支付方式 1 在线支付 2 paypal
const showpayment = ref(false)// 确认支付弹窗
const Agreementcheck = ref(true)// 隐私协议
const Totalamount = ref(0)// 商品总计金额
const payAllCNPrice = ref(0)// 商品优惠后的应付金额
const orderpayInput = ref(0)// 如果余额不足 订单的在线应付金额
const dizhiformdom = ref('')// 地址表单组件dom
const orderaddress = ref('')// 支付成功后的地址
const addressall = ref(false)// 是否展示全部收货地址
const showOperate = ref(false)
const fileList = ref([])
const BankingmodeArr = ref([
  {

    text: 'Fedwire',
    value: 'Fedwire',
  },
  {
    text: 'ACH',
    value: 'ACH',
  }
])


let startTime = ref([]);
var now = new Date();
let toLocaleDateString = now.toLocaleDateString().replace('/', '-').replace('/', '-');
let arr = toLocaleDateString.split("-");
let maxDate = new Date(arr[0], arr[1] - 1, arr[2]);
const startTimeShow = ref(false)// 日期弹层
const ruleform = ref('')//银行表单
const Bankingmode = ref('') // 银行
const showBankingmode = ref(false)
// 文字是否高亮
const stepArr = ref([true, false, false, false, false])
const steptext = ref([true, false, false, false,])
const { proxy } = getCurrentInstance()

showLoadingToast({
  duration: 0,
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
  loadingType: "spinner",
});


const registerRule = ref({

})

const ruleForm = ref({
  pay_realname: '', //提交人
  pay_amount: '', // 付款金额
  pay_remark: '', // 备注
  pay_image_url: '', // 凭证图片地址
  pay_date: '', // getCurrentDay()
})

// 首先判断是否是支付成功的回调 然后修改参数重新加载页面
// 订单
if (route.query.order_sn) {

  if (route.query.order_sn.split('@')[1] == 2) {
    proxy.$fun.toPage('/payment?order_sn=' + route.query.order_sn.split('@')[0] + "&step=" + route.query.order_sn.split('@')[1])
  }
}
//  配送单
if (route.query.porder_sn) {
  if (route.query.porder_sn.split('@')[1] == 2) {
    proxy.$fun.toPage('/payment?porder_sn=' + route.query.porder_sn.split('@')[0] + "&step=" + route.query.porder_sn.split('@')[1])

  }
}

const onConfirmBankingmode = ({ selectedOptions }) => {
  console.log(selectedOptions[0].value);
  Bankingmode.value = selectedOptions[0].value
  showBankingmode.value = false
  payType.value = 0
}
// 订单详情数据获取
const getgoodsData = (order_sn) => {

  proxy.$api
    .orderDetail({
      order_sn: order_sn,
    })
    .then((res) => {

      let buydata = [];
      res.data.detail.forEach((shopsItem) => {
        shopsItem.list.forEach((goodsItem) => {
          buydata.push(goodsItem);
        });
      });
      goodsdatas.value = res.data;
      buyGoodsArr.value = buydata;


    }).catch(() => {
      closeToast()
    })
}
var checkamount = () => {
  var price = orderpayInput.value
  if (ruleForm.value.pay_amount < price) {
    ruleForm.value.pay_amount = price
    return proxy.$fanyi('付款金额不能小于最低入金金额')
  }
  return true
}
// 配送单详情数据获取
const getpeisonggoodsData = () => {
  proxy.$api
    .storageDeliverDetail({ porder_sn: route.query.porder_sn })
    .then((res) => {
      let buydata = [];

      res.data.result.porder_detail.forEach((shopsItem) => {
        buydata.push(shopsItem.order_detail);
      });


      orderaddress.value = res.data.result.receive

      goodsdatas.value = res.data.result;

      buyGoodsArr.value = buydata.flat();
    })
}
const validateField = (type) => {
  ruleform.value
    .validate(type)
    .then(() => {
      registerRule.value[type] = 2
    })
    .catch(() => {
      registerRule.value[type] = 0
    })
}// 付款证明上传
const afterRead = (file, index) => {
  // 此时可以自行将文件上传至服务器
  console.log(file);
  compressionJudgment(file.file, index);
};
const deleteImage = () => {
  ruleForm.value.pay_image_url = ''
  validateField('pay_image_url')
}
const compressionJudgment = (file, index) => {
  console.log(file, index);
  let formData = new FormData();
  formData.append("file", file);
  // //文件上传请求
  var xhr = new XMLHttpRequest();
  xhr.open(
    "POST",
    'https://laravel.rakumart.us/api/common/uploadFile',
    true
  );
  xhr.onload = () => {
    if (xhr.status === 200) {

      const res = JSON.parse(xhr.responseText);

      if (res.code != 0) {
        validateField('pay_image_url')
        showFailToast('An error occurred while uploading the file');
        return
      }
      ruleForm.value.pay_image_url = res.data
      validateField('pay_image_url')
    } else {
      // console.log("An error occurred while uploading the file.");
      validateField('pay_image_url')
      showFailToast('An error occurred while uploading the file');

    }
  };
  xhr.send(formData);
};
// 获取订单支付数据
const getData = async () => {


  // 如果有订单号
  if (route.query.order_sn) {
    getgoodsData(route.query.order_sn)
    let datas = {
      order_sn: route.query.order_sn,
    }
    if (route.query.coupon_ids) {
      datas.coupon_ids = route.query.coupon_ids;
    }
    const res = await proxy.$api.orderPayment(datas)
    closeToast()
    if (res.code !== 0) {
      showFailToast(proxy.$fanyi(res.msg))
      return
    }
    balance.value = res.data.balance;
    paymentdata.value = res.data
    payAllCNPrice.value =
      res.data.sum_discount_price * proxy.$store.state.exchangeRate > 0 &&
        res.data.sum_discount_price * proxy.$store.state.exchangeRate < 0.01
        ? 0.01
        : proxy.$fun.ceil(
          res.data.sum_discount_price * proxy.$store.state.exchangeRate
        );

    Totalamount.value = res.data.sum_price * proxy.$store.state.exchangeRate > 0 && res.data.sum_price * proxy.$store.state.exchangeRate < 0.01 ? 0.01 : proxy.$fun.ceil(res.data.sum_price * proxy.$store.state.exchangeRate);
    orderpayInput.value =
      balance.value > 0 && balance.value - payAllCNPrice.value > 0
        ? proxy.$fun.EURNumSegmentation(payAllCNPrice.value)
        : res.data.min_bank_pay_amount;

    ruleForm.value.pay_amount = orderpayInput.value
  } else {
    Progressarr.value = [100, 100, 50]
    stepArr.value = [true, true, true, false,]
    steptext.value = [false, false, true, false,]
    getpeisonggoodsData()
    // 配送单支付
    let datas = {
      porder_sn: route.query.porder_sn,
    }
    if (route.query.coupon_ids) {
      datas.coupon_ids = route.query.coupon_ids;
    }
    const res = await proxy.$api.storagePOrderPayment(datas)
    closeToast()
    if (res.code !== 0) {
      showFailToast(proxy.$fanyi(res.msg))
      return
    }
    console.log(res);
    balance.value = res.data.balance;
    paymentdata.value = res.data
    payAllCNPrice.value = res.data.porder_amount

    Totalamount.value = res.data.porder_calculate
    orderpayInput.value =
      balance.value > 0 && balance.value - payAllCNPrice.value > 0
        ? proxy.$fun.EURNumSegmentation(payAllCNPrice.value)
        : res.data.min_bank_pay_amount;
    ruleForm.value.pay_amount = orderpayInput.value
  }


}

const startTimeConfirm = (e) => {
  startTimeShow.value = !startTimeShow.value;
  ruleForm.value.pay_date = e.selectedValues[0] + '-' + e.selectedValues[1] + '-' + e.selectedValues[2]
  validateField('pay_date')
}
//  获取订单地址
const getorderaddress = async () => {
  const { data } = await proxy.$api.orderAddress({
    order_sn: route.query.order_sn,
  });
  orderaddress.value = data;
}

if (Number(route.query.step) == 2) {
  // 如果有订单号 获取支付时传递的地址

  if (route.query.order_sn) {
    getorderaddress()
    Progressarr.value = [100, 50, 0]
    step.value = 2
    stepArr.value = [true, true, false, false,]
    steptext.value = [false, true, false, false,]
    // 页面滚动到顶部

  } else {
    Progressarr.value = [100, 100, 100]
    step.value = 2
    stepArr.value = [true, true, true, true,]
    steptext.value = [false, false, false, true,]
  }
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });

}

getData()
// 点击余额支付判断
const blanepayment = () => {

  if (!Agreementcheck.value) {
    showToast(proxy.$fanyi("使用条款和隐私政策还没有被同意。"));
    return;
  }
  // 如果是订单先进行表单校验
  if (route.query.order_sn) {

    dizhiformdom.value.ruleform.validate()
      .then(() => {
        showpayment.value = true
      })
      .catch(() => {

        showConfirmDialog({

          confirmButtonText: proxy.$fanyi('依然支付'),
          cancelButtonText: proxy.$fanyi('返回查看地址'),
          message:
            proxy.$fanyi('收货地址不完整，请查收'),
        })
          .then(() => {
            showpayment.value = true
          })
          .catch(() => {
            // on cancel
          });

      })

  } else {
    // 配送单
    showpayment.value = true
  }
}
// 点击在线支付 
const Online = () => {
  if (!Agreementcheck.value) {
    showToast(proxy.$fanyi("使用条款和隐私政策还没有被同意。"));
    return;
  }
  // 如果是订单先进行表单校验
  if (route.query.order_sn) {
    // 首先进行表单校验

    dizhiformdom.value.ruleform.validate()
      .then(() => {
        showpayment.value = true
      })
      .catch(() => {
        showConfirmDialog({

          confirmButtonText: proxy.$fanyi('依然支付'),
          cancelButtonText: proxy.$fanyi('返回查看地址'),
          message:
            proxy.$fanyi('收货地址不完整，请查收'),
        })
          .then(() => {
            showpayment.value = true
          })
          .catch(() => {
            // on cancel
          });
      })

  } else {
    // 配送单
    showpayment.value = true
  }
}
const Confirmthetransfer = () => {
  // 进项判断是订单还是配送单校验表单
  if (!Agreementcheck.value) {
    showToast(proxy.$fanyi("使用条款和隐私政策还没有被同意。"));
    return;
  }
  // 如果是订单先进行表单校验
  if (route.query.order_sn) {

    dizhiformdom.value.ruleform.validate()
      .then(() => {


        ruleform.value
          .validate()
          .then(() => {

            showpayment.value = true
          })
          .catch((err) => {
            err.forEach((item) => {

              validateField(item.name)
            })
            console.log(err);
            showToast(proxy.$fanyi("有必填项未填写"));
          })
      })
      .catch(() => {
        showConfirmDialog({

          confirmButtonText: proxy.$fanyi('依然支付'),
          cancelButtonText: proxy.$fanyi('返回查看地址'),
          message:
            proxy.$fanyi('收货地址不完整，请查收'),
        })
          .then(() => {
            ruleform.value
              .validate()
              .then(() => {

                showpayment.value = true
              })
              .catch((err) => {
                err.forEach((item) => {

                  validateField(item.name)
                })
                console.log(err);
                showToast(proxy.$fanyi("有必填项未填写"));
              })
          })
          .catch(() => {
            // on cancel
          });
      })

  } else {
    // 配送单
    showpayment.value = true

    // 校验银行
    ruleform.value
      .validate()
      .then(() => {
        console.log('21212');
      })
      .catch((err) => {
        err.forEach((item) => {
          validateField(item.name)
        })
        showToast(proxy.$fanyi("有必填项未填写"));
        showToast(proxy.$fanyi("有必填项未填写"));
      })
  }

}

//提交余额支付订单
const submitUserBalancePayOrder = () => {
  showLoadingToast({
    duration: 0,
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
    loadingType: "spinner",
  })
  proxy.$api
    .orderBalancePayment({
      order_sn: route.query.order_sn,
      coupon_ids: route.query.coupon_ids,

      address: dizhiformdom.value.registerform,
    })
    .then((res) => {
      closeToast()
      if (res.code != 0) {
        showFailToast(proxy.$fanyi(res.msg))

        return
      }
      if (route.query.order_sn) {

        getorderaddress();
      }
      Progressarr.value = [100, 50, 0]
      step.value = 2
      stepArr.value = [true, true, false, false,]
      steptext.value = [false, true, false, false,]
      // 页面滚动到顶部
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      showpayment.value = false
    }).catch(() => {
      closeToast()
    })

}

// 提交订单在线支付
const Onlinepayment = () => {
  showLoadingToast({
    duration: 0,
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
    loadingType: "spinner",
  });
  proxy.$api
    .orderPingPongPayment({
      order_sn: route.query.order_sn,
      coupon_ids: route.query.coupon_ids,
      amount: orderpayInput.value,
      address: dizhiformdom.value.registerform,
    })
    .then((res) => {
      closeToast()
      if (res.code != 0) {
        showFailToast(proxy.$fanyi(res.msg))
        return
      }

      window.open(res.data);
      getorderaddress()
      showpayment.value = false

    }).catch(() => {
      closeToast()
    })
}
// 提交配送余额支付
const Deliveryorderpayment = () => {

  showLoadingToast({
    duration: 0,
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
    loadingType: "spinner",
  });
  proxy.$api
    .storagePOrderBalancePayment({
      porder_sn: route.query.porder_sn,
      coupon_ids: route.query.coupon_ids,
    })
    .then((res) => {
      closeToast()
      if (res.code != 0) {
        showFailToast(proxy.$fanyi(res.msg))
        return
      }
      Progressarr.value = [100, 100, 100]
      step.value = 2
      stepArr.value = [true, true, true, true,]
      steptext.value = [false, false, false, true,]
      showpayment.value = false

      window.pageYOffset ? window.pageYOffset = 0 : document.documentElement.scrollTop = 0
    }).catch(() => {
      closeToast()
    })
}
// 提交配送单在线支付
const Deliveryorderonlinepayment = () => {
  showLoadingToast({
    duration: 0,
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
    loadingType: "spinner",
  })
  proxy.$api.storagepOrderPingPongPayment({
    porder_sn: route.query.porder_sn,
    coupon_ids: route.query.coupon_ids,
    amount: orderpayInput.value,
    address: dizhiformdom.value.registerform,
  })
    .then((res) => {
      closeToast()
      if (res.code != 0) {
        showFailToast(proxy.$fanyi(res.msg))
        return
      }

      window.open(res.data);

      showpayment.value = false

    }).catch(() => {
      closeToast()
    })
}

// paypal 支付
const Paypalpayment = async () => {
  let data = {

    coupon_ids: route.query.coupon_ids,
    amount: 0,

  }
  showLoadingToast({
    duration: 0,
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
    loadingType: "spinner",
  })
  //如果是订单支付 
  if (route.query.order_sn) {
    data.order_sn = route.query.order_sn
    data.address = dizhiformdom.value.registerform,
      data.amount = orderpayInput.value
    const res = await proxy.$api.orderPayPalPayment(data)
    closeToast()
    if (res.code != 0) {
      showFailToast(proxy.$fanyi(res.msg))
      return
    }
    window.open(res.data.pay_url)

    showpayment.value = false
  }
  // 如果是配送单支付
  if (route.query.porder_sn) {
    data.porder_sn = route.query.porder_sn
    data.amount = orderpayInput.value
    const res = await proxy.$api.pOrderPayPalPayment(data)
    closeToast()
    if (res.code != 0) {
      showFailToast(proxy.$fanyi(res.msg))
      return
    }
    console.log(res);
    window.open(res.data.pay_url)
    showpayment.value = false
  }




}
// 复制银行名称
const Copytheaccountname = async () => {
  let order_sn = route.query.order_sn ? route.query.order_sn : route.query.porder_sn
  try {
    // 要复制的文本
    const textToCopy = 'Sameway Global Trade Investment Limited' + '   ' + order_sn

    // 将文本写入剪贴板
    await navigator.clipboard.writeText(textToCopy)

    // 通知用户复制成功
    showToast(proxy.$fanyi('复制成功'))
  } catch (err) {
    // 创建一个临时的textarea元素
    // 要复制的文本
    const textToCopy = 'Sameway Global Trade Investment Limited' + '   ' + order_sn
    const textarea = document.createElement('textarea')
    textarea.value = textToCopy

    // 添加到文档中
    document.body.appendChild(textarea)

    // 选择文本
    textarea.select()

    // 复制选中的文本到剪贴板
    document.execCommand('copy')

    // 移除临时的textarea元素
    document.body.removeChild(textarea)
    // 通知用户复制成功
    showToast(proxy.$fanyi('复制成功'))
  }
}
// 银行支付
const copyTextToClipboard = async ($event) => {

  try {
    // 要复制的文本
    const textToCopy = $event.currentTarget.parentElement.textContent

    // 将文本写入剪贴板
    await navigator.clipboard.writeText(textToCopy)

    // 通知用户复制成功
    showToast(proxy.$fanyi('复制成功'))
  } catch (err) {
    // 创建一个临时的textarea元素
    // 要复制的文本
    const textToCopy = $event.currentTarget.parentElement.textContent
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;

    // 添加到文档中
    document.body.appendChild(textarea);

    // 选择文本
    textarea.select();

    // 复制选中的文本到剪贴板
    document.execCommand('copy');

    // 移除临时的textarea元素
    document.body.removeChild(textarea);
    // 通知用户复制成功
    showToast(proxy.$fanyi('复制成功'))
  }
}
const bankpayment = async () => {
  showLoadingToast({
    duration: 0,
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
    loadingType: "spinner",
  });
  // 如果是订单

  if (route.query.order_sn) {

    const res = await proxy.$api.orderBankPayment({
      order_sn: route.query.order_sn,
      coupon_ids:
        route.query.coupon_ids != 'undefined'
          ? route.query.coupon_ids
          : '',
      bill_method: Bankingmode.value == 'Fedwire'
        ? 2
        : Bankingmode.value == 'ACH'
          ? 3
          : '',
      pay_realname: ruleForm.value.pay_realname,
      pay_amount: ruleForm.value.pay_amount,
      pay_image_url: ruleForm.value.pay_image_url,
      pay_date: ruleForm.value.pay_date,
      pay_remark: ruleForm.value.pay_remark,
      address: dizhiformdom.value.registerform,
    })
    closeToast()
    if (res.code != 0) {
      showFailToast(proxy.$fanyi(res.msg))

      return
    }
    if (route.query.order_sn) {

      getorderaddress();
    }
    Progressarr.value = [100, 50, 0]
    step.value = 2
    stepArr.value = [true, true, false, false,]
    steptext.value = [false, true, false, false,]
    // 页面滚动到顶部
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    showpayment.value = false
  }
  // 如果是配送单支付
  if (route.query.porder_sn) {
    const res = await proxy.$api.storagePOrderBankPayment({
      porder_sn: route.query.porder_sn,
      coupon_ids: route.query.coupon_ids != 'undefined' ? route.query.coupon_ids : "",
      bill_method: Bankingmode.value == 'Fedwire'
        ? 2
        : Bankingmode.value == 'ACH'
          ? 3
          : '',
      pay_realname: ruleForm.value.pay_realname,
      pay_amount: ruleForm.value.pay_amount,
      pay_image_url: ruleForm.value.pay_image_url,
      pay_date: ruleForm.value.pay_date,
      pay_remark: ruleForm.value.pay_remark,
    })
    closeToast()
    if (res.code != 0) {
      showFailToast(proxy.$fanyi(res.msg))
      return
    }
    Progressarr.value = [100, 100, 100]
    step.value = 2
    stepArr.value = [true, true, true, true,]
    steptext.value = [false, false, false, true,]
    showpayment.value = false

    window.pageYOffset ? window.pageYOffset = 0 : document.documentElement.scrollTop = 0
  }




}




</script>
<style lang="scss">
#paymentdialong {
  width: 295px;
  height: 179px;

  border-radius: 8px;

  .van-dialog__header {
    width: 100%;
    border: 1px solid #dbdbdb;
    height: 50px;

    display: flex;
    margin: 0;
    padding: 0px;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 20px;
    align-items: center;

    span {
      font-size: 14px;

      font-weight: 400;
      color: #222222;
      line-height: 20px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  .van-dialog__content {
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;

    p {
      display: flex;

      width: 100%;
      padding: 0 20px;
      font-size: 12px;

      font-weight: 400;
      color: #000000;
      line-height: 18px;
    }
  }

  .deletefooter {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    .cofim,
    .queren {
      min-width: 120px;
      min-height: 40px;
      background: #ff730b;
      border-radius: 6px;
      font-size: 14px;

      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .cancel {
      width: 120px;
      height: 40px;
      background: #f2f2f2;
      border-radius: 6px;
      font-size: 14px;

      font-weight: 400;
      color: #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 20px;
    }
  }
}



// .van-popover__content {
//   background-color: #F6F6F6 !important;
// }
.van-dialog__footer {

  .van-button {
    font-size: 12px;
    padding: 0 15px;
  }
}

.van-dialog__message {
  font-size: 12px;
}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.headBox {
  h3 {
    font-size: 14px;

    font-weight: 400;
    color: #030303;
    line-height: 20px;
  }

  p {
    font-size: 10px;

    font-weight: 400;
    color: #030303;
    line-height: 14px;
    zoom: 0.9;
  }
}

.payment {
  width: 100%;
  min-height: calc(100vh - 44px);
  padding: 0 10px;
  background: #F6F6F6;
  padding-bottom: 20px;

  // 余额支付
  .blane {
    width: 1005;
    min-height: 321px;
    background: #FFFFFF;
    border-radius: 6px;
    padding-bottom: 15px;
    border: 0.5px solid #E2E2E2;

    .top-title {
      display: flex;
      height: 44px;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      border-bottom: 0.5px solid #DBDBDB;

      .left {
        font-size: 12px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #FF730B;
        line-height: 18px;

      }

      .right {
        display: flex;

        span {
          &:first-child {
            display: flex;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #608BFF;
          }

          &:last-child {
            margin-left: -3px;
            width: 16px;
            height: 16px;
            display: flex;
            border-radius: 50%;
            background: #00C18B;
          }
        }
      }
    }

    .content {
      padding: 0 15px;
    }

    .oeder-box {
      width: 100%;
      height: 80px;
      padding: 14px 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;


      .order {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          display: flex;

          &:first-child {
            font-size: 12px;
            font-family: ArialMT;
            color: #222222;
            line-height: 20px;

          }

          &:last-child {
            font-size: 14px;
            font-family: ArialMT;
            color: #FF730B;
            line-height: 20px;
          }

        }
      }

      .jine {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          display: flex;

          &:first-child {
            height: 20px;
            font-size: 12px;
            font-family: Arial-BoldMT, Arial;
            font-weight: 600;
            color: #222222;
            line-height: 20px;

          }

          &:last-child {
            font-size: 14px;
            font-family: Arial-BoldMT, Arial;
            font-weight: 600;
            color: #FF730B;
            line-height: 20px;
          }

        }
      }


      .useblane,
      .paymentblane {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          display: flex;

          &:first-child {
            font-size: 12px;
            font-family: ArialMT;
            color: #222222;
            line-height: 20px;

          }

          &:last-child {
            font-size: 14px;
            font-family: ArialMT;
            color: #222222;
            line-height: 20px;
          }

        }
      }


    }

    .payment-box {
      margin-top: 5px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 54px;
      background: #FF730B;
      border-radius: 6px;
      border-radius: 6px;

      font-size: 16px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #FFFFFF;

      line-height: 24px;

    }

    .Agreementcheck {
      margin-top: 15px;
      display: flex;
      // align-items: center;

      :deep().van-icon-success:before {
        width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        border-radius: 50%;
      }

      :deep().van-checkbox__icon {
        height: 12px;
        margin-right: 5px;
      }

      :deep().van-checkbox {
        overflow: visible;
        height: 16px;
      }

      :deep().van-badge__wrapper {
        width: 12px;
        height: 12px;
        font-size: 12px;
      }

      p {
        margin-left: 3px;
        font-size: 12px;

        font-weight: 400;
        color: #999999;
        line-height: 15px;


        span {
          font-size: 12px;

          font-weight: 400;
          color: #FF730B;
          text-decoration: underline;
        }
      }
    }


  }

  // 国际运输最终报价
  .Internationalquotation {
    width: 100%;
    min-height: 212px;
    padding: 15px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;
    margin-bottom: 10px;

    h2 {
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: 18px;
      margin-bottom: 10px;
    }

    p {
      font-size: 10px;

      font-weight: 400;
      color: #999999;
      line-height: 14px;
      margin-bottom: 5px;
    }

    .guojiwuliu,
    .meiguowuliu,
    .ortherprice {
      width: 100%;
      height: 33px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px dashed #DBDBDB;

      span {
        &:first-child {
          font-size: 10px;

          font-weight: 400;
          color: #999999;
          line-height: 14px;
          zoom: 0.9;

        }

        &:last-child {
          font-size: 10px;
          font-family: ArialMT;
          color: #222222;
          line-height: 14px;
          zoom: 0.9;
        }
      }
    }

    .feiyongzonge {
      margin-top: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: 18px;
    }
  }


  // 在线支付
  .Onlinepayment {
    width: 355px;
    min-height: 480px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;
    padding-bottom: 20px;

    .top-title {
      display: flex;
      height: 44px;
      justify-content: space-between;
      align-items: center;

      border-bottom: 0.5px solid #DBDBDB;
      display: flex;
      justify-content: space-between;
      overflow: hidden;

      .left {
        font-size: 12px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 18px;
        border-top-left-radius: 6px;
        flex: 1;
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 18px;
        flex: 1;
        border-top-right-radius: 6px;
      }

      .active {

        color: #FF730B;
        background-color: #FF730B;
        color: #ffffff;
        font-size: 14px;
      }

      // .right {
      //   display: flex;

      //   span {
      //     &:first-child {
      //       display: flex;
      //       width: 16px;
      //       height: 16px;
      //       border-radius: 50%;
      //       background: #608BFF;
      //     }

      //     &:last-child {
      //       margin-left: -3px;
      //       width: 16px;
      //       height: 16px;
      //       display: flex;
      //       border-radius: 50%;
      //       background: #00C18B;
      //     }
      //   }
      // }
    }


    .content {
      padding: 0 15px;

      .orderprice {
        width: 100%;
        min-height: 80px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px 0px;

        p {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 13px;

          &:last-child {
            margin-bottom: 0px;
          }

          span {
            display: flex;

            &:first-child {
              font-size: 12px;

              font-weight: 400;
              color: #222222;
              line-height: 20px;
            }


            &:last-child {
              font-size: 14px;
              font-family: ArialMT;
              color: #FF730B;
              line-height: 20px;
            }
          }
        }


      }

      .Paymentprompt {

        margin-bottom: 15px;
        font-size: 12px;

        font-weight: 400;
        color: #222222;
        line-height: 20px;
      }

      .Total {
        margin-top: 2px;
        font-size: 12px;

        font-weight: 400;
        color: #222222;
        line-height: 20px;
      }

      .Minimumpayment {
        width: 100%;
        height: 50px;
        border-top: 1px dashed #DBDBDB;
        border-bottom: 1px dashed #DBDBDB;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          &:first-child {
            font-size: 12px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #222222;
            line-height: 20px;
          }

          &:last-child {
            font-size: 14px;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            color: #222222;
            line-height: 20px;
          }

        }

      }

      .pingpong {
        margin-top: 25px;
        width: 100%;
        height: 60px;
        background: #F6F6F6;
        border-radius: 6px;
        border: 0.5px solid #DEDEDE;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;

        img {
          width: 80px;
          height: 25px;
        }
      }

      .zhifuprice {
        width: 100%;

        p {
          span {
            color: #E74C3C;
          }

          font-size: 12px;
          font-family: PingFangSC-Regular,
          PingFang SC;
          font-weight: 400;
          color: #222222;
          line-height: 18px;
          margin-bottom: 5px;

        }

        input {
          width: 325px;
          height: 40px;
          background: #FFFFFF;
          font-size: 12px;

          font-weight: 400;
          color: #222222;
          line-height: 18px;
          padding-left: 10px;
          border-radius: 6px;
          border: 0.5px solid #DEDEDE;
        }
      }

      .Agreementcheck {
        margin-top: 15px;
        display: flex;
        // align-items: center;

        :deep().van-icon-success:before {
          width: 12px;
          height: 12px;
          display: flex;
          align-items: center;
          border-radius: 50%;
        }

        :deep().van-checkbox__icon {
          height: 12px;
          margin-right: 5px;
        }

        :deep().van-checkbox {
          overflow: visible;
          height: 16px;
        }

        :deep().van-badge__wrapper {
          width: 12px;
          height: 12px;
          font-size: 12px;
        }

        p {
          margin-left: 3px;
          font-size: 12px;

          font-weight: 400;
          color: #999999;
          line-height: 15px;


          span {
            font-size: 12px;

            font-weight: 400;
            color: #FF730B;
            text-decoration: underline;
          }
        }
      }

      .Online {

        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 54px;
        margin-top: 25px;
        background: #FF730B;
        border-radius: 6px;
        border-radius: 6px;

        font-size: 16px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #FFFFFF;
        margin-bottom: 15px;
        line-height: 24px;
      }





    }

    // 银行卡支付

    // 银行卡
    .Rabobank {
      padding: 0 15px;

      .top-box {
        min-height: 80px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px 0px;

        border-bottom: 1px dashed #e2e2e2;

        .useblane {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 13px;

          span {
            &:first-child {
              font-size: 12px;

              font-weight: 400;
              color: #222222;
              line-height: 20px;
            }


            &:last-child {
              font-size: 14px;
              font-family: ArialMT;
              color: #FF730B;
              line-height: 20px;
            }
          }
        }

        .Lowest {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            &:first-child {
              font-size: 12px;

              font-weight: 400;
              color: #222222;
              line-height: 20px;
            }


            &:last-child {
              font-size: 14px;
              font-family: ArialMT;
              color: #FF730B;
              line-height: 20px;
            }
          }
        }
      }

      // 银行卡区域
      .content {
        width: 100%;
        padding: 0px;
        padding-top: 25px;
        border-bottom: 1px dashed #e2e2e2;
        padding-bottom: 10px;


        .pic-box {
          width: 100%;
          height: 60px;
          background: #f6f6f6;
          border-radius: 6px;
          border: 1px solid #dedede;
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
          align-items: center;

          // img {
          //   // width: 134px;
          //   height: 30px;
          // }
        }

        .operationshow {
          width: 100%;

          padding-top: 20px;
          display: flex;

          padding-left: 20px;

          .left {
            width: 36px;
            position: relative;
            height: 154px;
            margin-right: 10px;

            .one,
            .two {
              width: 36px;
              height: 36px;
              background: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              border: 1px solid #e2e2e2;
            }

            .two {
              margin-top: 74px;
            }

            span {
              position: absolute;
              left: 50%;
              width: 1px;
              top: 45px;
              transform: translateX(-50%);
              height: 54px;
              background-color: #e2e2e2;
            }
          }

          .right {
            .box {
              margin-bottom: 45px;

              h3 {
                font-size: 13px;

                font-weight: 400;
                color: #222222;
                margin-bottom: 10px;
              }

              p {
                font-size: 12px;

                font-weight: 400;
                color: #666666;
                line-height: 20px;
              }
            }
          }
        }

        .queren {
          min-width: 120px;
          height: 35px;
          margin: auto;
          margin-top: 20px;
          margin-bottom: 20px;
          background: #ff730b;
          border-radius: 6px;
          font-size: 14px;

          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        :deep().van-dialog__header {
          text-align-last: left !important;
          padding: 15px 20px 15px 20px;
          display: flex;
          font-size: 13px;
          justify-content: space-between;
          border-bottom: 1px solid #e2e2e2;
        }

        .Attninfo {


          h2 {
            font-size: 14px;

            font-weight: 600;
            color: #222222;
            margin-bottom: 10px;
          }

          p {
            font-size: 12px;

            font-weight: 400;


            font-weight: 400;
            color: #222222;
            line-height: 20px;
            margin-bottom: 10px;
          }

          .operation {
            display: flex;
            align-items: center;
            font-size: 12px;

            font-weight: 400;
            color: #ff730b;
            line-height: 20px;
            margin-bottom: 20px;

            img {
              margin-right: 10px;
              width: 18px;
              height: 18px;
            }
          }

          .ordersn {
            width: 100%;
            height: 50px;
            background: #fff8f3;
            border-radius: 6px;
            border: 1px solid #ff730b;
            display: flex;
            justify-content: center;
            font-size: 14px;
            align-items: center;
            margin-bottom: 25px;

            img {
              width: 18px;
              height: 18px;
              margin-right: 10px;
              margin-top: -2px;
            }

            span {
              height: 25px;
              line-height: 25px;
            }
          }

          .title {
            font-size: 12px;

            font-weight: 400;
            color: #666666;
            line-height: 18px;
            margin-bottom: 5px;
          }

          .text {
            margin-bottom: 12px;
            font-size: 12px;
            line-height: 20px;

            display: flex;
            align-items: center;

            img {
              margin-left: 5px;
              width: 20px;
              height: 20px;
            }

            font-weight: 400;
            color: #222222;
          }
        }
      }

      // 表单区域
      .ruleformbox {
        padding-top: 20px;

        :deep().van-cell-group {
          margin: 0px;
        }

        :deep().van-field__label {
          font-size: 12px;
        }

        :deep().van---disabled {
          color: #222222;
        }

        :deep() .van-field--disabled .van-field__label {
          color: #222222 !important;
        }

        :deep().van-field__control:disabled {
          -webkit-text-fill-color: #C0C4CC !important;
        }

        :deep().van-cell {
          flex-direction: column;
          min-height: 88px;
          padding: 0px;
          width: 323px !important;

          &::after {
            display: none !important;
          }
        }

        :deep().van-cell__value {
          &::after {
            display: none !important;
          }
        }

        :deep() .van-field__body {
          width: 323px !important;
          box-sizing: border-box;
          position: relative;

          input {
            width: 290px;
            height: 40px;
            background: #ffffff;
            border-radius: 6px;
            border: 0.5px solid #dedede;
            padding-left: 10px;
            font-size: 12px;
            font-family: ArialMT;

            color: #222222;
            line-height: 18px;
          }

          input {
            width: 100%;
          }

          input::placeholder {
            font-size: 12px;
            font-family: ArialMT;
            color: #c0c4cc;
            line-height: 18px;
          }
        }

        :deep() .van-field__right-icon {
          display: flex;
          position: absolute;
          justify-content: center;
          align-items: center;

          right: 15px;
          color: #ff730b;

          img {
            width: 18px;
            height: 18px;
          }
        }

        // 错误
        .errinput {
          :deep() input {
            background: #ffffff;
            border-radius: 6px;
            border: 1px solid #e74c3c;
          }
        }

        //  正确
        .correctinput {
          :deep() input {
            background: #fff8f3;
            border-radius: 6px;
            border: 1px solid #ff730b;
            -webkit-text-fill-color: #222222 !important;
          }

          :deep().van-field__control:disabled {
            -webkit-text-fill-color: #222222 !important;
          }
        }

        .uploadbox {
          position: relative;
          min-height: 30px;

          :deep().van-cell__value {
            display: none !important;
            height: 10px !important;

          }





        }

        .ererorimage {
          margin-top: 2px;
          color: #ee0a24;
          font-size: 12px;
        }

        :deep().van-uploader {
          border-radius: 8px !important;
        }

        :deep().van-uploader__input {
          border-radius: 8px !important;
        }

        :deep().van-uploader__upload {
          border-radius: 8px !important;
        }

      }



      .Confirmthetransfer {
        width: 100%;
        height: 54px;
        margin-top: 25px;
        background: #ff730b;
        border-radius: 6px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .Agreementcheck {
        margin-top: 15px;
        display: flex;
        // align-items: center;

        :deep().van-icon-success:before {
          width: 12px;
          height: 12px;
          display: flex;
          align-items: center;
          border-radius: 50%;
        }

        :deep().van-checkbox__icon {
          height: 12px;
          margin-right: 5px;
        }

        :deep().van-checkbox {
          overflow: visible;
          height: 16px;
        }

        :deep().van-badge__wrapper {
          width: 12px;
          height: 12px;
          font-size: 12px;
        }

        p {
          margin-left: 3px;
          font-size: 12px;

          font-weight: 400;
          color: #999999;
          line-height: 15px;


          span {
            font-size: 12px;

            font-weight: 400;
            color: #FF730B;
            text-decoration: underline;
          }
        }
      }
    }
  }
}


// 收获地址
.Receivingaddress {
  width: 100%;
  max-height: 48px;

  background: #FFFFFF;
  border-radius: 6px;
  border: 0.5px solid #E2E2E2;
  padding-bottom: 10px;
  overflow: hidden;
  transition: all 0.8s;
  margin-bottom: 10px;

  .top-box {
    padding: 0 15px;
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    justify-content: space-between;

    .left {
      font-size: 12px;

      font-weight: 600;
      color: #222222;
      line-height: 18px;
    }

    .right {
      display: flex;
      align-items: center;

      :deep().van-icon {
        font-size: 16px;
      }
    }
  }

  .addresslist {
    padding: 0 15px;

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      span {

        &:first-child {
          flex: 0 0 130px;

          font-size: 12px;

          font-weight: 400;
          color: #666666;
          line-height: 18px;
        }

        &:last-child {
          flex: 0 0 160px;

          font-size: 12px;
          font-family: ArialMT;
          color: #222222;
          line-height: 18px;
        }
      }

    }

  }
}

.paymentsucces {
  width: 100%;
  min-height: calc(100vh - 44px);
  padding: 0 10px;
  background: #F6F6F6;
  padding-bottom: 20px;
  overflow: hidden;


  // 订单付款完成
  .top-Complete {
    width: 100%;

    min-height: 306px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;
    padding-top: 40px;
    padding-bottom: 30px;
    margin-bottom: 10px;

    .img-box {
      margin: auto;
      width: 48px;
      height: 48px;

      margin-bottom: 20px;

      img {
        width: 48px;
        height: 48px;
      }



    }

    h2 {
      width: 100%;
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #222222;
      line-height: 20px;
      font-size: 12px;

      font-weight: 400;
      color: #222222;
      text-align: center;
      line-height: 18px;
      text-align: center;
      margin-bottom: 10px;
    }

    .ordertext {
      font-size: 12px;

      font-weight: 400;
      color: #222222;
      text-align: center;
      padding: 0 5px;
      line-height: 18px;
      margin-bottom: 20px;
    }



    .Personalcenter {
      margin: auto;
      width: 295px;
      min-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FF730B;
      border-radius: 6px;
      font-size: 14px;

      font-weight: 400;
      align-self: center;
      color: #FFFFFF;
      line-height: 20px;

    }


  }

  // 配送单付款完成
  .DeliveryorderComplete {
    width: 355px;
    min-height: 320px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;
    margin-bottom: 10px;
    padding-top: 40px;
    padding-bottom: 20px;

    .img-box {
      margin: auto;
      width: 48px;
      height: 48px;

      margin-bottom: 20px;

      img {
        width: 48px;
        height: 48px;
      }



    }

    h2 {
      width: 100%;
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #222222;
      line-height: 20px;
      font-size: 12px;

      font-weight: 400;
      color: #222222;
      text-align: center;
      line-height: 18px;
      text-align: center;
      margin-bottom: 10px;
    }


    .ordertext {
      font-size: 12px;

      font-weight: 400;
      color: #222222;
      text-align: center;
      padding: 0 5px;
      line-height: 18px;
      margin-bottom: 20px;
    }



    .Personalcenter {
      margin: auto;
      width: 295px;
      min-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FF730B;
      border-radius: 6px;
      font-size: 14px;

      font-weight: 400;
      align-self: center;
      color: #FFFFFF;
      line-height: 20px;

    }
  }


}

.maxaddress {
  max-height: 1000px !important;
  transition: all 0.8s;
}

:deep().van-progress {
  width: 100px;
  height: 2px;

}
</style>