<template>
  <!-- 附加服务弹出层 -->

  <van-popup z-index="1000" v-model:show="showBottom" id="Packinginformation" @close="close" position="bottom">
    <div class="main">
      <!-- 标题 -->
      <div class="top-box">
        <h2>{{ $fanyi('装箱信息') }}</h2>
        <van-icon @click="showBottom = false" name="cross" />
      </div>
      <!-- 内容区域 -->
      <div class="Content">
        <!-- 箱号 -->
        <div class="Casenumber">
          {{ $fanyi('当前箱号') }}: <span>{{ list.number }}</span>
        </div>
        <!-- 箱子里的商品信息 -->
        <div class="orderitem" v-for="(goodsitem) in  list.porder_freight_detail" :key="goodsitem.goods_id">
          <!-- 订单号 -->
          <div class="ordersn">
            {{ $fanyi('订单号') }}: <span>{{ goodsitem.order_detail.order_sn }}</span>
          </div>
          <div class="gooditem">
            <div class="Con">
              <!-- 图片 -->
              <div class="img-box" @click="
                $fun.toCommodityDetails({
                  goods_id: goodsitem.goods_id,
                  shop_type: goodsitem.shop_type,
                })
                ">
                <van-image :src="goodsitem.order_detail.pic">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </div>
              <!--右侧商品信息  -->
              <div class="rightgoodsinfo">
                <!-- 商品描述和删除 -->
                <div class="Description">
                  <p class="text">{{ goodsitem.order_detail.goods_title }}</p>
                </div>

                <div class="Commodityspecification">
                  <p>{{ Processingdata(goodsitem.order_detail.detail) }}</p>
                </div>
                <!-- 价格 -->
                <div class="price">
                  {{ $fanyi('装箱数量') }}: {{ goodsitem.num }}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </van-popup>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose, } from 'vue'
// import { showToast } from 'vant' defineEmits
const { proxy } = getCurrentInstance()
const showBottom = ref(false)
const list = ref([])






console.log(proxy);
const open = (item) => {
  list.value = item
  showBottom.value = true




}
const Processingdata = (str) => {
  console.log(str);
  var text = ''
  JSON.parse(str).forEach((detailitem) => {
    text += detailitem.key + ":" + " " + detailitem.value + " ";
  })
  return text
}

// 弹窗关闭
const close = () => {


}

defineExpose({
  open
})
</script>
<style lang="scss">
#Packinginformation {
  min-height: 50%;

  max-height: 88%;

  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;



  .top-box {
    position: sticky;
    top: 0px;
    z-index: 2000;
    width: 100%;
    height: 44px;
    background: #F6F6F6;
    border-radius: 8px 8px 0px 0px;

    display: flex;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    align-items: center;

    .van-icon-cross {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      color: #222222;
      font-weight: 600;
      font-size: 16px;

      // top: 14px;

    }
  }

}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.Content {
  width: 100%;
  height: 100%;
  padding: 10px;

  .Casenumber {
    width: 193px;
    height: 38px;
    background: #E6F9F4;
    border-radius: 6px;
    font-size: 12px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #00C18B;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    margin-bottom: 10px;

    span {
      margin-left: 3px;
    }
  }

  .orderitem {
    width: 100%;
    min-height: 158px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;
    margin-bottom: 10px;
    // padding: 0 15px;

    .ordersn {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 18px;
      height: 40px;
      display: flex;
      padding: 0 15px;
      align-items: center;
      border-bottom: 0.5px solid #E2E2E2;

    }

    .gooditem {
      width: 325px;
      height: 110px;
      display: flex;
      margin: auto;
      align-items: center;


      .Con {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;

        margin-bottom: 5px;

        .img-box {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          border-radius: 4px;

          :deep().van-image {
            width: 80px;
            height: 80px;
            border-radius: 4px;
          }

          :deep().van-image__img {
            border-radius: 4px;
          }
        }

        .rightgoodsinfo {
          flex: 1;
          height: 100%;

          // 描述
          .Description {
            height: 32px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;

            align-items: center;

            .text {
              height: 32px;
              font-size: 12px;
              font-family: ArialMT;
              color: #222222;
              line-height: 16px;
              padding-right: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }

            img {
              width: 24px;
              height: 24px;
              margin-left: 10px;
            }
          }

          .Commodityspecification {
            padding: 0 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 200px;
            height: 20px;
            background: #f6f6f6;
            border-radius: 4px;
            margin-bottom: 5px;

            p {
              align-items: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              // zoom: 0.9;
              font-size: 12px;
              font-family: ArialMT;
              color: #666666;
              line-height: 22px;
              zoom: 0.9;
            }

            img {
              margin-left: 10px;
              width: 8px;
              height: 4px;
            }
          }

          .price {
            height: 18px;
            font-size: 12px;
            font-family: ArialMT;
            color: #222222;
            line-height: 18px;

            font-weight: 600;
          }
        }
      }

    }

    .borderbotom {
      border-bottom: 1px dashed #DBDBDB;
    }
  }

}
</style>