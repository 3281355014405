<template>
  <!-- 注册页面 -->
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon name="arrow-left" @click="step == 1 ? $router.go(-1) : (step = 1)" />
    </template>

    <template #default>
      <span>
        {{ $fanyi('注册') }}
      </span>
    </template>
  </headBackBar>
  <!--账户注册页面页面 -->
  <div class="content">
    <div class="register" v-if="step == 1">
      <div class="register-top-title">
        {{ $fanyi('注册') }}
        <span></span>
      </div>

      <h3>{{ $fanyi('Need information') }}</h3>
      <p>
        {{
          $fanyi('Please fill in the following fields with your information')
        }}
      </p>
      <!-- 表单区域 -->
      <div class="registerform">
        <van-form ref="ruleform">
          <van-cell-group inset>
            <!-- 姓氏 -->
            <van-field @blur="validateField('user_last_name')" autocomplete="off"
              v-model.trim="registerform.user_last_name" :class="{
                errinput: registerRule.user_last_name == 0,
                correctinput: registerRule.user_last_name == 2,
              }" name="user_last_name" label-align="top" :label="$fanyi('姓氏')" :placeholder="$fanyi('请输入姓氏')" :rules="[
                {
                  required: true,
                  message: $fanyi('请输入姓氏'),
                  trigger: 'onBlur',
                },
                {
                  validator: validatorlastname,
                  trigger: 'onBlur',
                },
              ]">
              <template #right-icon>
                <van-icon name="success" v-if="registerRule.user_last_name == 2" />
              </template>
            </van-field>
            <!-- 名字 -->
            <van-field autocomplete="off" @blur="validateField('user_name')" v-model.trim="registerform.user_name"
              :class="{
                errinput: registerRule.user_name == 0,
                correctinput: registerRule.user_name == 2,
              }" name="user_name" label-align="top" :label="$fanyi('名字')" :placeholder="$fanyi('请输入名字')" :rules="[
                {
                  required: true,
                  message: $fanyi('请输入名字'),
                  trigger: 'onBlur',
                },
                {
                  validator: validatorusername,
                  trigger: 'onBlur',
                },
              ]">
              <template #right-icon>
                <van-icon name="success" v-if="registerRule.user_name == 2" />
              </template>
            </van-field>
            <!-- 州 -->
            <van-field class="state" :disabled="true" @click="showPicker = true" autocomplete="off"
              v-model.trim="registerform.state" :class="{
                errinput: registerRule.state == 0,
                correctinput: registerRule.state == 2,
              }" name="state" label-align="top" :label="$fanyi('州')" :placeholder="$fanyi('请选择地区')" :rules="[
                {
                  required: true,
                  message: $fanyi('请选择地区'),
                  trigger: 'onBlur',
                },
              ]">
              <template #right-icon>
                <van-icon name="success" v-if="registerRule.state == 2" />
                <van-icon name="arrow-down" />
              </template>
            </van-field>
            <!-- 州弹出层 -->
            <van-popup v-model:show="showPicker" round position="bottom" @close="validateField('state')">
              <van-picker :columns="columns" :cancel-button-text="$fanyi('取消')" :confirm-button-text="$fanyi('确认')"
                @cancel="showPicker = false" @confirm="onConfirm" />
            </van-popup>
            <!-- 邮箱 -->
            <van-field class="email" @blur="validateField('email')" autocomplete="off" v-model.trim="registerform.email"
              :class="{
                errinput: registerRule.email == 0,
                correctinput: registerRule.email == 2,
              }" name="email" label-align="top" :label="$fanyi('邮箱')" :placeholder="$fanyi('请输入邮箱')" :rules="[
                {
                  required: true,
                  message: $fanyi('请输入正确的邮箱地址'),
                  trigger: 'onBlur',
                },
                {
                  validator: validatoremail,
                  trigger: 'onBlur',
                },
              ]">
              <template #right-icon>
                <van-icon name="success" v-if="registerRule.email == 2" />
              </template>
            </van-field>
            <!-- 电话 -->
            <van-field class="mobile" @blur="validateField('mobile')" autocomplete="off"
              v-model.trim="registerform.mobile" :class="{
                errinput: registerRule.mobile == 0,
                correctinput: registerRule.mobile == 2,
              }" name="mobile" label-align="top" :label="$fanyi('手机号')" :placeholder="$fanyi('请输入您的手机号')" :rules="[
                {
                  required: true,
                  message: $fanyi('手机号不能为空'),
                  trigger: 'onBlur',
                },
                {
                  validator: validatormobile,
                  trigger: 'onBlur',
                },
              ]">
              <!-- 自定义左侧icon -->
              <template #left-icon>
                <div class="quhao" @click.stop="showAreacode = true">
                  <span>{{ Mobilephoneareacode }} </span>
                  <span><van-icon name="arrow-down" /></span>
                </div>
              </template>
              <template #right-icon>
                <van-icon name="success" v-if="registerRule.mobile == 2" />
              </template>
            </van-field>

            <!-- 验证码 -->
            <van-field class="code" autocomplete="off" v-model.trim="registerform.code" :class="{
              errinput: registerRule.code == 0,
              correctinput: registerRule.code == 2,
            }" name="code" label-align="top" :label="$fanyi('验证码')" :placeholder="$fanyi('请输入验证码')" :rules="[
              {
                required: true,
                message: $fanyi('请填写验证码'),
                trigger: 'onBlur',
              },
              {
                validator: validatorcode,
                trigger: 'onBlur',
              },
            ]">
              <!-- 自定义左侧icon -->
              <template #label>
                <div class="codelabel">
                  <div class="left">{{ $fanyi('验证码') }}</div>
                  <div class="right">
                    <span v-if="show" @click.stop="sendSMS">
                      <!-- 发送验证码 -->{{ $fanyi('发送验证码') }}</span>

                    <span v-else style="color: #999999">
                      {{ Countdown }} S</span>
                  </div>
                </div>
              </template>
              <template #right-icon>
                <van-icon name="success" v-if="registerRule.code == 2" />
              </template>
              <template #left-icon>
                <div class="way" @click="showcode = true">
                  <span>{{ tool }}</span> <van-icon name="arrow-down" />
                </div>
              </template>
            </van-field>
            <!-- 选择验证码弹出层 -->
            <van-popup v-model:show="showcode" round position="bottom">
              <van-picker :columns="columnscode" :cancel-button-text="$fanyi('取消')" :confirm-button-text="$fanyi('确认')"
                @cancel="showcode = false" @confirm="onConfirmcode" />
            </van-popup>
            <!-- 密码 -->

            <van-field class="password" @blur="validateField('password')" autocomplete="off" :type="passwordtype"
              v-model.trim="registerform.password" :class="{
                errinput: registerRule.password == 0,
                correctinput: registerRule.password == 2,
              }" name="password" label-align="top" :label="$fanyi('密码')" :placeholder="$fanyi('请输入密码')" :rules="[
                {
                  required: true,
                  message: $fanyi('请输入密码'),
                  trigger: 'onBlur',
                },
                {
                  validator: validatorpassword,
                  trigger: 'onBlur',
                },
              ]">
              <template #right-icon>
                <van-icon name="success" v-if="registerRule.password == 2" />

                <img src="../../assets/login/hide.svg" alt="" v-if="passwordtype == 'password'"
                  @click.stop="passwordtype = 'text'" />
                <img src="../../assets/login/display.svg" alt="" v-if="passwordtype == 'text'"
                  @click.stop="passwordtype = 'password'" />
              </template>
            </van-field>
            <!-- 确认密码 -->
            <van-field class="confirmpassword" @blur="validateField('confirmpassword')" autocomplete="off"
              :type="confirmpasswordtype" v-model.trim="registerform.confirmpassword" :class="{
                errinput: registerRule.confirmpassword == 0,
                correctinput: registerRule.confirmpassword == 2,
              }" name="confirmpassword" label-align="top" :label="$fanyi('密码确认')" :placeholder="$fanyi('请再次输入密码')"
              :rules="[
                {
                  required: true,
                  message: $fanyi('请再次输入密码'),
                  trigger: 'onBlur',
                },
                {
                  validator: validatorconfirmpassword,
                  trigger: 'onBlur',
                },
              ]">
              <template #right-icon>
                <van-icon name="success" v-if="registerRule.confirmpassword == 2" />
                <img src="../../assets/login/hide.svg" alt="" v-if="confirmpasswordtype == 'password'"
                  @click.stop="confirmpasswordtype = 'text'" />
                <img src="../../assets/login/display.svg" alt="" v-if="confirmpasswordtype == 'text'"
                  @click.stop="confirmpasswordtype = 'password'" />
              </template>
            </van-field>
          </van-cell-group>
        </van-form>

        <!-- 确认注册，进行下一步 -->
        <div class="Consentagreement" @click="register">
          {{ $fanyi('同意以上协议，进入下一步') }}
        </div>

        <!-- 隐私政策协议 -->
        <div class="Privacy">
          <div class="Privacy-left">
            <van-checkbox v-model="checked" checked-color="#FF730B" shape="square">
              <span>{{ $fanyi('RAKUMART') }} </span>
            </van-checkbox>

            <div class="text" @click="$fun.toPage('/privacypolicy')">
              <span class="agreement">{{
                $fanyi('的用户协议和隐私政策')
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="registersuccess" v-if="step == 2">
      <div class="register-top-title">
        {{ $fanyi('注册') }}
        <span></span>
      </div>
      <div class="img-box">
        <img src="../../assets/login/success.svg" alt="" />
      </div>
      <h3>{{ $fanyi('帐号注册完成') }}</h3>
      <p>{{ $fanyi('非常感谢您的注册') }}</p>
      <div class="gologin" @click="$fun.toPage('/login')">
        {{ $fanyi('去登录') }}
      </div>
    </div>
  </div>
  <!-- 图像验证码 -->
  <van-dialog v-model:show="showimg" :show-confirm-button="false">
    <!-- <img src="https://fastly.jsdelivr.net/npm/@vant/assets/apple-3.jpeg" /> -->
    <van-icon @click="showimg = false" class="close" name="cross" />
    <div class="imgdialog">
      <div class="header">
        <div class="img-box">
          <img :src="imgurl" alt="">
        </div>

      </div>
      <!-- 输入框 -->

      <div class="input-box">
        <input v-model="captcha" type="text">
        <div @click="sendSMS" class="Reset">{{ $fanyi('重置') }}</div>
        <div @click="cofirmcode" class="cofirm">{{ $fanyi('确认') }}</div>

      </div>


    </div>
  </van-dialog>
  <!-- 选择手机区号弹出层 -->
  <van-popup v-model:show="showAreacode" round position="bottom">
    <van-picker :columns="AreacodeArr" :cancel-button-text="$fanyi('取消')" :confirm-button-text="$fanyi('确认')"
      @cancel="showAreacode = false" @confirm="onConfirmAreacode" />
  </van-popup>
</template>
<!-- 因为 setup语法糖不支持，所以单独写一个-->
<!-- <script >
export default {
  beforeRouteEnter(to, from,) {
    console.log(to.path, from.path);

  },

}
</script> -->
<script setup>
import headBackBar from '../../components/headGoBack/index.vue'
import { getCurrentInstance, ref } from 'vue'
import stateList from '../../utlis/allImportData'
import { showLoadingToast, showToast, closeToast, showFailToast } from 'vant'
import Areacode from '../../utlis/mobilephoneareacode.js'
const { proxy } = getCurrentInstance()


const Verificationmode = ref('mobile')
const show = ref(true) // 验证码发送
const checked = ref(false) //是否同意协议
const Countdown = ref(60) // 倒计时
const ruleform = ref('') //表单dom
const showcode = ref(false) // 验证码弹出层
const tool = ref('mobile') // 验证方式
const showimg = ref(false)// 图片验证码弹层
const showAreacode = ref(false)// 区号弹出层
const imgurl = ref()// 图片链接
const captcha = ref('')// 图形验证码
const Mobilephoneareacode = ref('+001') // 手机区号
const step = ref(1)
// 区号列表
const AreacodeArr = ref([])

// 在组件进入之前触发的路由守卫

// 密码框类型
const passwordtype = ref('password')
const confirmpasswordtype = ref('password')
// 注册表单数据
const registerform = ref({
  user_last_name: '', //姓氏
  user_name: '', //名字，
  state: '', //州
  email: '', // 邮箱
  mobile: '', //手机号，
  code: '', //验证码  验证码验证方式目前默认手机号
  password: '', //密码，
  confirmpassword: '', //确认密码
})
// 在导航发生时执行的钩子函数

// 数据类型校验 0 错误， 1 正常 ，2 正确
const registerRule = ref({
  user_last_name: 1, //姓氏
  user_name: 1, //名字，
  state: 1, //州
  email: 1, // 邮箱
  mobile: 1, //手机号，
  code: 1, //验证码  验证码验证方式目前默认手机号
  password: 1, //密码，
  confirmpassword: 1, //确认密码
})
// 州弹出层数据
const columns = ref([])
stateList.provincesList.forEach((item) => {
  columns.value.push({
    text: item,
    value: item,
  })
})
const columnscode = ref([
  {
    text: 'mobile',
    value: 'mobile',
  },
  {
    text: 'email',
    value: 'email',
  },
])

Areacode.forEach((item) => {
  if (proxy.$store.state.languagetype == "English") {
    AreacodeArr.value.push({
      text: item.sx + ' ' + item.value,
      value: item.value
    })
  } else if (proxy.$store.state.languagetype == 'Chinese') {
    AreacodeArr.value.push({
      text: item.key + ' ' + item.value,
      value: item.value
    })
  }

})
// 点击确定选择区号
const onConfirmAreacode = ({ selectedOptions }) => {

  Mobilephoneareacode.value = selectedOptions[0].value
  showAreacode.value = false
}
const showPicker = ref(false)
const onConfirm = ({ selectedOptions }) => {
  showPicker.value = false
  registerform.value.state = selectedOptions[0].text
  registerRule.value.state = 2
}
// 选择验证方式
const onConfirmcode = ({ selectedOptions }) => {
  showcode.value = false
  tool.value = selectedOptions[0].text

}
const cofirmcode = () => {

  if (captcha.value == '') {
    showToast(proxy.$fanyi('请输入验证码'))

  } else {
    sendcode()
  }
}
// 点击发送验证码
const sendSMS = async () => {

  // 判断手机
  if (tool.value == 'mobile') {
    if (registerform.value.mobile == '') {
      showToast(proxy.$fanyi('请输入您的手机号'))
      return
    }

    // if (/^(\w){10}$/.test(registerform.value.mobile) === false) {

    //   return showToast(proxy.$fanyi('请输入正确的手机号'))

    // }
    let list = {
      number:
        tool.value == 'mobile'
          ? registerform.value.mobile
          : registerform.value.email,
    }
    const res = await proxy.$api.codeImg(list)
    if (res.code != 0) return showFailToast(proxy.$fanyi(res.msg))
    showimg.value = true
    imgurl.value = res.data.image
  }
  if (tool.value == 'email') {
    if (registerform.value.email == '') {
      showToast(proxy.$fanyi('请输入正确的邮箱地址'))
      return
    }
    //if (
    //  /^[a-zA-Z0-9.]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/.test(
    //  registerform.value.email
    //) === false
    //) {
    // return showToast(proxy.$fanyi('请输入正确的邮箱地址'))
    // }
    sendcode()
  }
  // 校验通过后 展示弹层进行图片验证
}

// 失去焦点校验表单 然后改变状态
const validateField = (type) => {
  ruleform.value
    .validate(type)
    .then(() => {
      registerRule.value[type] = 2
    })
    .catch(() => {
      registerRule.value[type] = 0
    })
}
const sendcode = () => {
  show.value = false
  // 60秒倒计时

  var timer = setInterval(() => {
    Countdown.value--
    if (Countdown.value <= 0) {
      show.value = true
      Countdown.value = 60
      clearInterval(timer)
    }
  }, 1000)

  let list = {
    occasion: 'register',
    tool: tool.value,
    captcha: captcha.value,
    number:
      tool.value == 'mobile'
        ? registerform.value.mobile
        : registerform.value.email,
  }
  if (tool.value == 'mobile') {
    list.area_code = Mobilephoneareacode.value
  }
  proxy.$api.sendVerificationCode(list).then((res) => {
    if (res.code != 0) {

      if (tool.value == 'mobile') {
        showimg.value = true
      }
      Countdown.value = 60
      show.value = true
      clearInterval(timer)
      captcha.value = ''
      showFailToast(proxy.$fanyi(res.msg))
      return
    }
    showimg.value = false
    captcha.value = ''
    showToast(proxy.$fanyi('验证码发送成功'))
  })
}

// 姓氏校验
const validatorlastname = (val) => {
  console.log(val)
  if (val.length > 24 || val.length < 1) {
    return proxy.$fanyi('姓氏长度不能大于24位')
  }
}
// 姓名校验
const validatorusername = (val) => {
  if (val.length > 24 || val.length < 1) {
    return proxy.$fanyi('姓氏长度不能大于24位')
  }
}
// 邮箱校验
const validatoremail = (val) => {
  // if (
  //   /^[a-zA-Z0-9.]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/.test(
  //     val
  //   ) === false
  // ) {
  //   return proxy.$fanyi('请输入正确的邮箱地址')
  // }
  proxy.$api
    .checkRegisterField({
      field: 'email',
      value: val,
    })
    .then((res) => {
      if (res.success == false) {
        registerform.value.email = ''
        registerRule.value.email = 1
        return showFailToast(res.msg)
      }
    })
}
// 电话校验
const validatormobile = (val) => {
  // if (/^(\w){10}$/.test(val) === false) {
  //   return proxy.$fanyi('请输入正确的手机号')
  // }
  proxy.$api
    .checkRegisterField({
      field: 'mobile',
      value: val,
    })
    .then((res) => {

      if (res.success == false) {
        registerform.value.mobile = ''
        registerRule.value.mobile = 1
        return showFailToast(res.msg)
      }
    })
}
// 验证码校验
const validatorcode = (val) => {
  if (val == '') {
    return proxy.$fanyi('请填写验证码')
  }
  // 手机号验证码或者邮箱验证码
  proxy.$api
    .checkVerificationCode({
      occasion: 'register',
      number: tool.value == 'mobile'
        ? registerform.value.mobile
        : registerform.value.email,
      code: val,
    })
    .then((res) => {
      if (res.success == false) {
        registerRule.value.code = 0
        return showFailToast(proxy.$fanyi(res.msg))
      }
      registerRule.value.code = 2
    })
}
/* eslint-disable*/
// 密码校验
const validatorpassword = (val) => {
  //     /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,16}$/
  if (/^.{6,16}$/.test(val) === false) {
    return proxy.$fanyi('请输入6-16位包含大小写字母和数字的字符串')
  }
}
// 密码确认
const validatorconfirmpassword = (val) => {
  // if (
  //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~@#$%.\*-\+=:,\\?\[\]\{}]).{6,16}$/.test(
  //     val
  //   ) === false
  // ) {
  //   return proxy.$fanyi("请输入6-16位包含大小写字母和数字的字符串");
  // }
  if (!val) {
    return proxy.$fanyi('请再次输入密码')
  }
  if (val !== registerform.value.password) {
    return proxy.$fanyi('密码输入不一致')
  }
}

// 注册函数
const register = async () => {
  try {
    await ruleform.value.validate()
    if (checked.value == false) {
      showToast(proxy.$fanyi('使用条款和隐私政策还没有被同意。'))
      return
    }
    showLoadingToast({
      duration: 0,
      message: proxy.$fanyi('加载中...'),
      forbidClick: true,
    })
    var list = {
      user_last_name: registerform.value.user_last_name, //登录名
      password: registerform.value.password, //密码
      user_name: registerform.value.user_name, //真实姓名
      email: registerform.value.email, //邮箱
      mobile: registerform.value.mobile, //手机号
      state: registerform.value.state, // 州
      area_code: Mobilephoneareacode.value,// 区号
      verification: {
        number: registerform.value.mobile,
        code: registerform.value.code,
      },
    }

    proxy.$api.register(list).then((res) => {
      closeToast()
      if (res.code != 0) return
      proxy.$fun.toPage('/registeredSuccess')
    })
  } catch { }
}

console.log(Verificationmode.value)
console.log(registerRule.value)
</script>
<style>
.van-toast {
  min-width: 150px !important;
  max-width: 280px !important;
}
</style>
<style lang="scss" scoped="scoped">
@import '@/style/scss/mixin.scss';

:deep() .van-field__body {
  position: relative;

  input {
    background: #ffffff;
    border-radius: 6px;
    border: 0.5px solid #dedede;
    padding-left: 10px;
    font-size: 12px;
    font-family: ArialMT;

    line-height: 18px;
    margin: 0;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    background-color: transparent !important;
    color: #333;
  }
}

:deep().van-icon-eye-o {
  margin-left: 10px;
}

:deep().van-icon-closed-eye {
  margin-left: 10px;
}

:deep() .van-picker-column__item {
  min-height: 44px;
  font-size: 16px;
}

:deep().van-hairline-unset--top-bottom {
  min-height: 44px;
}

:deep() .van-picker-column__item--selected {
  height: 44px;
}

// 设置州弹出层颜色
:deep() .van-picker__toolbar {
  background: #f6f6f6;
  border-radius: 8px 8px 0px 0px;
}


.close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 12px;
  margin-top: 10px;
}

.imgdialog {
  min-height: 300px;
  padding: 30px;


  .header {


    .img-box {
      width: 220px;
      height: 80px;
      margin: auto;

      img {
        width: 220px;
        height: 80px;
      }

      margin-bottom: 20px;
    }




  }

  .input-box {
    margin-top: 20px;
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: column;


    input {
      width: 220px;
      margin: auto;
      height: 38px;
      border-radius: 6px;
      border: 1px solid #dedede;
      margin-bottom: 20px;
      padding-left: 10px;
    }


    .Reset,
    .cofirm {
      margin: auto;
      width: 220px;
      height: 40px;
      background-color: #ff730b;
      border-radius: 6px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }

    .Reset {
      background-color: #0066cc;
      margin-bottom: 10px;
    }
  }
}

// :deep().van-field__control:disabled {
//   -webkit-text-fill-color: #222222 !important;
// }

.content {
  width: 100%;
  min-height: calc(100vh - 44px);
  padding: 20px;
  background: #f6f6f6;

  // 注册
  .register {
    padding: 20px;
    padding-left: 21px;
    min-height: 930px;
    background: #ffffff;
    border-radius: 6px;
    border: 0.5px solid #e2e2e2;

    .register-top-title {
      position: relative;
      font-size: 14px;
      font-family: Arial-BoldMT, Arial;
      font-weight: 600;
      color: #222222;
      line-height: 20px;
      margin-bottom: 17px;

      span {
        position: absolute;
        width: 30px;
        height: 2px;
        background: #ff730b;
        bottom: -5px;
        left: 0;
      }
    }

    h3 {
      font-size: 12px;
      font-family: ArialMT;
      color: #222222;
      line-height: 18px;
      margin-bottom: 5px;
    }

    p {
      font-size: 10px;
      zoom: 0.8;
      font-family: ArialMT;
      color: #999999;
      line-height: 16px;
      margin-bottom: 20px;
    }

    .registerform {
      :deep().van-cell {
        flex-direction: column;
        min-height: 92px;
        padding: 0px;
        width: 295px;
      }

      :deep() .van-field__body {
        width: 295px;
        box-sizing: border-box;
        position: relative;

        input {
          width: 290px;
          height: 40px;
          background: #ffffff;
          border-radius: 6px;
          border: 0.5px solid #dedede;
          padding-left: 10px;
          font-size: 12px;
          font-family: ArialMT;

          color: #222222;
          line-height: 18px;
        }

        input::placeholder {
          font-size: 12px;
          font-family: ArialMT;
          color: #c0c4cc;
          line-height: 18px;
        }
      }

      // 手机号码
      .mobile {
        :deep().van-field__body {
          position: relative;
          padding-right: 3px;



          display: flex;
          justify-content: flex-end;


          input {
            width: 210px !important;
          }
        }

        :deep() .van-field__left-icon {
          position: absolute;
          top: 26px;
          width: 75px;
          height: 40px;
          background: #ffffff;
          z-index: 55;
          border-radius: 6px;
          border: 0.5px solid #dedede;
          display: flex;
          justify-content: center;
          align-items: center;


          .quhao {
            display: flex;
            align-items: center;
            width: 65px;
            height: 30px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            line-height: 18px;
            border-radius: 4px;
            justify-content: center;
            background: #f6f6f6;

            .van-icon {
              font-size: 12px;
              width: 12px;
              height: 16px;
              // margin-left: 5px;
            }
          }
        }
      }

      // 验证码
      .code {
        .codelabel {
          padding-right: 5px;
          width: 100%;
          display: flex;
          justify-content: space-between;

          .right {
            font-size: 12px;
            font-family: ArialMT;
            color: #ff730b;
            line-height: 18px;
          }
        }

        :deep().van-field__body {
          input {
            padding-left: 85px !important;
          }
        }

        :deep().van-field__left-icon {
          min-width: 70px;
          height: 30px !important;
          background: #f6f6f6;
          position: absolute;
          top: 50%;
          left: 5px;

          z-index: 2;
          width: 70px;
          height: 30px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          line-height: 18px;
          background: #f6f6f6;
          width: 70px;
          height: 30px;
          background: #f6f6f6;
          border-radius: 4px;
          transform: translateY(-50%);
          display: flex;
          align-items: center;
          padding-left: 5px;
          padding-right: 5px;

          .way {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            font-size: 12px;
          }

          .van-icon {
            font-size: 12px;
            width: 12px;
            height: 16px;
            margin-left: 5px;
          }
        }
      }

      :deep() .van-field__right-icon {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;

        right: 15px;

        img {
          width: 18px;
          height: 18px;
        }
      }

      :deep() .van-icon-arrow-down {
        margin-left: 10px;
      }

      // 错误
      .errinput {
        :deep() input {
          background: #ffffff;
          border-radius: 6px;
          border: 1px solid #e74c3c;
        }
      }

      //  正确
      .correctinput {
        :deep() input {
          background: #fff8f3;
          border-radius: 6px;
          border: 1px solid #ff730b;
          -webkit-text-fill-color: #222222 !important;
        }
      }

      // 下一步
      .Consentagreement {
        width: 290px;
        height: 40px;
        font-size: 11px;
        font-family: ArialMT;
        color: #ffffff;
        line-height: 20px;
        background: #608bff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        // zoom: 0.8;
        // letter-spacing: -0.5px;
      }

      .Privacy {
        margin-top: 20px;
        display: flex;
        align-items: center;

        .Privacy-left {
          font-size: 12px;
          font-family: ArialMT;
          color: #222222;
          line-height: 18px;
          display: flex;
          align-items: center;

          :deep().van-checkbox {
            display: flex;
            align-items: center;
            vertical-align: middle;
          }

          .text {
            // margin-left: 5px;
            display: flex;
            align-items: center;
          }

          :deep().van-icon-success {
            display: flex;
            color: #fff;
            // margin-top: -0.5px;
          }

          :deep().van-badge__wrapper {
            display: flex;
            width: 12px;
            height: 12px;
            // border: 2px solid #c8c9cc;
            border-radius: 50%;
          }

          :deep().van-badge__wrapper::after {
            display: flex;
            width: 12px;
            height: 12px;
          }

          :deep().van-checkbox__icon {
            display: flex;
            width: 12px;
            height: 12px;
          }

          :deep().van-icon-success:before {
            width: 12px;
            height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
          }

          :deep().van-checkbox__label {
            display: flex;
            font-size: 12px;
            font-family: ArialMT;
            color: #222222;
            // padding-top: 1px;
            vertical-align: middle;
            margin-left: 5px !important;
            // letter-spacing: -0.5px;
          }

          .agreement {
            font-size: 12px;
            font-family: ArialMT;
            color: #ff730b;
            line-height: 18px;
            letter-spacing: -0.5px;
          }
        }
      }

      :deep().van-icon-success {
        color: #ff730b;
      }

      :deep().van-field__label {
        width: 100%;
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;
        margin-bottom: 8px !important;
      }

      :deep() .van-cell:after {
        border-bottom: none !important;
      }

      :deep().van-field__error-message {
        padding-top: 2px;
        -webkit-text-size-adjust: none;
        font-size: 10px !important;
        line-height: 15px;
        // height: 45px;
        zoom: 0.9;
      }

      :deep().van-cell-group {
        margin: 0px;
      }
    }
  }

  // 注册成功
  .registersuccess {
    padding: 20px;

    height: 318px;
    background: #ffffff;
    border-radius: 6px;
    border: 0.5px solid #e2e2e2;

    border: 0.5px solid #e2e2e2;

    .register-top-title {
      position: relative;
      font-size: 14px;
      font-family: Arial-BoldMT, Arial;
      font-weight: 600;
      color: #222222;
      line-height: 20px;
      margin-bottom: 17px;

      span {
        position: absolute;
        width: 30px;
        height: 2px;
        background: #ff730b;
        bottom: -5px;
        left: 0;
      }
    }

    .img-box {
      width: 48px;
      height: 48px;
      margin: auto;
      margin-top: 40px;

      margin-bottom: 20px;

      img {
        width: 48px;
        height: 48px;
      }
    }

    h3 {
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-family: ArialMT;
      color: #222222;
      line-height: 20px;
      margin-bottom: 5px;
    }

    p {
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-family: ArialMT;
      color: #999999;
      line-height: 18px;
      margin-bottom: 40px;
    }

    .gologin {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      font-size: 14px;
      font-family: ArialMT;
      color: #ffffff;
      line-height: 20px;
      background: #608bff;
      border-radius: 6px;
    }
  }
}
</style>
