<template>
  <div class="headbarbox">

    <!-- 顶部图片 -->
    <div class="top-img" v-if="showtopimg">
      <img src="../../assets/index/logo-rakumart.svg" alt="" @click="$fun.toPage('/')">

    </div>


    <!-- 搜索框盒子 -->
    <div class="search-box">
      <!-- 左侧图标 -->
      <div class="lefticon" v-if="lefticon">

        <van-icon @click="$fun.toPage('/')" name="arrow-left" />

      </div>
      <!-- 搜索盒子 -->
      <div class="input-box">

        <input @focus="setshowSearchBtn(true)" @keydown.enter="search" @blur="setshowSearchBtn(false)" type="text"
          v-model.trim="$store.state.searchInfo" :placeholder="$fanyi('请输入关键词、1688URL或通过商品图片搜索')">
        <!-- 搜索图标 -->
        <img class="serchicon" src="../../assets/index/search.svg" alt="" @click="search">
        <!-- 图片上传 -->
        <img class="upload" src="../../assets/index/upload.svg" alt="" @click="clickUpload">
        <!-- 搜索 -->
        <div class="searchBtn" :class="{ hideSearchBtn: !showSearchBtn }" @click="search">
          <span v-if="showSearchBtn"> {{ $fanyi('搜索') }}</span>
        </div>

        <!-- 上传控件 -->
        <input style="display: none" type="file" id="myFileInput" @change="uploadFile()" />



      </div>

    </div>


  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from 'vue'

import { showLoadingToast, closeToast, showToast } from 'vant'
const { proxy } = getCurrentInstance()
console.log(proxy);
/* eslint-disable*/
const props = defineProps({
  // 是否显示顶部照片
  showtopimg: {
    default: true,
  },
  // 是否显示左侧图标
  lefticon: {
    default: true,
  }
})

const showSearchBtn = ref(false) //是否显示确认搜索按钮
// 控制搜索按钮显示
const setshowSearchBtn = (status) => {
  if (!status) {
    setTimeout(() => {
      showSearchBtn.value = status;
    }, 500);
  } else {
    showSearchBtn.value = status;
  }
}
// 点击搜索按扭搜索
const search = () => {

  // 如果搜索的是同一词条就刷新页面
  if (proxy.$store.state.searchInfo == proxy.$route.query.keyword) {
    proxy.$router.go(0);
    return false;
  }
  // 如果关键词为空就不跳转
  if (!proxy.$store.state.searchInfo) {
    showToast(proxy.$fanyi("请输入关键词搜索"));
    return false;
  }
  // 跳转
  proxy.$fun.routerToPage(
    "/CommoditySearch?keyword=" +
    proxy.$store.state.searchInfo
  );



}
// 点击上传图片
const clickUpload = () => {

  document.querySelector("#myFileInput").click();
}
const uploadFile = () => {
  var input = document.getElementById("myFileInput");
  var file = input.files[0];
  var url = "https://laravel.rakumart.us/api/common/uploadFile";
  const isJPG =
    ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
  showLoadingToast({
    duration: 0,
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
    loadingType: "spinner",
  });
  if (!isJPG) {
    showToast(proxy.$fanyi("请上传图片"));
    closeToast();
    return isJPG;
  }

  var formData = new FormData();
  formData.append("file", file);

  var xhr = new XMLHttpRequest();

  xhr.open("POST", url, true);
  xhr.onload = () => {
    if (xhr.status === 200) {
      const res = JSON.parse(xhr.responseText);
      successup(res.data);
      proxy.$store.commit("getsearchImg", res.data);

    } else {
      console.log("An error occurred while uploading the file.");
    }
  };
  xhr.send(formData);
}
// 上传拿到文件对象
// 图片上传成功事件 
const successup = (val) => {

  var img = new Image();
  img.src = val
  img.crossOrigin = 'Anonymous'; // 如果图片需要跨域访问，可以设置这个属性
  img.onload = function () {
    let width = img.width;
    let height = img.height;
    if (width > height) {
      if (width > 200) {
        height *= 200 / width;
        width = 200;
      }
    } else {
      if (height > 200) {
        width *= 200 / height;
        height = 200;
      }
    }
    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);
    var base64 = canvas.toDataURL('image/png', 0.5); // 可以改为image/jpeg，image/webp等其他格式
    let index = base64.indexOf('base64,');
    let base64String = base64.substr(index + 7, base64.length);
    let datas = {
      url: '/1/com.alibaba.fenxiao.crossborder/product.image.upload',
      uploadImageParam: JSON.stringify({
        imageBase64: base64String
      })
    }
    proxy.$api.uploadImagePic(datas).then(res => {
      closeToast();
      if (proxy.$route.name == "commoditySearch") {
        proxy.$fun.routerToPage(
          "/commoditySearch?type=imgSearch&imgUrl=" + val + '&imageId=' + res.data.result
        );
      } else {
        proxy.$fun.routerToPage(
          "/commoditySearch?type=imgSearch&imgUrl=" + val + '&imageId=' + res.data.result
        );
      }

    })
  };
}
</script>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.headbarbox {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 50px;
  padding-bottom: 10px;
  background-color: white;
  z-index: 10;

  .top-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;

    img {
      width: 120px;
      height: 30px;
    }
  }

  .search-box {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    height: 40px;

    .lefticon {
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-right: 10px;

      :deep() .van-icon-arrow-left {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .input-box {
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;
      height: 40px;

      input {
        padding-left: 40px;
        width: 100%;
        height: 100%;
        font-size: 16px;
        border-radius: 6px;
        border: 2px solid #FF730B;
        padding-right: 40px;
      }

      input::placeholder {

        font-size: 10px;
        font-family: ArialMT;
        color: #C0C4CC;
        // zoom: 0.98;
        line-height: 16px;
      }

      .serchicon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
      }

      .upload {
        position: absolute;
        right: 10px;
        top: 50%;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
      }

      @keyframes open {
        0% {
          width: 0px;

        }

        100% {
          width: 60px;

        }
      }

      @keyframes hide {
        0% {
          width: 60px;

        }

        100% {
          width: 0px;

        }
      }

      .searchBtn {
        background-color: transparent;
        border: none;
        position: absolute;
        width: 0px;
        right: 4px;

        height: 32px;
        top: 50%;
        bottom: none;
        transform: translateY(-50%);
        font-size: 12px;
        font-family: ArialMT;
        color: #FFFFFF;
        // line-height: 18px;
        display: flex;
        // display: none;
        justify-content: center;
        align-items: center;
        background: #FF730B;
        border-radius: 4px;
        white-space: nowrap;
        animation: open 0.6s 0s forwards;


      }

      .hideSearchBtn {
        animation: hide 0.6s 0s;
      }
    }
  }
}
</style>