<template>
  <!--后续操作组件  -->
  <div class="subsequentoperations">
    <div class="top-title">
      <h2>{{ $fanyi('后续怎么操作?') }}</h2>
      <div @click="show = true" class="why"><img src="../../../assets/tichuorder/why.svg" alt="">
        <p>{{ $fanyi('为什么现在估计装运?') }}</p>
      </div>
    </div>

    <!-- 联系方式 -->
    <div class="item">
      <div class="left">

        1

      </div>
      <div class="right">
        <h2>{{ $fanyi('我们联系制造商') }}</h2>
        <p>{{ $fanyi('我们联系制造商确认必要的信息，并能够估计产品的运输成本。') }}</p>
      </div>
    </div>
    <div class="item">
      <div class="left">

        2


      </div>
      <div class="right">
        <h2>{{ $fanyi('你会在24小时内收到信息') }}</h2>
        <p>{{ $fanyi('我们通知您所有类型的估计费用 海运(空运、海运和陆运)。') }}</p>
      </div>

    </div>
    <div class="item">
      <div class="left">

        3


      </div>
      <div class="right">
        <h2>{{ $fanyi('产品的付款') }}</h2>
        <p>{{ $fanyi('一旦知道估计的运输成本，您可以正式购买您的订单的产品。') }}</p>
      </div>
    </div>
  </div>
  <van-dialog id="dialong" v-model:show="show" show-cancel-button>
    <!-- default	自定义内容 title	自定义标题footer -->
    <template #title>
      <span>{{
        $fanyi("为什么现在估计装运?") }}</span>


      <img @click="show = false" src="../../../assets/cart/close.svg" alt="" />
    </template>
    <template #default>
      <p>{{ $fanyi('为了提供最好的进口报价，一旦产品到达我们的仓库，就会计算出准确的运费和其他费用报价，所以现在你只支付产品。') }}</p>
      <p>{{ $fanyi('由于货物的分组和其他数据，我们可以提供一个更好的最终报价的运输。') }}</p>
      <p>{{ $fanyi('这一细分包括产品的质量控制、合同规定的额外服务，以及在适当情况下合同规定的其他费用。') }}</p>
    </template>

    <template #footer>
      <div class="cofim" @click="show = false">{{ $fanyi('确认') }}</div>
    </template>
  </van-dialog>
</template>
<script setup>
import { getCurrentInstance, ref } from 'vue'
const { proxy } = getCurrentInstance()
console.log(proxy);
const show = ref(false)
</script>
<style lang="scss">
#dialong {
  width: 320px;
  min-height: 210px;


  border-radius: 8px;

  .van-dialog__header {

    display: flex;
    align-items: center;
    padding: 10px 20px;
    justify-content: space-between;
    min-height: 50px;
    border-bottom: 0.5px solid #DBDBDB;


    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      text-align: left;
      line-height: 20px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  .van-dialog__content {
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    // zoom: 0.9;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 18px;
    padding-bottom: 20px;
  }

  .cofim {
    width: 120px;
    height: 40px;
    margin: auto;
    background: #FF730B;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
    margin-bottom: 30px;
  }
}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.subsequentoperations {
  width: 100%;

  min-height: 254px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 0.5px solid #E2E2E2;
  padding: 15px;
  margin-bottom: 10px;

  .top-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    h2 {
      font-size: 12px;
      font-family: PingFang-SC-Semibold, PingFang-SC;
      font-weight: 600;
      color: #222222;
      line-height: 18px;
    }

    .why {
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
        width: 10px;
        height: 10px;

      }

      font-size: 10px;
      font-family: PingFang-SC-Regular,
      PingFang-SC;
      font-weight: 400;
      color: #FF730B;
      line-height: 14px;
    }

  }

  .item {

    width: 325px;
    min-height: 68px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 0.5px solid #E2E2E2;
    display: flex;


    align-items: center;
    padding: 15px 15px;
    margin-bottom: 15px;

    .left {
      flex: 0 0 30px;
      height: 30px;
      background: #FFFFFF;
      border: 0.5px solid #E2E2E2;
      display: flex;
      justify-content: center;
      font-size: 10px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #222222;
      line-height: 14px;
      border-radius: 50%;
      align-items: center;
      margin-right: 10px;
    }


    .right {
      h2 {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 18px;
        margin-bottom: 5px;
      }

      p {
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
      }
    }


  }
}
</style>