<template>
  <van-popover :offset="[0, -6]" v-model:show="showPopover" :show-arrow="showarrow">
    <slot name="obody"></slot>
    <template #reference>
      <slot name="btn"></slot>
    </template>
  </van-popover>
</template>
<script setup>

import { ref, defineProps, defineExpose } from 'vue'
const showPopover = ref(false)

defineProps({
  showarrow: {
    default: true,
  },

})

const open = () => {
  showPopover.value = true
}
defineExpose({
  open
})
</script>
<style></style>
<style lang='scss' scoped></style>
