<template>
  <div class="goodsDetailsHeader" :style="{ background: bgcolor }" :class="{ zidingyiheadText: iCustomizdde }">

    <van-icon v-if="!icon" name="arrow-left" class="headerReturnOperationContainer" @click="goBack" />
    <slot v-else name="left-icon">
      <!-- 页面头部 -->
    </slot>
    <div class="headBox" :class="{ flexcenter: flexcenter }">
      <slot></slot>
    </div>
    <!-- 右侧 -->

    <slot name="right-icon">

    </slot>
  </div>
</template>
<script>
export default {
  props: {
    // 这个参数表示是否自定义左侧图标,false为自定义
    icon: {
      default: false,
    },
    // 这个参数表示是否自定义内容,false为自定义
    iCustomizdde: {
      default: false,
    },
    // 自定义点击事件
    myClick: {
      default: null,
    },
    // 居中还是靠左
    flexcenter: {
      default: false,
    },
    bgcolor: {
      default: '#F6F6F6',
    }
  },
  name: "HeadGoBackVue",
  data() {
    return {};
  },
  methods: {
    goBack() {
      if (this.myClick) {
        return this.myClick();
      }
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
// .dianzi {
//   height: 100px;
// }
.goodsDetailsHeader {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 44px;
  justify-content: space-between;
  padding: 0 18px;
  align-items: center;
  position: sticky;
  z-index: 10;
  font-size: 16px;
  font-family: PingFang-SC-Semibold, PingFang-SC;
  font-weight: 600;
  color: #030303;
  line-height: 22px;
  top: 0;
  left: 0;

  background: #F6F6F6;

  .headBox {
    position: relative;
    width: 100%;
    flex: 1;

    span {
      font-size: 16px !important;
      font-family: PingFang-SC-Semibold, PingFang-SC;
      font-weight: 600;
      color: #030303;
      line-height: 22px;
    }

  }


  .flexcenter {

    text-align: center;
    padding-right: 26px;
  }
}




.rightBox {
  width: 100px;
}

:deep().van-icon-arrow-left {
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  color: #333333;
  font-weight: 600;
  margin-right: 10px;
  margin-left: -2px;
}
</style>
