<!-- 首页小分类商品模块 -->
<template>
  <div v-for="bigItem in goodsList" :key="bigItem.id" class="commodityclassification">
    <div class="shangPinFenLei" :style="'background-color:' + bigItem.default_back_color">
      <div class="title">
        <span>{{ bigItem.translate_name }} </span>
        <img @click="
          $fun.routerToPage(
            '/CommoditySearch?keyword=' +
            bigItem.translate_name +
            '&CN=' +
            $fun.toCode(bigItem.chinese_name)
          )
          " :src="require('../../../assets/index/flechacategoriasWhite.svg')" alt="" />
      </div>
    </div>
    <div class="goodsList">

      <div class="shopitem" v-for="goodsItem in bigItem.goods" :key="goodsItem.id" @click="
        $fun.toCommodityDetails({
          goods_id: goodsItem.goods_id,
          shop_type: goodsItem.shop_type,
        })">
        <div class=" img-box">
          <van-image :src="goodsItem.imgUrl" lazy-load>
            <template v-slot:loading>
              <van-loading type="spinner" size="30" />
            </template>
          </van-image>
        </div>
        <!-- 商品描述 -->
        <div class="text">{{ goodsItem.title }}</div>
        <!-- 价格 -->
        <div class="price">
          <span class="dollar">
            ${{
              $fun.EURNumSegmentation(
                $fun.ceil(
                  goodsItem.goodsPrice * $store.state.exchangeRate
                )
              )
            }}

          </span>
          <span class="RMB">￥{{ $fun.RMBNumSegmentation(goodsItem.goodsPrice) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
const { proxy } = getCurrentInstance()

const goodsList = ref([])//商品分类列表



// 获取推荐商品数据
const getRecommendedGoods = () => {
  proxy.$api.getRecommendedGoods().then((res) => {
    goodsList.value = [];
    let alldata = res.data;
    // 将所有二级分类里的子数据全部放到一个数组里返回
    alldata.forEach((item) => {
      goodsList.value.push({
        translate_name: item.translation_name,
        default_back_color: item.default_back_color,
        chinese_name: item.chinese_name,
        goods: (() => {
          let goodsList = [];
          item.childs.forEach((childsItem) => {
            // 2023/2/23 因为随机取对浏览器加载速度不友好(图片需要一直加载,固定的只要加再一次就好了)并且客户想要返回首页购买商品时会找不到,所以这里取每个耳机分类前两个商品,不随机取
            goodsList.push(...childsItem.resources.slice(0, 2));
          });
          return goodsList;
        })(),
      });
    });
  });
}
getRecommendedGoods()
</script>


<style lang="scss" scoped="scoped">
@import "@/style/scss/mixin.scss";


.commodityclassification {
  width: 100%;
  height: 274px;
  margin-bottom: 30px;
  position: relative;
}

.shangPinFenLei {
  width: 375px;
  height: 120px;
  // background: #FFB517;
  border-radius: #ffff;
  border-radius: 8px 8px 0px 0px;

  .title {
    width: 100%;
    height: 64px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 16px;
      font-family: ArialMT;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 24px;
    }

    img {
      width: 24px;
      height: 24px;
    }

  }



  span {
    height: 34px;
    font-size: 34px;
    font-weight: 400;
    color: #ffffff;
  }

  img {
    width: 42px;
    height: 42px;
  }
}

.goodsList {
  position: absolute;
  left: 0;
  width: 100%;
  height: 210px;
  top: 64px;
  padding-left: 10px;
  display: flex;
  overflow-x: scroll;



  .shopitem {

    flex: 0 0 140px;
    width: 140px;
    height: 210px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 0.5px solid #E2E2E2;
    margin-right: 10px;
    padding: 10px;


    .img-box {
      width: 120px;
      height: 120px;
      background: #FFFFFF;
      margin-bottom: 10px;

      :deep().van-image {
        width: 120px;
        height: 120px;
      }
    }

    .text {
      height: 36px;
      font-size: 12px;
      font-family: ArialMT;
      color: #222222;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      margin-bottom: 5px;
    }

    .price {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      height: 20px;
      align-items: center;
      line-height: 20px;


      .dollar {
        font-size: 14px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #FF730B;
        // line-height: 20px;

      }

      .RMB {

        margin-left: 5px;
        font-size: 12px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #FF730B;
        // line-height: 18px;
      }
    }

  }
}
</style>
