<template>
  <!-- 底部tarber -->
  <div class="footer">
    <!-- 上面分类 -->
    <div class="top-classification">
      <h2>
        <span>{{ $fanyi('RAKUMART') }}</span>
        <span @click.stop="$fun.toPage('/commission')">{{ $fanyi('费用相关') }}</span>
      </h2>
      <p>
        <!-- 公司简介 -->

        <span @click="$fun.toPage('/company-introduction')">{{ $fanyi('公司简介') }}</span>
        <!-- 委员会 -->

        <span>{{ $fanyi('佣金') }}</span>
      </p>
      <p>
        <!-- 关于我们 -->
        <span @click="$fun.toPage('/about-us')">{{ $fanyi('关于我们') }}</span>
        <!-- 附加服务 -->
        <span @click="$fun.toPage('/additional-services')">{{ $fanyi('附加服务') }}</span>
      </p>
      <p>
        <!--  !用户指南 -->
        <span @click="$fun.toPage('/user-guide')">{{ $fanyi(' 用户指南') }}</span>
        <!-- 国际运输中 -->
        <span @click="$fun.toPage('/international-freight')">{{ $fanyi('国际物流') }}</span>
      </p>
      <p>
        <!--  !常见问题 -->
        <span @click="$fun.toPage('/frequently-asked-questions')">{{ $fanyi('常见问题') }}</span>

        <!-- 摄影服务 -->
        <span @click="$fun.toPage('/photography-services')">{{ $fanyi('摄影服务') }}</span>
      </p>
      <p style="margin-bottom: 30px;"> <span @click="$fun.toPage('/video-maintenance')">{{ $fanyi('视频维护')
      }}</span></p>
      <!-- 登录按钮 -->
      <div class="login" v-if="$store.state.userInfo == null">
        <span @click="$fun.routerToPage('/login')">{{ $fanyi('登录') }}</span>
        <i>/</i>
        <span @click="$fun.routerToPage('/register')">{{
          $fanyi('注册')
        }}</span>
      </div>
    </div>
    <!-- 中间 -->
    <div class="classification">
      <h2><span>{{ $fanyi('Customer focus') }}</span><span>{{ $fanyi('支持') }}</span></h2>
      <p>
        <span>service@rakumart.us</span>

        <!-- ! 服务条款 -->
        <span @click="$fun.toPage('/terms-of-service')">{{ $fanyi(' 服务条款') }}</span>
      </p>
      <p>
        <span>{{ $fanyi('工作日上午 9:00 至下午 5:00') }}</span>
        <!-- ! 隐私政策 -->
        <span @click="$fun.toPage('/privacypolicy')">{{ $fanyi(' 隐私政策') }}</span>
      </p>

      <!-- 图片盒子 -->
      <img src="../../assets/index/bank.png" alt="">
    </div>
    <!-- 底部 -->
    <div class="foot">
      <div class="left">
        ©Rakumart 2022
      </div>
      <div class="right">



        <a href="https://usa.pingpongx.com/index" target=_blank> <img src="../../assets/index/pingpong.svg" alt="" /></a>

        <a href="https://www.facebook.com/" target=_blank> <img src="../../assets/index/facebook.svg" alt="" /></a>

        <a href="https://www.instagram.com/" target=_blank> <img src="../../assets/index/instagram.svg" alt=""></a>

        <a href="https://www.linkedin.com/" target=_blank> <img src="../../assets/index/Twitter.svg" alt=""></a>
        <a href="https://www.tiktok.com/" target=_blank> <img src="../../assets/index/tik-tok.svg" alt="" /></a>
        <a href="https://www.youtube.com/" target=_blank> <img src="../../assets/index/youtube.svg" alt="" /></a>


      </div>

    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance } from 'vue'
const { proxy } = getCurrentInstance()
console.log(proxy)
</script>
<style lang="scss" scoped="scoped">
@import '@/style/scss/mixin.scss';

.footer {
  width: 100%;
  min-height: 383px;
  background: #ffffff;
  padding-left: 20px;
  padding-top: 40px;
  padding-right: 20px;

  .top-classification,
  .classification {
    width: 100%;

    h2 {
      display: flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      span {
        &:first-child {
          width: 160px;
          font-size: 12px;
          font-family: Arial-BoldMT, Arial;
          font-weight: 700;
          color: #222222;
          line-height: 16px;
        }

        &:last-child {
          width: 128px;

          font-size: 12px;
          font-family: Arial-BoldMT, Arial;
          font-weight: 700;
          color: #222222;
          line-height: 16px;
        }
      }
    }

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      span {
        &:first-child {
          width: 170px;
          font-size: 12px;
          font-family: ArialMT;
          color: #666666;
          line-height: 16px;
        }

        &:last-child {
          width: 128px;

          font-size: 12px;
          font-family: ArialMT;
          color: #666666;
          line-height: 16px;
        }
      }
    }

    .login {
      width: 160px;
      height: 40px;
      background: #ff730b;
      border-radius: 6px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: ArialMT;
      // margin-top: 25px;
      color: #ffffff;
      line-height: 20px;

      span {
        &:first-child {
          margin-right: 3px;
        }

        &:last-child {
          margin-left: 3px;
        }
      }
    }
  }

  .classification {
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 0.5px solid #DBDBDB;

    img {
      margin-top: 25px;
      width: 328px;
      height: 20px;
    }
  }

  .foot {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      font-size: 10px;
      font-family: ArialMT;
      color: #666666;
      line-height: 14px;
    }

    .right {


      a {
        margin-right: 10px;

        img {
          width: 24px;
          height: 24px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

  }
}
</style>
