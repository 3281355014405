<template>
  <!-- 商品推荐 -->
  <div class="recommendedgoods">
    <!-- 标题 -->
    <div class="top-title">
      <div class="left">
        {{ $fanyi('推荐商品') }}
      </div>
      <div class="rightnext">
        <!-- 上一页 -->
        <span class="Previouspage" @click="Previouspage">
          <img src="../../../assets/index/left.svg" alt="">
        </span>
        <!-- 下一页 -->
        <span class="Nextpage" @click="Nextpage">
          <img src="../../../assets/index/right.svg" alt="">
        </span>

      </div>




    </div>

    <!-- 推荐商品列表 -->
    <div class="goodlist">
      <van-swipe ref="swiper" :show-indicators="false" :touchable="false" class="my-swipe" indicator-color="white"
        lazy-render>
        <van-swipe-item v-for="(item, indx) in resultlist" :key="indx">
          <div class="shopitem" v-for="goodsItem in item" :key="goodsItem.id" @click="
            $fun.toCommodityDetails({
              goods_id: goodsItem.goods_id,
              shop_type: goodsItem.shop_type,
            })">
            <div class=" img-box">
              <van-image :src="goodsItem.imgUrl" lazy-load>
                <template v-slot:loading>
                  <van-loading type="spinner" size="30" />
                </template>
              </van-image>
            </div>
            <!-- 商品描述 -->
            <div class="text">{{ goodsItem.title }}</div>
            <!-- 价格 -->
            <div class="price">


              <span class="dollar">
                ${{
                  $fun.EURNumSegmentation(
                    $fun.ceil(
                      goodsItem.goodsPrice * $store.state.exchangeRate
                    )
                  )
                }}

              </span>
              <span class="RMB">￥{{ $fun.RMBNumSegmentation(goodsItem.goodsPrice) }}</span>
            </div>

          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from 'vue'
import { showFailToast } from 'vant'
const { proxy } = getCurrentInstance()

console.log(proxy);
// swiper实例
const swiper = ref('')

const resultlist = ref([])// 推荐商品数组

function groupArrays(arr) {
  const result = [];
  for (let i = 0; i < arr.length; i = i + 3) {
    const group = [arr[i], arr[i + 1], arr[i + 2]].filter(Boolean);
    // 上述代码等价于： const group = [ arr[i], arr[i+1], arr[i+2] ].filter(item => item !== undefined && item !== null);
    result.push(group);
  }
  return result;
}
const getPersonalizedGoods = async () => {
  const res = await proxy.$api.getPersonalizedGoods()
  if (res.code !== 0) {
    proxy.$fanyi(showFailToast(res.msg))
    return
  }
  resultlist.value = groupArrays(res.data.result)


}
getPersonalizedGoods()
// 点击上一页
const Previouspage = () => {
  console.log('111');
  swiper.value.prev()
}
// 点击下一页
const Nextpage = () => {
  swiper.value.next()

}
</script>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.recommendedgoods {
  width: 100%;
  height: 254px;
  margin-bottom: 30px;

  .top-title {
    width: 100%;
    display: flex;

    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;

    .left {
      padding-left: 10px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }

    .rightnext {
      display: flex;
      align-items: center;
      padding-right: 10px;

      // 上一步
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;

        img {
          width: 24px;
          height: 24px;

        }

      }

      // .Previouspage {}

      // 下一步
      .Nextpage {
        margin-left: 20px;

      }

    }
  }

  .goodlist {
    // display: flex;
    // padding-left: 10px;
    // // width: 375px;
    // overflow-x: scroll;
    // // overflow-y: hidden;
    // white-space: nowrap;

    .my-swipe {

      :deep().van-swipe-item {
        display: flex;
        overflow-x: scroll;

        padding-left: 10px;

      }

      .shopitem {

        flex: 0 0 140px;
        width: 140px;
        height: 210px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 0.5px solid #E2E2E2;
        margin-right: 10px;
        padding: 10px;


        .img-box {
          width: 120px;
          height: 120px;
          background: #FFFFFF;
          margin-bottom: 10px;

          :deep().van-image {
            width: 120px;
            height: 120px;
          }
        }

        .text {
          height: 36px;
          font-size: 12px;
          font-family: ArialMT;
          color: #222222;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          margin-bottom: 5px;
        }

        .price {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 20px;
          display: flex;
          align-items: center;
          line-height: 20px;

          .dollar {
            font-size: 14px;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            color: #FF730B;

            // line-height: 2px;

          }

          .RMB {

            margin-left: 5px;
            font-size: 12px;
            font-family: PingFang-SC-Semibold, PingFang-SC;
            font-weight: 600;
            color: #FF730B;
            // line-height: 18px;
          }
        }

      }
    }

  }
}
</style>