import {
  createRouter,
  createWebHistory
} from 'vue-router';
import
api
  from '../api/apis'

import store from '../store/index.js'



const routes = [

  // 首页
  {
    path: '/',
    name: 'homepage',
    meta: {
      keepAlive: true,
    },
    component: () => import('../view/homepageIndex/index.vue'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../view/userCenter/index.vue'),
    meta: {
      requireAuth: true,
    },
    children: [
      // 用户-首页
      {
        path: '/user/index',
        name: 'userIndex',
        component: () => import('../view/userCenter/views/userIndex/index.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'Rakumart Control Panel/Personal Center',
        },
      },
      // 用户-订单页面
      {
        path: '/user/order',
        name: 'userOrder',
        component: () =>
          import('../view/userCenter/views/userOrder/index.vue'),
      },
      // ! 配送单页面 '/user/wuliuStatus'    wuliuStatus
      {
        path: '/user/wuliuStatus',
        name: 'wuliuStatus',
        component: () =>
          import('../view/userCenter/views/userdistributionorder/index.vue'),
      },
      // 用户-问题产品
      {
        path: '/user/issueOrder',
        name: 'issueOrder',
        component: () =>
          import('../view/userCenter/views/issueOrder/index.vue'),
      },

    
      // 用户-问题产品详情
      {
        path: '/issueOrderDetail',
        name: 'issueOrderDetail',
        component: () =>
          import('../view/userCenter/views/issueOrder/view/issueOrderdetails/index.vue'),
      },


      // 用户-仓库页面
      {
        path: '/user/warehouse',
        name: 'warehouse',
        component: () =>
          import('../view/userCenter/views/warehouse/index.vue'),
      },

      // 用户-出入金记录页面
      {
        path: '/user/churujinList',
        name: 'churujinList',
        component: () =>
          import('../view/userCenter/views/churujinList/index.vue'),
      },
      // 用户-提现记录页面
      {
        path: '/user/WithdrawalRecord',
        name: 'WithdrawalRecord',
        component: () =>
          import('../view/userCenter/views/churujinList/views/WithdrawalRecord.vue'),
      },
      // 用户-收藏夹产品页面
      {
        path: '/user/collection',
        name: 'collection',
        component: () =>
          import('../view/userCenter/views/collection/index.vue'),
      },
      // // 用户-收藏夹商店页面
      {
        path: '/user/favoritec',
        name: 'favoritec',
        component: () =>
          import('../view/userCenter/views/favoritec'),
      },
      //
      // // 用户-收藏夹商品库列表页面
      {
        path: '/user/commodity',
        name: 'commodity',
        component: () =>
          import('../view/userCenter/views/commodity'),
      },
      // 用户-登录信息页面
      {
        path: '/user/userDetail',
        name: 'userDetail',
        component: () =>
          import('../view/userCenter/views/userDetail/index.vue'),
      },
      // 用户-修改基本信息页面
      {
        path: '/user/basicInformationUpdatePage',
        name: 'basicInformationUpdatePage',
        component: () =>
          import('../view/userCenter/views/userDetail/components/basicInformationUpdatePage'),
      },
      // 用户-修改手机与邮箱验证页面
      {
        path: '/user/changepassworld',
        name: 'changepassworld',
        component: () =>
          import('../view/userCenter/views/userDetail/components/updateInformationValidationPage'),
      },
      //用户-地址列表页面
      {
        path: '/user/deliverySite',
        name: 'deliverySite',
        component: () =>
          import('../view/userCenter/views/deliverySite/index.vue'),
      },
      //用户-编辑地址页面
      {
        path: '/user/deliverySiteEdit',
        name: 'deliverySiteEdit',
        component: () =>
          import('../view/userCenter/views/deliverySite/components/index.vue'),
      },
   
      // 用户-优惠券
      {
        path: '/user/coupon',
        name: 'coupon',
        component: () => import('../view/userCenter/views/coupon/index.vue'),
      },
      // 用户-优惠券使用记录
      {
        path: '/user/couponUsageRecord',
        name: 'couponUsageRecord',
        component: () => import('../view/userCenter/views/coupon/couponUsageRecord.vue'),
      },
      //
      // 用户-通知
      {
        path: '/user/inform',
        name: 'inform',
        component: () => import('../view/userCenter/views/inform'),
      },
      /* 用户提出提现页面*/
      {
        path: '/user/withdrawDeposit',
        name: 'withdrawDeposit',
        component: () => import('../view/userCenter/views/withdrawDeposit/index.vue'),
        meta: {

          temporary: ' Details for Co-signing an Order',
        },
      },
    ],
  },
  /* 登录页面*/
  {
    path: '/login',
    name: 'login',
    component: () => import('../view/login/login.vue'),
    meta: {
      title: ' Sign in to Rakumart Interface',
      keepAlive: true,
    },
  },
  /* 注册页面*/
  {
    path: '/register',
    name: 'register',
    component: () => import('../view/login/register.vue'),
    meta: {
      tagtitle: 'Rakumart - Register an account on RAKUMART',
    },
  },
  // 注册成功页面
    
     {
      path: '/registeredSuccess',
      name: 'registeredSuccess',
      component:()=> import ('../view/login/registeredSuccess.vue')
    },
  // 忘记密码页面
  {
    path: '/forgetThePassword',
    name: 'forgetThePassword',
    component: () => import('../view/login/ForgetThePassword.vue'),
  },
  /* 商品搜索页*/
  {
    path: '/commoditySearch',
    name: 'commoditySearch',
    component: () => import('../view/commoditySearch/index.vue'),
    meta: {
      title: 'Rakumart - Outdoor Sports Products',
    },
  },

  /* 商品详情*/
  {
    path: '/productDetails',
    name: 'productDetails',
    component: () => import('../view/productDetails/index.vue'),
    meta: {

    },
  },
  // !shopdetails  shopGoods
  {
    path: '/shopdetails',
    name: 'shopdetails',
    component: () => import('../view/shopdetails/index.vue'),

  },
  /* 购物车列表*/
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../view/cart/index.vue'),
    meta: {

      tagtitle: 'Shopping Cart',
    },
  },
  /* 提出订单页面*/
  {
    path: '/placeanorder',
    name: 'placeanorder',
    component: () => import('../view/placeanorder/index.vue'),
    meta: {
      requireAuth: true,
      temporary: ' Details for Co-signing an Order',
    },
  },

  /* 提出订单成功页面*/
  {
    path: '/successpage',
    name: 'successpage',
    component: () => import('../view/successpage/index.vue'),
    meta: {
      requireAuth: true,

    },
  },
  // 订单详情 页面
  {
    path: '/orderdetails',
    name: 'orderdetails',
    component: () =>
      import('../view/orderdetails/index.vue'),

    meta: {
      requireAuth: true,
      tagtitle: 'Order Details',
      temporary: ' Details for Co-signing an Order',
      obligation: 'Continuing Payment for Product',
    },
  },
  /*配送单详情*/
  {
    path: '/deliveryDetails',
    name: 'deliveryDetails',
    component: () =>
      import(
        '../view/deliveryorderdetails/index.vue'

      ),
    meta: {
      requireAuth: true,
    }
  },
  /* 订单支付*/
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../view/payment/index.vue'),
    meta: {
      tagtitle: 'Rakumart payment interface',
      requireAuth: true,
    },
  },

  // 支付失败页面

  {
    path: '/paypalPaymentError',
    name: 'paypalPaymentError',
    component: () => import('../view/paypalPaymentError/index.vue'),
    meta: {
      tagtitle: 'Rakumart payment interface',
      requireAuth: true,
    },
  },

  // 附加服务
  {
    path: '/additional-services',
    name: 'Additional services',
    component: () =>
      import(/* webpackChunkName: "additionalservices" */ '../view/staticpage/additionalservices.vue'),
    meta: {
      tagtitle: 'Rakumart - Additional Services',
      tagdescription:
        'Rakumart offers a range of additional services to enhance your experience, including FBA labeling, product photography, ODM customization, translation, and more',
    },
  },

  /* 公司介绍*/
  {
    path: '/company-introduction',
    name: 'company-introduction',
    component: () =>
      import(/* webpackChunkName: "companyintroduction" */ '../view/staticpage/companyintroduction.vue'),
  },
   /* 关于我们*/
   {
    path: '/about-us',
    name: 'about-us',
    component: () =>
      import(
        /* webpackChunkName: "about-us" */ '../view/staticpage/aboutus.vue'
      ),
    meta: {
      tagtitle: 'Rakumart - Making trade more convenient.',
      tagdescription:
        'Streamline your China purchasing and importing process while optimizing costs. Let us handle everything for you.',
    },
  },
     // /* 国际运费 物流*/
  {
    path: '/international-freight',
    name: 'international-freight',
    component: () =>
      import(
        /* webpackChunkName: "international-freight" */ '../view/staticpage/internationalfreight.vue'
      ),
    meta: {
      tagtitle:
        'Rakumart Logistics and Shipping - Let us handle everything for you.',
      tagdescription:
        "Transform the way you shop and import from China with Rakumart's comprehensive resources and services.",
    },
  },
  /* 摄影服务*/
  {
    path: '/photography-services',
    name: 'PhotographyServices',
    component: () =>
      import(
        /* webpackChunkName: "foot" */ '../view/staticpage/PhotographyServices.vue'
      ),
  },
    /*佣金*/
    {
      path: '/commission',
      name: 'commission',
      component: () =>
        import(
          /* webpackChunkName: "committee" */ '../view/staticpage/commission.vue'
        ),
      meta: {
        tagtitle: 'Rakumart Commission - Transparent rates, abundant value.',
      },
  },
      // 用户指南
  {
    path: '/user-guide',
    name: 'userGuide',
    component: () => import('../view/staticpage/userGuide.vue'),
    meta: {
      tagtitle: 'Rakumart User Guide - How to use',
    },
  },
    /* 常见问题*/
    {
      path: '/frequently-asked-questions',
      name: 'frequently-asked-questions',
      component: () =>
        import(/* webpackChunkName: "foot" */ '../view/staticpage/frequentlyaskedquestions.vue'),
  },
      /* 服务条款*/
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () =>
      import(/* webpackChunkName: "foot" */ '../view/staticpage/termsofservice.vue'),
    meta: {
      tagtitle: 'Rakumart - General Terms',
      tagdescription: 'Rakumart - General Terms',
    },
  },
    /* 隐私政策*/
    {
      path: '/privacypolicy',
      name: 'privacypolicy',
      component: () =>
        import(/* webpackChunkName: "privacypolicy" */ '../view/staticpage/privacypolicy.vue'),
      meta: {
        tagtitle: 'Rakumart - Privacy Policy',
        tagdescription: 'Rakumart - Privacy Policy',
      },
  },
      // 视频维护列表
  {
    path: '/video-maintenance',
    name: 'videomaintenance',
    component:()=> import ('../view/staticpage/video-maintenance.vue')
  },
        // 视频维护-类型 详情
        {
          path: '/video-maintenance-type',
          name: 'video-maintenance-type',
          component:()=> import ('../view/staticpage/video-maintenance-type.vue')
        },
  // // 404页面
  // {
  //   path: '/:catchAll(.*)',
  //    redirect:'/'
  // },


];
const router = createRouter({
  routes,
  history: createWebHistory(),
});
// // // 循环输出所有路由
// let logPath = () => {
//   // "<url> <loc>" + element2.path + "</loc> <priority>0.8</priority> <lastmod>2022-10-18T02:03:18+00:00</lastmod> <changefreq>Always</changefreq> </url>"
//   let data = []
//   routes.forEach(element1 => {
//     if (element1.meta && (element1.meta.tagTitle || element1.meta.buDaiRakumart || element1.meta.tagDescription)) {
//       data.push({
//         path: element1.path,
//         tagTitle: element1.meta.tagTitle,
//         buDaiRakumart: element1.meta.buDaiRakumart,
//         tagDescription: element1.meta.tagDescription
//       })
//     }

//     if (element1.children && element1.children.length != 0) {
//       element1.children.forEach(element2 => {
//         // data.push(element2.path)
//         if (element2.meta && (element2.meta.tagTitle || element2.meta.buDaiRakumart || element2.meta.tagDescription)) {
//           data.push({
//             path: element2.path,
//             tagTitle: element2.meta.tagTitle,
//             buDaiRakumart: element2.meta.buDaiRakumart,
//             tagDescription: element2.meta.tagDescription
//           })
//         }

//       });
//     }
//   });
//   console.log(data);
// }
// logPath()

let openLogin = function (to) {
  store.commit('changeshowLoginDia', true) //打开登录弹窗
  store.commit('getactivePage', to.fullPath)
}

// 全局路由守卫
router.beforeEach((to, from, next) => {
  // changetag(to)
  // changeDescription(to)


  if (to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限

    if (localStorage.getItem('user_token')) { //判斷是否拥有token
      api.EuropegetUserInfo().then(res => {
        // 登录失效
        if (res.code != 0) {
          // 需要登录并且已经登录但是token失效了就清空数据并打开登录弹窗
          store.commit('userData', null)
          localStorage.removeItem('user_token')
          // 有需要验证的用户中心
          openLogin(to)
          return
        } else {
          //需要登录并且已经登录了token正常的情况下跳转并更新用户信息
          store.commit('userData', res.data) // 用户信息存储
          next()
        }
      })
      // saveUserRequest('https://rakumart.es' + to.fullPath)
    } else {
      // 需要登录但是没有登录的情况下打开弹窗
      openLogin(to)


    }
  } else {
    // 不需要登录的路由直接跳转
    // saveUserRequest('https://rakumart.es' + to.fullPath)
    next();
  }

});
router.afterEach(() => {
  window.scrollTo(0, 0)
})




///////////////////////////////
// router.beforeEach((to, from, next) => {
//   const title = to.meta && to.meta.title;
//   if (title) {
//     document.title = title;
//   }
//   next();
// });
export default router
