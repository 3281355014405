<template>
  <!-- 替换链接 -->

  <van-popup z-index="1000" v-model:show="showBottom" id="replacementofgoods" @close="close" position="bottom">
    <div class="main">
      <!-- 标题 -->
      <div class="top-box">
        <h2>{{ $fanyi('替换链接') }}</h2>
        <van-icon @click="showBottom = false" name="cross" />
      </div>
      <!-- 内容区域 -->
      <div class="shopgoods">
        <div class="shopitem" v-for="goodsitem in   dataslst" :key="goodsitem.id">
          <!-- 店铺标题 -->
          <h2><span>{{ $fanyi('番号') }}</span> ：谦诺文化用品有限公司</h2>
          <div class="Content">
            <input class="checkbox-box" @change="goodscheckboxchange(goodsitem)" type="checkbox"
              v-model="goodsitem.goodschecked" />
            <!-- 图片 -->
            <div class="img-box" @click="
              $fun.toCommodityDetails({
                goods_id: goodsitem.goods_id,
                shop_type: goodsitem.from_platform,
              })
              ">
              <van-image :src="goodsitem.pic">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </div>
            <!--右侧商品信息  -->
            <div class="rightgoodsinfo">
              <!-- 商品描述和删除 -->
              <div class="Description">
                <p class="text">{{ goodsitem.goods_title }}</p>
              </div>

              <div class="Commodityspecification" @click="specificationspopupdom.open(goodsitem, 'cart')">
                <p>{{ goodsitem.text }}</p>
              </div>
              <!-- 价格 -->
              <div class="price">
                <span class="dollar">
                  ${{
                    $fun.EURNumSegmentation(
                      $fun.ceil(goodsitem.price * $store.state.exchangeRate)
                    )
                  }}
                </span>
                <span class="RMB">
                  (￥{{ $fun.RMBNumSegmentation(goodsitem.price) }})
                </span>
              </div>
            </div>
          </div>
          <div class="num">
            <div class="quantity"> {{ $fanyi('数量') }}: <span>{{ goodsitem.num }}</span></div>
            <div class="right">{{ $fanyi('在库数') }} :<span>{{ goodsitem.confirm_num }}</span></div>

          </div>
        </div>
      </div>


    </div>
    <div class="footer">
      <div @click="Chooseacommodity">{{ $fanyi('选择改商品') }}</div>

    </div>
  </van-popup>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose, defineEmits } from 'vue'
import { showToast } from 'vant'
const { proxy } = getCurrentInstance()
const showBottom = ref(false)
const dataslst = ref({})// 详情数据
let id = ref('')




console.log(proxy);
const open = (item) => {
  item.order_detail_replace.forEach(i => {
    i.text = ''

    JSON.parse(i.detail).forEach((detailitem) => {
      i.text += detailitem.key + ":" + " " + detailitem.value + " ";
    });
  })
  dataslst.value = item.order_detail_replace
  showBottom.value = true

}
const emit = defineEmits(['getdata'])
const goodscheckboxchange = (i) => {

  dataslst.value.forEach(item => {
    item.goodschecked = false
    console.log(item.goodschecked);
    if (item.id == i.id) {
      item.goodschecked = !item.goodschecked
      if (item.goodschecked == true) {
        id.value = item.id
      }
    }


  })

}

// 弹窗关闭
const close = () => {

}
const Chooseacommodity = async () => {

  console.log(id.value);
  if (id.value == '') {
    showToast(proxy.$fanyi('请选择商品'))
    return
  }
  const res = await proxy.$api.replaceGoods({
    replace_id: id.value,
  })
  if (res.code != 0) {
    showToast(proxy.$fanyi(res.msg))
    return
  }
  showBottom.value = false
  id.value = ''
  emit('getdata')
}
defineExpose({
  open
})
</script>
<style lang="scss">
#replacementofgoods {
  min-height: 80%;

  max-height: 88%;

  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;



  .top-box {
    position: sticky;
    top: 0px;
    z-index: 2000;
    width: 100%;
    height: 44px;
    background: #F6F6F6;
    border-radius: 8px 8px 0px 0px;

    display: flex;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    align-items: center;

    .van-icon-cross {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      color: #222222;
      font-weight: 600;
      font-size: 16px;

      // top: 14px;

    }
  }

}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

// 选择框样式
.checkbox-box {
  width: 12px;
  height: 12px;
  padding: 0px;
  margin: 0px;
  border-radius: 2px;
  border: 1px solid #dedede;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;
}

input[type="checkbox"]:checked {
  background: #ff730b;
  border-color: #ff730b;
  border: 1px solid#ff730b;
}

input[type="checkbox"]::before {
  position: absolute;
  top: -2px;
  left: -1px;
  content: "\2713";
  /* Unicode编码，表示对号 */
  display: inline-block;

  width: 12px;
  height: 12px;

  text-align: center;
  font-size: 14px;
  opacity: 0;
  color: #ffffff;
  /* 对号的颜色 */
  font-weight: bold;
  transform: scale(0.8, 0.5);
  /* 缩放 */
  transition: transform 0.2s ease-in-out;
  /* 缩放过渡效果 */
}

input[type="checkbox"]:checked::before {
  opacity: 1;
}

.main {
  width: 100%;
  height: 100%;
  padding-bottom: 100px;

  .shopgoods {
    width: 100%;
    height: 100%;
    padding: 10px;

    .shopitem {
      width: 100%;
      min-height: 171px;
      background: #FFFFFF;
      border-radius: 6px;
      border: 0.5px solid #E2E2E2;
      padding: 15px;

      h2 {
        height: 18px;
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;
        margin-bottom: 15px;
      }
    }

    .Content {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;

      margin-bottom: 10px;

      .img-box {
        width: 80px;
        height: 80px;
        margin-right: 10px;
        border-radius: 4px;

        :deep().van-image {
          width: 80px;
          height: 80px;
          border-radius: 4px;
        }

        :deep().van-image__img {
          border-radius: 4px;
        }
      }

      .rightgoodsinfo {
        flex: 1;
        height: 100%;

        // 描述
        .Description {
          height: 32px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;

          align-items: center;

          .text {
            height: 32px;
            font-size: 12px;
            font-family: ArialMT;
            color: #222222;
            line-height: 16px;
            padding-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          img {
            width: 24px;
            height: 24px;
            margin-left: 10px;
          }
        }

        .Commodityspecification {
          padding: 0 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 200px;
          height: 20px;
          background: #f6f6f6;
          border-radius: 4px;
          margin-bottom: 5px;

          p {
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // zoom: 0.9;
            font-size: 12px;
            font-family: ArialMT;
            color: #666666;
            line-height: 22px;
            zoom: 0.9;
          }

          img {
            margin-left: 10px;
            width: 8px;
            height: 4px;
          }
        }

        .price {
          height: 18px;
          font-size: 12px;
          font-family: ArialMT;
          color: #222222;
          line-height: 18px;

          .dollar,
          .RMB {
            font-size: 12px;
            font-family: ArialMT;
            color: #222222;
            line-height: 18px;
          }
        }
      }
    }

    .num {
      display: flex;
      justify-content: space-between;

      .quantity {
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;

        span {
          font-size: 12px;
          font-family: Arial-BoldMT,
            Arial;
          font-weight: normal;
          color: #222222;
          line-height: 18px;
          font-weight: 600;
          margin-left: 2px;
        }

      }

      .right {
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;

        span {
          font-size: 12px;
          font-family: Arial-BoldMT,
            Arial;
          font-weight: normal;
          color: #222222;
          line-height: 18px;
          font-weight: 600;
          margin-left: 2px;
        }
      }
    }
  }


}

.footer {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  position: fixed;
  bottom: 0;

  width: 100%;
  height: 88px;
  background: #FFFFFF;
  box-shadow: inset 0px 0.5px 0px 0px #E2E2E2;

  div {
    width: 335px;
    height: 48px;
    background: #FF730B;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 16px;
  }
}
</style>