<template>
  <!-- 商品规格 -->
  <div class="Commodityspecification" @click="showBottom = true">
    <div class="lift-title">
      {{ $fanyi('商品详细') }}

    </div>
    <div class="text">{{ $parent.text }}</div>
    <img src="../../../assets/productDetails/right.svg" alt="">
  </div>
  <!-- 底部弹出商品规格 -->
  <van-popup z-index="50" closeable v-model:show="showBottom" id="Commodityspecification" position="bottom">
    <!-- 商品标题 -->
    <div class="shoptitle">

      {{ props.details.title }}
    </div>
    <!-- 商品规格 -->
    <div> <!-- 循环每一项 -->
      <div class="Specifications">

        <div class="Specificationsitem" v-for="(key, value) in props.details.goodsInfo.specification" :key='key'>
          <div class="left-title">{{ RemoveDoublequotationmarks(value) }}</div>

          <div class="listitem">
            <div class="item" @click="getcolor(value, ind, item)" :class="{ itemcolor: item.check }"
              v-for="(item, ind ) in key" :key="ind">
              <van-image v-if="item.picUrl" lazy-load :src="item.picUrl">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              <span class="Description">{{ item.name }}</span>


            </div>
          </div>

        </div>


      </div>
    </div>
    <!-- 单价和库存  -->
    <div class="Unitprice-box">
      <!-- 单价 -->
      <div class="Unitprice">

        {{ $fanyi('单价') }}:
        <span>${{ $fun.EURNumSegmentation(
          $fun.ceil(
            props.details.Unitprice * $store.state.exchangeRate
          )
        ) }}</span>
      </div>
      <!-- 库存 -->
      <div class="Inventory">

        {{ $fanyi('In the library for several') }}:
        <span> {{ props.details.amountOnSale }}</span>
      </div>

    </div>
    <!-- 选择框 -->
    <div class="input-box">
      <div class="left-title">
        {{ $fanyi('数量') }}
      </div>
      <div class="right-input">
        <van-stepper @change="changeinput" v-model="inputvalue" :min="0" :max="props.details.amountOnSale" integer />
      </div>

    </div>



  </van-popup>
</template>
<script setup>
import { defineProps, ref, defineEmits, defineExpose } from 'vue'


const showBottom = ref(false)// 控制弹窗显示
const inputvalue = ref(0)// 商品输入框数量
const props = defineProps({
  details: {
    type: Object,
    default: () => { }

  }
})

const emit = defineEmits(['getcolor', 'getprice'])
// 点击切换规格 传回父组件修改数据
const getcolor = (key, ind, item) => {

  emit('getcolor', key, ind, item)
  emit('getprice', inputvalue.value)
}
// 去除上双引号
const RemoveDoublequotationmarks = (value) => {
  value = value.replace(/^"|"$/g, '')
  return value
}
// 输入框的值发生变化
const changeinput = () => {

  emit('getprice', inputvalue.value)


}

defineExpose({
  inputvalue
})
</script>
<style lang="scss">
#Commodityspecification {
  width: 375px;
  min-height: 474px;
  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
  padding-top: 44px;
  padding-bottom: 124px;

  .shoptitle {
    width: 100%;
    padding: 0 20px;
    height: 40px;
    font-size: 14px;
    font-family: ArialMT;
    color: #222222;
    line-height: 20px;
    margin-bottom: 30px;
    height: 40px;
    font-size: 14px;
    font-family: ArialMT;
    color: #222222;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .van-icon-cross {
    color: #333;
    font-weight: 500;
  }

  p {
    width: 100%;
    padding: 0 20px;
    margin: 20px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {

      &:first-child {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-right: 5px;


      }

      &:last-child {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-family: ArialMT;
        color: #222222;
        line-height: 18px;
      }
    }
  }

  // 规格
  .Specifications {

    width: 100%;

    .Specificationsitem {
      height: 40px;
      margin-bottom: 30px;
      padding-left: 20px;
      display: flex;
      overflow-x: scroll;
      // overflow-y: hidden;
      white-space: nowrap;

      .left-title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 12px;
        font-family: ArialMT;
        color: #666666;
        line-height: 18px;
        margin-right: 22px;
      }

      .listitem {
        display: flex;
        overflow-x: scroll;
        // overflow-y: hidden;
        white-space: nowrap;

        .item {
          height: 40px;

          padding: 0 10px;
          background: #FFFFFF;
          border-radius: 4px;
          border: 0.5px solid #E2E2E2;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;



          .van-image {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }


          .Description {

            font-size: 12px;
            font-family: ArialMT;
            color: #666666;
            line-height: 18px;
          }

        }

        .itemcolor {
          border: 1px solid #FF730B;

          .Description {
            color: #FF730B !important;
          }

        }

      }

    }


  }

  // 单价
  .Unitprice-box {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    margin-bottom: 30px;

    .Unitprice {
      font-size: 12px;
      font-family: ArialMT;
      color: #666666;
      line-height: 18px;

      span {
        font-size: 12px;
        font-family: Arial-BoldMT, Arial;
        font-weight: 600;
        color: #666666;
        margin-left: 2px;
      }
    }

    .Inventory {
      font-size: 12px;
      font-family: ArialMT;
      color: #666666;
      line-height: 18px;

      span {
        font-size: 12px;
        font-family: ArialMT;
        color: #666666;
        line-height: 18px;
        margin-left: 2px;
      }
    }
  }

  // 输入框
  .input-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .left-title {
      height: 100%;
      display: flex;
      font-size: 12px;
      font-family: ArialMT;
      color: #666666;
      line-height: 18px;
      align-items: center;
    }

    .right-input {
      width: 100px;
      height: 30px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #222222;
      display: flex;
      align-items: center;
      justify-content: center;

      .van-haptics-feedback {
        width: 22px;
        height: 22px;
        background: #F2F2F2;
        border-radius: 2px;
      }

      .van-stepper__minus--disabled {
        width: 22px;
        height: 22px;
        background: #F2F2F2;
        border-radius: 2px;

      }

      .van-stepper {
        height: 22px;
        display: flex;
        align-items: center;
      }

      .van-stepper__input {
        width: 40px;
        height: 16px;
        font-size: 12px;

        font-family: ArialMT;
        background-color: #fff;
      }
    }
  }
}
</style>
<style lang='scss' scoped='scoped'>
@import "@/style/scss/mixin.scss";

.Commodityspecification {
  position: relative;
  width: 355px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 0.5px solid #E2E2E2;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  align-items: center;
  margin-bottom: 10px;

  .lift-title {
    font-size: 12px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #222222;
    line-height: 18px;
  }

  img {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;

  }

  .text {
    max-width: 220px;
    padding-right: 15px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

}
</style>